import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { TypeCell } from "@/pages/banners/preferences/TypeCell";
import TrashIconButton from "@/components/icons/TrashIconButton";
import TableSort from "@/pages/browser-defender/components/TableSort";
import ModalAddSafeList from "@/pages/banners/preferences/ModalAddSafeList";
import { transformToSafeContact } from "@/pages/banners/remote-apis/bannersCompanies";
import DeleteSafeItemsConfirmationDialog from "@/pages/banners/preferences/DeleteSafeItemsConfirmationDialog";
import AddIcon from "@mui/icons-material/Add";
import CustomTable, {
  HeaderFilterField,
  HeadersExtra,
} from "@/pages/banners/preferences/components/CustomTable";
import { ContactType } from "@/pages/banners/preferences/state";

const headers = [
  { label: "Item", alignment: "left" as const },
  { label: "Type", alignment: "left" as const },
  { label: "Added By", alignment: "left" as const },
  { label: "Added to list", alignment: "left" as const },
  { label: "Action", alignment: "left" as const },
];

const sortingColumns = [
  { label: "Item", name: "item" },
  { label: "Added By", name: "added_by" },
  { label: "Added to list", name: "added_at" },
];

const filterColumns = ["item", "added_by", "added_at"];

export default function DataSafesListTable({
  data,
  setData,
  setOpenSuccessSnackBar,
  fetchSafeContacts,
  contactType,
}) {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmationOpenAddItems, setConfirmationOpenAddItems] =
    useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);

  const handleDelete = ({ row }) => {
    setItemToDelete(
      transformToSafeContact(row.item, row.type, row.added_at, row.added_by),
    );
    setDeleteConfirmationDialogOpen(true);
  };
  const handleAddItems = (event) => {
    setConfirmationOpenAddItems(true);
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#7567FE",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlinedPrimary: {
            color: "#061123",
            marginLeft: "10px",
            borderColor: "#0611233A",
          },
        },
      },
    },
  });

  function AddItemsValue() {
    if (contactType === ContactType.email_address) {
      return "Add safe senders";
    }
    return "Add safe domains";
  }
  const AddItems = () => {
    return (
      <div style={{ float: "right" }}>
        <ThemeProvider theme={theme}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={(e) => {
              handleAddItems(e);
            }}
          >
            {" "}
            {AddItemsValue()}
          </Button>
        </ThemeProvider>
      </div>
    );
  };

  useEffect(() => {
    let filteredValues = [...data.values];
    if (searchTerm) {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          let value = row[attribute];
          if (attribute === "added_at") {
            value = value.toLocaleDateString();
          } else {
            try {
              value = row[attribute].toLowerCase();
            } catch {
              value = row[attribute];
            }
          }
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    if (sorting) {
      let condition = 1;
      filteredValues.sort((p1, p2) => {
        for (let sortRule of sorting) {
          if (p1[sortRule.column] < p2[sortRule.column]) {
            return -1 * sortRule.direction;
          } else {
            return 1 * sortRule.direction;
          }
        }
        return condition;
      });
    }

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [data.values, searchTerm, sorting, setData]);

  return (
    <>
      <CustomTable
        headers={headers}
        HeadersExtra={
          <HeadersExtra>
            <HeaderFilterField
              setPage={setPage}
              setSearchTerm={setSearchTerm}
            />
            <TableSort
              setOrderBy={setSorting}
              columns={sortingColumns}
              defaultOrder={[{ column: "item", direction: 1 }]}
            />
            <AddItems />
          </HeadersExtra>
        }
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={data.filteredValues.map((row, index) => {
          return (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                width={"20%"}
                component="th"
                scope="row"
                align={headers[0].alignment}
              >
                {row.item}
              </TableCell>
              <TableCell align={headers[1].alignment}>
                <TypeCell value={row.type} />
              </TableCell>
              <TableCell align={headers[2].alignment}>{row.added_by}</TableCell>
              <TableCell align={headers[3].alignment}>
                {dayjs(row.added_at).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align={headers[4].alignment}>
                <TrashIconButton
                  onClick={() => {
                    handleDelete({ row });
                  }}
                />
              </TableCell>

              <TableCell>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                ></Grid>
              </TableCell>
            </TableRow>
          );
        })}
      />
      <ModalAddSafeList
        confirmationDialogOpen={confirmationOpenAddItems}
        setConfirmationDialogOpen={setConfirmationOpenAddItems}
        fetchSafeContacts={fetchSafeContacts}
        contactType={contactType}
        setOpenSuccessSnackBar={setOpenSuccessSnackBar}
      />
      <DeleteSafeItemsConfirmationDialog
        target={itemToDelete}
        confirmationDialogOpen={deleteConfirmationDialogOpen}
        setConfirmationDialogOpen={setDeleteConfirmationDialogOpen}
        fetchSafeContacts={fetchSafeContacts}
      />
    </>
  );
}
