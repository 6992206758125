import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from "@mui/material";

export default function CustomDialog({
  callToAction = null,
  callToActionText,
  actionButtonText,
  actionButtonCallback,
  dialogTitle,
  children,
  cancelButtonText = "Cancel",
  maxWidth = "sm",
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={callToActionText}>
        <Button
          onClick={handleClickOpen}
          variant={callToAction ? "text" : "outlined"}
          style={{ marginBottom: callToAction ? 0 : "1rem" }}
        >
          {callToAction ? callToAction : callToActionText}
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={maxWidth}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            formData.set(
              "user_can_disable_extension",
              formData.get("user_can_disable_extension") ? true : false,
            );

            formData.set(
              "downloads_protection_policy_id",
              formData.get("downloads_protection_policy_id") || 0,
            );

            const formJson = Object.fromEntries(formData.entries());
            actionButtonCallback(formJson);
            handleClose();
          },
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            {actionButtonText}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            {cancelButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
