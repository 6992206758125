import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";

const BeamerNotificationBell = () => {
  return (
    <div
      id="beamer-notification-bell"
      style={{
        padding: "0.5rem",
        backgroundColor: "#142947",
        borderRadius: "50%",
        border: "1px solid #2c3545",
      }}
    >
      <NotificationsIcon
        sx={{ fill: "white", transform: "scaleX(1.15) translateY(-1px)" }}
        viewBox="0 0 24 24"
      />
    </div>
  );
};

export default BeamerNotificationBell;
