import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { sentryShouldIgnoreExtension } from "@/utils/sentry";
import { useAuth0 } from "@auth0/auth0-react";

const isLocalEnv = import.meta.env.DEV;

const SentryInit = (props: PropsWithChildren): ReactNode => {
  const [isSentryStarted, setIsSentryStarted] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    if (!isLocalEnv && !isSentryStarted) {
      Sentry.init({
        dsn: "https://aa38ae0dbc1d42ba8247872f43d5ac85@o401641.ingest.sentry.io/5261681",
        integrations: [new BrowserTracing()],
        release: import.meta.env.VITE_APP_CI_COMMIT_SHA || "Manual",
        environment: import.meta.env.VITE_APP_ENVIRONMENT,
        attachStacktrace: true, // doc: Attaches stacktraces to pure capture message / log integrations
        beforeSend: (event, hint) => {
          const exception = hint.originalException;
          if (sentryShouldIgnoreExtension(exception as Error)) {
            return undefined;
          }

          return event;
        },
      });
      setIsSentryStarted(true);
    }
    if (!isLocalEnv && isSentryStarted && user != null) {
      Sentry.setUser({ email: user.email, username: user.name });
    }
  }, [isSentryStarted, setIsSentryStarted, user]);

  return <>{props.children}</>;
};

export default SentryInit;
