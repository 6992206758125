import React from "react";
import Header from "./header/Header";
import EmailHeaders from "@/pages/threatsng/components/detailview/email_headers/EmailHeaders";
import Overview from "./overview/Overview";
import ThreatDeleteConfirmationDialog from "@/pages/threatsng/components/dialog/ThreatDeleteConfirmationDialog";
import ThreatFeedbackDialog from "@/pages/threatsng/components/dialog/ThreatFeedbackDialog";
import { Box, Card, CardHeader, CardContent } from "@mui/material";
import BoxTitleBar from "./box_title_bar/BoxTitleBar";

type DetailViewProps = {
  threatDetails: ThreatDetails;
  onClose: () => void;
  onPrevious: () => void;
  onNext: () => void;
  onDelete: () => void;
  isThreatArchived: boolean;
  hasPrevious: () => boolean;
  hasNext: () => boolean;
  onFeedback: (feedbackType: FeedbackType, comment: string) => void;
  onUnarchive: () => void;
};

const DetailView = ({
  threatDetails,
  onClose,
  onPrevious,
  onNext,
  isThreatArchived,
  onDelete,
  hasNext,
  hasPrevious,
  onFeedback,
  onUnarchive,
}: DetailViewProps) => {
  const [showHeaders, setShowHeaders] = React.useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState<boolean>(false);
  const [confirmFeedbackType, setConfirmFeedbackType] =
    React.useState<FeedbackType | null>(null);

  const handleTabChange = (tab: ThreatDetailsTab) => {
    switch (tab) {
      case "overview":
        setShowHeaders(false);
        break;
      case "headers":
        setShowHeaders(true);
        break;
      default:
        throw new Error(`Invalid tab: '${tab}'`);
    }
  };

  return (
    <>
      <Header
        onTabChange={handleTabChange}
        onClose={onClose}
        onPrevious={onPrevious}
        onNext={onNext}
        onDelete={() => setShowDeleteConfirmation(true)}
        isThreatArchived={isThreatArchived}
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        onFeedback={(feedbackType: FeedbackType) =>
          setConfirmFeedbackType(feedbackType)
        }
        onUnarchive={onUnarchive}
      />

      <Box sx={{ pt: 3 }}>
        <Card>
          <CardHeader
            title={
              <BoxTitleBar
                threatDetails={threatDetails}
                isThreatArchived={isThreatArchived}
              />
            }
          />
          <CardContent style={{ padding: "auto" }}>
            {showHeaders ? (
              <EmailHeaders headers={threatDetails.headers} />
            ) : (
              <Overview threatDetails={threatDetails} />
            )}
          </CardContent>
        </Card>
      </Box>

      {showDeleteConfirmation && (
        <ThreatDeleteConfirmationDialog
          onDelete={() => {
            onDelete();
            setShowDeleteConfirmation(false);
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
          threats={[
            {
              id: threatDetails.id,
              subject: threatDetails.subject,
              report_date: threatDetails.report_date,
            },
          ]}
        />
      )}
      {confirmFeedbackType && (
        <ThreatFeedbackDialog
          onConfirm={(feedbackType: FeedbackType, comment?: string) => {
            onFeedback(feedbackType, comment);
            setConfirmFeedbackType(null);
          }}
          onCancel={() => setConfirmFeedbackType(null)}
          action={confirmFeedbackType}
          threatCount={1}
        />
      )}
    </>
  );
};

export default DetailView;
