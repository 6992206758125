import { styled } from "@mui/material";
import TableRow from "@mui/material/TableRow";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-body": {
    fontSize: "1rem",
    fontWeight: "400",
    color: "#061123",
    verticalAlign: "center",
  },
}));

export default StyledTableRow;
