import { Box, LinearProgress } from "@mui/material";
import { debounce } from "lodash";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserDataTable } from "@/pages/general-settings/UsersManagement/usersPanel/UserDataTable";
import { Link } from "react-router-dom";
import { BlackTextLink } from "@/utils/styled-components";
import axios from "axios";
import { sentryCaptureException } from "@/utils/sentry";

interface Data {
  users: any[];
  total_count: number;
}

interface FiltersList {
  departments: any[];
  languages: any[];
  locations: any[];
}
function usersUrl(
  sort,
  page,
  per_page,
  activeUsersOnly,
  search = null,
  filters = {},
) {
  let endpoint_user_url = "users";
  let urlParams = new URLSearchParams();
  urlParams.append("page_number", String(Number(page) + 1)); // datatable count page starting with 0 but django Paginator starts with 1.
  urlParams.append("per_page", String(per_page));
  urlParams.append("sort", sort ?? "lastname");
  urlParams.append("active_only", activeUsersOnly ? "true" : "false");
  urlParams.append("email_details", "false");
  urlParams.append("only_has_sent_messages", "false");
  if (search != null && search !== "") {
    urlParams.append("search", search);
  }
  for (let [k, v] of Object.entries(filters)) {
    urlParams.append(`filter__${k}`, v as string);
  }
  if (urlParams.toString() !== "") {
    endpoint_user_url = `${endpoint_user_url}?${urlParams.toString()}`;
  }
  return endpoint_user_url;
}

export function UserList({ activeUsersOnly }) {
  const [sortingColumn, setSortingColumn] = useState("lastname");
  const [searchTerm, setSearchTerm] = useState(null);
  const [data, setData] = useState<Data>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState({});
  const { getAccessTokenSilently } = useAuth0();
  const [filtersList, setFiltersList] = useState<FiltersList>({
    departments: [],
    languages: [],
    locations: [],
  });
  const columns1 = useMemo(
    () => [
      {
        name: "id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
          searchable: true,
        },
      },
      {
        name: "firstname",
        label: "Firstname",
        options: {
          filter: false,
          sort: true,
          searchable: true,
          customBodyRender: (value, tableMeta) => (
            <BlackTextLink to={{ pathname: `/users/${tableMeta.rowData[0]}` }}>
              {value}
            </BlackTextLink>
          ),
        },
      },
      {
        name: "lastname",
        label: "Lastname",
        options: {
          filter: false,
          sort: true,
          searchable: true,
          customBodyRender: (value, tableMeta) => (
            <BlackTextLink to={{ pathname: `/users/${tableMeta.rowData[0]}` }}>
              {value}
            </BlackTextLink>
          ),
        },
      },
      {
        name: "email",
        label: "EMAIL", // is not displayed in upper case automatically because sort=false
        options: {
          filter: false,
          sort: false,
          searchable: true,
          customBodyRender: (value, tableMeta) => (
            <Link to={{ pathname: `/users/${tableMeta.rowData[0]}` }}>
              {value}
            </Link>
          ),
        },
      },
      {
        name: "language_code",
        label: "Language",
        options: {
          filter: true,
          filterOptions: {
            names: filtersList.languages ?? [],
          },
          sort: true,
          searchable: false,
        },
      },
      {
        name: "departments",
        label: "Departments",
        options: {
          filter: true,
          filterOptions: {
            names: filtersList.departments ?? [],
          },
          sort: false,
          searchable: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (value || []).join(", ");
          },
        },
      },
      {
        name: "location",
        label: "Location",
        options: {
          filter: true,
          filterOptions: {
            names: filtersList.locations ?? [],
          },
          sort: true,
          searchable: false,
        },
      },
      {
        name: "active",
        label: "Status",
        options: {
          filter: false,
          sort: true,
          searchable: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{value ? "Active" : "Deactivated"}</div>;
          },
        },
      },
    ],
    [filtersList.languages, filtersList.locations, filtersList.departments],
  );

  const fetchUsersData = useCallback(
    async (url) => {
      try {
        setError(null);
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently();
        const resource = await axios.get(
          `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${url}`,
          { headers: { Authorization: `Bearer ${accessToken}` } },
        );
        setIsLoading(false);
        setData(resource.data);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently],
  );

  const fetchFilterList = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const resource = await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/users/filters`,
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );
      setFiltersList(resource.data);
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  const updateSearchItemAndPage = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setPage, setSearchTerm],
  );

  // memoize the debounce call with useMemo
  const debouncedUpdate = useMemo(() => {
    return debounce(updateSearchItemAndPage, 1000);
  }, [updateSearchItemAndPage]);

  const options = useMemo(
    () => ({
      download: false,
      print: false,
      serverSide: true,
      count: count,
      rowsPerPageOptions: [10, 50, 100, 500],
      selectableRows: "none",
      onSearchChange: (searchText) => {
        debouncedUpdate(searchText);
      },
      onColumnSortChange: (changedColumn, direction) => {
        setSortingColumn((direction === "desc" ? "-" : "") + changedColumn);
      },
      onChangePage: (page) => {
        setPage(page);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        setRowsPerPage(numberOfRows);
      },
      onFilterChange: (_, filterList) => {
        if (columns.length <= 0) {
          console.error("no columns available");
          return;
        }
        const currentFilters = {};
        for (let i = 0; i < filterList.length; i++) {
          if (filterList[i].length > 0) {
            currentFilters[columns[i].name] = filterList[i][0];
          }
        }
        setFilters(currentFilters);
      },
    }),
    [count, debouncedUpdate, columns],
  );

  useEffect(() => {
    fetchFilterList();
  }, [fetchFilterList]);

  useEffect(() => {
    setCount(data?.total_count);
    setColumns([...columns1]);
  }, [data, columns1]);

  useEffect(() => {
    fetchUsersData(
      usersUrl(
        sortingColumn,
        page,
        rowsPerPage,
        activeUsersOnly,
        searchTerm,
        filters,
      ),
    );
  }, [
    sortingColumn,
    page,
    searchTerm,
    filters,
    activeUsersOnly,
    fetchUsersData,
    rowsPerPage,
  ]);

  if (error != null) {
    return <div>An error has occurred</div>;
  } else if (isLoading) {
    return (
      <div>
        <Box height={"10px"}>
          <LinearProgress />
        </Box>
      </div>
    );
  }
  return (
    <div>
      <UserDataTable
        users={data?.users ?? []}
        options={options}
        columns={columns}
      />
    </div>
  );
}
