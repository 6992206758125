import { Portlet, PortletBody } from "@/components/content/Portlet";
import { HeaderBox } from "@/utils/styled-components";
import { color } from "chart.js/helpers";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { BackendSuspense } from "@/pages/awareness/dashboard/BackendSuspense";
import { ToggleButtonGroup } from "@mui/material";
import { MantraToggleButton } from "@/components/controls/ToggleButton/ToggleButton";
import { Chart as ChartJS, Filler, PointElement, LineElement } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(PointElement, LineElement, Filler);

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

const formatData = (data, isMonthly) => {
  if (!data) {
    return [];
  }
  const format = isMonthly ? "MM/YYYY" : "DD/MM/YYYY";
  const courseCount = data.course_count;
  const chartFormattedData = [];
  for (let el of data.course_completion_rundown) {
    chartFormattedData.push({
      x: dayjs(el.date).format(format),
      y:
        courseCount > 0
          ? Math.round((el.completed_courses / courseCount) * 100)
          : 0,
      count: el.completed_courses,
    });
  }
  return chartFormattedData;
};

const CourseRundownChart = ({ data, isMonthly }) => {
  const brandColor = "#2c77f4";
  const shape2Color = "#afb4d4";
  const shape3Color = "#646c9a";

  const chartData = {
    datasets: [
      {
        fill: true,
        data: formatData(data, isMonthly),
        backgroundColor: function (context) {
          const ctx = context.chart.ctx;
          const gradient = isMonthly
            ? ctx.createLinearGradient(0, 0, 0, 225)
            : ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(128, 173, 248, 1)");
          gradient.addColorStop(1, "rgba(128, 173, 248, 0)");
          return gradient;
        },
        pointBackgroundColor: color(brandColor).alpha(0.6).rgbString(),
        borderColor: shape3Color,
        tension: 0.1,
        spanGaps: true,
        cubicInterpolationMode: "monotone",
        barPercentage: 0.7,
        pointRadius: 4,
        pointHitRadius: 20,
        pointHoverRadius: 10,
        pointBorderColor: "white",
        borderWidth: 1,
        categoryPercentage: 0.35,
      },
    ],
  };

  const chartConfig = {
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        grid: {
          color: shape2Color,
          offset: false,
          drawTicks: false,
          borderDash: [3, 4],
          zeroLineWidth: 1,
          zeroLineColor: shape2Color,
          zeroLineBorderDash: [3, 4],
        },
        ticks: {
          stepSize: 5,
          display: true,
          fontColor: shape3Color,
          fontSize: 13,
          padding: 10,
          callback: function (value) {
            return value + " %";
          },
        },
      },
      x: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          footer: (ctx) => ctx[0]?.raw?.count + " courses completed",
          label: (ctx) => ctx.formattedValue + "%",
        },
      },
    },
  };

  return <Line data={chartData} options={chartConfig} />;
};

export const CourseRundown = ({
  className,
  changeRundownChartMode,
  data,
  error,
  isPending,
  isMonthly,
}) => {
  return (
    <Portlet className={className}>
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            Percentage of completed courses
          </h3>
        </div>
        <HeaderBox>
          <ToggleButtonGroup
            id="isMonthlyCheckbox"
            title="Weekly/Monthly toggle"
            onChange={changeRundownChartMode}
            exclusive
            color="primary"
            size="small"
            value={isMonthly}
          >
            <MantraToggleButton sx={{ lineHeight: "0.75" }} value={true}>
              Monthly
            </MantraToggleButton>
            <MantraToggleButton sx={{ lineHeight: "0.75" }} value={false}>
              Weekly
            </MantraToggleButton>
          </ToggleButtonGroup>
        </HeaderBox>
      </div>
      <PortletBody>
        <div className="d-flex justify-content-center align-items-center">
          <BackendSuspense
            data={data}
            error={error}
            isPending={isPending}
            hasData={data?.course_completion_rundown?.length > 0}
          >
            <CourseRundownChart data={data} isMonthly={isMonthly} />
          </BackendSuspense>
        </div>
      </PortletBody>
    </Portlet>
  );
};
