import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { sentryCaptureException } from "@/utils/sentry";
import StickyHeader from "@/components/StickyHeader";
import { useUserContext } from "@/utils/contexts/UserContext";
import TabPanelHeader from "@/pages/browser-defender/components/TabPanelHeader";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import DataTableDemo from "@/pages/browser-defender/demo/passwordMisuse/DataTable";
import ButtonWithDropdown from "@/pages/browser-defender/passwordMisuse/ButtonWithDropdown";

export function PasswordMisuseDemo() {
  const [buttonStatus, setLabelSelectionStatus] = useState({
    archive: false,
    label: false,
    selectedRows: [],
  });
  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const [currentTab, setCurrentTab] = useState("unarchived");
  const [logsData, setLogsData] = useState({
    values: [],
    filteredValues: [],
    loading: true,
  });

  const fetchLogsData = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = new URL(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/demo/actionable_logs/password_misuse/unarchived`,
      );

      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      setLogsData({
        values: resource.data,
        filteredValues: resource.data,
        loading: false,
      });
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchLogsData();
  }, [fetchLogsData, info_user]);

  async function setDomainType(label, action) {
    const data = logsData.filteredValues.map((item) => {
      if (buttonStatus.selectedRows.indexOf(item.id) > -1) {
        item.company_domain_label = label;
      }
      return item;
    });
    if (action === "no_action") {
      setLogsData({
        values: logsData.values,
        filteredValues: data,
        loading: false,
      });
      return;
    }

    handleAlerts("archive", action);
  }

  async function handleAlerts(label, action) {
    const rows = logsData.filteredValues.filter(
      (item) => buttonStatus.selectedRows.indexOf(item.id) > -1,
    );

    const domains = rows.map((item) => item.domain);
    const emails = rows.map((item) => item.email);

    let data;
    if (action === "archive_selected") {
      data = logsData.values.map((item) => {
        if (buttonStatus.selectedRows.includes(item.id)) {
          item.archived = label === "archive" ? "archived" : "unarchived";
        }
        return item;
      });
    } else if (action === "archive_similar_by_user_and_domain") {
      data = logsData.values.map((item) => {
        if (
          domains.includes(item.domain) &&
          emails.includes(item.email) &&
          item.archive !== label
        ) {
          item.archived = label === "archive" ? "archived" : "unarchived";
        }
        return item;
      });
    } else if (action === "archive_similar_by_domain") {
      data = logsData.values.map((item) => {
        if (domains.includes(item.domain) && item.archive !== label) {
          item.archived = label === "archive" ? "archived" : "unarchived";
        }
        return item;
      });
    }

    setLogsData({
      values: logsData.values,
      filteredValues: data,
      loading: false,
    });
  }

  function selectionCounts() {
    const selectedRows = logsData.filteredValues.filter(
      (item) => buttonStatus.selectedRows.indexOf(item.id) > -1,
    );

    const selectedDomains = selectedRows.map((item) => item.domain);
    const selectedUsers = selectedRows.map((item) => item.email);

    const similarByDomain = logsData.filteredValues.filter((item) =>
      selectedDomains.includes(item.domain),
    );
    const similarByDomainAndUser = logsData.filteredValues.filter(
      (item) =>
        selectedDomains.includes(item.domain) &&
        selectedUsers.includes(item.email),
    );

    return {
      selectedRows: selectedRows.length,
      similarByDomain: similarByDomain.length,
      similarByDomainAndUser: similarByDomainAndUser.length,
    };
  }

  return (
    <Box>
      <StickyHeader>
        <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
          <Typography
            variant="h2"
            style={{
              marginRight: "auto",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Password Alerts
          </Typography>
          <ButtonWithDropdown
            archivePossible={buttonStatus.archive}
            labelActionPossible={buttonStatus.label}
            labelDomains={setDomainType}
            handleAlerts={handleAlerts}
            currentTab={currentTab}
            selectionCounts={selectionCounts()}
          />
        </Stack>
      </StickyHeader>
      <TabPanelHeader
        tabsHeaders={[
          {
            label: "Unarchived",
            key: "unarchived",
          },
          { label: "Archived", key: "archived" },
        ]}
        tabsContents={["unarchived", "archived"].map((item) => {
          return (
            <GenericCard title={""} isLoading={logsData.loading}>
              <DataTableDemo
                data={logsData}
                setData={setLogsData}
                setLabelSelectionStatus={setLabelSelectionStatus}
                dataType={item}
              />
            </GenericCard>
          );
        })}
        setCurrentTab={setCurrentTab}
      />
    </Box>
  );
}
