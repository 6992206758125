import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSetupChecklistState } from "../SetupChecklistSlice";

const TestDelivery: React.FC = () => {
  const { has_confirmed_all_delivery_tests: hasConfirmedAllDeliveryTests } =
    useSelector(selectSetupChecklistState);

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        Check that users can follow through simulations
      </Typography>
      <Typography>
        You need ensure that emails are received correctly and that users can
        follow simulations correctly.
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link to="/phishing-simulation/setup#delivery-test">
          <Button variant="contained">Test Simulation Delivery</Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          {hasConfirmedAllDeliveryTests ? (
            <>
              <CheckCircleOutlinedIcon color="success" />
              <Typography variant="body_small" color="#2e7d32">
                It works!
              </Typography>
            </>
          ) : (
            <>
              <WarningAmberOutlinedIcon sx={{ fill: "#fe9534" }} />
              <Typography color="#fe9534">
                some simulations have not been tested properly
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TestDelivery;
