import { ConfirmationDialog } from "@/components/modals/ConfirmationDialog";
import { Typography } from "@mui/material";
import React from "react";

export type ThreatDeleteInfo = {
  id: number;
  subject: string;
  report_date: string;
};

type ThreatDeleteConfirmationDialogProps = {
  onDelete: () => void;
  onCancel: () => void;
  threats: ThreatDeleteInfo[];
};

const ThreatDeleteConfirmationDialog = ({
  onDelete,
  onCancel,
  threats,
}: ThreatDeleteConfirmationDialogProps) => {
  return (
    <ConfirmationDialog
      isShown={threats.length > 0}
      onConfirm={onDelete}
      isLoading={false}
      disabled={false}
      onConfirmText="Delete"
      onCancel={onCancel}
      onCancelText="Cancel"
      title={
        threats.length > 1 ? (
          <span>
            Do you really want to delete those <b>{threats.length}</b> threats?
          </span>
        ) : (
          <div>Do you really want to delete this threat?</div>
        )
      }
    >
      <div
        style={{
          overflowY: "scroll",
          maxHeight: "100px",
          maxWidth: "500px",
        }}
      >
        {threats.map((el) => (
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            key={el.subject + el.report_date + el.id}
          >
            <Typography
              sx={{
                color: "#5012FF",
                fontWeight: 500,
                maxWidth: "225px",
              }}
              noWrap
            >
              {el.subject}
            </Typography>
            <div style={{ fontWeight: "400" }}>
              {new Date(Date.parse(el.report_date)).toLocaleTimeString(
                undefined,
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                },
              )}
            </div>
          </div>
        ))}
      </div>
    </ConfirmationDialog>
  );
};

export default ThreatDeleteConfirmationDialog;
