import React, { useMemo, useState } from "react";
import {
  Icon,
  Stack,
  MenuItem,
  Autocomplete,
  Tooltip,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSaasSearch,
  selectSearch,
  selectTemplateTypeSearch,
  selectActiveStatus,
  setSearch,
  setTemplateTypeSearch,
  setSaasSearch,
  setActiveStatus,
  selectBestPerformersOnly,
  setBestPerformersOnly,
} from "./templateSlice";
import { SaasType, TemplateTypeEnum, ActiveStatusEnum } from "./templateUtils";
import { debounce } from "chart.js/helpers";
import { useFetchSaasListQuery } from "./templateApi";
import InfoIcon from "@mui/icons-material/Info";
import { SearchField } from "@/components/SearchField";

export const TemplateSearchbar = () => {
  const dispatch = useDispatch();

  // Need useState here because of the debounce.
  // A call is made when one of the values in the store change.
  // And we want to be able to display what the user type without
  // making a call right away.

  const storeSearchValue = useSelector(selectSearch);
  const storeTemplateTypeValue = useSelector(selectTemplateTypeSearch);
  const storeSaasSearchValue = useSelector(selectSaasSearch);
  const storeActiveStatus = useSelector(selectActiveStatus);
  const storeBestPerformersOnly = useSelector(selectBestPerformersOnly);

  const [searchValue, setSearchValue] = useState(storeSearchValue);
  const [searchTemplateTypeValue, setSearchTemplateTypeValue] = useState(
    storeTemplateTypeValue,
  );
  const [searchSaasValue, setSearchSaasValue] = useState(storeSaasSearchValue);
  const [stateActiveStatus, setStateActiveStatus] = useState(storeActiveStatus);

  const { data, isError, isFetching } = useFetchSaasListQuery();

  // why do we need debounced for anything else than this text field?
  const debouncedSearch = useMemo(
    () =>
      debounce((search: any) => {
        if (search.length > 0) {
          dispatch(setSearch({ search: search[0] }));
        } else {
          dispatch(setSearch({ search: null }));
        }
      }, 250),
    [dispatch],
  );

  const debouncedTemplateTypeSearch = useMemo(
    () =>
      debounce((type: any) => {
        if (type.length > 0) {
          dispatch(setTemplateTypeSearch(type[0]));
        } else {
          dispatch(setTemplateTypeSearch(null));
        }
      }, 250),
    [dispatch],
  );

  const debouncedSaasSearch = useMemo(
    () =>
      debounce((saas: any) => {
        if (saas.length > 0) {
          dispatch(setSaasSearch({ saas: saas[0] }));
        } else {
          dispatch(setSaasSearch({ saas: null }));
        }
      }, 250),
    [dispatch],
  );

  const debouncedActiveStatus = useMemo(
    () =>
      debounce((status: ActiveStatusEnum) => {
        dispatch(setActiveStatus(status));
      }, 250),
    [dispatch],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const tooltipContent = (
    <Box>
      <Typography>
        <strong>Active</strong> - Candidate for simulations
      </Typography>
      <br />
      <Typography>
        <strong>Blocked</strong> - Selected in Company Scan but individually
        blocked
      </Typography>
      <br />
      <Typography>
        <strong>Not included</strong> - Toggle can be enabled by adding the
        corresponding tag in Company Scan
      </Typography>
    </Box>
  );

  return (
    <Stack spacing={2} direction="row">
      <SearchField
        value={searchValue}
        onChange={handleSearchChange}
        sx={{
          width: "220px",
        }}
        startIcon={<Icon baseClassName="fas" className="fa-search" />}
      />
      <Autocomplete
        disablePortal
        value={searchSaasValue}
        onChange={(e: any, newValue: SaasType | null) => {
          setSearchSaasValue(newValue);
          debouncedSaasSearch(newValue);
        }}
        options={data ?? []}
        getOptionLabel={(option: SaasType) => option.name ?? ""}
        disabled={isFetching || isError}
        size="small"
        renderInput={(params) => (
          <TextField {...params} sx={{ minWidth: "200px" }} label="Tags" />
        )}
      />
      <TextField
        label="Payload"
        size="small"
        value={searchTemplateTypeValue}
        onChange={(e) => {
          setSearchTemplateTypeValue(e.target.value as TemplateTypeEnum);
          debouncedTemplateTypeSearch(e.target.value as TemplateTypeEnum);
        }}
        select
        sx={{ minWidth: "100px" }}
      >
        {Object.values(TemplateTypeEnum).map((type) => (
          <MenuItem key={type} value={type}>
            {type[0].toUpperCase() + type.slice(1)}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        size="small"
        value={stateActiveStatus}
        onChange={(e) => {
          setStateActiveStatus(e.target.value as ActiveStatusEnum);
          debouncedActiveStatus(e.target.value as ActiveStatusEnum);
        }}
        select
        sx={{ minWidth: "130px" }}
        label={
          <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
            Activation Status
            <Tooltip title={tooltipContent} arrow>
              <InfoIcon sx={{ ml: 1, fontSize: "medium", cursor: "pointer" }} />
            </Tooltip>
          </Box>
        }
      >
        <MenuItem value={ActiveStatusEnum.ALL}>All</MenuItem>
        <MenuItem value={ActiveStatusEnum.ACTIVE}>Active</MenuItem>
        <MenuItem value={ActiveStatusEnum.BLOCKED}>Blocked</MenuItem>
        <MenuItem value={ActiveStatusEnum.NOT_INCLUDED}>Not included</MenuItem>
      </TextField>
      <FormControlLabel
        control={
          <Checkbox
            checked={storeBestPerformersOnly}
            onClick={() =>
              dispatch(setBestPerformersOnly(!storeBestPerformersOnly))
            }
          ></Checkbox>
        }
        label={"Recommended Audit Templates"}
      />
    </Stack>
  );
};
