import React, { useEffect, useState } from "react";
import Header from "./Header";
import ThreatList from "./threatlist/ThreatList";
import { PaginationProps } from "./table/CollapsibleTable";
import ThreatDeleteConfirmationDialog, {
  ThreatDeleteInfo,
} from "../dialog/ThreatDeleteConfirmationDialog";
import ThreatFeedbackDialog from "../dialog/ThreatFeedbackDialog";
import { Box, Card, CardHeader, CardContent } from "@mui/material";

type ListViewProps = {
  threats: ThreatInfo[];
  paginationProps: PaginationProps;
  onShowDetails: (threatId: number) => void;
  fetchOptions: ListFetchOptions;
  setFetchOptions: React.Dispatch<React.SetStateAction<ListFetchOptions>>;
  onDeleteThreats: (threatIds: number[]) => void;
  onFeedback: (
    threatsIds: number[],
    feedbackType: FeedbackType,
    comment?: string,
  ) => void;
  onUnarchive: (threatsIds: number[]) => void;
};

const ListView = ({
  threats,
  paginationProps,
  onShowDetails,
  fetchOptions,
  setFetchOptions,
  onDeleteThreats,
  onFeedback,
  onUnarchive,
}: ListViewProps) => {
  const [selectedThreatIds, setSelectedThreatIds] = useState<number[]>([]);
  const [threatsToDelete, setThreatsToDelete] = useState<ThreatDeleteInfo[]>(
    [],
  );
  const [confirmFeedbackType, setConfirmFeedbackType] =
    React.useState<FeedbackType | null>(null);

  const handleSelectThreat = (threatIds: number[], isSelected: boolean) => {
    if (isSelected) {
      setSelectedThreatIds([
        ...selectedThreatIds.filter((id) => !threatIds.includes(id)),
        ...threatIds,
      ]);
    } else {
      setSelectedThreatIds(
        selectedThreatIds.filter((id) => !threatIds.includes(id)),
      );
    }
  };

  useEffect(() => {
    setSelectedThreatIds([]);
  }, [threats]);

  const handleTabChange = (tab: ThreatListTab) => {
    let value: boolean;
    switch (tab) {
      case "threats":
        value = false;
        break;
      case "archived":
        value = true;
        break;
      default:
        throw new Error(`Invalid tab: '${tab}'`);
    }
    setFetchOptions((prev) => ({ ...prev, archived: value }));
    setSelectedThreatIds([]);
  };

  const showDeleteDialog = () => {
    const buildInfo = (id: number) => {
      const t = threats.find((t) => t.id === id);
      return {
        id: id,
        subject: t.subject,
        report_date: t.report_date,
      };
    };
    setThreatsToDelete(selectedThreatIds.map((id) => buildInfo(id)));
  };

  const handleDeleteThreatsConfirmation = () => {
    onDeleteThreats(selectedThreatIds);
    setSelectedThreatIds([]);
    setThreatsToDelete([]);
  };

  return (
    <Box>
      <Header
        actionButtonsEnabled={selectedThreatIds.length > 0}
        isArchived={fetchOptions.archived}
        onFeedback={(feedbackType: FeedbackType) =>
          setConfirmFeedbackType(feedbackType)
        }
        onDelete={showDeleteDialog}
        onUnarchive={() => onUnarchive(selectedThreatIds)}
        onTabChange={handleTabChange}
      />
      <Box sx={{ pt: 3 }}>
        <Card>
          <CardHeader title="" />
          <CardContent style={{ padding: "auto" }}>
            <ThreatList
              threats={threats}
              onShowDetails={onShowDetails}
              paginationProps={paginationProps}
              showFeedback={fetchOptions.archived}
              fetchOptions={fetchOptions}
              setFetchOptions={setFetchOptions}
              selectedThreatIds={selectedThreatIds}
              onSelectedThreatIdsChange={handleSelectThreat}
            />
          </CardContent>
        </Card>
      </Box>
      {threatsToDelete.length > 0 && (
        <ThreatDeleteConfirmationDialog
          onDelete={handleDeleteThreatsConfirmation}
          onCancel={() => setThreatsToDelete([])}
          threats={threatsToDelete}
        />
      )}
      {confirmFeedbackType && (
        <ThreatFeedbackDialog
          onConfirm={(feedbackType: FeedbackType, comment?: string) => {
            onFeedback(selectedThreatIds, feedbackType, comment);
            setConfirmFeedbackType(null);
          }}
          onCancel={() => setConfirmFeedbackType(null)}
          action={confirmFeedbackType}
          threatCount={selectedThreatIds.length}
        />
      )}
    </Box>
  );
};

export default ListView;
