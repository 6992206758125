export enum ContactType {
  domain = "Domain",
  email_address = "EmailAddress",
}

export interface SafeContact {
  item: string;
  type: ContactType;
  added_by: string;
  added_at: Date;
}
