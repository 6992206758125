const baseUrl = `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/threats-ng/`;

export const buildThreatListUrl = (
  startIndex: number,
  pageSize: number,
  fetchOptions: ListFetchOptions,
): URL => {
  const url: URL = new URL(`threats`, baseUrl);
  url.searchParams.set("offset", startIndex.toString());
  url.searchParams.set("limit", pageSize.toString());
  url.searchParams.set("archived", fetchOptions.archived.toString());
  if (fetchOptions.sort) {
    const sortParams = fetchOptions.sort.map(
      (sort) => `${sort.direction === "asc" ? "" : "-"}${sort.column}`,
    );
    sortParams.forEach((sortParam) =>
      url.searchParams.append("sort", sortParam),
    );
  }
  if (fetchOptions.searchTerm) {
    url.searchParams.set("search", fetchOptions.searchTerm.toString());
  }
  if (fetchOptions.source) {
    url.searchParams.set("source", fetchOptions.source.toString());
  }
  if (fetchOptions.riskLevel) {
    url.searchParams.set("risk_level", fetchOptions.riskLevel.toString());
  }
  if (fetchOptions.hasAttachments != null) {
    url.searchParams.set(
      "has_attachments",
      fetchOptions.hasAttachments.toString(),
    );
  }
  return url;
};

export const buildThreatDetailsUrl = (threatId: number): URL => {
  return new URL(`threats/${threatId}`, baseUrl);
};

export const buildThreatDeleteUrl = (): URL => {
  return new URL(`threats/batch-delete`, baseUrl);
};

export const buildThreatUnarchiveUrl = (): URL => {
  return new URL(`threats/batch-unarchive`, baseUrl);
};

export const buildThreatSendFeedbackUrl = (): URL => {
  return new URL(`threats/batch-feedback`, baseUrl);
};

export const buildIsNewThreatsUIEnabledUrl = (): URL => {
  return new URL(`threats/is-threats-ng-enabled`, baseUrl);
};
