import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Paper, Popover, Typography } from "@mui/material";

export function ThreatListHeaderTooltip() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <HelpOutlineIcon
        sx={{
          fontSize: "1.2rem",
          opacity: "50%",
          cursor: "pointer",
        }}
      />
      <Popover
        sx={{
          pointerEvents: "none",
          left: "-10px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        disableRestoreFocus
      >
        <Paper sx={{ background: "#061123" }}>
          <Typography
            sx={{
              p: 1,
              fontSize: "0.8rem",
              width: "200px",
              textAlign: "center",
            }}
            color="white"
          >
            Use shift or control keys with mouse click to select multiple
            threats
          </Typography>
        </Paper>
      </Popover>
    </div>
  );
}
