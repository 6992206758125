import React from "react";
import { Chip, Tooltip } from "@mui/material";

export default function ActionChip({ data, actionMapping, colors }) {
  if (!data.user_action) {
    return <></>;
  }

  const formatColor = Object.keys(colors).includes(data.user_action)
    ? colors[data.user_action]
    : colors["default"];

  return (
    <Tooltip title={actionMapping[data.user_action]}>
      <Chip
        label={actionMapping[data.user_action]}
        variant="outlined"
        sx={{
          color: `${formatColor.textColor}`,
          bgcolor: `${formatColor.bgColor}33`,
          borderColor: `${formatColor.bgColor}`,
          fontWeight: "600",
          p: "0.5rem",
          fontSize: "0.7rem",
          display: "box",
          textAlign: "center",
        }}
      />
    </Tooltip>
  );
}
