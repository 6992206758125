import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/CloudDownload";

// need to use union type from MUI here.
type IconButtonColors =
  | "inherit"
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
type CloudDownloadButtonProps = {
  onClick: () => void;
  title: string;
  disabled?: boolean;
};
const CloudDownloadButton = ({
  onClick,
  title,
  disabled,
}: CloudDownloadButtonProps) => {
  const [iconColor, setIconColor] = useState<IconButtonColors>("default");

  return (
    <Tooltip title={title && title.charAt(0).toUpperCase() + title.slice(1)}>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={() => setIconColor("primary")}
        onMouseLeave={() => setIconColor("default")}
        color={iconColor}
        sx={{
          alignSelf: "center",
          margin: "0 10px",
        }}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CloudDownloadButton;
