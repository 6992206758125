import React from "react";
import ExtensionStatusChip from "@/pages/browser-defender/components/ExtensionStatusChip";

export function ExtensionStatus({ data }) {
  let counters = {
    active: 0,
    inactive: 0,
    partially_configured: 0,
    disabled: 0,
  };

  data.forEach((item) => {
    if (item.days_since_last_seen < 30) {
      counters[item.extension_status] += 1;
    } else {
      counters["inactive"] += 1;
    }
  });

  let status = { label: "Never installed", key: "unknown" };
  if (counters["active"] > 0) {
    status = { label: "Active", key: "active" };
  } else if (counters["partially_configured"] > 0) {
    status = { label: "Active w/o password", key: "partially_configured" };
  } else if (counters["disabled"] > 0) {
    status = { label: "Disabled by user", key: "disabled" };
  } else if (counters["inactive"] > 0) {
    status = { label: "Last seen +30 days ago", key: "inactive" };
  }

  return <ExtensionStatusChip status={status} />;
}
