import React from "react";
import { createSvgIcon } from "@mui/material";

export const CancelIcon = createSvgIcon(
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 12.741525,0.19915254 c -6.8773514,0 -12.4364403,5.52547556 -12.4364403,12.36124446 0,6.835767 5.5590889,12.361242 12.4364403,12.361242 6.877352,0 12.436441,-5.525475 12.436441,-12.361242 0,-6.8357689 -5.559089,-12.36124446 -12.436441,-12.36124446 z M 18.959745,16.998083 17.206207,18.741018 12.741525,14.303332 8.2768432,18.741018 6.523305,16.998083 10.987987,12.560397 6.523305,8.1227092 8.2768432,6.3797741 12.741525,10.817461 17.206207,6.3797741 18.959745,8.1227092 14.495063,12.560397 Z"
      fill="#ed3b5b"
      id="path66"
    />
  </svg>,
  "WarningIcon",
);
