import React from "react";

const PencilIcon = ({ size = 15 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.95178 12.6305L5.08272 11.4263C5.28298 11.3493 5.38311 11.3108 5.47679 11.2605C5.56001 11.2158 5.63933 11.1643 5.71394 11.1064C5.79794 11.0412 5.8738 10.9653 6.02552 10.8136L12.3896 4.44949C13.0129 3.82628 13.0129 2.81586 12.3896 2.19266C11.7664 1.56945 10.756 1.56945 10.1328 2.19265L3.76869 8.55676C3.61697 8.70848 3.54111 8.78434 3.47593 8.86834C3.41803 8.94295 3.36648 9.02228 3.32181 9.10549C3.27152 9.19917 3.23301 9.2993 3.15599 9.49956L1.95178 12.6305ZM1.95178 12.6305L3.11299 9.6114C3.19608 9.39536 3.23763 9.28733 3.30889 9.23785C3.37117 9.19461 3.44823 9.17826 3.5227 9.19248C3.60792 9.20875 3.68976 9.29059 3.85344 9.45427L5.12803 10.7289C5.29171 10.8925 5.37355 10.9744 5.38982 11.0596C5.40404 11.1341 5.38769 11.2111 5.34445 11.2734C5.29497 11.3447 5.18694 11.3862 4.9709 11.4693L1.95178 12.6305Z"
        stroke="#7F8995"
        strokeWidth="1.12842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilIcon;
