import { RootState } from "@/store";
import { createSlice } from "@reduxjs/toolkit";

interface AuthSliceType {
  token: string | undefined;
  email: string | undefined;
  uses_formal_tone: boolean;
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: undefined,
    email: undefined,
    uses_formal_tone: false,
  } as AuthSliceType,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setFormalTone: (state, action) => {
      state.uses_formal_tone = action.payload;
    },
  },
});

export const { setToken, setEmail, setFormalTone } = authSlice.actions;

export const selectIsMantraUser = (state: RootState) => {
  return state.auth.email?.endsWith("@mantra.ms") ?? false;
};

export const selectUsesFormalTone = (state: RootState) => {
  return state.auth.uses_formal_tone;
};

export default authSlice.reducer;
