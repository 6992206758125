import React from "react";
import { IconButton, Stack } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCount,
  selectPage,
  selectTemplatesPerPage,
  setPage,
} from "./templateSlice";

export const TemplateFooter = () => {
  const dispatch = useDispatch();
  const page = useSelector(selectPage);
  const templatesPerPage = useSelector(selectTemplatesPerPage);
  const count = useSelector(selectCount);

  return (
    <Stack direction="row" justifyContent="end" alignItems="center">
      <div>
        {count > 0 ? (page - 1) * templatesPerPage + 1 : 0} -{" "}
        {Math.min(page * templatesPerPage, count)} on {count}
      </div>
      <IconButton
        size="small"
        disabled={page <= 1}
        onClick={() => dispatch(setPage({ page: page - 1 }))}
      >
        <ArrowLeft />
      </IconButton>
      <IconButton
        size="small"
        disabled={page * templatesPerPage >= count}
        onClick={() => dispatch(setPage({ page: page + 1 }))}
      >
        <ArrowRight />
      </IconButton>
    </Stack>
  );
};
