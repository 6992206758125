import React, { useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import { useSnackbar } from "material-ui-snackbar-provider";

const BlockingText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  font-size: 32px;
  color: black;
  font-weight: 400;
`;

const RequestCallbackButton = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 6px 24px;
  background-color: #4700f7;
  border-radius: 100px;
  color: white;
  &:hover {
    background-color: #3c00d1;
    cursor: pointer;
  }
`;

export function PleaseActivateAwarenessPopup() {
  const [salesNotified, setSalesNotified] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const handleClick = async (event) => {
    event.preventDefault();

    if (!salesNotified) {
      setSalesNotified(true);

      // Make a call to learning to send an email to sales team
      const accessToken = await getAccessTokenSilently();
      try {
        await axios.post(
          import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/feature_interest",
          null,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (error) {
        snackbar.showMessage(extractErrorMessage(error));
        setSalesNotified(false);
      }
    }
  };

  return (
    <div>
      {!salesNotified ? (
        <BlockingText>
          <p>Interested in cyber awareness ?</p>
          <RequestCallbackButton href="#" onClick={handleClick}>
            Request a callback
          </RequestCallbackButton>
        </BlockingText>
      ) : (
        <BlockingText>
          <p>Callback request sent &#10004;</p>
          <p>We will get back to you shortly</p>
        </BlockingText>
      )}
    </div>
  );
}
