import React, { ReactElement } from "react";
import { AttachmentsIcon } from "@/components/icons/AttachmentsIcon";
import { ClickRate } from "@/components/icons/ClickRate";
import { PermissionsIcon } from "@/components/icons/PermissionsIcon";
import { CredentialsIcon } from "@/components/icons/CredentialsIcon";
import { DownloadsIcon } from "@/components/icons/DownloadsIcon";
import { TemplateTypeEnum } from "./templateUtils";
import { Box } from "@mui/material";

const TemplateTypeIcon = ({ type }: { type: TemplateTypeEnum }) => {
  if (type === TemplateTypeEnum.ALL) {
    return <></>;
  }

  let icon: ReactElement;
  switch (type) {
    case TemplateTypeEnum.CREDENTIALS:
      icon = <CredentialsIcon />;
      break;
    case TemplateTypeEnum.ATTACHMENT:
      icon = <AttachmentsIcon />;
      break;
    case TemplateTypeEnum.PERMISSION:
      icon = <PermissionsIcon />;
      break;
    case TemplateTypeEnum.DOWNLOAD:
      icon = <DownloadsIcon />;
      break;
    case TemplateTypeEnum.IMPERSONATION:
      icon = <ClickRate htmlColor="#98A5B3" />;
      break;
    case TemplateTypeEnum.CLICK:
      icon = <ClickRate htmlColor="#98A5B3" />;
      break;
  }
  return (
    <Box
      sx={{
        border: "1.03px solid #E2E2E2",
        borderRadius: "25px",
        px: ".75rem",
        py: ".1rem",
      }}
    >
      {icon}
    </Box>
  );
};

export default TemplateTypeIcon;
