import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import {
  useAcknowledgeTestSetupMutation,
  useSendDeploymentTestMutation,
  useStatusQuery,
} from "@/pages/banners/setup/BannerSetupChecklist";
import { useUserContext } from "@/utils/contexts/UserContext";
import { Link } from "react-router-dom";
import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

type DeploymentTestProps = { title: string };

export const DeploymentTest: React.FC<DeploymentTestProps> = ({
  title,
}: DeploymentTestProps) => {
  const { email } = useUserContext();

  const {
    data,
    isLoading: isStatusLoading,
    isError: isStatusError,
    isFetching: isStatusFetching,
  } = useStatusQuery();

  const [sendDeploymentTest, { isLoading, isSuccess, isError, error }] =
    useSendDeploymentTestMutation();
  const [acknowledgeTestSetup, { isLoading: isAckLoading }] =
    useAcknowledgeTestSetupMutation();

  if (isStatusError) return <>Error</>;

  if (isStatusLoading) return <>Loading</>;

  const checkbox = data.deployment_test_done;
  const atLeastOneUserEngaged = data.engaged_users > 0;

  return (
    <ChecklistPane title={title}>
      <Typography>
        In order to test the deployment, engage at least one user
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link to="/banners/users">
          <Button variant="contained">Engage one User</Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          {atLeastOneUserEngaged ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          )}
        </Box>
      </Box>
      <Typography>
        Click on the button below to check deployment of Smart Banners. You
        should receive an email with a green banner in your inbox. <br />
        If you don't receive it, <a href="mailto:team@mantra.ms">contact us</a>.
      </Typography>

      <Box display="flex" alignItems="center" gap="2.5rem">
        <Button
          variant="contained"
          onClick={() => {
            sendDeploymentTest();
          }}
          disabled={isLoading}
        >
          Test deployment
        </Button>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontStyle: "italic",
            display: "inline",
          }}
        >
          You must be engaged to receive Smart Banners (in{" "}
          <Link to="/banners/users">Users tab</Link>).
        </Typography>
      </Box>
      {isSuccess && (
        <div style={{ color: "green" }}>
          Email successfully sent to {email}.
        </div>
      )}
      {isError && <div style={{ color: "red" }}>{JSON.stringify(error)}</div>}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkbox}
              disabled={isAckLoading || isStatusFetching}
              onChange={(e) => {
                acknowledgeTestSetup(e.target.checked);
              }}
            />
          }
          label="I confirm I have received an email with a green banner in my inbox."
        />
      </FormGroup>
    </ChecklistPane>
  );
};
