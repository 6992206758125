import React from "react";
import { createTheme, darken, PaletteColor } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body: React.CSSProperties;
    body_large: React.CSSProperties;
    body_small: React.CSSProperties;
    body_extrasmall: React.CSSProperties;
    helper_text: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
    body_large?: React.CSSProperties;
    body_small?: React.CSSProperties;
    body_extrasmall?: React.CSSProperties;
    helper_text: React.CSSProperties;
  }
  interface Palette {
    neutral?: string;
    yellow?: string;
  }
  interface PaletteOptions {
    neutral?: PaletteColor;
    yellow?: PaletteColor;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body: true;
    body_large: true;
    body_small: true;
    body_extrasmall: true;
    h6: true;
    body1: false;
    body2: false;
    helper_text: true;
    subtitle1: true;
    subtitle2: false;
    caption: false;
    overline: false;
  }
}

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    yellow: true;
  }
}

const { palette } = createTheme();
export let theme = createTheme({
  zIndex: {
    drawer: 1300,
  },
  spacing: [0, 8, 16, 20, 24, 28, 32, 40, 48],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 961,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: 56,
      fontWeight: 600,
      lineHeight: "72px",
      letterSpacing: 1,
    },
    h2: {
      fontSize: 18,
      fontWeight: 700,
      color: "#061123",
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: "36px",
      color: "#061123",
      letterSpacing: 0,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: 0,
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "24px",
    },
    body_small: {
      fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "20px",
    },
    helper_text: {
      fontWeight: "500",
      fontSize: "0.9rem",
      fontStyle: "italic",
    },
  },
  palette: {
    primary: {
      main: "#7576FE",
    },
    background: {
      paper: "#fff",
      default: "#f9f9f9",
    },
    text: {
      primary: "#061123",
      secondary: "#656E77",
    },
    divider: "#ebedf2",
    warning: {
      main: "#fd397a",
    },
    neutral: palette.augmentColor({
      color: {
        main: "#b7babf",
      },
    }),
    yellow: palette.augmentColor({
      color: {
        main: "#fbaa00",
      },
    }),
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow:
            "0px 2px 8px rgba(4, 9, 33, 0.08), 0px 2px 0px rgba(4, 9, 33, 0.04)",
          paddingBottom: theme.spacing(3),
          height: "100%",
        }),
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h5",
        },
        sx: {
          px: 3,
          pt: 3,
          pb: 2,
        },
      },
      styleOverrides: {
        action: {
          margin: 0,
        },
      },
    },
    MuiCardContent: {
      defaultProps: {
        sx: {
          px: 3,
          pt: 0,
          pb: 0,
        },
      },
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          height: "32px",
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: "small",
        color: "primary",
      },
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          width: "100px",
          fontWeight: "500",
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        size: "small",
        sx: {
          lineHeight: 1, // TODO to validate
          textTransform: "none",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
      },
      styleOverrides: {
        root: {
          color: "primary",
        },
        marginDense: {
          margin: "0",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          margin: "unset",
        },
        displayedRows: {
          margin: "unset",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false, // need to add focus-visible styling if true
        centerRipple: false,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1rem",
          fontWeight: 450,
        },
        outlined: ({ theme, ownerState }) => {
          if (
            ownerState.variant === "outlined" &&
            ownerState.color === "neutral"
          ) {
            return {
              // don't know why but typing is inconsistent here
              // @ts-ignore
              color: darken(theme.palette[ownerState.color].main, 0.3),
            };
          }
          return {
            // @ts-ignore
            color: theme.palette[ownerState.color].main,
          };
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            "0px 2px 8px rgba(4, 9, 33, 0.08), 0px 2px 0px rgba(4, 9, 33, 0.04)",
          overflow: "hidden",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "11px",
        },
      },
    },
  },
});

export default theme;
