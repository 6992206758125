import React from "react";
import { ThreatList } from "./ThreatList";
import { ThreatDetails } from "./ThreatDetails";
import ThreatStickyHeader from "./ThreatStickyHeader";
import { useThreats } from "./ThreatHooks";
import style from "./Threats.module.css";

export function Threats() {
  const {
    threats,
    isLoadingThreats,
    isArchiveDisplayed,
    setArchiveMode,
    search,
    selectedColumns,
    setSelectedColumns,
    updateSelectedThreats,
    isUpdatingSelectedThreats,
    activeId,
    setActiveId,
    threatDetails,
    isLoadingThreatDetails,
    resetThreatDetails,
    offset,
    rowPerPages,
    nextPaginate,
    prevPaginate,
    totalThreatsCount,
    onSort,
    numPages,
  } = useThreats();

  return (
    <>
      <ThreatStickyHeader
        isArchiveDisplayed={isArchiveDisplayed}
        selectedThreats={selectedColumns}
        threats={threats}
        onSearch={search}
        updateSelectedThreats={updateSelectedThreats}
        isUpdatingSelectedThreats={isUpdatingSelectedThreats}
        onSort={onSort}
        numPages={numPages}
      />
      <div className={style.threatLayout}>
        <ThreatList
          isArchiveDisplayed={isArchiveDisplayed}
          setArchiveMode={setArchiveMode}
          threats={threats}
          totalThreatsCount={totalThreatsCount}
          isLoading={isLoadingThreats}
          selectedIds={selectedColumns}
          setSelectedIds={setSelectedColumns}
          setActiveId={setActiveId}
          resetThreatDetails={resetThreatDetails}
          activeId={activeId}
          offset={offset}
          rowPerPages={rowPerPages}
          nextPaginate={nextPaginate}
          prevPaginate={prevPaginate}
        />
        <ThreatDetails
          details={threatDetails}
          isLoading={isLoadingThreatDetails}
        />
      </div>
    </>
  );
}
