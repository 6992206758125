import * as React from "react";
import { Grid, Tooltip, Typography } from "@mui/material";

export default function LabelItem(props) {
  const { icon, title, description, disabled = false } = props;

  return (
    <Tooltip title={description ? description : ""} style={{ cursor: "help" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography
            style={{
              color: !disabled && "#061123",
              fontSize: "1rem",
              fontWeight: "400",
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Tooltip>
  );
}
