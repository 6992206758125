import React from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useStatusQuery } from "@/pages/banners/setup/BannerSetupChecklist";
import { ChecklistPane } from "@/components/stepper/ChecklistPane";

type SafeListsSetupProps = { title: string };

export const SafeListsSetup: React.FC<SafeListsSetupProps> = ({
  title,
}: SafeListsSetupProps) => {
  const { data, isLoading, isError } = useStatusQuery();

  if (isError) return <>Error</>;

  if (isLoading) return <>Loading</>;
  return (
    <ChecklistPane title={title}>
      <Typography>
        Declare to Mantra the senders and domains on which you don't want
        banners to be displayed.
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link to="/banners/setup#preferences">
          <Button variant="contained">Safe Lists</Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          {data.have_safe_lists ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          )}
        </Box>
      </Box>
    </ChecklistPane>
  );
};
