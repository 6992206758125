import { createTheme } from "@mui/material/styles";

export const CustomCampaignTheme = createTheme({
  palette: {
    primary: {
      main: "#7567FE",
    },
    warning: {
      main: "#F2994A",
      light: "#F2394A",
    },
    success: {
      main: "#43B170",
    },
    error: {
      main: "#F6285FCC",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          width: "100%",
          marginBottom: "15px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          // width: 85,
          fontWeight: 500,
        },
        colorPrimary: {
          backgroundColor: "#7567FE1A",
        },
        colorSuccess: {
          backgroundColor: "#65C58B1A",
        },
        colorWarning: {
          backgroundColor: "#F2994A1A",
        },
        colorError: {
          backgroundColor: "#F6285F1A",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "50px",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          paddingTop: "0",
        },
        item: {
          paddingTop: "0",
        },
        "grid-xs-6": {
          paddingTop: 0,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          marginTop: "1rem",
        },
        selectLabel: {
          marginTop: "1rem",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          marginBottom: 0,
          fontWeight: "unset",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: "10px",
          fontWeight: "bold",
        },
      },
    },
  },
});
