import useThreatFetch from "./_fetch_get";
import { useCallback } from "react";
import { buildThreatDeleteUrl } from "./_url_builders";

const useFetchThreatDelete = () => {
  const {
    isLoading: threatDeleteIsLoading,
    isError: threatDeleteIsError,
    threatFetch,
  } = useThreatFetch();

  const fetchThreatDelete = useCallback(
    async (threatIds: number[]) => {
      const url = buildThreatDeleteUrl();
      threatFetch(url, "POST", {
        threat_ids: threatIds,
      });
    },
    [threatFetch],
  );

  return { threatDeleteIsLoading, threatDeleteIsError, fetchThreatDelete };
};

export default useFetchThreatDelete;
