import React from "react";
import { Box, Chip, Tooltip } from "@mui/material";

export default function ExtensionStatusChip({ status }) {
  const statusColors = {
    active: { color: "#1dc9b7", backgroundColor: "#1dc9b71A" },
    partially_configured: { color: "#5CD85A", backgroundColor: "#5CD85A1A" },
    disabled: { color: "#949291", backgroundColor: "#9492911A" },
    unknown: { color: "#EE97A7", backgroundColor: "#FF5F7C1A" },
    inactive: { color: "#EE97A7", backgroundColor: "#FF5F7C1A" },
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Tooltip title={status.label}>
        <Chip
          label={status.label}
          variant={"outlined"}
          style={{
            color: statusColors[status.key].color,
            backgroundColor: statusColors[status.key].backgroundColor,
            borderColor: statusColors[status.key].color,
          }}
        />
      </Tooltip>
    </Box>
  );
}
