import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import AlertTriangleEmpty from "@/pages/general-settings/permissions-attribution/icons/empty/AlertTriangleEmpty";
import SettingsEmpty from "@/pages/general-settings/permissions-attribution/icons/empty/SettingsEmpty";
import BookOpenEmpty from "@/pages/general-settings/permissions-attribution/icons/empty/BookOpenEmpty";
import ShieldTickEmpty from "@/pages/general-settings/permissions-attribution/icons/empty/ShieldTickEmpty";
import GlobeEmpty from "@/pages/general-settings/permissions-attribution/icons/empty/GlobeEmpty";
import TargetEmpty from "@/pages/general-settings/permissions-attribution/icons/empty/TargetEmpty";
import TargetOutlined from "@/pages/general-settings/permissions-attribution/icons/outlined/TargetOutlined";
import TargetFull from "@/pages/general-settings/permissions-attribution/icons/full/TargetFull";
import BookOpenFull from "@/pages/general-settings/permissions-attribution/icons/full/BookOpenFull";
import BookOpenOutlined from "@/pages/general-settings/permissions-attribution/icons/outlined/BookOpenOutlined";
import ShieldTickFull from "@/pages/general-settings/permissions-attribution/icons/full/ShieldTickFull";
import ShieldTickOutlined from "@/pages/general-settings/permissions-attribution/icons/outlined/ShieldTickOutlined";
import GlobeFull from "@/pages/general-settings/permissions-attribution/icons/full/GlobeFull";
import GlobeOutlined from "@/pages/general-settings/permissions-attribution/icons/outlined/GlobeOutlined";
import AlertTriangleOutlined from "@/pages/general-settings/permissions-attribution/icons/outlined/AlertTriangleOutlined";
import AlertTriangleFull from "@/pages/general-settings/permissions-attribution/icons/full/AlertTriangleFull";
import SettingsFull from "@/pages/general-settings/permissions-attribution/icons/full/SettingsFull";
import SettingsOutlined from "@/pages/general-settings/permissions-attribution/icons/outlined/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import EditPermission from "@/pages/general-settings/permissions-attribution/EditPermission";
import KeyEmpty from "@/pages/general-settings/permissions-attribution/icons/empty/KeyEmpty";
import KeyOutlined from "@/pages/general-settings/permissions-attribution/icons/outlined/KeyOutlined";
import KeyFull from "@/pages/general-settings/permissions-attribution/icons/full/KeyFull";
import CorporateEmpty from "@/pages/general-settings/permissions-attribution/icons/empty/CorporateEmpty";
import CorporateOutlined from "@/pages/general-settings/permissions-attribution/icons/outlined/CorporateOutlined";
import CorporateFull from "@/pages/general-settings/permissions-attribution/icons/full/CorporateFull";
import ValidIcon from "@/pages/general-settings/permissions-attribution/icons/ValidIcon";
import InvalidIcon from "@/pages/general-settings/permissions-attribution/icons/InvalidIcon";
import CreatePermission from "@/pages/general-settings/permissions-attribution/CreatePermission";
import {
  CategoryTitle,
  Permission,
  PermissionCategory,
  UserWithPermissions,
} from "@/pages/general-settings/permissions-attribution/permissions";
import Dialog from "@mui/material/Dialog";
import RemovePermission from "@/pages/general-settings/permissions-attribution/RemovePermission";
import { usePermissionService } from "@/pages/general-settings/permissions-attribution/permissionService";
import { CenteredStyledSpinner } from "@/components/Spinner";

function PermissionSearchBar({
  value,
  onChange,
}: {
  value: string;
  onChange: (x: string) => void;
}) {
  return (
    <OutlinedInput
      sx={{ height: 35 }}
      value={value}
      onChange={(input) => onChange(input.target.value)}
      endAdornment={<SearchIcon />}
    />
  );
}

function AddPermissionButton({ onClick }: { onClick: () => void }) {
  return (
    <Button variant="contained" startIcon={<AddIcon />} onClick={onClick}>
      Add admin
    </Button>
  );
}

function PermissionsToolbar({
  buttonAction,
  searchInput,
  setSearchInput,
}: {
  searchInput: string;
  setSearchInput: (x: string) => void;
  buttonAction: () => void;
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <PermissionSearchBar value={searchInput} onChange={setSearchInput} />
      <AddPermissionButton onClick={buttonAction} />
    </Box>
  );
}

function PermissionSymbolTooltipContent({
  category,
  permissions,
}: {
  category: PermissionCategory;
  permissions: Array<Permission>;
}) {
  const relevantPermissions = Permission.allEditable().filter(
    (p) => p.category === category,
  );

  return (
    <div style={{ padding: "0.3rem" }}>
      <span style={{ margin: "0 0.5rem", fontWeight: "bold" }}>
        {CategoryTitle.get(category)}
      </span>
      <br />
      {relevantPermissions.map((p, i) => (
        <span key={i}>
          {permissions.includes(p) ? (
            <ValidIcon style={{ marginRight: "3px", fontSize: "1.1rem" }} />
          ) : (
            <InvalidIcon style={{ marginRight: "3px", fontSize: "1.1rem" }} />
          )}
          {p.name} <br />
        </span>
      ))}
    </div>
  );
}

function PermissionSymbol({
  EmptyIcon,
  OutlinedIcon,
  FullIcon,
  permissions,
  category,
}: {
  EmptyIcon: () => React.JSX.Element;
  OutlinedIcon: () => React.JSX.Element;
  FullIcon: () => React.JSX.Element;
  permissions: Array<Permission>;
  category: PermissionCategory;
}) {
  const relevantPermissions = Permission.allEditable().filter(
    (p) => p.category === category,
  );

  let Icon = null;
  if (!relevantPermissions.some((p) => permissions.includes(p))) {
    Icon = EmptyIcon;
  } else if (relevantPermissions.every((p) => permissions.includes(p))) {
    Icon = FullIcon;
  } else {
    Icon = OutlinedIcon;
  }

  return (
    <Tooltip
      title={
        <PermissionSymbolTooltipContent
          category={category}
          permissions={permissions}
        />
      }
      placement="top"
    >
      <div>
        <Icon />
      </div>
    </Tooltip>
  );
}

function PermissionsTableBodyRow({
  user,
  onClick,
  buttonClick,
}: {
  user: UserWithPermissions;
  onClick: () => void;
  buttonClick: () => void;
}) {
  return (
    <TableRow
      sx={{ "&:hover": { backgroundColor: "#D9D9D949", cursor: "pointer" } }}
    >
      <TableCell onClick={onClick} align="left">
        {user.firstname}
      </TableCell>
      <TableCell onClick={onClick} align="left">
        {user.lastname}
      </TableCell>
      <TableCell onClick={onClick} align="left">
        {user.email}
      </TableCell>
      <TableCell onClick={onClick}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <PermissionSymbol
            EmptyIcon={TargetEmpty}
            OutlinedIcon={TargetOutlined}
            FullIcon={TargetFull}
            permissions={user.permissions}
            category={PermissionCategory.SIMULATION}
          />
          <PermissionSymbol
            EmptyIcon={BookOpenEmpty}
            OutlinedIcon={BookOpenOutlined}
            FullIcon={BookOpenFull}
            permissions={user.permissions}
            category={PermissionCategory.AWARENESS}
          />
          <PermissionSymbol
            EmptyIcon={ShieldTickEmpty}
            OutlinedIcon={ShieldTickOutlined}
            FullIcon={ShieldTickFull}
            permissions={user.permissions}
            category={PermissionCategory.BANNERS}
          />
          <PermissionSymbol
            EmptyIcon={GlobeEmpty}
            OutlinedIcon={GlobeOutlined}
            FullIcon={GlobeFull}
            permissions={user.permissions}
            category={PermissionCategory.BROWSER}
          />
          <PermissionSymbol
            EmptyIcon={AlertTriangleEmpty}
            OutlinedIcon={AlertTriangleOutlined}
            FullIcon={AlertTriangleFull}
            permissions={user.permissions}
            category={PermissionCategory.THREATS}
          />
          <PermissionSymbol
            EmptyIcon={SettingsEmpty}
            OutlinedIcon={SettingsOutlined}
            FullIcon={SettingsFull}
            permissions={user.permissions}
            category={PermissionCategory.SETTINGS}
          />
          <PermissionSymbol
            EmptyIcon={KeyEmpty}
            OutlinedIcon={KeyOutlined}
            FullIcon={KeyFull}
            permissions={user.permissions}
            category={PermissionCategory.PERMISSIONS_ATTRIBUTION}
          />
          <PermissionSymbol
            EmptyIcon={CorporateEmpty}
            OutlinedIcon={CorporateOutlined}
            FullIcon={CorporateFull}
            permissions={user.permissions}
            category={PermissionCategory.CORPORATE}
          />
        </Box>
      </TableCell>
      <TableCell align="center">
        <IconButton title="Remove custom permissions" onClick={buttonClick}>
          <ClearIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function userSearchFilter(
  user: UserWithPermissions,
  searchText: string,
): boolean {
  if (searchText == null || searchText === "") {
    return true;
  }

  const searchWords = searchText
    .trim()
    .split(" ")
    .map((w) => w.toLowerCase());

  const checks = [
    (word: string) => user.firstname.toLowerCase().startsWith(word),
    (word: string) => user.lastname.toLowerCase().startsWith(word),
    (word: string) => user.email.toLowerCase().includes(word),
  ];

  const matchingWords = searchWords.filter((word) =>
    checks.some((check) => check(word)),
  );

  return matchingWords.length === searchWords.length;
}

function usePermissionsDrawer() {
  const [userToEdit, setUserToEdit] = useState<UserWithPermissions>(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  function openCreationDrawer() {
    setUserToEdit(null);
    setDrawerOpen(true);
  }

  function openEditionDrawer(user: UserWithPermissions) {
    setUserToEdit(user);
    setDrawerOpen(true);
  }

  function closeDrawer() {
    setDrawerOpen(false);
  }

  return {
    isDrawerOpen,
    userToEdit,
    openCreationDrawer,
    openEditionDrawer,
    closeDrawer,
  };
}

function usePermissionDialog() {
  const [userToClear, setUserToClear] = useState<UserWithPermissions>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  function openDialog(user: UserWithPermissions) {
    setUserToClear(user);
    setDialogOpen(true);
  }

  function closeDialog() {
    setDialogOpen(false);
  }

  return { userToClear, isDialogOpen, openDialog, closeDialog };
}

export default function PermissionsAttribution() {
  const { users, fetchIsLoading } = usePermissionService();
  const [searchInput, setSearchInput] = useState("");
  const {
    isDrawerOpen,
    userToEdit,
    openEditionDrawer,
    openCreationDrawer,
    closeDrawer,
  } = usePermissionsDrawer();
  const { userToClear, isDialogOpen, openDialog, closeDialog } =
    usePermissionDialog();

  if (fetchIsLoading) {
    return <CenteredStyledSpinner />;
  }

  return (
    <Portlet>
      <PortletHeader
        title="Permissions"
        toolbar={
          <PermissionsToolbar
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            buttonAction={openCreationDrawer}
          />
        }
      />
      <PortletBody>
        <TableContainer>
          <Table>
            <TableBody>
              {users
                .filter((user) => userSearchFilter(user, searchInput))
                .map((user) => (
                  <PermissionsTableBodyRow
                    user={user}
                    key={user.email}
                    onClick={() => openEditionDrawer(user)}
                    buttonClick={() => openDialog(user)}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
          {userToEdit == null ? (
            <CreatePermission
              closeDrawer={closeDrawer}
              openUserEdit={openEditionDrawer}
              admins={users}
            />
          ) : (
            <EditPermission user={userToEdit} closeDrawer={closeDrawer} />
          )}
        </Drawer>
        <Dialog open={isDialogOpen} onClose={closeDialog}>
          <RemovePermission user={userToClear} closeDialog={closeDialog} />
        </Dialog>
      </PortletBody>
    </Portlet>
  );
}
