import { useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

type JsonData = { [key: string]: any };

type UseFetchReturnType = {
  data: JsonData;
  isLoading: boolean;
  isError: boolean;
  threatFetch: (
    url: URL,
    method?: string,
    jsonData?: JsonData,
    transformer?: (data: any) => any,
  ) => void;
};

const useThreatFetch = (): UseFetchReturnType => {
  const [data, setData] = useState<JsonData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(null);
  const { getAccessTokenSilently } = useAuth0();

  const threatFetch = useCallback(
    async (
      url: URL,
      method: string = "GET",
      jsonData?: JsonData,
      transformer?: (data: any) => any,
    ) => {
      setIsError(false);
      setIsLoading(true);

      const headers: { [key: string]: string } = {};
      const options = {
        method,
        headers: headers,
      };

      const accessToken = await getAccessTokenSilently();
      headers["Authorization"] = `Bearer ${accessToken}`;

      if (jsonData != null) {
        options.headers = {
          ...options.headers,
          "Content-Type": "application/json",
        };
        options["body"] = JSON.stringify(jsonData);
      }

      try {
        const response = await fetch(url.href, options);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        setData(transformer ? transformer(json) : json);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently],
  );

  return { data, isLoading, isError, threatFetch };
};

export default useThreatFetch;
