import { CenteredStyledSpinner } from "@/components/Spinner";

export const BackendSuspense = (props) => {
  const { data, error, isPending } = { ...props };

  if (isPending) {
    return <CenteredStyledSpinner width="50px" color="#777" />;
  } else if (error) {
    return <div>An error occured</div>;
  } else if (data?.length === 0 || (props.hasData && !props.hasData)) {
    return <div>No data available</div>;
  }

  return props.children;
};
