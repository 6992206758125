import css from "@/pages/banners/mark-as-safe/LandingPage.module.css";
import { browserLanguage } from "@/pages/banners/utils";
import { LANDING_PAGE_MARK_AS_SAFE } from "@/pages/banners/translations";

const LandingPage = ({ emailAddress }) => {
  const browser_language = browserLanguage();
  const translate = LANDING_PAGE_MARK_AS_SAFE[browser_language];

  return (
    <div className={css.container}>
      <div className={css.message_container}>
        <img
          src="/media/logos/logo_mantra_loading_screen.png"
          alt="Mantra logo"
        />
        <p className={css.sender_email}> {emailAddress}</p>
        <p className={css.message}>{translate.add_to_safe}</p>
        <p className={css.small_message}>{translate.not_display_again}</p>
        <p className={css.small_message}>{translate.close}</p>
      </div>
      <div className={css.footer}>
        <p>
          {translate.footer} <a href="mailto:team@mantra.ms">team@mantra.ms</a>
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
