import { alpha, Chip } from "@mui/material";
import React from "react";

const ThreatBannerLevelChip = ({
  bannerLevel,
}: {
  bannerLevel: BannerLevel | undefined;
}) => {
  if (bannerLevel !== "very_suspicious" && bannerLevel !== "suspicious")
    return null;

  const color = bannerLevel === "very_suspicious" ? "#F53F42" : "#F9C23C";
  const label = bannerLevel === "very_suspicious" ? "red" : "yellow";

  return (
    <Chip
      variant="outlined"
      size={"medium"}
      sx={{
        backgroundColor: alpha(color, 0.1),
        borderColor: color,
        color: color,
        fontWeight: "bold",
      }}
      label={label}
    />
  );
};

export default ThreatBannerLevelChip;
