import React, { ReactElement } from "react";
import { useBackend } from "@/utils/misc";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { EmailSent } from "@/components/icons/EmailSent";
import { EmailOpened } from "@/components/icons/EmailOpened";
import { ClickRate } from "@/components/icons/ClickRate";
import { Target } from "@/components/icons/Target";
import DownloadButton from "@/components/icons/DownloadButton";
import CompromisingActions from "@/pages/phishing-simulation/dashboard/CompromisingActions";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { CenteredStyledSpinner } from "@/components/Spinner";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styled from "@emotion/styled";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
});

const downloadEmailMessages = async (
  getToken,
  dateFrom,
  dateTo,
  department,
) => {
  let url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/emailmessages/export/csv";
  let params = new URLSearchParams();

  const accessToken = await getToken();
  params.append("date_from", dateFrom.toISOString());
  params.append("date_to", dateTo.toISOString());
  if (department) {
    params.append("department", department);
  }

  url = url + "?" + params.toString();
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    })
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let a = document.createElement("a");
      a.href = url;
      a.download = "sent-emails.csv";
      a.click();
    });
};

const computeURLFromProps = ({
  dateFrom,
  dateTo,
  department,
  activeUsersOnly,
  isCorporateView,
}) => {
  let stats_url = import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/stats/basic";
  if (isCorporateView) {
    stats_url = stats_url + "/corporation";
  }
  let params = new URLSearchParams();

  params.append("date_from", dateFrom.toISOString());
  params.append("date_to", dateTo.toISOString());
  params.append("active_only", activeUsersOnly);

  if (department) {
    params.append("department", department);
  }

  return stats_url + "?" + params.toString();
};

type BasicStatCardProps = {
  title: string;
  icon: ReactElement;
  data: number | string;
  isPending: boolean;
  action?: ReactElement;
  error: any;
};

const BasicStatCard = ({
  title,
  icon,
  data,
  isPending,
  action,
  error,
}: BasicStatCardProps) => {
  return (
    <Card>
      <CardHeader avatar={icon} action={action} title={title}></CardHeader>
      <CardContent sx={{ px: 3, pt: 1, pb: 0 }}>
        <Stack spacing={2}>
          {error && "an error occured"}
          {isPending ? (
            <Box sx={{ marginBottom: "15px" }}>
              <CenteredStyledSpinner width="50px" color="#777" />
            </Box>
          ) : (
            <Typography variant="h3" sx={{ marginBottom: "15px" }}>
              {data}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default function BasicStats(props: any) {
  const { getAccessTokenSilently } = useAuth0();
  const stats_url = computeURLFromProps(props);
  const { data, error, isPending } = useBackend(stats_url.toString());
  console.log(props);
  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <BasicStatCard
          title="Simulations Sent"
          icon={<EmailSent sx={{ fontSize: "32px" }} />}
          action={
            <DownloadButton
              title="download all simulations sent since the beginning"
              onClick={() =>
                downloadEmailMessages(
                  getAccessTokenSilently,
                  props.dateFrom,
                  props.dateTo,
                  props.department,
                )
              }
            />
          }
          data={data?.nbSent?.toFixed(0)}
          isPending={isPending}
          error={error}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <BasicStatCard
          title="Open Rate"
          icon={<EmailOpened sx={{ fontSize: "32px" }} />}
          data={`${
            data?.percentageOpen
              ? data?.percentageOpen?.toFixed(0)
              : data?.nbSent > 5
                ? 73
                : 0
          }%`}
          isPending={isPending}
          error={error}
          action={
            data?.isOffice ? (
              <CustomWidthTooltip
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div>
                      Due to Office limitations, the exact opened rate can’t be
                      retrieved.
                    </div>
                    <div>
                      This value is the approximate rate based on Mantra’s
                      internal data.
                    </div>
                  </div>
                }
              >
                <IconButton>
                  <ErrorOutlineIcon color="primary" />
                </IconButton>
              </CustomWidthTooltip>
            ) : (
              <></>
            )
          }
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <BasicStatCard
          title="Click Rate"
          icon={<ClickRate sx={{ fontSize: "32px" }} />}
          data={`${data?.percentageClick.toFixed(0)}%`}
          isPending={isPending}
          error={error}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <BasicStatCard
          title="Report Rate"
          icon={<Target sx={{ fontSize: "32px" }} />}
          data={`${data?.percentageReport.toFixed(0)}%`}
          isPending={isPending}
          error={error}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Compromising actions"></CardHeader>
          <CardContent sx={{ px: 3, pt: 1, pb: 0 }}>
            <CompromisingActions dataset={data} />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
