import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useSelector } from "react-redux";
import { selectAwarenessSetupChecklistState } from "../SetupChecklistSlice";
import { useUserContext } from "@/utils/contexts/UserContext";
import { Link } from "react-router-dom";

const AssignCourse: React.FC = () => {
  const { current_company } = useUserContext();
  const { oneCourseAssigned } = useSelector(selectAwarenessSetupChecklistState);

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">Assign you first course</Typography>
      <Box mb="1rem">
        Assign your first course to {current_company.name} employees to start
        their awareness training.
      </Box>
      <Box display="flex" alignItems="center" gap="1.5rem">
        <Link to="/awareness/courses">
          <Button variant="contained">Assign a course</Button>
        </Link>
        <Box display="flex" alignItems="center" gap="0.5rem">
          {oneCourseAssigned ? (
            <>
              <CheckCircleOutlinedIcon color="success" />
              <Typography variant="body_extrasmall" color="#2e7d32">
                Completed
              </Typography>
            </>
          ) : (
            <>
              <CancelOutlinedIcon color="error" />
              <Typography variant="body_extrasmall" color="error">
                Not Completed
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AssignCourse;
