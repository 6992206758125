import React from "react";
import { createSvgIcon } from "@mui/material";

export const CompletedIcon = createSvgIcon(
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="11" fill="#4F46E5" />
    <path
      d="M6.98956 11.5729L9.28123 13.8645L15.0104 8.13538"
      stroke="white"
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "CompletedIcon",
);
