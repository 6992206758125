import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useSelector } from "react-redux";
import { selectSetupChecklistState } from "@/pages/phishing-simulation/setup/SetupChecklistSlice";
import { Link } from "react-router-dom";
import { TemplateAssignationStatus } from "../SetupChecklistTypes";

const TemplateCheckIcon = ({
  status,
}: {
  status: TemplateAssignationStatus;
}) => {
  switch (status) {
    case "no-tags":
      return (
        <>
          <CancelOutlinedIcon color="error" />
          <Typography color="#ed3b5b">
            You need to assign at least one template to departments
          </Typography>
        </>
      );
    case "default-tags":
      // TODO yellow
      return (
        <>
          <CancelOutlinedIcon color="warning" />
          <Typography color="#ed3b5b">
            You need to assign some templates to departments
          </Typography>
        </>
      );
    case "user-assigned-tags":
      return (
        <>
          <CheckCircleOutlinedIcon color="success" />
          <Typography variant="body_small" color="#2e7d32">
            it's good !
          </Typography>
        </>
      );
  }
};

const AttributeTemplatesToDepartments: React.FC = () => {
  const { assign_templates_to_departments: assignTemplatesToDepartments } =
    useSelector(selectSetupChecklistState);

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">Assign templates to departements</Typography>
      <Typography>
        You need to personnalise template attribution to let us send the proper
        simulation to given users
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link to="/phishing-simulation/company#template-attribution">
          <Button variant="contained">Assign Templates to departments</Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          <TemplateCheckIcon status={assignTemplatesToDepartments} />
        </Box>
      </Box>
    </Box>
  );
};

export default AttributeTemplatesToDepartments;
