import { cloneDeep, isEmpty, uniq } from "lodash";
import { createSlice, nanoid } from "@reduxjs/toolkit";
import { supportedLanguages } from "@/pages/awareness/courses/course-editor/hooks";
import { RootState } from "@/store";

export const courseSlice = createSlice({
  name: "courseEditor",
  initialState: {
    editMode: false,
    currentLanguage: "en",
    course: {
      id: undefined,
      name: undefined,
      description: undefined,
      draft: {},
      published: {},
      owner_id: -1,
    },
    interactions: [],
  },
  reducers: {
    checkEditMode: (state) => {
      const currentLanguage = state.currentLanguage;
      const drafts = state.course?.draft;
      const draftLanguages = Object.keys(drafts) ?? [];
      const published = state.course?.published;
      const publishedLanguages = Object.keys(published) ?? [];

      if (isEmpty(state.course.published)) {
        state.editMode = true;
      }

      if (state.editMode && drafts[currentLanguage] == null) {
        if (draftLanguages.length === 0) {
        }
        state.currentLanguage = draftLanguages[0];
      } else if (!state.editMode && published[currentLanguage] == null) {
        state.currentLanguage = publishedLanguages[0] ?? null;
      }
    },
    toggleEditMode: (state) => {
      state.editMode = !state.editMode;
      courseSlice.caseReducers.checkEditMode(state);
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
      courseSlice.caseReducers.checkEditMode(state);
    },
    changeLanguage: (state, action) => {
      state.currentLanguage = action.payload;
      const drafts = state.course?.draft;
      const draftsLanguages = Object.keys(drafts) ?? [];
      const published = state.course?.published;
      const publishedLanguages = Object.keys(published) ?? [];
      if (!publishedLanguages.includes(state.currentLanguage)) {
        state.editMode = true;
      } else if (!draftsLanguages.includes(state.currentLanguage)) {
        state.editMode = false;
      }
    },
    setLocalizedCourseField: (state, action) => {
      const id = action.payload.id;
      const name = action.payload.name;
      const description = action.payload.description;
      for (let [entry, dictValue] of Object.entries(state.course.draft)) {
        // @ts-ignore
        if (dictValue.id === id) {
          state.course.draft[entry].name = name;
          state.course.draft[entry].description = description;
        }
      }
    },
    setLocalizedCourseDraftForLanguage: (state, action) => {
      state.course.draft[action.payload.language] = {
        ...action.payload,
      };
    },
    publishLocalizedCourse: (state, action) => {
      const localizedCourseId = action.payload.id;
      let language: string;
      let localizedCourse: object;
      for (let [key, value] of Object.entries(state.course.draft)) {
        // @ts-ignore
        if (value.id === localizedCourseId) {
          language = key;
          // @ts-ignore
          localizedCourse = value;
          break;
        }
      }
      if (language == null || localizedCourse == null) {
        return;
      }
      state.course.published[language] = localizedCourse;
      state.course.published[language].published = true;
      delete state.course.draft[language];
    },
    setCourseState: (state, action) => {
      state.course = action.payload;
    },
    setInteractions: (state, action) => {
      const interactions = cloneDeep(action.payload);
      state.interactions = interactions.map((x) => {
        x.keyId = nanoid();
        x.answers = x.answers.map((y) => {
          y.keyId = nanoid();
          return y;
        });
        return x;
      });
    },
    setInteractionContent: (state, action) => {
      const interactionId = action.payload.id;
      const interaction = state.interactions.find(
        (i) => i.id === interactionId,
      );
      if (interaction) {
        interaction.type = action.payload.type;
        interaction.content = action.payload.content;
      }
    },
    deleteInteraction: (state, action) => {
      const interactionId = action.payload.id;
      const placeholderId = action.payload.placeholderId;
      if (placeholderId != null) {
        state.interactions = state.interactions.filter(
          (i) => i.placeholderId !== placeholderId,
        );
      } else if (interactionId != null) {
        state.interactions = state.interactions.filter(
          (i) => i.id !== interactionId,
        );
      }
    },
    addInteraction: (state, action) => {
      const previousIndex = action.payload.previousIndex;
      if (previousIndex == null) {
        state.interactions.unshift({
          keyId: nanoid(),
          placeholderId: nanoid(),
          id: null,
          content: "",
          type: "txt",
          answers: [],
        });
      } else {
        state.interactions.splice(previousIndex + 1, 0, {
          keyId: nanoid(),
          placeholderId: nanoid(),
          id: null,
          content: "",
          type: "txt",
          answers: [],
        });
      }
    },
    createInteractionFromPlaceholder: (state, action) => {
      const placeholderId = action.payload.placeholderId;
      const interaction = state.interactions.find(
        (i) => i.placeholderId === placeholderId,
      );
      if (interaction == null) {
        return;
      }
      interaction.id = action.payload.id;
      interaction.content = action.payload.content ?? "";
      interaction.interaction_type = action.payload.interaction_type;
      interaction.placeholderId = undefined;
      interaction.previous_interaction_id =
        action.payload.previous_interaction_id;
    },
    setAnswerContent: (state, action) => {
      const interactionId = action.payload.interactionId;
      const answerId = action.payload.answerId;
      const interaction = state.interactions.find(
        (i) => i.id === interactionId,
      );
      if (interaction == null) {
        return;
      }
      const answer = interaction.answers.find((a) => a.id === answerId);
      if (answer == null) {
        return;
      }
      answer.content = action.payload.content;
      answer.reaction = action.payload.reaction;
      answer.is_correct = action.payload.is_correct;
    },
    deleteAnswer: (state, action) => {
      const interactionId = action.payload.interactionId;
      const answerId = action.payload.answerId;
      const placeholderId = action.payload.placeholderId;
      const interaction = state.interactions.find(
        (i) => i.id === interactionId,
      );
      if (interaction == null) {
        return;
      }
      if (answerId) {
        interaction.answers = interaction.answers.filter(
          (a) => a.id !== answerId,
        );
      } else if (placeholderId) {
        interaction.answers = interaction.answers.filter(
          (a) => a.placeholderId !== placeholderId,
        );
      }
    },
    addAnswer: (state, action) => {
      const interactionId = action.payload.interactionId;
      const interaction = state.interactions.find(
        (i) => i.id === interactionId,
      );
      if (interaction == null) {
        return;
      }
      interaction.answers.push({
        keyId: nanoid(),
        id: action.payload.answerId ?? null,
        placeholderId: action.payload.placeholderId,
        content: action.payload.content ?? "",
        reaction: action.payload.reaction ?? "",
        is_correct: action.payload.is_correct ?? false,
        shouldFocus: true,
      });
    },
    createAnswerFromPlaceholder: (state, action) => {
      const interactionId = action.payload.interactionId;
      const interaction = state.interactions.find(
        (i) => i.id === interactionId,
      );
      if (interaction == null) {
        return;
      }
      const placeholderId = action.payload.placeholderId;
      const answer = interaction.answers.find(
        (a) => a.placeholderId === placeholderId,
      );
      if (answer == null) {
        return;
      }
      answer.id = action.payload.answerId ?? null;
      answer.content = action.payload.content ?? "";
      answer.reaction = action.payload.reaction ?? "";
      answer.is_correct = action.payload.is_correct ?? false;
      answer.placeholderId = undefined;
    },
  },
});

export const {
  toggleEditMode,
  checkEditMode,
  changeLanguage,
  setLocalizedCourseField,
  setLocalizedCourseDraftForLanguage,
  setCourseState,
  setInteractions,
  setInteractionContent,
  createInteractionFromPlaceholder,
  deleteInteraction,
  addInteraction,
  setAnswerContent,
  deleteAnswer,
  addAnswer,
  createAnswerFromPlaceholder,
  publishLocalizedCourse,
  setEditMode,
} = courseSlice.actions;

export const selectCurrentLocalizedCourse = (state: RootState) => {
  if (state.courseEditor.editMode) {
    return state.courseEditor.course?.draft[state.courseEditor.currentLanguage];
  } else {
    return state.courseEditor.course?.published[
      state.courseEditor.currentLanguage
    ];
  }
};

export const selectDraftLocalizedCourses = (state: RootState) => {
  return state.courseEditor.course?.draft ?? {};
};

export const selectCurrentAvailableLanguages = (state: RootState) => {
  if (state.courseEditor.editMode) {
    const drafts = state.courseEditor.course?.draft;
    return Object.keys(drafts) ?? [];
  } else {
    const published = state.courseEditor.course?.published;
    return Object.keys(published) ?? [];
  }
};

export const selectAllAvailableLanguages = (state: RootState) => {
  const drafts = state.courseEditor.course?.draft;
  const draftsLanguages = Object.keys(drafts) ?? [];
  const published = state.courseEditor.course?.published;
  const publishedLanguages = Object.keys(published) ?? [];
  return uniq([...draftsLanguages, ...publishedLanguages]).toSorted();
};

export const selectAvailableDraftsLanguages = (state: RootState) => {
  const drafts = state.courseEditor.course?.draft;
  return Object.keys(drafts) ?? [];
};

export const selectAvailablePublishedLanguages = (state: RootState) => {
  const published = state.courseEditor.course?.published;
  return Object.keys(published) ?? [];
};

export const selectMissingDraftLanguages = (state: RootState) => {
  const drafts = state.courseEditor.course?.draft;
  const draftsLanguages = Object.keys(drafts) ?? [];
  return supportedLanguages.filter(
    (language) => !draftsLanguages.includes(language),
  );
};

export const selectMissingLanguages = (state: RootState) => {
  const drafts = state.courseEditor.course?.draft;
  const published = state.courseEditor.course?.published;
  const draftsLanguages = Object.keys(drafts) ?? [];
  const publishedLanguages = Object.keys(published) ?? [];
  const availableLanguages = uniq([...draftsLanguages, ...publishedLanguages]);
  return supportedLanguages
    .filter((language: string) => !availableLanguages.includes(language))
    .toSorted();
};

export const selectCourse = (state: RootState) => {
  return state.courseEditor.course;
};

export const selectCourseId = (state: RootState) => {
  return state.courseEditor.course?.id;
};

export const selectEditMode = (state: RootState) => {
  return state.courseEditor.editMode;
};

export const selectCurrentLanguage = (state: RootState) => {
  return state.courseEditor.currentLanguage;
};

export const selectInteractions = (state: RootState) => {
  return state.courseEditor.interactions;
};

export const selectHasPublishedCourses = (state: RootState) => {
  const published = state.courseEditor.course?.published;
  if (published == null) {
    return false;
  }
  return !isEmpty(published);
};

export const selectIsProvidedByMantra = (state: RootState) => {
  return state.courseEditor.course.owner_id == null;
};

export const selectHasInteractions = (state: RootState) => {
  const interactions = state.courseEditor?.interactions ?? [];
  const filteredInteractions = interactions.filter((i) => i.id != null);
  return filteredInteractions.length > 0;
};

export const selectHasNotSavedInteractions = (state: RootState) => {
  const interactions = state.courseEditor?.interactions ?? [];
  const filteredInteractions = interactions.filter((i) => i.id == null);
  return filteredInteractions.length > 0;
};

export default courseSlice.reducer;
