import useThreatFetch from "./_fetch_get";
import { useCallback } from "react";
import { buildThreatUnarchiveUrl } from "./_url_builders";

const useFetchThreatUnarchive = () => {
  const {
    isLoading: threatUnarchiveIsLoading,
    isError: threatUnarchiveIsError,
    threatFetch,
  } = useThreatFetch();

  const fetchThreatUnarchive = useCallback(
    async (threatIds: number[]) => {
      const url = buildThreatUnarchiveUrl();
      threatFetch(url, "POST", {
        threat_ids: threatIds,
      });
    },
    [threatFetch],
  );

  return {
    threatUnarchiveIsLoading,
    threatUnarchiveIsError,
    fetchThreatUnarchive,
  };
};

export default useFetchThreatUnarchive;
