import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "@/utils/contexts/UserContext";

export function HomeRedirect() {
  const { is_admin } = useUserContext();
  if (is_admin !== null) {
    return (
      <Navigate
        to={is_admin ? "/phishing-simulation/dashboard" : "/my-progress"}
      />
    );
  } else {
    return <div>Waiting for admin rights</div>;
  }
}
