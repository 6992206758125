import React, { useContext, useEffect } from "react";
import { DeploymentTest } from "@/pages/banners/setup/DeploymentTest";
import { SampleEmails } from "@/pages/banners/setup/SampleEmails";
import { HistoricalAnalysis } from "@/pages/banners/setup/HistoricalAnalysis";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import { StepperStateKey } from "@/pages/banners/setup/BannersChecklistTypes";
import { EngageCompanyStatus } from "@/pages/banners/setup/EngageCompanyStatus";
import { CompanySetup } from "@/pages/banners/setup/CompanySetup";
import { SafeListsSetup } from "@/pages/banners/setup/SafeListsSetup";
import { ApiAccessSetup } from "@/pages/banners/setup/ApiAccessSetup";
import {
  selectBannersSetupChecklistStatus,
  setStepperState,
  useStatusQuery,
} from "@/pages/banners/setup/BannerSetupChecklist";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { CenteredBigSpinner } from "@/components/Spinner";

const BannerCurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  const steps = useSelector(selectBannersSetupChecklistStatus);

  const title = steps[checklist.activeIndex].label;

  switch (checklist.activeIndex) {
    case StepperStateKey.COMPANY_SETUP:
      return <CompanySetup title={title} />;
    case StepperStateKey.API_ACCESS:
      return <ApiAccessSetup title={title} />;
    case StepperStateKey.DEPLOYMENT_TEST:
      return <DeploymentTest title={title} />;
    case StepperStateKey.HISTORICAL_ANALYSIS:
      return <HistoricalAnalysis title={title} />;
    case StepperStateKey.SAFE_LIST_SETUP:
      return <SafeListsSetup title={title} />;
    case StepperStateKey.SAMPLE_EMAILS:
      return <SampleEmails title={title} />;
    case StepperStateKey.ENGAGE_COMPANY:
      return <EngageCompanyStatus title={title} />;
  }
};

export const BannersSetupChecklist: React.FC = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useStatusQuery();
  const steps = useSelector(selectBannersSetupChecklistStatus);

  useEffect(() => {
    if (!isLoading) dispatch(setStepperState(data));
  }, [isLoading, data, dispatch]);

  return (
    <>
      {isLoading && !isError ? (
        <Box marginTop="5rem">
          <CenteredBigSpinner />
        </Box>
      ) : (
        <SetupChecklist steps={steps}>
          <BannerCurrentComponent />
        </SetupChecklist>
      )}
      {isError ? "an error occurred please try again later" : null}
    </>
  );
};
