import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const CredentialsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ fontSize: 12 }} htmlColor={"#98A5B3"} {...props}>
      <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.91667 4.83333V3.66667C8.91667 2.05584 7.61083 0.75 6 0.75C4.38917 0.75 3.08333 2.05584 3.08333 3.66667V4.83333M6 7.45833V8.625M4.13333 11.25H7.86667C8.84676 11.25 9.3368 11.25 9.71115 11.0593C10.0404 10.8915 10.3081 10.6238 10.4759 10.2945C10.6667 9.92014 10.6667 9.43009 10.6667 8.45V7.63333C10.6667 6.65324 10.6667 6.1632 10.4759 5.78885C10.3081 5.45957 10.0404 5.19185 9.71115 5.02407C9.3368 4.83333 8.84676 4.83333 7.86667 4.83333H4.13333C3.15324 4.83333 2.66319 4.83333 2.28885 5.02407C1.95957 5.19185 1.69185 5.45957 1.52407 5.78885C1.33333 6.1632 1.33333 6.65324 1.33333 7.63333V8.45C1.33333 9.43009 1.33333 9.92014 1.52407 10.2945C1.69185 10.6238 1.95957 10.8915 2.28885 11.0593C2.66319 11.25 3.15324 11.25 4.13333 11.25Z"
          stroke="#98A5B3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
