import React from "react";
import { Box, Button, Typography } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useSelector } from "react-redux";
import { selectSetupChecklistState } from "../SetupChecklistSlice";
import { Link } from "react-router-dom";

const CompanyScan = () => {
  const { company_scan: companyScan } = useSelector(selectSetupChecklistState);

  const categories = [
    { label: "Key People", isCompleted: companyScan.has_key_people },
    { label: "Email Signature", isCompleted: companyScan.has_email_signature },
    { label: "Key Customers", isCompleted: companyScan.has_key_customers },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        To enable spear phishing, fill in key information in company scan
      </Typography>
      {categories.map((category) => (
        <Box
          key={category.label}
          display="grid"
          gridTemplateColumns="2fr 1fr"
          gap="1.5rem"
          width="30%"
        >
          <Typography color="warning">{category.label}</Typography>
          {category.isCompleted ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <WarningAmberOutlinedIcon sx={{ fill: "#fe9534" }} />
          )}
        </Box>
      ))}
      <Box display="flex" alignItems="center" gap="1rem">
        <Typography>You can setup company informations here :</Typography>
        <Link to="/phishing-simulation/company#general-informations">
          <Button variant="contained">Go to company scan</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default CompanyScan;
