import React from "react";
import { Button } from "@mui/material";
import { useDownloadUsers } from "@/pages/general-settings/UsersManagement/utils";

export function ExportButton() {
  const { startDownload } = useDownloadUsers();

  return (
    <div>
      <Button variant="contained" onClick={() => startDownload()}>
        Export Users
      </Button>
    </div>
  );
}
