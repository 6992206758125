import { createCourseSlice, createSelectors } from "../utils/courseSlice";
import { RootState } from "@/store";

const courseSharingSlice = createCourseSlice("courseSharing");

export const {
  setCourses,
  setUserInfos,
  setSearch,
  setFilterSharingPolicy,
  setIsAdmin,
} = courseSharingSlice.actions;

const selectCourseSharingState = (state: RootState) => state.courseSharing;
export const {
  selectCourses,
  selectSearchValue,
  selectFilterSharingPolicy,
  selectCustomCourseBySearch,
} = createSelectors(selectCourseSharingState, "courseSharing");
export default courseSharingSlice.reducer;
