import LandingPage from "@/pages/banners/mark-as-safe/LandingPage";
import MarkAsSafeErrorPage from "@/pages/banners/mark-as-safe/MarkAsSafeErrorPage";
import { useBackend } from "@/utils/misc";
import LoadingPage from "@/pages/banners/loading-page/LoadingPage";
import { browserLanguage } from "@/pages/banners/utils";
import { LANDING_PAGE_ERROR } from "@/pages/banners/translations";

export function MarkAsSafe() {
  const endpoint_url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/banners/mark_as_safe";
  const params = new URLSearchParams(window.location.search);
  const sender_address = params.get("sender_address");
  const recipient_address = params.get("recipient_address");
  const browser_language = browserLanguage();
  const translate = LANDING_PAGE_ERROR[browser_language];

  const { error, isPending } = useBackend(
    endpoint_url,
    "post",
    JSON.stringify({
      sender_address: sender_address,
      recipient_address: recipient_address,
    }),
  );
  let body = null;

  if (isPending) {
    body = <LoadingPage />;
  } else if (error) {
    if (error.response && error.response.status === 403) {
      body = <MarkAsSafeErrorPage message={translate.action_not_allowed} />;
    } else {
      body = <MarkAsSafeErrorPage message={translate.an_error_has_occurred} />;
    }
  } else {
    body = <LandingPage emailAddress={sender_address} />;
  }

  return body;
}
