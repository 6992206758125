import React, { useEffect, useState } from "react";
import { TextField, Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentLocalizedCourse, selectEditMode } from "./courseSlice";
import { usePatchLocalizedCourseMutation } from "@/api";
import { useDebounceRequest } from "./hooks";

const CourseHeaderFields = () => {
  const editMode = useSelector(selectEditMode);
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  const [patch, { isLoading }] = usePatchLocalizedCourseMutation();
  const debouncedPatch = useDebounceRequest(patch, {
    successMessage: "Course successfully updated",
    errorMessage: "Error while updating the course",
  });

  useEffect(() => {
    setName(localizedCourse?.name);
    setDescription(localizedCourse?.description);
  }, [localizedCourse]);

  const [name, setName] = useState(localizedCourse?.name);
  const [description, setDescription] = useState(localizedCourse?.description);

  const onInput = (field: string) => (e) => {
    if (field === "name") {
      setName(e.target.value);
    } else if (field === "description") {
      setDescription(e.target.value);
    }
    debouncedPatch({
      ...localizedCourse,
      name: field === "name" ? e.target.value : name,
      description: field === "description" ? e.target.value : description,
    });
  };

  return (
    <Stack spacing={2} sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
      {editMode ? (
        <>
          <TextField
            value={name}
            fullWidth
            label="name"
            placeholder="Write the name of the course here..."
            disabled={!editMode || isLoading}
            onInput={onInput("name")}
          />
          <TextField
            value={description}
            fullWidth
            label="description"
            placeholder="Write the name of the description here..."
            disabled={!editMode || isLoading}
            onInput={onInput("description")}
          />
        </>
      ) : (
        <>
          <Typography variant="h4">{localizedCourse?.name}</Typography>
          <Typography variant="body">{localizedCourse?.description}</Typography>
        </>
      )}
    </Stack>
  );
};

export default CourseHeaderFields;
