import { createCourseSlice, createSelectors } from "../utils/courseSlice";
import { RootState } from "@/store";

const courseCatalogSlice = createCourseSlice("courseCatalog");

export const {
  updateSubscription,
  setDepartments,
  setCourses,
  setUserInfos,
  setSearch,
  setFilterDepartments,
  setFilterSharingPolicy,
  subscribeAll,
  setIsAdmin,
  setIsPublicWebchat,
  setOnboardingCourses,
  setRunCourses,
} = courseCatalogSlice.actions;

const selectCourseCatalogState = (state: RootState) => state.courseCatalog;
export const {
  selectDepartments,
  selectUserInfos,
  selectIsDemoAcme,
  selectFilterDepartments,
  selectFilterSharingPolicy,
  selectOnboardingCourses,
  selectRunCourses,
  selectSearchValue,
  selectHasFilter,
  selectCourseBySearch,
  selectOnboardingCourseBySearch,
  selectRunCoursesBySearch,
  selectCourses,
} = createSelectors(selectCourseCatalogState, "courseCatalog");

export default courseCatalogSlice.reducer;
