import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BACKEND_URL } from "@/pages/banners/remote-apis/config";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

export function useSendEmail() {
  const [isSendError, setIsSendError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const sendEmails = async ({ templateNames, userEmails }) => {
    const accessToken = await getAccessTokenSilently();
    try {
      setIsSending(true);
      const url = new URL(`${BACKEND_URL}/banners/users/send_template_emails`);
      await axios.post(
        url.href,
        { templates: templateNames, email_addresses: userEmails },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      setIsSendError(false);
      setIsSending(false);
      enqueueSnackbar("The emails have been sent.", { variant: "success" });
    } catch (error) {
      setIsSendError(true);
      setIsSending(false);
      enqueueSnackbar("The emails couldn't be sent.", { variant: "error" });
    }
  };

  return { isSendError, isSending, sendEmails };
}
