import React, { useState } from "react";
import { useBackend } from "@/utils/misc";
import {
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { LeaderboardList } from "@/pages/my-progress/leaderboard/LeaderboardList";
import theme from "@/theme";
import { useTranslation } from "react-i18next";

const TabsEnum = Object.freeze({ quarter: 0, year: 1, all_times: 2 });

export function Leaderboard() {
  const [selectedTab, setSelectedTab] = useState(TabsEnum.quarter);
  const { t } = useTranslation();

  const handleButtonToggle = (event, value) => {
    if (value !== null) {
      setSelectedTab(value);
    }
  };

  // Date from based on the selected tab
  const getDateFrom = () => {
    // Get a date object for the current time
    let now = new Date();
    let d;
    if (selectedTab === TabsEnum.quarter) {
      // Go back in time
      let firstQuarterMonth = 3 * Math.floor(now.getMonth() / 3);
      d = new Date(Date.UTC(now.getFullYear(), firstQuarterMonth));
    } else if (selectedTab === TabsEnum.year) {
      d = new Date(Date.UTC(now.getFullYear(), 0));
    } else {
      d = new Date(0); // 01/01/1970
    }
    return d.toISOString();
  };
  const tonight = new Date();
  tonight.setUTCHours(23, 59, 59, 999);

  let urlParams = new URLSearchParams();
  urlParams.append("date_from", getDateFrom());
  urlParams.append("date_to", tonight.toISOString());
  const endpoint_url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
    "/users/leaderboard?" +
    urlParams.toString();
  const { data, error, isPending } = useBackend(endpoint_url);

  let body;
  if (isPending || !data) body = <div>Loading...</div>;
  if (error && error?.response?.status === 403) {
    body = <div>The leaderboard will be available shortly.</div>;
  } else if (error) {
    console.log(error);
    body = <div>An error occurred.</div>;
  }
  if (data) {
    if (data?.length === 0) {
      body = <div>No data available.</div>;
    } else {
      body = <LeaderboardList data={data} />;
    }
  }

  return (
    <Card sx={{ height: "unset", marginBottom: "20px" }}>
      <CardHeader
        title={t("Leaderboard")}
        sx={{ borderBottom: "1px solid " + theme.palette.divider }}
        action={
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={selectedTab}
            onChange={(e, v) => handleButtonToggle(e, v)}
          >
            <ToggleButton value={TabsEnum.quarter}>{t("Quarter")}</ToggleButton>
            <ToggleButton value={TabsEnum.year}>{t("Year")}</ToggleButton>
            <ToggleButton value={TabsEnum.all_times}>
              {t("All times")}
            </ToggleButton>
          </ToggleButtonGroup>
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>{body}</CardContent>
    </Card>
  );
}
