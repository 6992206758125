import React, { useEffect, useMemo, useState } from "react";
import Stats from "@/pages/phishing-simulation/dashboard/Stats";
import UsersTable from "@/pages/phishing-simulation/dashboard/UsersTable";
import ClicksPerDimension from "@/pages/phishing-simulation/dashboard/ClicksPerDimension";
import DepartmentSelector from "@/pages/phishing-simulation/dashboard/DepartmentSelector";
import TimeFrameSelector from "@/components/controls/TimeFrameSelector";
import BasicStats from "@/pages/phishing-simulation/dashboard/BasicStats/BasicStats";
import { TimeCohorts } from "@/pages/phishing-simulation/dashboard/TimeCohorts";
import { useSimulationResource } from "@/utils/ResourceGet";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import StickyHeader from "@/components/StickyHeader";
import UserPerLevel from "./UserPerLevel";
import dayjs from "dayjs";
import { useBackend } from "@/utils/misc";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";

/**
 * Adapted from https://www.w3resource.com/javascript-exercises/javascript-date-exercise-50.php
 */
function getLastMondayDiff(date: Date) {
  return (
    date.getUTCDate() - date.getUTCDay() + (date.getUTCDay() === 0 ? -6 : 1)
  );
}

/**
 * Add days to a date
 * @param date
 * @param days
 * @returns {Date}
 */
export function addDays(date, days: number) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

/**
 * Add days to a given date to fill the all month.
 * @param date
 * @returns {Date}
 */
export function addDaysForMonth(date) {
  const newDate = new Date(date);
  return new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
}

export default function Dashboard({
  isCorporateView,
}: {
  isCorporateView: boolean;
}) {
  const [selectedPeriod, setSelectedPeriod] = useState({
    unit: "month",
    value: null,
  });
  const [department, setDepartment] = useState("");
  const [firstSimulationData] = useSimulationResource(
    "company/first_simulation_date",
    null,
  );
  const [dateSinceBeginningTimeFrame, setDateSinceBeginningTimeFrame] =
    useState<TimeFrame | null>(null);

  useEffect(() => {
    if (firstSimulationData) {
      setDateSinceBeginningTimeFrame({
        label: "Max",
        value: firstSimulationData.nb_months_since,
        unit: "months",
      });
    }
  }, [firstSimulationData]);

  const timeFrames: TimeFrame[] = useMemo(
    () => [
      { label: "1 Month", value: 1, unit: "months" },
      { label: "3 Months", value: 3, unit: "months" },
      { label: "6 Months", value: 6, unit: "months" },
      { label: "1 Year", value: 12, unit: "months" },
    ],
    [],
  );

  const updatedTimeFrames = useMemo(() => {
    return dateSinceBeginningTimeFrame
      ? [...timeFrames, dateSinceBeginningTimeFrame]
      : timeFrames;
  }, [timeFrames, dateSinceBeginningTimeFrame]);

  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>(
    timeFrames[2],
  );
  const [activeUsersOnly, setActiveUsersOnly] = useState(true);

  const dateFromSelector = useMemo(() => {
    const date = new Date();
    date.setUTCMonth(date.getUTCMonth() - selectedTimeFrame.value);
    date.setUTCHours(0, 0, 0, 1);
    return date;
  }, [selectedTimeFrame]);

  const dateFrom = useMemo(() => {
    if (selectedPeriod.value) {
      return selectedPeriod.value;
    }
    if (selectedPeriod.unit === "week") {
      const date = new Date(dateFromSelector);
      date.setDate(getLastMondayDiff(date));
      return date;
    }
    const date = new Date(dateFromSelector); // rewind to beginning of the corresponding month
    date.setUTCDate(1);
    return date;
  }, [dateFromSelector, selectedPeriod.unit, selectedPeriod.value]);

  const dateTo = useMemo(() => {
    if (selectedPeriod.value) {
      if (selectedPeriod.unit === "week") {
        return addDays(selectedPeriod.value, 7);
      }
      return addDaysForMonth(selectedPeriod.value);
    }
    const date = new Date();
    date.setUTCHours(23, 59, 59, 999); // end of day
    return date;
  }, [selectedPeriod.unit, selectedPeriod.value]);

  const HighestClickRateReq = (dimension) => {
    let endpoint_url =
      import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
      "/stats/click_rate_per_" +
      dimension;
    let urlParams = new URLSearchParams();

    urlParams.append("date_from", dateFrom.toISOString());
    urlParams.append("date_to", dateTo.toISOString());
    urlParams.append("active_only", String(activeUsersOnly));

    if (department) {
      urlParams.append("department", department);
    }

    if (urlParams.toString() !== "") {
      endpoint_url = endpoint_url + "?" + urlParams.toString();
    }
    return useBackend(endpoint_url);
  };

  const HighestClickRateReqDepartment = HighestClickRateReq("department");
  const HighestClickRateReqLocation = HighestClickRateReq("location");
  let dimensionSize = 4;

  if (HighestClickRateReqLocation && HighestClickRateReqLocation.data) {
    if (
      Object.keys(HighestClickRateReqLocation.data).length === 1 &&
      Object.keys(HighestClickRateReqLocation.data)[0] === "UNKNOW"
    ) {
      dimensionSize = 6;
    } else {
      dimensionSize = 4;
    }
  }

  return (
    <>
      <StickyHeader>
        <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
          <Typography
            variant="h2"
            style={{
              marginRight: "auto",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Phishing Simulation Dashboard
          </Typography>
          <BootstrapStyleSwitch
            id="active-users-switch"
            label="Show active users only"
            checked={activeUsersOnly}
            onChange={(event) => setActiveUsersOnly(event.target.checked)}
          />
          {selectedPeriod?.value && (
            <Chip
              style={{ width: "200px" }}
              label={`
              Selected ${selectedPeriod.unit}:
              ${dayjs(selectedPeriod.value).format(
                selectedPeriod.unit === "week" ? "DD/MM/YYYY" : "MM/YYYY",
              )}
              `}
              onDelete={() =>
                setSelectedPeriod((x) => ({ unit: x.unit, value: null }))
              }
              variant="outlined"
              color="primary"
            />
          )}

          {!isCorporateView && (
            <DepartmentSelector
              department={department}
              setDepartment={setDepartment}
              setSelectedPeriod={setSelectedPeriod}
            />
          )}
          <TimeFrameSelector
            selectedTimeFrame={selectedTimeFrame}
            setSelectedTimeFrame={setSelectedTimeFrame}
            timeFrames={updatedTimeFrames}
          />
        </Stack>
      </StickyHeader>
      <Grid container alignItems="stretch">
        <Grid item xs={12}>
          <Stats
            department={department}
            timeframe={selectedTimeFrame.value}
            setSelectedPeriod={setSelectedPeriod}
            isCorporateView={isCorporateView}
            activeUsersOnly={activeUsersOnly}
          />
        </Grid>
        <BasicStats
          dateFrom={dateFrom}
          dateTo={dateTo}
          department={department}
          isCorporateView={isCorporateView}
          activeUsersOnly={activeUsersOnly}
        />

        {!isCorporateView && (
          <>
            <Grid item md={6} xs={12}>
              <TimeCohorts
                dateFrom={dateFrom}
                department={department}
                activeUsersOnly={activeUsersOnly}
              />
            </Grid>
            <Grid item md={dimensionSize} xs={12}>
              <UserPerLevel
                department={department}
                activeUsersOnly={activeUsersOnly}
              />
            </Grid>
            <Grid item md={dimensionSize} xs={12}>
              <ClicksPerDimension
                title="Highest click rate departments"
                backgroundColor="#1662ff"
                dataErrorPending={HighestClickRateReqDepartment}
              />
            </Grid>
            {dimensionSize === 4 && (
              <Grid item md={4} xs={12}>
                <ClicksPerDimension
                  title="Highest click rate locations"
                  backgroundColor="#19439e"
                  dataErrorPending={HighestClickRateReqLocation}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <UsersTable
                dateFrom={dateFrom}
                dateTo={dateTo}
                department={department}
                fetchActiveUsersOnly={activeUsersOnly}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
