import React from "react";
import { Chip, Tooltip } from "@mui/material";

export default function InstallTypeChip({ user, riskColors }) {
  return (
    <Tooltip title={user.risk_level.cause}>
      <Chip
        sx={{
          color: `${riskColors[user.risk_level.level].textColor}`,
          bgcolor: `${riskColors[user.risk_level.level].bgColor}`,
          borderColor: `${riskColors[user.risk_level.level].textColor}`,
          padding: "0 6px",
          width: "auto",
        }}
        style={{ cursor: "help" }}
        variant="outlined"
        label={user.install_type}
      />
    </Tooltip>
  );
}
