import React from "react";
import DeleteButton from "../button/DeleteButton";
import NavigationButtons from "../button/NavigationButtons";
import FeedbackButtons from "@/pages/threatsng/components/common/button/FeedbackButtons";

const UnarchivedThreatDetailButtonGroup = ({
  onPrevious,
  onNext,
  onClose,
  onFeedback,
  onDelete,
  hasNext,
  hasPrevious,
  actionButtonsEnabled,
}: {
  onPrevious: () => void;
  onNext: () => void;
  onClose: () => void;
  onFeedback: (feedbackType: FeedbackType) => void;
  onDelete: () => void;
  hasNext: () => boolean;
  hasPrevious: () => boolean;
  actionButtonsEnabled: boolean;
}) => {
  return (
    <>
      <NavigationButtons
        onPrevious={onPrevious}
        onNext={onNext}
        onClose={onClose}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
      />
      <FeedbackButtons onClick={onFeedback} enabled={actionButtonsEnabled} />
      <DeleteButton onClick={onDelete} enabled={actionButtonsEnabled} />
    </>
  );
};

export default UnarchivedThreatDetailButtonGroup;
