import * as React from "react";
import { Box, Typography } from "@mui/material";
import {
  passwordMisuseDomainLabelButtonOptions,
  mantraDomainLabelOptions,
} from "@/pages/browser-defender/passwordMisuse/domain-label/configuration";
import LabelItem from "@/pages/browser-defender/passwordMisuse/domain-label/LabelItem";

const transformedAdminLabelOptions =
  passwordMisuseDomainLabelButtonOptions.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});

const transformedMantraLabelOptions = mantraDomainLabelOptions.reduce(
  (acc, item) => {
    acc[item.key] = item.value;
    return acc;
  },
  {},
);

export function CompanyDomainLabel({ company_domain_label, editor }) {
  let label = company_domain_label;

  return (
    <Box>
      {label ? (
        <LabelItem
          icon={transformedAdminLabelOptions[label].icon}
          title={transformedAdminLabelOptions[label].title}
          description={company_domain_label && `Labelled by ${editor}`}
        />
      ) : (
        <Typography style={{ fontStyle: "italic", color: "#6A707B" }}>
          Not labelled
        </Typography>
      )}
    </Box>
  );
}

export function MantraDomainLabel({ potential_phishing }) {
  let info =
    transformedMantraLabelOptions[
      potential_phishing ? "potential_phishing" : "reuse"
    ];

  return (
    <Box>
      {info && (
        <LabelItem
          icon={info.icon}
          title={info.title}
          description={
            potential_phishing ? "AI based detection" : "Mantra knowledge base"
          }
        />
      )}
    </Box>
  );
}
