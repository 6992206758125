import { useBackend } from "@/utils/misc";
import { BACKEND_URL } from "@/pages/banners/remote-apis/config";

interface StatsFrequency {
  date: string;
  processed: number;
  with_banner: number;
  with_no_banners: number;
  with_info: number;
  with_suspicious: number;
  with_very_suspicious: number;
}

interface StatsData {
  start_date: string;
  end_date: string;
  mailboxes_monitored: number;
  messages_processed: number;
  messages_with_banner: number;
  stats_per_day: Array<StatsFrequency>;
  banners_types?: any;
}

export function useStats(startDate: Date, endDate: Date) {
  const url = new URL(`${BACKEND_URL}/banners/stats/banners`);
  url.searchParams.append("start_date", startDate.toISOString());
  url.searchParams.append("end_date", endDate.toISOString());

  const {
    data,
    error,
    isPending,
  }: { data: StatsData; error: any; isPending: boolean } = useBackend(url.href);

  if (!data) {
    return { data: null, error, isPending };
  }

  const dailyStatistics = data.stats_per_day.map((stat) => {
    return {
      date: stat.date,
      bannerInfo: stat.with_info,
      bannerSuspicious: stat.with_suspicious,
      bannerVerySuspicious: stat.with_very_suspicious,
      mailsProcessed: stat.processed,
    };
  });

  return {
    data: {
      dailyStatistics,
      messagesProcessed: data.messages_processed,
      messagesWithBanner: data.messages_with_banner,
      mailboxesMonitored: data.mailboxes_monitored,
      bannerTypes: data.banners_types,
    },
    error,
    isPending,
  };
}
