import useThreatFetch from "./_fetch_get";
import { useCallback } from "react";
import { buildThreatSendFeedbackUrl } from "./_url_builders";

const useFetchThreatSendFeedback = () => {
  const {
    isLoading: threatSendFeedbackIsLoading,
    isError: threatSendFeedbackIsError,
    threatFetch,
  } = useThreatFetch();

  const fetchThreatSendFeedback = useCallback(
    async (
      threatIds: number[],
      feedbackType: FeedbackType,
      feedbackComment: string,
    ) => {
      const url = buildThreatSendFeedbackUrl();
      threatFetch(url, "POST", {
        threat_ids: threatIds,
        feedback: feedbackType,
        comment: feedbackComment,
      });
    },
    [threatFetch],
  );

  return {
    threatSendFeedbackIsLoading,
    threatSendFeedbackIsError,
    fetchThreatSendFeedback,
  };
};

export default useFetchThreatSendFeedback;
