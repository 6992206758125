import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import { StyledTypo } from "@/pages/awareness/setup/Teams/StyledTypo";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Button, Typography } from "@mui/material";
import React from "react";

const CONSENT_URL =
  "https://login.microsoftonline.com/organizations/v2.0/adminconsent";

export const GrantPermissionComponent = ({ user, deployBotConsent }) => {
  const consentParams = {
    client_id: import.meta.env.VITE_APP_AZURE_AWARENESS_CLIENT_ID,
    scope: "https://graph.microsoft.com/.default",
    redirect_uri: `${
      import.meta.env.VITE_APP_ENDPOINT_LEARNING
    }/office/record_consent`,
    state: user.email,
  };

  const urlParams = Object.keys(consentParams)
    .map((key) => `${key}=${consentParams[key]}`)
    .join("&");
  return (
    <React.Fragment>
      <StyledTypo variant={"h2"}>Grant API permissions to Mantra</StyledTypo>
      <StyledBox>
        <StyledBox sx={{ justifyContent: "unset", width: "600px" }}>
          <Button
            sx={{ width: "11rem" }}
            variant={"outlined"}
            onClick={() => window.open(CONSENT_URL + "?" + urlParams, "_blank")}
          >
            Grant Consent
          </Button>
          <Typography
            sx={{ paddingLeft: "3rem", paddingRight: "2rem" }}
            color={"text.secondary"}
          >
            Allow Mantra to manage Teams apps
          </Typography>
          {deployBotConsent ? (
            <CheckCircleOutlineOutlinedIcon color={"success"} />
          ) : (
            <CancelOutlinedIcon color={"error"} />
          )}
        </StyledBox>
      </StyledBox>
    </React.Fragment>
  );
};
