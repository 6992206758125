import React, { ReactNode } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { AppRoutes } from "@/router/AppRoutes";

import BeamerInit from "@/components/utils/BeamerInit";
import ComposeProviders from "@/components/utils/ComposeProviders";
import ReduxProvider from "@/components/utils/ReduxProvider";
import SnackbarProvider from "@/components/utils/SnackbarProvider";
import ThemeProvider from "@/components/utils/ThemeProvider";
import { UserContextProvider } from "@/utils/contexts/UserContext";
import SentryInit from "@/components/utils/SentryInit";
import Auth0Init from "@/components/utils/Auth0Init";

const App = (): ReactNode => {
  return (
    <SentryErrorBoundary fallback={<p>An error has occurred</p>}>
      <ComposeProviders
        components={[
          Auth0Init, // Must be first
          SentryInit,
          ReduxProvider,
          SnackbarProvider,
          UserContextProvider,
          ThemeProvider,
          BeamerInit, // Must be put after the UserContextProvider
        ]}
      >
        <AppRoutes />
      </ComposeProviders>
    </SentryErrorBoundary>
  );
};

export default App;
