import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import { useStatusQuery } from "@/pages/banners/setup/BannerSetupChecklist";
import Box from "@mui/material/Box";
import DoughnutGraph from "@/pages/browser-defender/components/DoughnutGraph";

const labels = {
  engaged: { color: "#1dc9b7", label: "Engaged" },
  disengaged: { color: "#e76b82", label: "Disengaged" },
  disabled: { color: "#dce5ec", label: "Disabled" },
};

type EngageCompanyStatusProps = { title: string };

export const EngageCompanyStatus: React.FC<EngageCompanyStatusProps> = ({
  title,
}: EngageCompanyStatusProps) => {
  const { data, isLoading } = useStatusQuery();

  const [status, setStatus] = useState({
    engaged: 0,
    disengaged: 0,
    disabled: 0,
  });

  useEffect(() => {
    if (isLoading) return;

    setStatus({
      engaged: data.engaged_users,
      disengaged:
        data.active_users - (data.engaged_users + data.disabled_users),
      disabled: data.disabled_users,
    });
  }, [data, isLoading, setStatus]);

  return (
    <ChecklistPane title={title}>
      <Typography>The whole company can now be engaged</Typography>

      <Box>
        <DoughnutGraph data={status} labelMapping={labels} />
      </Box>
    </ChecklistPane>
  );
};
