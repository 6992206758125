import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSendEmail } from "@/pages/banners/remote-apis/bannerTemplates";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
  useAcknowledgeTestEmailsMutation,
  useStatusQuery,
  useTemplateNamesQuery,
} from "@/pages/banners/setup/BannerSetupChecklist";
import Box from "@mui/material/Box";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import { useUsersQuery } from "@/pages/banners/remote-apis/bannerUsersApi";

const StyledAutocomplete = styled(Autocomplete)(
  () =>
    `
    & .MuiChip-root {
        width: initial;
        fontWeight: 500;
      }
    `,
);

type SampleEmailsProps = { title: string };

export const SampleEmails: React.FC<SampleEmailsProps> = ({
  title,
}: SampleEmailsProps) => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [acknowledgeTestEmails] = useAcknowledgeTestEmailsMutation();

  const {
    data: users,
    isLoading: usersPending,
    isError: isUsersError,
  } = useUsersQuery();

  const {
    data: templates,
    error: templatesError,
    isError: isTemplateError,
    isLoading: templatesPending,
  } = useTemplateNamesQuery();

  const { isSending, sendEmails } = useSendEmail();

  const { data, isLoading, isError } = useStatusQuery();

  const buttonActivated =
    !isSending &&
    !isUsersError &&
    !isTemplateError &&
    selectedUsers.length !== 0 &&
    selectedTemplates.length !== 0;

  const handleSend = async (e) => {
    e.preventDefault();
    await sendEmails({
      templateNames: selectedTemplates,
      userEmails: selectedUsers,
    });
    await acknowledgeTestEmails(true);
  };

  const ErrorMessage = (error) => {
    return (
      <div>
        {error.error?.data?.error === 'The field "ceo" is required.' ? (
          <span style={{ color: "red" }}>
            To use this feature, the{" "}
            <Link to="/phishing-simulation/company">Key People</Link> must be
            filled out.
          </span>
        ) : (
          <span>Unable to load the scenario list...</span>
        )}
      </div>
    );
  };

  if (isError) return <>Error</>;

  if (isLoading) return <>Loading</>;

  return (
    <ChecklistPane title={title}>
      <form onSubmit={handleSend}>
        <div>
          See how banners look like in your inbox by sending fake phishing
          emails triggering them.
        </div>
        <div className="mt-4 mb-4">
          {templatesPending ? (
            <span>Loading scenario list...</span>
          ) : templatesError ? (
            <ErrorMessage error={templatesError} />
          ) : (
            <Tags
              options={templates}
              label={"Phishing scenario"}
              selected={selectedTemplates}
              onChange={(_, newValue) => setSelectedTemplates(newValue)}
            />
          )}
        </div>
        <div className="mt-4 mb-4">
          {usersPending ? (
            <span>Loading user list...</span>
          ) : isUsersError ? (
            <span>Unable to load the user list...</span>
          ) : (
            <Tags
              options={users.map((u) => u.email)}
              label={"Target users"}
              selected={selectedUsers}
              onChange={(_, newValue) => setSelectedUsers(newValue)}
            />
          )}
        </div>
        <div>
          <Button variant="contained" type="submit" disabled={!buttonActivated}>
            Send sample emails
          </Button>
        </div>
      </form>
      <Box>
        <Typography>
          Test emails already sent:
          {data.sent_emails_done ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          )}
        </Typography>
      </Box>
    </ChecklistPane>
  );
};

export default function Tags({ options, label, onChange, selected }) {
  return (
    <StyledAutocomplete
      multiple
      options={options}
      getOptionLabel={(option: string) => option}
      filterSelectedOptions
      disableCloseOnSelect
      onChange={onChange}
      value={selected}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
