import styled from "styled-components";

export const CourseProgressTable = styled.table`
  table-layout: fixed;
  border-spacing: 0.5rem;

  & th,
  & td {
    padding: 0.75rem 0;
  }
`;

export const CourseHeader = styled.th`
  width: 30px;
  height: 140px;
  overflow: visible;

  & p {
    transform-origin: 10% 50%;
    transform: rotate(-75deg);

    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    margin: 0 auto;

    text-transform: uppercase;

    cursor: default;
  }
`;

export const FilterOverlay = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
`;

export const SpinnerOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 240, 240, 0.75);
`;

export const PageControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;
