import {
  selectStepperFromChecklistState,
  updateChecklistState,
  updateMessagingPlatformInstalledStatus,
} from "@/pages/awareness/setup/Checklist/SetupChecklistSlice";
import axios, { AxiosResponse } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AwarenessStepperStateKey,
  MessagingPlatform,
} from "./SetupChecklistTypes";
import MessagingPlatformInstalled from "./stepComponents/MessagingPlatformInstalled";
import SelectedMessagingPlatform from "./stepComponents/SelectedMessagingPlatform";
import AssignCourse from "./stepComponents/AssignCourse";
import CourseDeliveryActivated from "./stepComponents/CourseDeliveryActivated";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import { Box } from "@mui/material";
import { CenteredBigSpinner } from "@/components/Spinner";

const NO_CALL_TO_SIMULATION = "NO_CALL_TO_SIMULATION";

const useAwarenessChecklist = () => {
  const dispatch = useDispatch();
  const steps = useSelector(selectStepperFromChecklistState);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // TODO when we will have an interactive setup checklist / wizard,
  // we may want to move this logic in a Redux/rtk-query slice
  // (and do the rest of the logic also with rtk-query)
  useEffect(
    function fetchSetupStatus() {
      axios
        .get(
          `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/company/setup_status`,
        )
        .then((response) => {
          dispatch(updateChecklistState(response.data));
          if (response.data.messaging_platform === MessagingPlatform.WEBCHAT) {
            return axios.get(
              `${
                import.meta.env.VITE_APP_ENDPOINT_SIMULATION
              }/company/setup-status`,
            );
          }
          // In case we don't use
          // webchat, we should not make
          // a call to simulaiton
          return new Promise((resolve) =>
            resolve({
              data: NO_CALL_TO_SIMULATION,
            }),
          );
        })
        .then((response: AxiosResponse) => {
          if (response.data === NO_CALL_TO_SIMULATION) {
            setIsLoading(false);
            return;
          }
          dispatch(
            updateMessagingPlatformInstalledStatus(
              response.data.has_mail_permissions,
            ),
          );
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch, setIsLoading, setIsError],
  );

  return {
    steps,
    isLoading,
    isError,
  };
};

const AwarenessCurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  switch (checklist.activeIndex) {
    case AwarenessStepperStateKey.MESSAGING_PLATFORM:
      return <SelectedMessagingPlatform />;
    case AwarenessStepperStateKey.MESSAGING_PLATFORM_INSTALLED:
      return <MessagingPlatformInstalled />;
    case AwarenessStepperStateKey.ONE_COURSE_ASSIGNED:
      return <AssignCourse />;
    case AwarenessStepperStateKey.COURSE_DELIVERY_ACTIVATED:
      return <CourseDeliveryActivated />;
  }
};

const AwarenessSetupChecklist: React.FC = () => {
  const { steps, isLoading, isError } = useAwarenessChecklist();

  return (
    <>
      {isLoading && !isError ? (
        <Box marginTop="5rem">
          <CenteredBigSpinner />
        </Box>
      ) : (
        <SetupChecklist steps={steps}>
          <AwarenessCurrentComponent />
        </SetupChecklist>
      )}
      {isError ? "an error occurred please try again later" : null}
    </>
  );
};

export default AwarenessSetupChecklist;
