import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid } from "@mui/material";
import { BarPart } from "@/pages/awareness/dashboard/BarPart";
import { CenteredStyledSpinner } from "@/components/Spinner";
import { sentryCaptureException } from "@/utils/sentry";
import { useUserContext } from "@/utils/contexts/UserContext";
import axios from "axios";

const barColors = {
  strong: "#1dc9b7",
  average: "#f57040",
  weak: "#ee97a7",
};

export function PasswordStrengthDistribution() {
  const [distribution, setDistribution] = useState({
    data: { total: 0, segments: [] },
    loading: true,
  });

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const url = new URL(
          `${
            import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
          }/demo/password_strength`,
        );

        let resource;
        try {
          resource = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } catch (e) {
          if (e.response.status === 400) {
            setDistribution({
              data: [],
              loading: false,
            });
            return;
          }
        }

        setDistribution({
          data: resource.data,
          loading: false,
        });
      } catch (e) {
        sentryCaptureException(e);
      }
    };

    fetchData();
  }, [info_user, getAccessTokenSilently]);

  return (
    <Box sx={{ p: "10px" }}>
      {distribution.loading ? (
        <CenteredStyledSpinner width="50px" color="#777" />
      ) : (
        <Grid container direction="row">
          {distribution.data &&
            distribution.data.map((item) => (
              <BarPart
                width={item.percentage}
                minHeight={"25px"}
                userCount={item.count}
                label={item.level.charAt(0).toUpperCase() + item.level.slice(1)}
                color={barColors[item.level]}
                tooltip={`${item.level}: ${item.count}`}
                key={item.level}
              />
            ))}
        </Grid>
      )}
    </Box>
  );
}
