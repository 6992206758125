import React, { useState } from "react";
import { Button } from "@mui/material";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export function Validation({ setStep, users }) {
  const [error, setError] = useState(""); // Display an error if not empty
  const { getAccessTokenSilently } = useAuth0();

  const btnNextClicked = async () => {
    setError("");

    // Remove the users 'to delete' from the list because they should not be added
    const usersToUpload = users.filter((user) => user.status !== "To delete");

    // Call api to get the import plan
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/users/import/apply",
        usersToUpload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;

      if (data.error) {
        setError(data.error);
      } else {
        // It is a success
        // Go to next step
        setStep(4);
      }
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };
  return (
    <div>
      <br />
      <Portlet>
        <PortletHeader title="Import Users - Step 3/4: Validation" />
        <PortletBody>
          <div>
            Please check that everything looks correct:
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Firstname</th>
                  <th scope="col">Lastname</th>
                  <th scope="col">Email</th>
                  <th scope="col">Language</th>
                  <th scope="col">Location</th>
                  <th scope="col">Timezone</th>
                  <th scope="col">Group</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.email}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>{user.language}</td>
                    <td>{user.location}</td>
                    <td>{user.timezone}</td>
                    <td>{user.group}</td>
                    <td
                      className={(() => {
                        switch (user.status) {
                          case "To add":
                            return "table-success";
                          case "To deactivate":
                            return "table-danger";
                          case "To reactivate":
                            return "table-warning";
                          case "To update":
                            return "table-info";
                          case "To link":
                            return "table-info";
                          default:
                            return "#FFFFFF";
                        }
                      })()}
                    >
                      {user.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {error !== "" && <div style={{ color: "red" }}>{error}</div>}
          <div>
            <Button variant="contained" onClick={btnNextClicked}>
              Apply changes
            </Button>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
}
