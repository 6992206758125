import css from "@/pages/banners/loading-page/LoadingPage.module.css";

const LoadingPage = () => {
  return (
    <div className={css.container}>
      <img
        src="/media/logos/logo_mantra_loading_screen.png"
        alt="Mantra logo"
      />
      <svg className={css.spinner} viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none"></circle>
      </svg>
    </div>
  );
};
export default LoadingPage;
