import React from "react";

const SaveIcon = ({ size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8719 2.08299C15.5235 1.99934 15.1647 1.99967 14.7578 2.00005L7.7587 2.0001C6.95373 2.00009 6.28937 2.00008 5.74818 2.0443C5.18608 2.09022 4.66937 2.18878 4.18404 2.43608C3.43139 2.81957 2.81947 3.43149 2.43598 4.18414C2.18868 4.66948 2.09012 5.18618 2.04419 5.74828C1.99998 6.28947 1.99999 6.95382 2 7.75879V16.2414C1.99999 17.0464 1.99998 17.7107 2.04419 18.2519C2.09012 18.814 2.18868 19.3307 2.43598 19.8161C2.81947 20.5687 3.43139 21.1806 4.18404 21.5641C4.66937 21.8114 5.18608 21.91 5.74818 21.9559C6.28936 22.0001 6.95372 22.0001 7.75868 22.0001H16.2413C17.0463 22.0001 17.7106 22.0001 18.2518 21.9559C18.8139 21.91 19.3306 21.8114 19.816 21.5641C20.5686 21.1806 21.1805 20.5687 21.564 19.8161C21.8113 19.3307 21.9099 18.814 21.9558 18.2519C22 17.7107 22 17.0464 22 16.2414L22.0001 9.24229C22.0004 8.83539 22.0008 8.47664 21.9171 8.12819C21.8436 7.82208 21.7224 7.52945 21.5579 7.26103C21.3707 6.95549 21.1168 6.70205 20.8288 6.4146L17.5855 3.17131C17.298 2.88333 17.0446 2.62942 16.7391 2.44218C16.4707 2.2777 16.178 2.15648 15.8719 2.08299ZM15.405 4.02763C15.3167 4.00643 15.2113 4 14.6745 4H7.8C7.50216 4 7.23749 4.00009 7 4.00176V6.3999C7 6.95995 7 7.23998 7.10899 7.45389C7.20487 7.64205 7.35785 7.79503 7.54601 7.8909C7.75992 7.9999 8.03995 7.9999 8.6 7.9999H15.4C15.9601 7.9999 16.2401 7.9999 16.454 7.8909C16.6422 7.79503 16.7951 7.64205 16.891 7.45389C17 7.23998 17 6.95995 17 6.3999V5.41421L16.2302 4.64436C15.8506 4.2648 15.7715 4.19482 15.6941 4.14736C15.6046 4.09253 15.5071 4.05213 15.405 4.02763ZM8.6 12.9999H15.4C15.9601 12.9999 16.2401 12.9999 16.454 13.1089C16.6422 13.2048 16.7951 13.3577 16.891 13.5459C17 13.7598 17 14.0398 17 14.5999V19.9999H7V14.5999C7 14.0398 7 13.7598 7.10899 13.5459C7.20487 13.3577 7.35785 13.2048 7.54601 13.1089C7.75992 12.9999 8.03995 12.9999 8.6 12.9999Z"
        fill="rgba(0,0,0,.54)"
      />
    </svg>
  );
};

export default SaveIcon;
