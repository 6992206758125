import React, { ReactElement } from "react";
import { TimeCohortsChart } from "@/pages/phishing-simulation/dashboard/TimeCohortsChart";
import { useBackend } from "@/utils/misc";
import { Card, CardContent, CardHeader } from "@mui/material";
import { CenteredStyledSpinner } from "@/components/Spinner";

export function TimeCohorts({ dateFrom, department, activeUsersOnly }) {
  const endpoint_url = new URL(
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/stats/time_cohorts",
  );

  endpoint_url.searchParams.append("date_from", dateFrom.toISOString());
  endpoint_url.searchParams.append("active_only", activeUsersOnly);

  if (department) {
    endpoint_url.searchParams.append("department", department);
  }

  const { data, error, isPending } = useBackend(endpoint_url.href);

  let body: ReactElement | null;
  if (isPending || !data) {
    body = <CenteredStyledSpinner width="50px" color="#777" />;
  } else if (error) {
    body = <div>An error occurred</div>;
  } else if (data) {
    const values = [
      data.lt_3_months_users_click_rate,
      data.bt_3_6_months_users_click_rate,
      data.bt_6_9_months_users_click_rate,
      data.gt_9_months_users_click_rate,
    ];
    const user_number = {
      lt_3_months_users_number: data.lt_3_months_users_number,
      bt_3_6_months_users_number: data.bt_3_6_months_users_number,
      bt_6_9_months_users_number: data.bt_6_9_months_users_number,
      gt_9_months_users_number: data.gt_9_months_users_number,
    };
    const labels = ["< 3 mths", "< 6 mths", "< 9 mths", "> 9 mths"];
    body = (
      <TimeCohortsChart
        title="Users click rate"
        color="#36D9C0"
        values={values}
        labels={labels}
        users={user_number}
      />
    );
  }

  return (
    <Card>
      <CardHeader title="Click rate by seniority" />
      <CardContent>
        <div>{body}</div>
      </CardContent>
    </Card>
  );
}
