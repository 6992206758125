import React, { useEffect } from "react";
import { usePublicLearningResource } from "@/utils/ResourceGet";
import { useNavigate, useParams } from "react-router-dom";
import { Webchat } from "@/pages/webchat/Webchat";
import { FullSizeBigSpinner } from "@/components/Spinner";

export function UnauthenticatedEnterpriseWebchat() {
  const { userTempId } = useParams();
  const navigate = useNavigate();

  const [connectionInfo, apiError, fetchConnectionInfo, isLoading] =
    usePublicLearningResource(
      `public/webchat/unlogged-user/${userTempId}/connection-info`,
      {
        conversation_id: "",
        access_token: "",
        user_id: "",
        user_name: "",
        course_name: "",
        interactions: [],
      },
      false,
    );

  useEffect(() => {
    if (userTempId) {
      fetchConnectionInfo();
    }
  }, [fetchConnectionInfo, userTempId]);

  useEffect(() => {
    if (apiError) {
      navigate("/webchat");
    }
  }, [apiError, navigate]);

  if (!connectionInfo.conversation_id) {
    return (
      <FullSizeBigSpinner
        shouldDisplayText={true}
        text="This may take a while..."
      />
    );
  }

  return (
    <Webchat
      connectionInfo={connectionInfo}
      courseTitle={connectionInfo.course_name}
      allInteractions={connectionInfo.interactions}
      goBackPath="/awareness/courses"
      loadingHistory={isLoading}
    />
  );
}
