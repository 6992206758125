import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  Brave,
  Chrome,
  Edge,
  Generic,
  Opera,
} from "@/components/icons/BrowserLogos";

const statusColors = {
  active: { color: "#1dc9b7", backgroundColor: "#1dc9b71A" },
  partially_configured: { color: "#5CD85A", backgroundColor: "#5CD85A1A" },
  disabled: { color: "#949291", backgroundColor: "#9492911A" },
  unknown: { color: "#EE97A7", backgroundColor: "#FF5F7C1A" },
  inactive: { color: "#EE97A7", backgroundColor: "#FF5F7C1A" },
};

function DeploymentDetailsTooltip({ data }) {
  return (
    <Box sx={{ minWidth: "200px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>V{data.version}</Typography>
        <Typography
          style={{
            color: statusColors[data.extension_status].color,
            fontWeight: "bold",
          }}
        >
          {data.extension_status_label}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
        <Typography style={{ fontWeight: "500", marginRight: "0.5rem" }}>
          Last seen:
        </Typography>
        <Typography>{data.latest_heartbeat}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography style={{ fontWeight: "500", marginRight: "0.5rem" }}>
          Browser:
        </Typography>
        <Typography>
          {data.browser ? data.browser : "Unknown"}{" "}
          {data.browser_version && `[${data.browser_version}]`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography style={{ fontWeight: "500", marginRight: "0.5rem" }}>
          Platform:
        </Typography>
        <Typography>
          {data.platform ? data.platform : "Unknown"}{" "}
          {data.platform_version && `[${data.platform_version}]`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function ExtensionDeploymentDetails({ data }) {
  function getBrowserIcon(deployment, index) {
    let icon = <Generic color="#7567fe" fontSize="14" />;

    if (
      deployment.browser &&
      deployment.browser.toLowerCase().includes("brave")
    ) {
      icon = <Brave color="#7567fe" fontSize="14" />;
    } else if (
      deployment.browser &&
      deployment.browser.toLowerCase().includes("chrome")
    ) {
      icon = <Chrome color="#7567fe" fontSize="14" />;
    } else if (
      deployment.browser &&
      deployment.browser &&
      deployment.browser.toLowerCase().includes("edge")
    ) {
      icon = <Edge color="#7567fe" fontSize="14" />;
    } else if (
      deployment.browser &&
      deployment.browser.toLowerCase().includes("opera")
    ) {
      icon = <Opera color="#7567fe" fontSize="14" />;
    }

    return (
      <Tooltip
        key={index}
        title={<DeploymentDetailsTooltip data={deployment} />}
      >
        <IconButton
          style={{
            border: "1px",
            backgroundColor:
              statusColors[deployment.extension_status].backgroundColor,
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Box sx={{ textAlign: "center" }}>
      {data.active_deployments.map((item, index) =>
        getBrowserIcon(item, index),
      )}
    </Box>
  );
}
