import React, { useMemo, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { Slider } from "@mui/material";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import { debounce } from "lodash";

export const DaysBetweenCourses = ({
  days_between_courses,
}: {
  days_between_courses: number;
}) => {
  const [days, setDays] = useState(days_between_courses);
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 40,
      label: "40",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 60,
      label: "60",
    },
  ];

  const updateDaysBetweenCourses = useMemo(
    () =>
      debounce(async (days) => {
        // update the learning
        const accessToken = await getAccessTokenSilently();
        try {
          await axios.put(
            import.meta.env.VITE_APP_ENDPOINT_LEARNING + "/company/",
            {
              days_between_courses: days,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          snackbar.showMessage("Updated to " + days + " days");
        } catch (error) {
          snackbar.showMessage(extractErrorMessage(error));
        }
      }, 1000),
    [getAccessTokenSilently, snackbar],
  );

  const handleChange = async (newValue) => {
    setDays(newValue);
    if (newValue > 0 && newValue <= 60) {
      updateDaysBetweenCourses(newValue);
    }
  };

  return (
    <Portlet>
      <PortletHeader title="Number of days between two courses" />
      <PortletBody>
        <div>
          <Slider
            aria-labelledby="discrete-slider-small-steps"
            step={1}
            marks={marks}
            min={1}
            max={60}
            valueLabelDisplay="auto"
            value={days}
            onChange={(event, newValue) => handleChange(newValue)}
          />
          <div>
            Days between two courses:&nbsp;&nbsp;
            <input
              type="number"
              id="daysBetweenCourseInput"
              size={5}
              min="1"
              max="60"
              value={days}
              onChange={(event) => handleChange(event.target.value)}
            />
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
};
