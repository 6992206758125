import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import MenuStyle from "@/components/menu/Menu.module.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress, ListItemText, Typography } from "@mui/material";
import { extractErrorMessage, useHasCorporateAdminRights } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "material-ui-snackbar-provider";
import clsx from "clsx";
import axios from "axios";
import colorutil from "color-util";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useLocation } from "react-router-dom";
import CorporateSwitchToggleIcon from "./CorporateSwitchToggleIcon";

const COMPANY_COLOR_CYCLE = [
  "#36D9C0",
  "#4CA2ED",
  "#FEB534",
  "#FF7B73",
  "#DAD6FF",
];

type CompanyType = {
  id: number;
  name: string;
  color?: string;
  backgroundColor?: string;
};

export function CorporateSwitch() {
  const location = useLocation();
  const hasCorporateAdminRights = useHasCorporateAdminRights();
  const snackbar = useSnackbar();
  const user = useUserContext();
  const { getAccessTokenSilently } = useAuth0();
  const [currentCompany, setCurrentCompany] = useState<CompanyType>({
    id: -1,
    name: "",
  });
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [hasSwitch, setHasSwitch] = useState(false);
  const open = Boolean(anchorMenu);
  const [isError, setIsError] = useState(false);
  const companiesList: CompanyType[] = useMemo(() => {
    if (!user?.companies) {
      return [];
    }
    const sortedList = user.companies
      .sort((a: CompanyType, b: CompanyType) => a.name.localeCompare(b.name))
      .map((company: CompanyType, idx: number) => {
        company.backgroundColor =
          COMPANY_COLOR_CYCLE[idx % COMPANY_COLOR_CYCLE.length];
        // compute dynamic color inspired by https://stackoverflow.com/a/3943023
        const c = colorutil.color(company.backgroundColor);
        company.color =
          (c.rgb.r * 299 + c.rgb.g * 587 + c.rgb.b * 114) / 1000 > 160
            ? "#142947"
            : "#fff";
        return company;
      });
    const listWithCurrentCompany = sortedList.filter(
      (el) => el?.id !== currentCompany?.id,
    );
    listWithCurrentCompany.unshift(user.current_company);
    return listWithCurrentCompany;
  }, [user.companies, user.current_company, currentCompany?.id]);

  useEffect(() => {
    if (user?.current_company) {
      setCurrentCompany(
        user?.companies.filter(
          (x: CompanyType) => x.id === user?.current_company?.id,
        )[0],
      );
      if (user?.companies?.length > 1) {
        setHasSwitch(true);
      } else {
        setHasSwitch(false);
      }
    } else {
      setCurrentCompany({ id: -1, name: "" });
      setHasSwitch(false);
    }
  }, [user, setHasSwitch, setCurrentCompany]);

  const handleClick = (e: MouseEvent) => {
    if (hasCorporateAdminRights && hasSwitch) {
      setAnchorMenu(e.currentTarget);
    }
  };

  const handleClose = async (company: CompanyType) => {
    if (company?.name && company?.id !== undefined) {
      try {
        const accessToken = await getAccessTokenSilently();
        await axios.request({
          method: "PATCH",
          url:
            import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/current_user/companies",
          data: {
            id: company.id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setTimeout(() => window.location.reload(), 500);
      } catch (error) {
        snackbar.showMessage(extractErrorMessage(error));
        setIsError(true);
      }
      setCurrentCompany({ id: -1, name: "" });
    }
    setAnchorMenu(null);
  };

  if (["demo", "public"].some((p) => location.pathname.includes(p))) {
    return <></>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  if (
    !user?.current_company?.id ||
    currentCompany?.id !== user?.current_company?.id
  ) {
    return (
      <div className={MenuStyle.corporateSwitchContainer}>
        <div
          className={clsx(
            MenuStyle.corporateSwitch,
            MenuStyle.corporateSwitchSpinner,
          )}
        >
          <CircularProgress size="1.5rem" sx={{ color: "#c0c2c6" }} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={MenuStyle.corporateSwitchContainer}>
        <div
          className={clsx(
            MenuStyle.corporateSwitch,
            hasSwitch && MenuStyle.corporateSwitchActive,
          )}
          onClick={handleClick}
          style={{
            cursor: hasSwitch && hasCorporateAdminRights ? "pointer" : "auto",
          }}
        >
          <div
            className={MenuStyle.badge}
            style={{
              backgroundColor: currentCompany?.backgroundColor,
              color: currentCompany?.color,
            }}
          >
            {currentCompany?.name?.length > 0
              ? currentCompany?.name[0]?.toUpperCase()
              : ""}
          </div>
          <div className={MenuStyle.corporateSwitchText}>
            {currentCompany?.name}
          </div>
          {hasSwitch && hasCorporateAdminRights && (
            <CorporateSwitchToggleIcon style={{ marginLeft: "auto" }} />
          )}
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { maxWidth: "250px", minWidth: "150px" },
        }}
      >
        {companiesList.map((el) => (
          <MenuItem
            key={el?.id}
            onClick={() => handleClose(el)}
            selected={el?.id === currentCompany?.id}
          >
            <ListItemText>
              <Typography variant="inherit" noWrap>
                {el?.name}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
