import React from "react";
import { Box, BoxProps, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

type InfoTooltipProps = {
  content: string;
} & BoxProps;

const InfoTooltip = (props: InfoTooltipProps): JSX.Element => {
  return (
    <Box {...props}>
      <Tooltip title={props.content} arrow>
        <InfoIcon
          sx={{
            ml: 1,
            fontSize: "medium",
            cursor: "pointer",
            verticalAlign: "middle",
            color: "darkgray",
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default InfoTooltip;
