import React from "react";
import { Button, ButtonGroup } from "@mui/material";

export const NavigationButtons = ({
  onPrevious,
  onNext,
  onClose,
  hasPrevious,
  hasNext,
}: {
  onPrevious: () => void;
  onNext: () => void;
  onClose: () => void;
  hasNext: () => boolean;
  hasPrevious: () => boolean;
}) => {
  return (
    <ButtonGroup color="neutral" variant="outlined" sx={{ flex: "0 0 auto" }}>
      <Button
        onClick={onPrevious}
        title="Go to previous threat"
        disabled={!hasPrevious()}
      >
        <i className="fas fa-arrow-left" />
        &nbsp; Previous
      </Button>
      <Button onClick={onNext} title="Go to next threat" disabled={!hasNext()}>
        <i className="fas fa-arrow-right" />
        &nbsp; Next
      </Button>
      <Button
        onClick={onClose}
        title="Close this view and go back to the threat list"
      >
        <i className="fas fa-xmark" />
        &nbsp; Close
      </Button>
    </ButtonGroup>
  );
};

export default NavigationButtons;
