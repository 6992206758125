import React, { useMemo, useState, useCallback } from "react";
import { useBackend } from "@/utils/misc";
import { useUserContext } from "@/utils/contexts/UserContext";
import { UserRepartitionStats } from "@/pages/awareness/dashboard/UserRepartitionStats";
import { CourseRundown } from "@/pages/awareness/dashboard/CourseRundown";
import { PleaseActivateAwarenessPopup } from "@/pages/awareness/setup/PleaseActivateAwarenessPopup";
import { useSimulationResource } from "@/utils/ResourceGet";
import { CoursesProgress } from "@/pages/awareness/progress/CoursesProgress";
import CompletedRatePerDepartments from "@/pages/awareness/dashboard/CompletedRatePerDepartments";
import { Blur } from "@/utils/styled-components";
import { FullSizeBigSpinner } from "@/components/Spinner";
import { CourseCompletedCard } from "./CourseCompletedCard";
import { NbUserCard } from "./NbUsersCard";
import { NbEngagedUserCard } from "./NbEngagedUsersCard.jsx";

export const AwarenessDashboard = () => {
  const [company, error] = useSimulationResource("company", null);
  const [isMonthly, setIsMonthly] = useState(false);
  const user = useUserContext();
  const courseRundownUrl =
    import.meta.env.VITE_APP_ENDPOINT_LEARNING +
    `/stats/rundown/${isMonthly ? "monthly" : "weekly"}?company_id=${
      user?.current_company?.id
    }`;
  const repartitionUrl =
    import.meta.env.VITE_APP_ENDPOINT_LEARNING +
    `/stats/repartition?company_id=${user?.current_company?.id}`;
  const {
    data: repartitionData,
    error: repartitionError,
    isPending: repartitionIsPending,
  } = useBackend(repartitionUrl);
  const { data, dataError, isPending } = useBackend(courseRundownUrl);

  const changeRundownChartMode = useCallback(
    (event, value) => {
      if (value === null) {
        return;
      }
      setIsMonthly(value);
    },
    [setIsMonthly],
  );

  function checkEvent(e) {
    if (!company.awareness_activated) {
      e.stopPropagation();
    }
  }

  const mainContent = useMemo(
    () => (
      <div>
        <UserRepartitionStats
          data={repartitionData}
          error={repartitionError}
          isPending={repartitionIsPending}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 5fr",
            gap: "20px",
          }}
        >
          <NbUserCard usersCount={repartitionData?.user_count} />
          <NbEngagedUserCard
            engagedUsersPercentage={repartitionData?.engaged_user_percentage}
          />
          <CourseCompletedCard
            isPending={isPending}
            data={data}
            error={error}
          />
        </div>
        <CourseRundown
          changeRundownChartMode={changeRundownChartMode}
          isMonthly={isMonthly}
          data={data}
          error={dataError}
          isPending={isPending}
        />
        <CompletedRatePerDepartments />
        <CoursesProgress />
      </div>
    ),
    [
      isMonthly,
      data,
      error,
      dataError,
      isPending,
      changeRundownChartMode,
      repartitionData,
      repartitionError,
      repartitionIsPending,
    ],
  );

  if (error) {
    return "Something went wrong when loading the page, please try again later.";
  } else if (!company) {
    return (
      <FullSizeBigSpinner
        shouldDisplayText={true}
        text="This may take a while..."
      />
    );
  }
  if (company.awareness_activated) {
    return mainContent;
  }

  return (
    <div style={{ overflow: "hidden", position: "relative", top: 0, left: 0 }}>
      <Blur style={{ maxHeight: "90vh" }} onClickCapture={checkEvent}>
        {mainContent}
      </Blur>
      <div style={{ position: "absolute", top: "50%", left: "25%" }}>
        <PleaseActivateAwarenessPopup />
      </div>
    </div>
  );
};
