import { ToggleButton as MuiToggleButton } from "@mui/material";
import { ToggleButtonProps } from "@mui/material";
import { styled } from "@mui/system";

export const MantraToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(
  () => ({
    textTransform: "none",
    "&  .MuiButtonBase-root": {
      color: "#98A5B3",
    },
    "&  .Mui-selected": {
      color: "#7567FE",
    },
  }),
);
