import { Tab, Tabs } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root": {
    color: "#6A707B",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "24px",
    textTransform: "none",
  },
  "& .MuiTab-root.Mui-selected": {
    color: "#061123",
  },
}));

const ThreatTabs = ({
  onChange,
  tab,
}: {
  onChange: (tab: ThreatListTab) => void;
  tab: ThreatListTab;
}) => {
  return (
    <StyledTabs
      indicatorColor="primary"
      textColor="inherit"
      className="builder-tabs"
      value={tab}
      onChange={(_, nextTab) => onChange(nextTab)}
    >
      <Tab disableRipple label="Threats" value="threats" />
      <Tab disableRipple label="Archived" value="archived" />
    </StyledTabs>
  );
};

export default ThreatTabs;
