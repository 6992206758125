import React from "react";
import { ServiceAccount } from "@/pages/general-settings/GSuite/ServiceAccount";
import { ManualSync } from "@/pages/general-settings/commonUserProvisioning/ManualSync";
import { EnableCheckbox } from "@/pages/general-settings/commonUserProvisioning/EnableCheckbox";
import { Typography, Paper, Stack, Link, Box } from "@mui/material";
import { Blacklist } from "@/pages/general-settings/commonUserProvisioning/Blacklist";
import { useUserContext } from "@/utils/contexts/UserContext";
import { GroupWhitelist } from "@/pages/general-settings/GroupWhitelist";

export function GSuiteSync() {
  const user = useUserContext();

  return (
    <Paper sx={{ padding: "2rem" }}>
      {user?.base_company.id === user?.current_company.id ? (
        <Stack useFlexGap sx={{ gap: "4rem" }}>
          <Box>
            <Typography sx={{ color: "black", mb: "1rem" }}>
              Please follow{" "}
              <Link
                href="https://www.notion.so/mantrams/User-Provisioning-4e417c72f3074344b1a90c1fc991551e"
                target="_blank"
                rel="noopener noreferrer"
              >
                this procedure
              </Link>{" "}
              to configure your Google Workspace account.
            </Typography>
            <Typography variant="h2" sx={{ mb: "1rem" }}>
              Service account status
            </Typography>
            <ServiceAccount />
          </Box>
          <Box>
            <Typography variant="h2" sx={{ mb: "1rem" }}>
              Blacklisted users (optional)
            </Typography>
            <Blacklist />
          </Box>
          <Box>
            <Typography variant="h2" sx={{ mb: "1rem" }}>
              Whitelisted groups (optional)
            </Typography>
            <GroupWhitelist />
          </Box>
          <Stack>
            <Typography variant="h2" sx={{ mb: "1rem" }}>
              Provisioning
            </Typography>
            <EnableCheckbox />
            <ManualSync />
          </Stack>
        </Stack>
      ) : (
        <Typography color="error">
          You can only modify settings from {user?.base_company.name}
        </Typography>
      )}
    </Paper>
  );
}
