import React from "react";
import { alpha } from "@mui/material";
import { Chip } from "@mui/material";

export function TypeCell({ value }) {
  let color = "#666";
  let label: string = "Unknown";
  if (value === "Domain") {
    color = "#7566FE";
    label = "Safe Domain";
  } else if (value === "EmailAddress") {
    color = "#1EC9B7";
    label = "Safe Senders";
  }

  if (!value) {
    return <></>;
  }
  return (
    <Chip
      variant="outlined"
      size={"medium"}
      sx={{
        backgroundColor: alpha(color, 0.1),
        borderColor: color,
        color: color,
        fontWeight: "bold",
      }}
      label={label}
    />
  );
}
