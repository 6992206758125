import {
  Autocomplete,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { debounce } from "lodash";

export type AudienceSelectionMode = "users" | "departments" | "everyone";

export type AudienceSelectionDepartment = {
  id: number;
  name: string;
};

type DepartmentSelectionOption = {
  label: string;
  department: AudienceSelectionDepartment;
};

export type AudienceSelectionUser = {
  email: string;
};

type UserSelectionOption = {
  label: string;
  user: AudienceSelectionUser;
};

export const AudienceSelector = ({
  audienceSelectionMode,
  setAudienceSelectionMode,
  setSelectedUsers,
  setSelectedDepartments,
  userList,
  fetchUsers,
  departmentHook,
}) => {
  const { departments } = departmentHook();

  const refetchUsers = useMemo(() => {
    return debounce(fetchUsers, 300);
  }, [fetchUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  return (
    <RadioGroup
      onChange={(e) => setAudienceSelectionMode(e.target.value)}
      value={audienceSelectionMode}
    >
      <FormControlLabel value="users" control={<Radio />} label={"Users"} />
      <Collapse in={audienceSelectionMode === "users"} timeout="auto">
        <Autocomplete
          disableCloseOnSelect
          multiple
          onChange={(event, value) => setSelectedUsers(value)}
          options={userList.map((user: AudienceSelectionUser) => ({
            label: user.email,
            user: user,
          }))}
          isOptionEqualToValue={(
            option: UserSelectionOption,
            value: UserSelectionOption,
          ) => {
            return option.user === value.user;
          }}
          sx={{ padding: "20px 0", width: "300" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Users"
              onChange={(e) => refetchUsers(e)}
            />
          )}
        ></Autocomplete>
      </Collapse>
      <FormControlLabel
        value="departments"
        control={<Radio />}
        label={"Departements"}
      />
      <Collapse in={audienceSelectionMode === "departments"} timeout="auto">
        <Autocomplete
          disableCloseOnSelect
          multiple
          onChange={(event, value) => setSelectedDepartments(value)}
          renderInput={(params) => (
            <TextField {...params} label="Departments" />
          )}
          isOptionEqualToValue={(
            option: DepartmentSelectionOption,
            value: DepartmentSelectionOption,
          ) => {
            return option.department.id === value.department.id;
          }}
          options={departments.map(
            (department: AudienceSelectionDepartment) => ({
              label: department.name,
              department: department,
            }),
          )}
        ></Autocomplete>
      </Collapse>
      <FormControlLabel
        value="everyone"
        control={<Radio />}
        label={"Everyone"}
      />
    </RadioGroup>
  );
};
