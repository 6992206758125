import React from "react";
import DataTable from "../table/DataTable";
import { PaginationProps } from "../table/CollapsibleTable";

type ThreatListProps = {
  threats: ThreatInfo[];
  onShowDetails: (threatId: number) => void;
  paginationProps: PaginationProps;
  showFeedback: boolean;
  fetchOptions: ListFetchOptions;
  setFetchOptions: React.Dispatch<React.SetStateAction<ListFetchOptions>>;
  onSelectedThreatIdsChange: (threatIds: number[], isSelected: boolean) => void;
  selectedThreatIds: number[];
};

const ThreatList = ({
  threats,
  onShowDetails,
  paginationProps,
  showFeedback,
  setFetchOptions,
  fetchOptions,
  onSelectedThreatIdsChange,
  selectedThreatIds,
}: ThreatListProps) => {
  return (
    <>
      <DataTable
        threats={threats}
        selectedThreatIds={selectedThreatIds}
        onSelectedChange={onSelectedThreatIdsChange}
        onShowDetails={onShowDetails}
        paginationProps={paginationProps}
        showFeedback={showFeedback}
        fetchOptions={fetchOptions}
        setFetchOptions={setFetchOptions}
      />
    </>
  );
};

export default ThreatList;
