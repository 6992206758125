import React, { useState } from "react";
import { FileUpload } from "@/pages/general-settings/UsersManagement/csvImport/FileUpload";
import { TimeZoneWizard } from "@/pages/general-settings/UsersManagement/csvImport/timezone/TimeZoneWizard";
import { Validation } from "@/pages/general-settings/UsersManagement/csvImport/Validation";
import { Confirmation } from "@/pages/general-settings/UsersManagement/csvImport/Confirmation";

export function ImportWizard() {
  const [step, setStep] = useState(1);
  const [users, setUsers] = useState([]);

  let displayedStep;
  if (step === 1) {
    displayedStep = <FileUpload setStep={setStep} setUsers={setUsers} />;
  } else if (step === 2) {
    displayedStep = (
      <TimeZoneWizard setStep={setStep} users={users} setUsers={setUsers} />
    );
  } else if (step === 3) {
    displayedStep = <Validation setStep={setStep} users={users} />;
  } else if (step === 4) {
    displayedStep = <Confirmation />;
  }

  return <div>{displayedStep}</div>;
}
