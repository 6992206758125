import { Button, DialogActions, DialogTitle, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useShareAllCoursesMutation } from "./courseSharingApi";

export const ShareAllModal = ({ openModal, setOpenModal, onShareAll }) => {
  const [shareAllLoading, setShareAllLoading] = useState(false);
  const snackbar = useSnackbar();
  const [shareAllCourses] = useShareAllCoursesMutation();

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleConfirm = async () => {
    setShareAllLoading(true);
    try {
      await shareAllCourses().unwrap();
      onShareAll();
      snackbar.showMessage("All courses shared successfully");
    } catch (error) {
      snackbar.showMessage("Error sharing all courses");
    }
    setShareAllLoading(false);
    setOpenModal(false);
  };

  return (
    <Dialog open={openModal} fullWidth>
      <DialogTitle variant={"h3"} sx={{ fontSize: "24px" }}>
        Share All Courses
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography sx={{ fontSize: "14px" }}>
          Do you want to share all custom courses with all companies in your
          corporate group?
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          loading={shareAllLoading}
          variant={"contained"}
          onClick={handleConfirm}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
