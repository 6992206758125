import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Download users in CSV
 */
export const useDownloadUsers = () => {
  const { getAccessTokenSilently } = useAuth0();

  const url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/users/export/csv";

  const startDownload = async () => {
    const accessToken = await getAccessTokenSilently();

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let a = document.createElement("a");
        a.href = url;
        a.download = "users.csv";
        a.click();
      });
  };

  return { startDownload };
};
