import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const UserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 35 35"
      sx={{ fontSize: props.fontSize ? props.fontSize : 12 }}
      htmlColor={props.color ? props.color : "#98A5B3"}
      {...props}
    >
      <path
        fill="none"
        d="M29.1252 30.625C29.1252 28.5898 29.1252 27.5722 28.8791 26.7442C28.3251 24.8798 26.8959 23.4209 25.0695 22.8553C24.2583 22.6042 23.2614 22.6042 21.2676 22.6042H14.1244C12.1306 22.6042 11.1338 22.6042 10.3226 22.8553C8.49619 23.4209 7.06695 24.8798 6.51292 26.7442C6.26685 27.5722 6.26685 28.5898 6.26685 30.625M24.1249 10.9375C24.1249 14.5619 21.2466 17.5 17.696 17.5C14.1454 17.5 11.2671 14.5619 11.2671 10.9375C11.2671 7.31313 14.1454 4.375 17.696 4.375C21.2466 4.375 24.1249 7.31313 24.1249 10.9375Z"
        stroke={props.color ? props.color : "#98A5B3"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
