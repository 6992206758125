import { Layout } from "@/components/layout/Layout";
import React from "react";
import { AuthenticatedComponent } from "./AuthenticatedComponent";
import { RouteType } from "./AppRoutes";

type RoutePropertiesWrapperProps = {
  children: React.ReactNode;
  route: RouteType;
  isAuthenticated?: boolean;
};

export const RoutePropertiesWrapper = ({
  children,
  route,
  isAuthenticated = true,
}: RoutePropertiesWrapperProps) => {
  let content: any;
  if (isAuthenticated) {
    content = (
      <AuthenticatedComponent route={route}>{children}</AuthenticatedComponent>
    );
  } else {
    content = children;
  }

  const hasMenu = route.hasOwnProperty("hasMenu") ? route.hasMenu : true;
  const hasPadding = route.hasOwnProperty("hasPadding")
    ? route.hasPadding
    : true;

  return (
    <Layout hasMenu={hasMenu} hasPadding={hasPadding}>
      {content}
    </Layout>
  );
};
