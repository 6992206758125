import React, { useEffect, useState } from "react";
import ListView from "./listview/ListView";
import DetailView from "./detailview/DetailView";
import { PaginationProps } from "./listview/table/CollapsibleTable";
import useThreatsPaginator, {
  defaultListFetchOptions,
} from "../hooks/paginator";
import useThreatNavigator from "../hooks/threat_navigator";
import useThreatController from "../hooks/controller";

const ThreatsNG = () => {
  const threatPaginator = useThreatsPaginator(10);
  const threatNavigator = useThreatNavigator(threatPaginator);
  const threatController = useThreatController();

  const [fetchOptions, setFetchOptions] = useState<ListFetchOptions>(
    defaultListFetchOptions,
  );

  useEffect(() => {
    threatPaginator.updateFetchOptions(fetchOptions);
    threatNavigator.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOptions]);

  useEffect(() => {
    if (threatPaginator.items.length === 0) {
      threatNavigator.close();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threatPaginator.items]);

  const handleShowDetails = (threatId: number) => {
    const f = async () => {
      threatNavigator.setup(threatId);
    };
    f();
  };

  const handleFeedbacks = (
    threatIds: number[],
    feedbackType: FeedbackType,
    comment?: string,
  ) => {
    threatController.sendFeedbacks(threatIds, feedbackType, comment);
    threatPaginator.removeItems(threatIds);
  };

  const handleFeedback = (feedbackType: FeedbackType, comment: string) => {
    threatController.sendFeedbacks(
      [threatNavigator.item.id],
      feedbackType,
      comment,
    );
    threatNavigator.removeCurrentItem();
  };

  const handleDeleteThreats = (threatIds: number[]) => {
    threatController.deleteThreats(threatIds);
    threatPaginator.removeItems(threatIds);
  };

  const handleDeleteCurrentThreat = () => {
    threatController.deleteThreats([threatNavigator.item.id]);
    threatNavigator.removeCurrentItem();
  };

  const handleUnarchiveThreats = (threatIds: number[]) => {
    threatController.unarchiveThreats(threatIds);
    threatPaginator.removeItems(threatIds);
  };

  const handleUnarchiveThreat = () => {
    threatController.unarchiveThreats([threatNavigator.item.id]);
    threatNavigator.removeCurrentItem();
  };

  let viewComponent: React.ReactElement<typeof DetailView | typeof ListView>;
  if (threatNavigator.item != null) {
    viewComponent = (
      <DetailView
        threatDetails={threatNavigator.item}
        onClose={() => threatNavigator.close()}
        onPrevious={threatNavigator.moveToPreviousItem}
        onNext={threatNavigator.moveToNextItem}
        isThreatArchived={fetchOptions.archived}
        onDelete={handleDeleteCurrentThreat}
        hasPrevious={threatNavigator.hasPreviousItem}
        hasNext={threatNavigator.hasNextItem}
        onFeedback={handleFeedback}
        onUnarchive={handleUnarchiveThreat}
      />
    );
  } else {
    const paginationProps: PaginationProps = {
      itemsPerPage: threatPaginator.pageSize,
      moveToNextPage: threatPaginator.moveToNextPage,
      moveToPreviousPage: threatPaginator.moveToPreviousPage,
      setItemsPerPage: threatPaginator.setPageSize,
      totalItemCount: threatPaginator.getTotalItems(),
      currentPage: Math.max(threatPaginator.getCurrentPage() - 1, 0), // ListView expects 0-based page index
    };
    viewComponent = (
      <ListView
        threats={threatPaginator.items}
        paginationProps={paginationProps}
        onShowDetails={handleShowDetails}
        fetchOptions={fetchOptions}
        setFetchOptions={setFetchOptions}
        onDeleteThreats={handleDeleteThreats}
        onFeedback={handleFeedbacks}
        onUnarchive={handleUnarchiveThreats}
      />
    );
  }

  return <>{viewComponent}</>;
};

export default ThreatsNG;
