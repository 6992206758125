import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { BackendSuspense } from "./BackendSuspense";

export const CourseCompletedCard = ({ className, data }) => {
  const completedCourses =
    data?.course_completion_rundown.slice(-1)[0]?.completed_courses;
  const courseCount = data?.course_count;
  const width =
    courseCount && courseCount > 0
      ? Math.round((completedCourses / courseCount) * 100)
      : 0;

  return (
    <Portlet className={className}>
      <PortletHeader title="Courses Completed" />
      <PortletBody style={{ height: "95px" }}>
        <div className="d-flex justify-content-center">
          <BackendSuspense hasData={courseCount > 0}>
            <div className="w-100">
              <div className="rounded" style={{ backgroundColor: "#E9EDF0" }}>
                <div
                  className="text-white rounded"
                  style={{ width: width + "%", backgroundColor: "#7576FE" }}
                >
                  <div
                    className="d-flex justify-content-center text-white rounded"
                    style={{ minHeight: "25px" }}
                  >
                    <div className="my-auto">{width} %</div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div>0</div>
                {completedCourses > 0 && completedCourses < courseCount ? (
                  <div
                    className="d-flex justify-content-end"
                    style={{ width: width + "%" }}
                  >
                    <div>{completedCourses}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="ml-auto">{courseCount}</div>
              </div>
            </div>
          </BackendSuspense>
        </div>
      </PortletBody>
    </Portlet>
  );
};
