export type TemplateAssignationStatus =
  | "no-tags"
  | "default-tags"
  | "user-assigned-tags";

export enum MailHost {
  OFFICE = 0,
  GMAIL = 1,
}

export enum StepperStateKey {
  MAILING_PERMISSIONS = 0,
  PROVISIONING_PERMISSIONS = 1,
  PROVISION_ONE_USER = 2,
  REPORT_BUTTON_DEPLOYMENT = 3,
  SIMULATION_DELIVERY_TEST = 4,
  COMPANY_SCAN = 5,
  MAP_GROUPS_DEPARTMENTS = 6,
  ATTRIBUTE_TEMPLATES_DEPARTMENTS = 7,
}

type CompanyScanStatus = {
  has_key_people: boolean;
  has_company_logo: boolean;
  has_email_signature: boolean;
  has_key_customers: boolean;
};

export type SimulationSetupChecklistStatus = {
  mail_host: MailHost;
  has_mail_permissions: boolean;
  has_provisioning_permissions: boolean;
  has_at_least_one_non_admin_user: boolean;
  delivery_tests: {};
  company_scan: CompanyScanStatus;
  has_mapped_groups_to_departments: boolean;
  assign_templates_to_departments: TemplateAssignationStatus;
  has_confirmed_all_delivery_tests: boolean;
  has_report_button_deployment: boolean;
};
