import React from "react";

const EyeIcon = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.44688 6.04802C1.36177 5.91327 1.31922 5.84589 1.2954 5.74196C1.27751 5.6639 1.27751 5.5408 1.2954 5.46273C1.31922 5.35881 1.36177 5.29143 1.44688 5.15668C2.15016 4.04309 4.24353 1.22797 7.43371 1.22797C10.6239 1.22797 12.7173 4.04309 13.4205 5.15668C13.5056 5.29143 13.5482 5.35881 13.572 5.46273C13.5899 5.5408 13.5899 5.6639 13.572 5.74196C13.5482 5.84589 13.5056 5.91327 13.4205 6.04802C12.7173 7.1616 10.6239 9.97673 7.43371 9.97673C4.24353 9.97673 2.15016 7.1616 1.44688 6.04802Z"
        stroke="#7F8995"
        strokeWidth="1.24982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.43371 7.47709C8.4691 7.47709 9.30845 6.63774 9.30845 5.60235C9.30845 4.56696 8.4691 3.72761 7.43371 3.72761C6.39832 3.72761 5.55898 4.56696 5.55898 5.60235C5.55898 6.63774 6.39832 7.47709 7.43371 7.47709Z"
        stroke="#7F8995"
        strokeWidth="1.24982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
