import React, { useRef, useState, useEffect, ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { Portlet, PortletBody } from "@/components/content/Portlet";
import "@/pages/phishing-simulation/dashboard/emailmessage/EmailMessage.css";
import { sanitizeHTML, useBackend } from "@/utils/misc";
import { Button } from "@mui/material";
import { CenteredStyledSpinner } from "@/components/Spinner";

export default function EmailMessage() {
  // Retrieve message id
  const location = useLocation();
  const messageId = new URLSearchParams(location.search).get("id");
  // Retrieve email from server
  const {
    data: emailMessageData,
    error,
    isPending,
  } = useBackend(
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
      "/emailmessage?email_id=" +
      messageId,
  );
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const [iFrameHeight, setIFrameHeight] = useState("100px");
  const [sanitizedBody, setSanitizedBody] = useState("");

  useEffect(() => {
    if (!emailMessageData) {
      return;
    }
    setSanitizedBody(sanitizeHTML(emailMessageData.content));
  }, [emailMessageData, setSanitizedBody]);

  useEffect(() => {
    if (
      isPending ||
      !emailMessageData ||
      !iFrameRef.current ||
      !sanitizedBody
    ) {
      return;
    }
    iFrameRef.current.onload = (e: Event) => {
      const iFrameBody = (e.target as HTMLIFrameElement)?.contentDocument?.body;
      if (!iFrameBody) {
        return;
      }
      const fullHeight = iFrameBody.scrollHeight;
      iFrameBody.style.overflowY = "hidden";
      setIFrameHeight(fullHeight + 50 + "px");
    };
  }, [emailMessageData, error, isPending, iFrameRef, sanitizedBody]);

  let body: ReactElement | null;
  if (isPending || !emailMessageData)
    body = <CenteredStyledSpinner width="50px" color="#777" />;
  if (error) body = <div>An error occurred</div>;
  if (emailMessageData)
    body = (
      <>
        <div>
          <b>From</b>: {emailMessageData.from_name} &lt;
          {emailMessageData.from_email}&gt;
        </div>
        <div>
          <b>To</b>: {emailMessageData.to_email}
        </div>
        <div>
          <b>Subject</b>: {emailMessageData.subject}
        </div>
        <div className={"mt-3"}>
          <Button
            variant="contained"
            href={
              import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
              `/click?email_id=${messageId}&tracking=false`
            }
            target="_blank"
            title={
              "Live the recipient click experience, without tracking: your actions (credentials, download, ..) will not impact dashboard statistics"
            }
          >
            Simulate Click
          </Button>
        </div>
        <p />
        <iframe
          sandbox="allow-same-origin"
          ref={iFrameRef}
          style={{ minHeight: iFrameHeight }}
          title="Template View"
          srcDoc={sanitizedBody}
          className="email-body"
        />
      </>
    );

  return (
    <Portlet>
      <PortletBody>{body}</PortletBody>
    </Portlet>
  );
}
