import React from "react";
import { Box, Typography } from "@mui/material";

export default function PhishingDetailsHeader({ domain }) {
  return (
    <Box sx={{ padding: "1rem 1.5rem 0.5rem" }}>
      <Typography sx={{ fontSize: "1.5rem" }}>
        Potential phishing attack
      </Typography>
      <Typography component={"span"} noWrap>
        {domain}
      </Typography>
    </Box>
  );
}
