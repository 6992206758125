import React from "react";

import { Box, Button, Stack, Typography } from "@mui/material";

export function ManualDeployment() {
  return (
    <div>
      <Stack spacing={2} style={{ marginBottom: "3rem" }}>
        <Typography>
          Browser Defender is compatible with <b>Chromium</b> browsers (
          <b>Chrome</b>, <b>Edge</b>, <b>Opera</b>, <b>Brave</b>, etc) and can
          be installed on any of them from the <b>Chrome Web Store</b>.
        </Typography>

        <Box display="flex" alignItems="center" gap="2.5rem">
          <Button
            variant="contained"
            onClick={() =>
              window.open(
                "https://chrome.google.com/webstore/detail/mantra-browser-defender/eohnjdeamkjliemanljkkjcepdfgcggl",
                "_blank",
              )
            }
          >
            Take me to the Chrome Web Store page
          </Button>
        </Box>
      </Stack>
    </div>
  );
}
