import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface BannerMailsProcessedChartData {
  date: string;
  mailsProcessed: number;
}

export default function BannerMailsProcessedChart({
  data,
  title,
}: {
  data: Array<BannerMailsProcessedChartData>;
  title: string;
}) {
  return (
    <Portlet>
      <PortletHeader title={title} />
      <PortletBody>
        <BannerMailsProcessedAreaChart dataset={data} />
      </PortletBody>
    </Portlet>
  );
}

const ActiveDot = (props: any) => {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 6.5}
      y={cy - 6}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.28125"
        cy="6"
        r="5"
        fill="white"
        stroke="#4CA2ED"
        strokeWidth="2"
      />
      <ellipse cx="6.28125" cy="6" rx="2" ry="2" fill="#4CA2ED" />
    </svg>
  );
};

const BannerMailsProcessedAreaChart = ({
  dataset,
}: {
  dataset: Array<BannerMailsProcessedChartData>;
}) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart
        data={dataset}
        margin={{ left: 0, top: 10, right: 10, bottom: 0 }}
      >
        <defs>
          <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor="#4CA2ED" stopOpacity={1} />
            <stop offset="97%" stopColor="#4CA2ED" stopOpacity={0.05} />
            <stop offset="100%" stopColor="#4CA2ED" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray={5} horizontal={false} />
        <Tooltip
          cursor={{
            color: "#4CA2ED",
            stroke: "#4CA2ED",
            strokeDasharray: 5,
            strokeWidth: 1,
          }}
        />
        <Area
          name="Emails processed"
          type="monotone"
          dataKey={"mailsProcessed"}
          fill="url(#blueGradient)"
          hide={false}
          activeDot={<ActiveDot />}
        />
        <YAxis width={50} axisLine={false} tickLine={false} />
        <XAxis dataKey={"date"} axisLine={false} tickLine={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};
