import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { extractErrorMessage } from "@/utils/misc";
import { Typography } from "@mui/material";
import axios from "axios";
import TrashIconButton from "@/components/icons/TrashIconButton";

export function AllKeyCustomers() {
  // Retrieve the customers from the API
  const endpointPath =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/company/key_customers";
  const [customers, setCustomers] = useState([]);
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  // Retrieve the customers
  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        const result = await axios.get(endpointPath, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setCustomers(result.data);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently, endpointPath]);

  const add = async () => {
    const nameTrimmed = name.trim();
    const exist =
      customers.find((customer) => customer.name === nameTrimmed) !== undefined;

    // Avoid empty strings and existing record
    if (nameTrimmed !== "" && !exist) {
      const accessToken = await getAccessTokenSilently();

      try {
        const customer = {
          name: nameTrimmed,
        };

        const result = await axios.post(endpointPath, customer, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setName("");
        setCustomers([...customers, result.data]);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    }
  };

  const onDelete = async (customerId) => {
    const accessToken = await getAccessTokenSilently();

    const updatedCustomersList = customers.filter(
      (customer) => customer.id !== customerId,
    );
    setCustomers(updatedCustomersList);

    await axios.delete(endpointPath + "/" + customerId, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <input
            type="text"
            placeholder="Company Name"
            value={name}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                // noinspection JSIgnoredPromiseFromCall
                add();
              }
            }}
            onChange={(e) => setName(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-2 mt-1">
          <Button variant="outlined" color="neutral" onClick={() => add()}>
            Add Customer
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <table className="table">
            <tbody>
              {customers.map((customer) => (
                <tr key={customer.id}>
                  <td style={{ verticalAlign: "middle" }}>{customer.name}</td>
                  <td>
                    <TrashIconButton
                      onClick={() => onDelete(customer.id)}
                      title="delete key customer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {errorMessage !== null && (
          <Typography style={{ color: "red" }}>{errorMessage}</Typography>
        )}
      </div>
    </>
  );
}
