import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import TableCell from "@mui/material/TableCell";
import StyledTableRow from "@/pages/browser-defender/styles/StyledTableRow";

const headers = [
  { label: "Detection time (UTC)", alignment: "left" },
  { label: "Email", alignment: "left" },
  { label: "Service", alignment: "left" },
  { label: "Score", alignment: "left" },
  { label: "", alignment: "left" },
];

const sortingColumns = [
  { label: "Email", name: "email" },
  { label: "Service", name: "service" },
  { label: "Score", name: "score" },
];

const filterColumns = ["email", "service", "score"];

function styleScore(score) {
  let bgColor, textColor;

  if (score === "average") {
    bgColor = "rgba(255, 175, 44, 0.2)";
    textColor = "#BE7A0C";
  } else if (score === "weak") {
    bgColor = "rgba(255, 49, 111, 0.2)";
    textColor = "#B50A3E";
  }

  return (
    <Box
      sx={{
        color: `${textColor}`,
        bgcolor: `${bgColor}`,
        fontWeight: "600",
        p: "0.5rem",
        fontSize: "0.7rem",
        display: "inline-flex",
      }}
    >
      {score[0].toUpperCase() + score.slice(1)}
    </Box>
  );
}

export default function DataTable({ data, setData }) {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [spinnerDisplay, setSpinnerDisplay] = useState(-1);

  function styleAction(index, data) {
    if (spinnerDisplay === index) {
      return <CircularProgress size="1.7rem" color="inherit" />;
    }

    if (data[index].password_change_requested) {
      return (
        <Box>
          <Typography sx={{ color: "rgba(117, 103, 254, 0.75)" }}>
            Password change requested
          </Typography>
          <Typography
            sx={{ color: "rgba(92, 87, 106, 0.75)", fontSize: "0.78rem" }}
          >
            {data[index].password_change_requested}
          </Typography>
        </Box>
      );
    }

    return (
      <Button
        variant="outlined"
        sx={{
          color: "#7567FE",
          fontWeight: "500",
          pt: "0.1rem",
          pb: "0.1rem",
        }}
        onClick={() => {
          setSpinnerDisplay(index);
          setTimeout(() => {
            data[index].password_change_requested =
              moment().format("yyyy-MM-DD HH:mm");
            setSpinnerDisplay(-1);
          }, 1000);
        }}
      >
        Request password change
      </Button>
    );
  }

  useEffect(() => {
    let filteredValues;

    if (!searchTerm) {
      filteredValues = data.values;
    } else {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    filteredValues.sort((p1, p2) => {
      let condition = 1;
      for (let sortRule of sorting) {
        condition *=
          p1[sortRule.column].toLowerCase() < p2[sortRule.column].toLowerCase()
            ? -1 * sortRule.direction
            : p1[sortRule.column].toLowerCase() >
                p2[sortRule.column].toLowerCase()
              ? 1 * sortRule.direction
              : 0;
      }
      return condition;
    });

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [searchTerm, sorting, data.values, setData]);

  return (
    <CollapsibleTable
      headers={headers}
      sortingColumns={sortingColumns}
      setSorting={setSorting}
      setSearchTerm={setSearchTerm}
      defaultOrder={[{ column: "email", direction: 1 }]}
      rows={data.filteredValues.map((row, index) => {
        return (
          <StyledTableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" align={headers[0].alignment}>
              {row.detection_time}
            </TableCell>
            <TableCell align={headers[1].alignment}>{row.email}</TableCell>
            <TableCell align={headers[2].alignment}>{row.service}</TableCell>
            <TableCell align={headers[3].alignment}>
              {styleScore(row.score)}
            </TableCell>
            <TableCell align={headers[4].alignment}>
              {styleAction(index, data.filteredValues)}
            </TableCell>
          </StyledTableRow>
        );
      })}
    />
  );
}
