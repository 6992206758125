import React from "react";

export function PortletHeaderCustom({ title }) {
  return (
    <h3 className="MuiTypography-root makeStyles-header-52 MuiTypography-body1">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label makeStyles-header-52">
          <h3 className="kt-portlet__head-title" style={{ fontSize: 18 }}>
            {title}
          </h3>
        </div>
      </div>
    </h3>
  );
}
