import React from "react";
import { Chip, Grid, Tooltip } from "@mui/material";

export default function RiskChip({
  danger_level,
  colors,
  tooltipInfo,
  tooltipConfig,
  size = "medium",
}) {
  const danger_label =
    danger_level.charAt(0).toUpperCase() + danger_level.slice(1);

  const formatColor = Object.keys(colors).includes(danger_level)
    ? colors[danger_level]
    : colors["default"];

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <Tooltip
          title={tooltipInfo ? tooltipInfo : danger_label}
          slotProps={tooltipConfig}
        >
          <Chip
            icon={formatColor.icon}
            size={size}
            sx={{
              color: `${formatColor.textColor}`,
              bgcolor: `${formatColor.bgColor}`,
              borderColor: `${formatColor.textColor}`,
              padding: "0 6px",
              width: "auto",
            }}
            variant="outlined"
            label={danger_label}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
}
