import React from "react";
import { Portlet, PortletBody } from "@/components/content/Portlet";

export function OktaSync() {
  return (
    <Portlet>
      <PortletBody>
        Please follow{" "}
        <a
          href="https://www.notion.so/mantrams/Okta-SCIM-Provisioning-f45b2f511419461da2c59e6ea7ceb9f0"
          rel="noopener noreferrer"
          target="_blank"
        >
          the Mantra-Okta integration guide
        </a>{" "}
        to configure Okta user provisioning.
        <br />
        <br />
      </PortletBody>
    </Portlet>
  );
}
