import useThreatFetch from "./_fetch_get";
import { useCallback, useEffect, useState } from "react";
import { buildThreatDetailsUrl } from "./_url_builders";

// TODO
const isThreatDetails = (o: any): o is ThreatDetails => {
  return true;
};

export const useFetchThreatDetails = () => {
  const {
    data,
    isLoading: threatDetailsIsLoading,
    isError: threatDetailsIsError,
    threatFetch,
  } = useThreatFetch();
  const [threatDetails, setThreatDetails] = useState<ThreatDetails | null>(
    null,
  );

  const fetchThreatDetails = useCallback(
    async (threatId: number) => {
      const url = buildThreatDetailsUrl(threatId);
      threatFetch(url);
    },
    [threatFetch],
  );

  useEffect(() => {
    if (data === null) {
      setThreatDetails(null);
      return;
    }
    if (isThreatDetails(data)) {
      setThreatDetails(data);
    } else {
      throw new Error("Invalid threat details data");
    }
  }, [data]);

  return {
    threatDetails,
    threatDetailsIsLoading,
    threatDetailsIsError,
    fetchThreatDetails,
  };
};
