import React from "react";
import { Consent } from "@/pages/general-settings/Office/Consent";
import { Blacklist } from "@/pages/general-settings/commonUserProvisioning/Blacklist";
import { EnableCheckbox } from "@/pages/general-settings/commonUserProvisioning/EnableCheckbox";
import { ManualSync } from "@/pages/general-settings/commonUserProvisioning/ManualSync";
import { GroupWhitelist } from "@/pages/general-settings/GroupWhitelist";
import { Typography, Paper, Stack, Box } from "@mui/material";
import { useUserContext } from "@/utils/contexts/UserContext";
import { UsersWithNoLicenseCheckbox } from "@/pages/general-settings/Office/UsersWithNoLicenseCheckbox";

export function OfficeSync() {
  const user = useUserContext();

  return (
    <Paper sx={{ padding: "2rem" }}>
      {user?.base_company.id === user?.current_company.id ? (
        <Stack useFlexGap sx={{ gap: "4rem" }}>
          <Box>
            <Typography variant="h2" sx={{ mb: "1rem" }}>
              Grant Consent
            </Typography>
            <Consent />
          </Box>
          <Box>
            <Typography variant="h2" sx={{ mb: "1rem" }}>
              Blacklisted users (optional)
            </Typography>
            <Blacklist />
          </Box>
          <Box>
            <Typography variant="h2" sx={{ mb: "1rem" }}>
              Whitelisted groups (optional)
            </Typography>
            <GroupWhitelist />
          </Box>
          <Stack>
            <Typography variant="h2" sx={{ mb: "1rem" }}>
              Provisioning
            </Typography>
            <EnableCheckbox />
            <UsersWithNoLicenseCheckbox />
            <ManualSync />
          </Stack>
        </Stack>
      ) : (
        <Typography color="error">
          You can only modify settings from {user?.base_company.name}
        </Typography>
      )}
    </Paper>
  );
}
