import React from "react";
import { Stack, Typography } from "@mui/material";
import StickyHeader from "@/components/StickyHeader";
import { TemplateSearchbar } from "./TemplateSearchbar";
import { useTemplateCatalogContext } from "@/pages/templates/TemplateCatalogContextProvider";

export const TemplateHeader = () => {
  const catalogContext = useTemplateCatalogContext();
  return (
    <>
      <StickyHeader height="110px">
        <Stack direction="column" gap={3}>
          <Typography
            variant="h2"
            style={{
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Template{" "}
            {catalogContext.isInSelectionMode ? "Selection" : "Catalog"}
          </Typography>
          <TemplateSearchbar />
        </Stack>
      </StickyHeader>
    </>
  );
};
