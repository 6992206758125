import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { AllKeyCustomers } from "@/pages/phishing-simulation/company/keycustomers/AllKeyCustomers";

export function KeyCustomers() {
  return (
    <Portlet>
      <PortletHeader title="Key Customers" />

      <PortletBody>
        <div>
          <AllKeyCustomers />
        </div>
      </PortletBody>
    </Portlet>
  );
}
