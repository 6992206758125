import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { BrowserIcon } from "@/components/icons/BrowserIcon";
import { FolderDownloadIcon } from "@/components/icons/FolderDownloadIcon";
import { PasswordExposedIcon } from "@/components/icons/PasswordExposedIcon";
import { ShieldIcon } from "@/components/icons/ShieldIcon";
import StatsCard from "@/pages/browser-defender/components/StatsCard";

export default function StatCards({ error, eventsData }) {
  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <StatsCard
          title="Users with active extensions"
          icon={<ShieldIcon fontSize="32px" color="#7567FE" />}
          link={"/browser-defender/users"}
          data={
            <>
              {eventsData.values.extensions &&
                eventsData.values.extensions.active}

              <Box
                sx={{
                  fontSize: "2rem",
                  textAlign: "left",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  color: "#646c9a",
                  marginTop: "1rem",
                }}
              >
                <Typography>
                  Active w/o password:&nbsp;
                  {eventsData.values.extensions &&
                    eventsData.values.extensions.with_partial_defense}
                </Typography>
                <Typography>
                  Disabled by user:&nbsp;
                  {eventsData.values.extensions &&
                    eventsData.values.extensions.disabled}
                </Typography>
                <Typography>
                  Last seen +30 days ago:&nbsp;
                  {eventsData.values.extensions &&
                    eventsData.values.extensions.inactive}
                </Typography>
              </Box>
            </>
          }
          isPending={eventsData.loading}
          error={error.events}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <StatsCard
          title="Password alerts"
          icon={<PasswordExposedIcon fontSize="32px" color="#7567FE" />}
          link={"/browser-defender/password-misuse"}
          data={
            <>
              {eventsData.values.password_misuse &&
                eventsData.values.password_misuse.count}

              <Box
                sx={{
                  fontSize: "2rem",
                  textAlign: "left",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  color: "#646c9a",
                  marginTop: "1rem",
                }}
              >
                <Typography>
                  With domain set as trusted:&nbsp;
                  {
                    eventsData.values.password_misuse &&
                      // <Link
                      //   style={{ fontWeight: "bold" }}
                      //   to={`/browser-defender/password-misuse?filter=mark_as_safe`}
                      // >
                      // {
                      eventsData.values.password_misuse.with_domain_set_as_safe
                    // }
                    // </Link>
                  }
                </Typography>
              </Box>
            </>
          }
          isPending={eventsData.loading}
          error={error.events}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <StatsCard
          title="Dangerous download alerts"
          icon={<FolderDownloadIcon fontSize="32px" color="#7567FE" />}
          link="/browser-defender/dangerous-downloads"
          data={
            <>
              {eventsData.values.dangerous_downloads &&
                eventsData.values.dangerous_downloads.count}

              <Box
                sx={{
                  fontSize: "2rem",
                  textAlign: "left",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  color: "#646c9a",
                  marginTop: "1rem",
                }}
              >
                <Typography>
                  With file downloaded:&nbsp;
                  {eventsData.values.dangerous_downloads &&
                    eventsData.values.dangerous_downloads.completed}
                </Typography>
              </Box>
            </>
          }
          isPending={eventsData.loading}
          error={error.events}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <StatsCard
          title="Dangerous browsing alerts"
          icon={<BrowserIcon fontSize="32px" color="#7567FE" />}
          link="/browser-defender/dangerous-browsing"
          data={
            <>
              {eventsData.values.unsafe_navigation &&
                eventsData.values.unsafe_navigation.count}

              <Box
                sx={{
                  fontSize: "2rem",
                  textAlign: "left",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  color: "#646c9a",
                  marginTop: "1rem",
                }}
              >
                <Typography>
                  With page visited:&nbsp;
                  {eventsData.values.unsafe_navigation &&
                    eventsData.values.unsafe_navigation.completed}
                </Typography>
              </Box>
            </>
          }
          isPending={eventsData.loading}
          error={error.events}
        />
      </Grid>
    </>
  );
}
