import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "@/utils/contexts/UserContext";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import axios from "axios";
import { sentryCaptureException } from "@/utils/sentry";
import { GeneralInfoTable } from "@/pages/browser-defender/userDetails/GeneraLInfoTable";

export function GeneralInfoCard({ user_email }) {
  const [user_details, setUserDetails] = useState({ data: {}, loading: true });

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchUserDetails = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const path = "/user/general_details";

      const url = `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}${path}`;

      let resource;
      try {
        resource = await axios.post(
          url,
          { email: user_email },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      setUserDetails({
        data: resource.data,
        loading: false,
      });
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently, user_email]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchUserDetails();
  }, [fetchUserDetails, info_user]);

  return (
    <GenericCard title={"General information"} isLoading={user_details.loading}>
      <GeneralInfoTable data={user_details.data} />
    </GenericCard>
  );
}
