import { Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(0,0,0,0.75)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0,0,0,0.75)",
    color: "rgb(255,255,255)",
    fontSize: "0.8rem",
    textAlign: "center",
  },
}));

export const BarPart = (props) => {
  const minHeight = props.minHeight ? props.minHeight : "25px";

  return (
    <CustomTooltip
      disableInteractive
      placement="top"
      title={props.tooltip}
      arrow
    >
      <div style={{ width: props.width + "%" }}>
        <div
          className="d-flex justify-content-center border-left border-dark"
          style={{ minHeight: minHeight, backgroundColor: props.color }}
        >
          <div
            className="my-auto text-white text-truncate"
            style={{ fontWeight: 500 }}
          >
            {props.width}%
          </div>
        </div>
        <div className="pl-2 border-left border-dark text-truncate">
          {props.label} : {props.userCount} users
        </div>
      </div>
    </CustomTooltip>
  );
};
