import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Box } from "@mui/material";
import { setIFrameHeight } from "@/utils/misc";

const EmailBody = ({
  body,
  isExpanded,
}: {
  body: string;
  isExpanded: boolean;
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  let content: React.JSX.Element;
  if (expanded) {
    content = (
      <iframe
        sandbox="allow-same-origin"
        width="100%"
        title="Threat view"
        onLoad={(e) => setIFrameHeight(e.target as HTMLIFrameElement)}
        onChange={(e) => setIFrameHeight(e.target as HTMLIFrameElement)}
        srcDoc={body}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          outline: 0,
        }}
      />
    );
  } else {
    content = (
      <Button
        variant="outlined"
        color="neutral"
        sx={{ flex: "0 0 auto" }}
        onClick={() => setExpanded(true)}
        startIcon={<VisibilityIcon />}
      >
        View body
      </Button>
    );
  }

  return (
    <>
      <div>
        <strong>Email Body:</strong>
      </div>
      <Box sx={{ mt: 1 }}>{content}</Box>
    </>
  );
};

export default EmailBody;
