import React from "react";
import { YourStats } from "@/pages/my-progress/stats/YourStats";
import { UserActivity } from "@/pages/my-progress/activity/UserActivity";
import { useBackend } from "@/utils/misc";
import { Leaderboard } from "@/pages/my-progress/leaderboard/Leaderboard";
import { Badges } from "@/pages/my-progress/badges/Badges";
import { LearningLanguage } from "@/pages/my-progress/LearningLanguage";

export function MyProgress() {
  let endpoint_url = import.meta.env.VITE_APP_ENDPOINT_SIMULATION;
  const { data, error, isPending } = useBackend(
    endpoint_url + "/current_user/activity",
  );

  let body = null;
  if (isPending || !data)
    body = (
      <div>
        <div className="row row-full-height">
          <div className="col-md-3">
            Loading...
            <Leaderboard />
          </div>
          <div className="col-md-9">Loading...</div>
        </div>
      </div>
    );
  if (error) body = <div>An error occurred</div>;
  if (data) {
    body = (
      <div>
        <div className="row row-full-height">
          <div className="col-md-4">
            <YourStats />
            <Leaderboard />
            <LearningLanguage />
          </div>
          <div className="col-md-8">
            <Badges />
            <UserActivity data={data} />
          </div>
        </div>
      </div>
    );
  }

  return body;
}
