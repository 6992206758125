import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { BackendSuspense } from "./BackendSuspense";
import { Tooltip } from "@mui/material";

export const NbUserCard = ({ className, usersCount }) => {
  return (
    <Tooltip title="Total number of users">
      <Portlet
        className={className}
        style={{
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PortletHeader title="User count" />
        <PortletBody style={{ height: "95px" }}>
          <div className="d-flex justify-content-center align-items-center">
            <BackendSuspense hasData={usersCount > 0}>
              <h1 className="mb-3">{usersCount}</h1>
            </BackendSuspense>
          </div>
        </PortletBody>
      </Portlet>
    </Tooltip>
  );
};
