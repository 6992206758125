import React from "react";
import EmailHeader from "./EmailHeader";

const EmailHeaders = ({ headers }: { headers: EmailHeaderNameValue[] }) => {
  return (
    <>
      {headers.map((header) => (
        <EmailHeader key={header.name + header.value} header={header} />
      ))}
    </>
  );
};

export default EmailHeaders;
