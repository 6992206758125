import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const weeksInterval = [
  { value: 1, label: "1 Week" },
  { value: 2, label: "2 Weeks" },
  { value: 4, label: "1 Month" },
  { value: 8, label: "2 Months" },
];

const monthsInterval = [
  { value: 1, label: "1 Month" },
  { value: 3, label: "3 Months" },
  { value: 6, label: "6 Months" },
  { value: 12, label: "1 Year" },
];

export default function TimeFrameDropdown({
  dateFromNbWeeksAgo,
  setDateFromNbWeeksAgo,
  setSelectedPeriod,
  intervalType = "weeks",
}) {
  const handleChange = (event) => {
    setDateFromNbWeeksAgo(event.target.value);
    setSelectedPeriod((current) => ({ ...current, value: null }));
  };

  let intervals = intervalType === "months" ? monthsInterval : weeksInterval;

  return (
    <>
      <FormControl
        sx={{
          width: "150px",
        }}
      >
        <InputLabel size="small" htmlFor="timeframe-select">
          Timeframe
        </InputLabel>
        <Select
          label="Timeframe"
          id="timeframe-select"
          size="small"
          value={dateFromNbWeeksAgo}
          onChange={handleChange}
        >
          {intervals.map((item, index) => {
            return (
              <MenuItem value={item.value} key={index}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}
