import React from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const CourseStatusChip = ({ course, learningUserInfo }) => {
  const { t } = useTranslation("translation", {
    lng: learningUserInfo.language,
  });

  if (learningUserInfo.current_course === course.id) {
    return (
      <Chip
        label={t("ONGOING")}
        size={"small"}
        style={{
          backgroundColor: "#BE7A0C1A",
          borderRadius: "10px",
          color: "#BE7A0C",
          width: "auto",
        }}
        sx={{ marginLeft: "10px" }}
      />
    );
  }
  if (learningUserInfo.finished_courses.includes(course.id)) {
    return (
      <Chip
        label={t("COURSE COMPLETED")}
        size={"small"}
        style={{
          backgroundColor: "#147B1E1A",
          borderRadius: "10px",
          color: "#147B1E",
          width: "auto",
        }}
        sx={{ marginLeft: "10px" }}
      />
    );
  }
  if (learningUserInfo.viewed_courses.includes(course.id)) {
    return (
      <Chip
        label={t("COURSE FOLLOWED")}
        size={"small"}
        style={{
          backgroundColor: "#6DAF5F1A",
          borderRadius: "10px",
          color: "#6DAF5F",
          width: "auto",
        }}
        sx={{ marginLeft: "10px" }}
      />
    );
  }
  return (
    <Chip
      label={t("TO COMPLETE")}
      size={"small"}
      style={{
        backgroundColor: "#317AE41A",
        borderRadius: "10px",
        color: "#317AE4",
        width: "auto",
      }}
      sx={{ marginLeft: "10px" }}
    />
  );
};
