import React, { useState } from "react";
import {
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import AutocompleteSelectAll from "./AutocompleteSelectAll";
import { useSimulationResource } from "@/utils/ResourceGet";
import { Controller } from "react-hook-form";

const TargetedDepartmentsComponent = ({
  open,
  departments,
  errors,
  watch,
  setValue,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  return (
    <Collapse
      in={open}
      timeout="auto"
      sx={{ width: "100%" }}
      unmountOnExit
      key={"departments_subset_collapsed"}
    >
      <AutocompleteSelectAll
        options={departments}
        type={"department"}
        setSelectAll={setSelectAll}
        setSelectedOptions={(data: { id: number; name: string }) =>
          setValue("selectedDepartments", data, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          })
        }
        selectedOptions={watch("selectedDepartments")}
        selectAll={selectAll}
        error={!!errors["selectedDepartments"]}
        helperText={errors["selectedDepartments"]?.message}
      />
    </Collapse>
  );
};

const TargetedUsersComponent = ({ open, users, errors, watch, setValue }) => {
  const [selectAll, setSelectAll] = useState(false);
  const emails = users.map((user) => user?.email);

  return (
    <Collapse
      in={open}
      timeout="auto"
      unmountOnExit
      key={"users_subset_collapsed"}
    >
      {/* Solve multiple same value */}
      <AutocompleteSelectAll
        options={emails ?? []}
        type={"user"}
        setSelectAll={setSelectAll}
        setSelectedOptions={(data: string) => {
          setValue("selectedUsers", data, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });
        }}
        selectedOptions={watch("selectedUsers")}
        selectAll={selectAll}
        error={!!errors["selectedUsers"]}
        helperText={errors["selectedUsers"]?.message}
      />
    </Collapse>
  );
};

export const SelectTargetedRadioFormControl = ({
  watch,
  errors,
  control,
  setValue,
}) => {
  // TODO handle errors and handling
  const [departments] = useSimulationResource(
    "departments_from_user?with_id=True",
    [],
  );
  const [users] = useSimulationResource("banners/users", []);

  return (
    <FormControl
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Controller
        name="targetedType"
        control={control}
        render={({ field }) => (
          <RadioGroup
            {...field}
            sx={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControlLabel
              value="departments"
              control={<Radio />}
              label="Send to selected departments"
            />
            <TargetedDepartmentsComponent
              open={watch("targetedType") === "departments"}
              departments={departments}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
            <FormControlLabel
              value="users"
              control={<Radio />}
              label="Send to selected users"
            />
            <TargetedUsersComponent
              open={watch("targetedType") === "users"}
              users={users}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
