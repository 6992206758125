import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import App from "./App";
import "./index.scss"; // Standard version
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./auth_config.json";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "@/i18n";

// Suppress React warnings that are outside our control: https://stackoverflow.com/questions/64158705/
const originalConsoleError = console.error;

console.error = function filterWarnings(msg) {
  const supressedWarnings = [
    {
      // Microsoft's ReactWebChat
      msg: "Warning: Cannot update a component (`%s`) while rendering a different component (`%s`)",
      arg1: "ActivityAcknowledgementComposer",
      arg2: "InternalTranscriptScrollable",
    },
  ];
  const args = arguments; // get variable arguments: https://stackoverflow.com/questions/7362671/
  if (
    !supressedWarnings.some((entry) => {
      return (
        msg?.includes &&
        msg.includes(entry.msg) &&
        (!entry.arg1 || entry.arg1 === args[1]) &&
        (!entry.arg2 || entry.arg2 === args[2])
      );
    })
  ) {
    originalConsoleError.apply(console, arguments);
  }
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin + "/callback",
        audience: config.audience,
      }}
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>,
);
