import React from "react";
import ThreatTabs from "./ThreatTabs";
import UnarchivedThreatListButtonGroup from "../../common/button_group/UnarchivedThreatListButtonGroup";
import ArchivedThreatListButtonGroup from "../../common/button_group/ArchivedThreatListButtonGroup";
import Title from "./Title";
import StickyHeader from "@/components/StickyHeader";
import { Box, Stack, Typography } from "@mui/material";

type TopBarProps = {
  actionButtonsEnabled: boolean;
  onFeedback: (feedback: FeedbackType) => void;
  onDelete: () => void;
  onUnarchive: () => void;
  isArchived: boolean;
  onTabChange: (tab: ThreatListTab) => void;
};

const TopBar = ({
  actionButtonsEnabled,
  onFeedback,
  onDelete,
  onUnarchive,
  isArchived,
  onTabChange,
}: TopBarProps) => {
  return (
    <>
      <StickyHeader>
        <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
          <Typography
            variant="h2"
            style={{
              marginRight: "auto",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Title />
          </Typography>
          {isArchived ? (
            <UnarchivedThreatListButtonGroup
              onUnarchive={onUnarchive}
              onDelete={onDelete}
              actionButtonsEnabled={actionButtonsEnabled}
            />
          ) : (
            <ArchivedThreatListButtonGroup
              onFeedback={onFeedback}
              onDelete={onDelete}
              actionButtonsEnabled={actionButtonsEnabled}
            />
          )}
        </Stack>
      </StickyHeader>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <ThreatTabs
            onChange={(tab) => onTabChange(tab)}
            tab={isArchived ? "archived" : "threats"}
          />
        </Box>
      </Box>
    </>
  );
};

export default TopBar;
