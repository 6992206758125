import { extractErrorMessage } from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignCreate/customCampaignError";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useBackend } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";

export default function RestartDropOffConfirmationDialog({
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  target,
}) {
  const user = useUserContext();
  const base_url = `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/company/${
    user.current_company.id
  }`;
  const url = `${base_url}/total_reset`;
  const { data } = useBackend(url);
  const [sendInProgress, setSendInProgress] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  let total = 0;
  if (data) {
    total =
      target === "drop_off" ? data.total_drop_off : data.total_not_started;
  }

  const title =
    target === "drop_off"
      ? "Restart courses of drop off users"
      : "Remind not started users";
  const onClickReset = async (e) => {
    const token = await getAccessTokenSilently();
    setSendInProgress(true);
    try {
      await axios
        .post(
          `${base_url}/schedule_reset`,
          { target: target },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          enqueueSnackbar("Email was sent successfully ", {
            variant: "success",
            autoHideDuration: 3000,
          });
          setSendInProgress(false);
          setConfirmationDialogOpen(false);
        });
    } catch (error) {
      setSendInProgress(false);
      enqueueSnackbar(extractErrorMessage(error), { variant: "error" });
    }
  };

  const onClickSendPreviewEmail = async (e) => {
    const token = await getAccessTokenSilently();
    setSendInProgress(true);
    try {
      await axios
        .post(
          `${
            import.meta.env.VITE_APP_ENDPOINT_LEARNING
          }/user/preview_email_reset`,
          { target: target },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          enqueueSnackbar("Email was sent successfully ", {
            variant: "success",
          });
          setSendInProgress(false);
        });
    } catch (error) {
      setSendInProgress(false);
      enqueueSnackbar(extractErrorMessage(error), { variant: "error" });
    }
  };

  return (
    <Dialog open={confirmationDialogOpen} maxWidth={"xs"} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      {data && (
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>
              <strong>
                You’re about to {target === "drop_off" ? "restart" : "remind"}{" "}
                the course of {total} users
              </strong>
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              When you click “Send now”, we will send them a notification email
              and ping them on their course channel tomorrow
            </Typography>
          </Box>
        </DialogContent>
      )}

      <Divider />

      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button
            disabled={sendInProgress}
            onClick={() => setConfirmationDialogOpen(false)}
          >
            Cancel
          </Button>
          <Box>
            <Button
              disabled={sendInProgress}
              onClick={onClickSendPreviewEmail}
              variant={"outlined"}
              sx={{ marginRight: "5px" }}
            >
              Preview
            </Button>
            <Button
              disabled={sendInProgress}
              disableElevation
              onClick={onClickReset}
              variant={"contained"}
            >
              Send now
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
