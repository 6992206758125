import styled from "styled-components";
import { Link } from "react-router-dom";

export const BlackTextLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface StyledBlurProps {
  blur: number;
}
export const Blur = styled.div<StyledBlurProps>`
  filter: blur(${(props) => props?.blur ?? 5}px);
`;
