import React from "react";
import { EtcRow } from "@/pages/my-progress/leaderboard/EtcRow";
import { LeaderboardRow } from "@/pages/my-progress/leaderboard/LeaderboardRow";
import { List } from "@mui/material";

export const LeaderboardList = ({ data }) => {
  return (
    <List sx={{ py: 0 }}>
      {data.map(
        (
          user_row: {
            rank: number;
            name: string;
            starts: number;
            is_logged_user: boolean;
          },
          index: number,
        ) => {
          const is_there_rank_gap =
            index !== 0 && user_row.rank - data[index - 1].rank > 1;
          return (
            <React.Fragment key={user_row.name}>
              {is_there_rank_gap && <EtcRow />}
              <LeaderboardRow user_row={user_row} is_first_row={index === 0} />
            </React.Fragment>
          );
        },
      )}
    </List>
  );
};
