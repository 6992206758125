import StickyHeader from "@/components/StickyHeader";
import { Typography } from "@mui/material";
import React from "react";
import { SimulationSetupChecklist } from "./SimulationSetupChecklist";
import { Preferences } from "./Preferences";
import { DeliveryTest } from "./DeliveryTest";
import Tabs, {
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { useUserContext } from "@/utils/contexts/UserContext";
import { SimulationAccess } from "@/types/user";

const InnerSetup: React.FC = () => {
  const { currentTabComponent } = useTabsContext();

  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">Simulation Setup</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
};

export const Setup: React.FC = () => {
  const { current_company } = useUserContext();

  const tabs = [
    {
      anchor: "setup-checklist",
      label: "Setup Checklist",
      component: <SimulationSetupChecklist />,
    },
    {
      anchor: "delivery-test",
      label: "Delivery Test",
      component: <DeliveryTest />,
    },
  ];

  // Add Preferences tab only if the company has full access
  if (current_company?.simulation_access === SimulationAccess.FULL_ACCESS) {
    tabs.push({
      anchor: "preferences",
      label: "Preferences",
      component: <Preferences />,
    });
  }

  return (
    <TabsContextProvider value={{ tabs }}>
      <InnerSetup />
    </TabsContextProvider>
  );
};
