import React from "react";
import UnarchiveButton from "../button/UnarchiveButton";
import DeleteButton from "../button/DeleteButton";

const UnarchivedThreatListButtonGroup = ({
  onUnarchive,
  onDelete,
  actionButtonsEnabled,
}: {
  onUnarchive: () => void;
  onDelete: () => void;
  actionButtonsEnabled: boolean;
}) => {
  return (
    <>
      <UnarchiveButton onClick={onUnarchive} enabled={actionButtonsEnabled} />
      <DeleteButton onClick={onDelete} enabled={actionButtonsEnabled} />
    </>
  );
};

export default UnarchivedThreatListButtonGroup;
