import React, { useEffect, useState } from "react";
import { Delete } from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/Delete";
import { TextField } from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "@/utils/misc";
import SaveIconButton from "@/components/icons/SaveIconButton";
import EditIconButton from "@/components/icons/EditIconButton";

const updateDepartments = (allDepartments, newDepartment) => {
  const filteredDepartments = allDepartments.filter(
    (dept) => dept.id !== newDepartment.id,
  );
  const previousDepartment = allDepartments.filter(
    (dept) => dept.id === newDepartment.id,
  );

  return [
    ...filteredDepartments,
    { ...newDepartment, saas: previousDepartment[0].saas },
  ];
};

export function DepartmentItem({ department, departments, setDepartments }) {
  const [editing, setEditing] = useState(false);
  const [departmentNameLocal, setDepartmentNameLocal] = useState(
    department.name,
  );

  useEffect(() => {
    setDepartmentNameLocal(department.name);
    setEditing(false);
  }, [department.name]);

  const { getAccessTokenSilently } = useAuth0();
  const handleSave = async () => {
    const response = await axios.patch(
      `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/departments/${
        department.id
      }`,
      { name: departmentNameLocal },
      await getConfig(getAccessTokenSilently),
    );

    setDepartments((allDepartments) =>
      updateDepartments(allDepartments, response.data),
    );
    setEditing(false);
  };

  return (
    <tr key={department.id}>
      <td style={{ width: 220 }}>
        {editing ? (
          <TextField
            variant="standard"
            value={departmentNameLocal}
            onChange={(event) => setDepartmentNameLocal(event.target.value)}
          />
        ) : (
          <span>{department.name}</span>
        )}
      </td>
      <td>
        {editing ? (
          <SaveIconButton title="Save department" onClick={handleSave} />
        ) : (
          <EditIconButton
            title="Edit department"
            onClick={() => setEditing(true)}
          />
        )}
      </td>
      <td>
        <Delete
          tooltip="Delete department"
          department={department}
          departments={departments}
          setDepartments={setDepartments}
        />
      </td>
    </tr>
  );
}
