import React, { useState } from "react";
import { UserList } from "@/pages/general-settings/UsersManagement/usersPanel/UserList";
import { ImportButton } from "@/pages/general-settings/UsersManagement/csvImport/ImportButton";
import { ExportButton } from "@/pages/general-settings/UsersManagement/export/ExportButton";
import styled from "styled-components";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";
import { useUserContext } from "@/utils/contexts/UserContext";

const UserOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;

  & > .buttons {
    display: flex;
    gap: 10px;
  }

    > label {
      margin-bottom: 0;
    }

    .custom-control-label {
      cursor: pointer;
    }
  }
`;

export function Users() {
  const [activeUsersOnly, setActiveUsersOnly] = useState(true);

  return (
    <div>
      <UserOptions>
        {useUserContext().current_company.provider === "CSV" && (
          <div className="buttons">
            <ImportButton />
            <ExportButton />
          </div>
        )}
        <BootstrapStyleSwitch
          id="active-users-switch"
          label="Show active users only"
          checked={activeUsersOnly}
          onChange={(event) => setActiveUsersOnly(event.target.checked)}
        />
      </UserOptions>
      <div className="row">
        <div className="col-md-12 no-right-padding no-left-padding">
          <UserList activeUsersOnly={activeUsersOnly} />
        </div>
      </div>
    </div>
  );
}
