import React, { useMemo } from "react";
import { PeopleSelector } from "@/pages/phishing-simulation/company/keypeople/PeopleSelector";
import { useSimulationResource } from "@/utils/ResourceGet";
import { Typography } from "@mui/material";

export function AllKeyPeople() {
  const [users, usersError] = useSimulationResource("users/basic_infos", []);

  // order by firstname
  const orderedUsers = useMemo(
    () =>
      users
        ?.slice()
        ?.sort((a, b) =>
          a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0,
        ) ?? [],
    [users],
  );

  return (
    <>
      {usersError !== null && (
        <div className="row">
          <Typography style={{ color: "red" }}>{usersError.message}</Typography>
        </div>
      )}
      {orderedUsers.length > 0 && (
        <>
          <PeopleSelector
            users={orderedUsers}
            path="ceo"
            label="CEO"
            mandatory={true}
          />
          <br />
          <PeopleSelector
            users={orderedUsers}
            path="cfo"
            label="Chief Financial Officer"
            mandatory={true}
          />
          <br />
          <PeopleSelector
            users={orderedUsers}
            path="cpo"
            label="Chief Product Officer"
            mandatory={false}
          />
          <br />
          <PeopleSelector
            users={orderedUsers}
            path="hr"
            label="Human Resources Director"
            mandatory={true}
          />
          <br />
          <PeopleSelector
            users={orderedUsers}
            path="sales_director"
            label="Sales Director"
            mandatory={false}
          />
          <br />
          <PeopleSelector
            users={orderedUsers}
            path="it_manager"
            label="IT Manager"
            mandatory={true}
          />
        </>
      )}
    </>
  );
}
