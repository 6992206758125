import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStepperFromChecklistState,
  setStepperState,
  useStatusQuery,
} from "@/pages/phishing-simulation/setup/SetupChecklistSlice";
import { StepperStateKey } from "@/pages/phishing-simulation/setup/SetupChecklistTypes";
import MailingPermissions from "@/pages/phishing-simulation/setup/stepComponents/MailingPermissions";
import ProvisioningPermissions from "@/pages/phishing-simulation/setup/stepComponents/ProvisioningPermissions";
import ProvisionAtLeastOneOtherUser from "@/pages/phishing-simulation/setup/stepComponents/ProvisionAtLeastOneOtherUser";
import { ReportButtonDeployment } from "@/pages/phishing-simulation/setup/stepComponents/ReportButtonDeployment";
import TestDelivery from "@/pages/phishing-simulation/setup/stepComponents/TestDelivery";
import CompanyScan from "@/pages/phishing-simulation/setup/stepComponents/CompanyScan";
import MapGroupsToDepartments from "@/pages/phishing-simulation/setup/stepComponents/MapGroupsToDepartments";
import AttributeTemplatesToDepartments from "@/pages/phishing-simulation/setup/stepComponents/AttributeTemplatesToDepartments";
import { Box } from "@mui/material";
import { CenteredBigSpinner } from "@/components/Spinner";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";

const SimulationCurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  switch (checklist.activeIndex) {
    case StepperStateKey.MAILING_PERMISSIONS:
      return <MailingPermissions />;
    case StepperStateKey.PROVISIONING_PERMISSIONS:
      return <ProvisioningPermissions />;
    case StepperStateKey.PROVISION_ONE_USER:
      return <ProvisionAtLeastOneOtherUser />;
    case StepperStateKey.REPORT_BUTTON_DEPLOYMENT:
      return <ReportButtonDeployment />;
    case StepperStateKey.SIMULATION_DELIVERY_TEST:
      return <TestDelivery />;
    case StepperStateKey.COMPANY_SCAN:
      return <CompanyScan />;
    case StepperStateKey.MAP_GROUPS_DEPARTMENTS:
      return <MapGroupsToDepartments />;
    case StepperStateKey.ATTRIBUTE_TEMPLATES_DEPARTMENTS:
      return <AttributeTemplatesToDepartments />;
  }
};

export const SimulationSetupChecklist: React.FC = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useStatusQuery();
  const steps = useSelector(selectStepperFromChecklistState);

  useEffect(() => {
    if (!isLoading) dispatch(setStepperState(data));
  }, [isLoading, data, dispatch]);

  return (
    <>
      {isLoading && !isError ? (
        <Box marginTop="5rem">
          <CenteredBigSpinner />
        </Box>
      ) : (
        <SetupChecklist steps={steps}>
          <SimulationCurrentComponent />
        </SetupChecklist>
      )}
      {isError ? "an error occurred please try again later" : null}
    </>
  );
};
