import * as React from "react";
import Box from "@mui/material/Box";
import BasicTabs from "@/pages/browser-defender/extensionGovernance/TabsComponent";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { Card, CardContent, Grid } from "@mui/material";
import ExtensionDetailsCard from "@/pages/browser-defender/extensionGovernance/ExtensionDetailsCard";
import ExtensionsTable from "@/pages/browser-defender/extensionGovernance/ExtensionsTable";

export default function DataPerCompany({ content }) {
  const [currentExtensionId, setCurrentExtension] = React.useState("");

  return (
    <Box style={{ height: "100%" }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={5} style={{ height: "100%" }}>
          <Card>
            <CardContent>
              <BasicTabs
                tabsLabels={["All"]}
                tabsContent={[
                  <ExtensionsTable
                    values={content}
                    setCurrentExtension={setCurrentExtension}
                  />,
                ]}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7} style={{ height: "100%" }}>
          {currentExtensionId ? (
            <Box style={{ height: "100%" }}>
              <ExtensionDetailsCard
                row={
                  content.filter(
                    (item) => item.unique_id === currentExtensionId,
                  )[0]
                }
              />
            </Box>
          ) : (
            <GenericCard></GenericCard>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
