import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";

export default function DownloadsPolicyDialog({
  callToAction = null,
  callToActionText,
  actionButtonCallback,
  dialogTitle,
  cancelCallback,
  children,
  canEdit = true,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    cancelCallback();
  };

  return (
    <React.Fragment>
      <Tooltip title={callToActionText}>
        <Button
          size={"small"}
          onClick={handleClickOpen}
          variant={callToAction ? "text" : "outlined"}
          style={{ marginBottom: callToAction ? 0 : "1rem", minWidth: 0 }}
        >
          {callToAction ? callToAction : callToActionText}
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();

            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            const selectedCheckboxes = Array.from(
              event.currentTarget.querySelectorAll(
                'input[type="checkbox"]:checked',
              ),
            );
            const groupedByCategory = selectedCheckboxes.reduce(
              (acc, checkbox) => {
                const category = checkbox.getAttribute("data-category");
                if (!acc[category]) {
                  acc[category] = [];
                }
                acc[category].push(checkbox.name);
                return acc;
              },
              {},
            );

            const media_types = Object.keys(groupedByCategory).map(
              (mediaType) => {
                return {
                  key: mediaType,
                  media_types: groupedByCategory[mediaType],
                };
              },
            );

            actionButtonCallback({
              name: formJson.name,
              media_types: media_types,
              max_size_allowed: formJson.max_size_allowed,
            });
            setOpen(false);
          },
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {canEdit && (
            <Button variant="contained" type="submit">
              Save
            </Button>
          )}
          <Button variant="outlined" onClick={handleClose}>
            {canEdit ? "Cancel" : "Close"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
