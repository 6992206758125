import { Box, Typography, Tooltip } from "@mui/material";
import React from "react";
import MUIDataTable from "mui-datatables";
import { sentryCaptureException } from "@/utils/sentry";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export function EmailTableComponent(props) {
  const SCENARIO_ACTIONS = {
    simple_text_email: "Simple text email",
    login_pages: "🔑 Credentials",
    software_update: "⬇️ Downloads",
    permission_grant: "✅ Permissions",
    attachment: "📎 Attachments",
    click: "👉 Click on link",
  };

  let columns = [
    {
      name: "template_scenario",
      label: "Compromising action",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let row_data = props.data[dataIndex];
          return SCENARIO_ACTIONS[row_data.template_scenario] ?? "";
        },
      },
    },
    {
      name: "sender",
      label: "Sender",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let row_data = props.data[dataIndex];
          return row_data.from_name + " <" + row_data.from_email + ">";
        },
      },
    },
    {
      name: "subject",
      label: "Subject",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "send_date",
      label: "Last sending date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return { style: { textAlign: "center" } };
        },
        setCellHeaderProps: () => {
          return { style: { textAlign: "center" } };
        },
        customBodyRenderLite: (dataIndex) => {
          let sentDate = props.data[dataIndex].sent_date;

          if (props.data[dataIndex].send_date === "no_date") {
            return (
              <Tooltip title="This is not a real simulation. Send date is irrelevant">
                <Typography
                  align="center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  {"No date"}
                  <HelpOutlineIcon
                    sx={{
                      fontSize: "1.2rem",
                      opacity: "50%",
                      marginLeft: "3px",
                    }}
                  />
                </Typography>
              </Tooltip>
            );
          }

          try {
            return (
              <Typography align="center" style={{ width: "100%" }}>
                {new Date(sentDate).toLocaleString()}
              </Typography>
            );
          } catch (e) {
            sentryCaptureException(e);
            return "-";
          }
        },
        customHeadLabelRender: (columnMeta) => {
          if (!props.customHeaders) {
            return columnMeta.label;
          }

          if (columnMeta.name in props.customHeaders) {
            return props.customHeaders[columnMeta.name];
          }

          return columnMeta.label;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return { style: { textAlign: "center" } };
        },
        setCellHeaderProps: () => {
          return { style: { textAlign: "center" } };
        },
        customBodyRenderLite: (dataIndex) => {
          let status = props.data[dataIndex].status;

          if (!status) {
            return "-";
          }

          let bgColor = "rgba(0, 194, 174, 0.2)";
          let textColor = "#06584F";

          if (status === "sent") {
            bgColor = "rgba(69, 143, 247, 0.2)";
            textColor = "#124896";
          } else if (status === "not_opened") {
            bgColor = "rgba(69, 143, 247, 0.2)";
            textColor = "#124896";
            status = status.replace("_", " ");
          } else if (status === "opened") {
            bgColor = "rgba(71, 0, 247, 0.2)";
            textColor = "#083297";
          } else if (status === "clicked") {
            bgColor = "rgba(255, 49, 111, 0.2)";
            textColor = "#B50A3E";
          }
          return (
            <Box
              sx={{
                color: `${textColor}`,
                bgcolor: `${bgColor}`,
                fontWeight: "600",
                p: "0.5rem",
                fontSize: "0.7rem",
                display: "inline-flex",
              }}
            >
              {status.toUpperCase()}
            </Box>
          );
        },
      },
    },
  ];

  if (props.additionalColumns) {
    columns.push.apply(columns, props.additionalColumns);
  }

  return (
    <MUIDataTable
      marginLeft={50}
      data={props.data}
      columns={columns}
      options={props.tableDisplayOptions}
    />
  );
}
