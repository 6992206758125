import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useBackend } from "@/utils/misc";

export default function DepartmentSelector({
  department,
  setDepartment,
  setSelectedPeriod,
}) {
  let endpoint_url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments_from_user";

  const { data, error, isPending } = useBackend(endpoint_url);

  const handleChange = (event) => {
    if (event.target.value === "") {
      setDepartment("");
    } else {
      setDepartment(event.target.value);
    }
    setSelectedPeriod((current) => ({ ...current, value: null }));
  };

  let body;
  if (isPending || !data) body = <div>Loading...</div>;
  if (error) body = <div>An error occurred</div>;
  if (data && !isPending) {
    if (data?.length === 0) {
      body = <div>No data available</div>;
    } else {
      const menuItems = data.map((name) => (
        <MenuItem value={name} key={name}>
          {name}
        </MenuItem>
      ));

      body = (
        <div>
          <FormControl
            sx={{
              width: "150px",
              background: department === "" ? "" : "#ebefff",
            }}
          >
            <InputLabel size="small" htmlFor="department-select">
              Department
            </InputLabel>
            <Select
              label="Department"
              id="department-select"
              size="small"
              value={department}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {menuItems}
            </Select>
          </FormControl>
        </div>
      );
    }
  }

  return body;
}
