import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const DownloadsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ fontSize: 12 }} htmlColor={"#98A5B3"} {...props}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.25 12.25H1.75M10.5 6.41667L7 9.91667M7 9.91667L3.5 6.41667M7 9.91667V1.75"
          stroke="#98A5B3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
