import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import LoadingPage from "@/pages/banners/loading-page/LoadingPage";
import axios from "axios";
import ReportSuccessPage from "@/pages/banners/report-as-threat/ReportSuccessPage";
import ReportAsThreatErrorPage from "@/pages/banners/report-as-threat/ReportAsThreatErrorPage";
import { browserLanguage } from "@/pages/banners/utils";
import { LANDING_PAGE_ERROR } from "@/pages/banners/translations";

interface ReportThreatParams {
  report_id?: string;
  report_data?: string;
}

function parseParams(params: URLSearchParams): ReportThreatParams {
  const report_data = params.get("report_data");
  const report_id = params.get("report_id");

  if (report_data !== null) {
    return { report_data: report_data };
  }

  if (report_id !== null) {
    return { report_id: report_id };
  }

  throw new Error("Invalid Parameters: should have report_id or report_data");
}

export function ReportAsThreat() {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null); // Display an error if not empty
  const [isPending, setPending] = useState(true);
  const browser_language = browserLanguage();
  const translate = LANDING_PAGE_ERROR[browser_language];

  useEffect(() => {
    const f = async () => {
      const params = new URLSearchParams(window.location.search);
      const accessToken = await getAccessTokenSilently();

      try {
        const command_params = parseParams(params);
        await axios.post(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/banners/report_as_threat",
          command_params,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setPending(false);
      } catch (error) {
        setError(error);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently]);

  let body = null;

  if (isPending && error == null) {
    body = <LoadingPage />;
  } else if (error) {
    if (error.response && error.response.status === 403) {
      body = <ReportAsThreatErrorPage message={translate.action_not_allowed} />;
    } else {
      body = (
        <ReportAsThreatErrorPage message={translate.an_error_has_occurred} />
      );
    }
  } else {
    body = <ReportSuccessPage />;
  }
  return body;
}
