import React from "react";
import { Portlet, PortletBody } from "@/components/content/Portlet";
import { Typography } from "@mui/material";
import { StatsItem } from "@/pages/my-progress/stats/StatsItem";
import tick from "@/pages/my-progress/stats/tick.png";
import star from "@/pages/my-progress/stats/star.png";
import envelope from "@/pages/my-progress/stats/envelope.png";
import target from "@/pages/my-progress/stats/target.png";
import { User } from "@/pages/my-progress/stats/User";
import { Level } from "@/pages/my-progress/stats/Level";
import { useUserStats } from "@/pages/my-progress/activity/utils";
import { useTranslation } from "react-i18next";

export const YourStats = ({ userId }) => {
  const stats = useUserStats({ userId });
  const { t } = useTranslation();

  // No data to display.
  if (stats.error) {
    return <div>An error occurred</div>;
  }
  if (stats.isPending) {
    return null;
  }

  return (
    <Portlet>
      <PortletBody>
        <User userId={userId} />
        <Level {...stats} />
        <Typography component={"span"} sx={{ fontSize: 16, color: "black" }}>
          <div className="row">
            <StatsItem
              number={stats.successRate + "%"}
              subtitle={t("Success rate")}
              iconImage={tick}
            />
            <StatsItem
              number={stats.stars}
              subtitle={t("Stars")}
              iconImage={star}
            />
          </div>
          <div style={{ height: "8px" }} />
          <div className="row">
            <StatsItem
              number={stats.received}
              subtitle={t("Received")}
              iconImage={envelope}
            />
            <StatsItem
              number={stats.reported}
              subtitle={t("Reported")}
              iconImage={target}
            />
          </div>
        </Typography>
      </PortletBody>
    </Portlet>
  );
};
