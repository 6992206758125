import React, { useEffect } from "react";
import css from "@/components/modals/Modal.module.css";
import { Close } from "@mui/icons-material";
import { filterChildrenKey } from "@/utils/components";
import ReactDOM from "react-dom";

export const CHILD_KEYS = {
  TITLE: "ModalTitle",
  CONTENT: "ModalContent",
  STEP: "ModalStep",
};

export interface ModalElementProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * The component to use for render, "div" by default.
   */
  component?: string | React.JSXElementConstructor<any>;
}

export interface ModalSectionButton {
  /**
   * Button background.
   */
  background?: string;
  /**
   * Button text color.
   */
  color?: string;

  /**
   * Button background when hovered.
   */
  backgroundHover?: string;
  /**
   * Button text color when hovered.
   */
  colorHover?: string;

  /**
   * Action to execute when clicking the button.
   */
  action: React.MouseEventHandler<HTMLButtonElement>;

  /**
   * Content of the button.
   */
  content: React.ReactNode;

  /**
   * Unique identifier for the button (required for react render).
   */
  key: string;
}

export interface ModalSectionWithButtonsProps
  extends React.HTMLProps<HTMLDivElement> {
  buttons: ModalSectionButton[];
}

export interface ModalProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * If true, display the modal (it is hidden otherwise).
   *
   * Be careful to only display one modal at a time, to avoid conflicts.
   */
  show: boolean;
  /**
   * The function to close the modal, that should toggle {@link show} to false.
   */
  close: () => void;
  /**
   * The title of the modal. If you want more customization, you may prefer the {@link ModalTitle} element.
   */
  title?: string;
}

/**
 * Renders in the title area of a {@link Modal}.
 *
 * @example
 * <Modal>
 *   <ModalTitle>My Title.</ModalTitle>
 * </Modal>
 */
export const ModalTitle: React.FC<ModalElementProps> = ({
  component: Component = "div",
  className,
  ...props
}) => <Component className={`${css.modalTitle} ${className}`} {...props} />;
/**
 * Renders in the content area of a {@link Modal}.
 *
 * @example
 * <Modal>
 *   <ModalContent>My content.</ModalContent>
 * </Modal>
 */
export const ModalContent: React.FC<ModalElementProps> = ({
  component: Component = "div",
  className,
  ...props
}) => <Component className={`${css.modalContent} ${className}`} {...props} />;

// Avoid issues when minified in prod.
ModalTitle.displayName = CHILD_KEYS.TITLE;
ModalContent.displayName = CHILD_KEYS.CONTENT;

/**
 * Generic component to add a title to a {@link Modal} or {@link ModalMultiStep} section.
 */
export const ModalSectionTitle: React.FC<
  React.HTMLProps<HTMLHeadingElement>
> = ({ className, children, ...props }) => (
  <h1 className={`${css.modalSectionTitle} ${className}`} {...props}>
    {children}
  </h1>
);

export const ModalSectionWithButtons: React.FC<
  ModalSectionWithButtonsProps
> = ({ buttons, className, children, ...props }) => (
  <div className={`${css.sectionWithButtons} ${className}`} {...props}>
    <div className={css.sectionWithButtonsContent} children={children} />
    <div className={css.sectionWithButtonsActions}>
      {buttons.map(
        ({
          background,
          color,
          backgroundHover,
          colorHover,
          action,
          key,
          content,
        }) => (
          <button
            key={key}
            className={css.sectionButton}
            style={
              {
                backgroundColor: background,
                color,
                "--background-hover": backgroundHover,
                "--color-hover": colorHover,
              } as React.CSSProperties /* https://stackoverflow.com/a/54128069/9021186 */
            }
            onClick={action}
            children={content}
          />
        ),
      )}
    </div>
  </div>
);

/**
 * Use this component to render modals. Once rendered, a modal will take the entire screen space and lock any actions
 * on the background content (such as scrolling or clicking).
 *
 * All children MUST BE wrapped in either {@link ModalTitle} or {@link ModalContent}, otherwise it will not be rendered.
 *
 * You can use the title prop for simple text titles, to avoid using {@link ModalTitle}. {@link ModalContent} is
 * mandatory.
 *
 * @example
 * const Component = () => {
 *   const [openModal, setOpenModal] = useState(false);
 *
 *   return (
 *     <Modal show={openModal} close={() => setOpenModal(false)}>
 *       <ModalContent>
 *         This is my modal.
 *       </ModalContent>
 *     </Modal>
 *   );
 * };
 */
export const Modal: React.FC<ModalProps> = ({
  className,
  show,
  close,
  children,
  title,
  ...props
}) => {
  useEffect(() => {
    if (show) document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [show]);

  const modalPortal = document.getElementById("modals");

  return ReactDOM.createPortal(
    <div
      className={`${css.wrapper} ${show ? css.wrapperVisible : ""}`}
      onClick={(e) => {
        // Close the modal only when clicking on the blurred zone, not the modal within.
        if (e.target !== e.currentTarget) return;
        close();
      }}
      {...props}
    >
      <div className={`${css.modal} ${className}`}>
        <div className={css.modalHeader}>
          <Close className={css.modalClose} onClick={close} />
          {title ? (
            <ModalTitle children={title} />
          ) : (
            filterChildrenKey(children, CHILD_KEYS.TITLE)
          )}
        </div>
        {filterChildrenKey(children, CHILD_KEYS.CONTENT)}
      </div>
    </div>,
    modalPortal,
  );
};
