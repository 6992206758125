import React from "react";
import Title from "./Title";
import ThreatTabs from "./ThreatTabs";
import ArchivedThreatDetailButtonGroup from "../../common/button_group/ArchivedThreatDetailButtonGroup";
import UnarchivedThreatDetailButtonGroup from "../../common/button_group/UnarchivedThreatDetailButtonGroup";
import StickyHeader from "@/components/StickyHeader";
import { Box, Stack, Typography } from "@mui/material";

type HeaderProps = {
  onTabChange: (tab: ThreatDetailsTab) => void;
  onClose: () => void;
  isThreatArchived: boolean;
  onPrevious: () => void;
  onNext: () => void;
  onDelete: () => void;
  hasPrevious: () => boolean;
  hasNext: () => boolean;
  onFeedback: (feedbackType: FeedbackType) => void;
  onUnarchive: () => void;
};

const Header = ({
  onTabChange,
  isThreatArchived,
  onClose,
  onPrevious,
  onNext,
  onDelete,
  hasNext,
  hasPrevious,
  onFeedback,
  onUnarchive,
}: HeaderProps) => {
  return (
    <>
      <StickyHeader>
        <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
          <Typography
            variant="h2"
            style={{
              marginRight: "auto",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Title />
          </Typography>
          {isThreatArchived ? (
            <ArchivedThreatDetailButtonGroup
              onPrevious={onPrevious}
              onNext={onNext}
              onClose={onClose}
              onUnarchive={onUnarchive}
              onDelete={onDelete}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              actionButtonsEnabled={true}
            />
          ) : (
            <UnarchivedThreatDetailButtonGroup
              onPrevious={onPrevious}
              onNext={onNext}
              onClose={onClose}
              onFeedback={onFeedback}
              onDelete={onDelete}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              actionButtonsEnabled={true}
            />
          )}
        </Stack>
      </StickyHeader>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <ThreatTabs onChange={(tab) => onTabChange(tab)} />
        </Box>
      </Box>
    </>
  );
};

export default Header;
