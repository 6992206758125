import { Tab, Tabs } from "@mui/material";
import React from "react";

const ThreatTabs = ({
  onChange,
}: {
  onChange: (tab: ThreatDetailsTab) => void;
}) => {
  const [activeTab, setActiveTab] =
    React.useState<ThreatDetailsTab>("overview");

  const handleTabChange = (newTab: ThreatDetailsTab) => {
    setActiveTab(newTab);
    onChange(newTab);
  };

  return (
    <Tabs
      indicatorColor="primary"
      textColor="inherit"
      className="builder-tabs"
      value={activeTab}
      onChange={(_, nextTab) => handleTabChange(nextTab)}
      sx={{
        "& .MuiTabs-scroller": {
          maxHeight: "30px",
          display: "flex",
          alignItems: "center",
        },
        minHeight: "initial",
      }}
    >
      <Tab disableRipple label="Overview" value="overview" />
      <Tab disableRipple label="Headers" value="headers" />
    </Tabs>
  );
};

export default ThreatTabs;
