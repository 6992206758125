import React from "react";
import IconButton from "./IconButton";
import SaveIcon from "./SaveIcon";

const SaveIconButton = ({ title = "", onClick = () => null, size = 16 }) => {
  return (
    <IconButton
      title={title && title.charAt(0).toUpperCase() + title.slice(1)}
      onClick={onClick}
    >
      <SaveIcon size={size} />
    </IconButton>
  );
};

export default SaveIconButton;
