import React from "react";

export function UserList({ title, users }) {
  return (
    <div className="row">
      <div className="col-md-6">
        <h6>{title}</h6>
        {users?.length === 0 && (
          <>
            None
            <br />
            <br />
          </>
        )}
        {users?.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Firstname</th>
                <th scope="col">Lastname</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => {
                return (
                  <tr key={user.email}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
