import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "@/utils/contexts/UserContext";
import {
  useAcknowledgeTestSetupMutation,
  useStatusQuery,
} from "@/pages/phishing-simulation/setup/SetupChecklistSlice";
import { CircularProgress } from "@mui/material";
import {
  SIMULATION_REPORT_BUTTON_DEPLOYMENT_OFFICE,
  SIMULATION_REPORT_BUTTON_DEPLOYMENT_GMAIL,
} from "@/utils/constants";

export const ReportButtonDeployment: React.FC = () => {
  const { current_company } = useUserContext();
  const {
    data,
    isLoading: isStatusLoading,
    isError: isStatusError,
    isFetching: isStatusFetching,
  } = useStatusQuery();
  const [acknowledgeTestSetup, { isLoading: isAckLoading }] =
    useAcknowledgeTestSetupMutation();

  if (isStatusError) return <>Error</>;

  if (isStatusLoading) return <>Loading</>;

  const hasReportButtonDeployment = data.has_report_button_deployment;

  const guide_link: string =
    current_company.mailHost === "OFFICE"
      ? SIMULATION_REPORT_BUTTON_DEPLOYMENT_OFFICE
      : SIMULATION_REPORT_BUTTON_DEPLOYMENT_GMAIL;
  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        Make the phishing report button available
      </Typography>
      <Typography>
        Follow the guide below to deploy the report button to all your users.
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link to={guide_link} target="_blank">
          <Button variant="contained">Go to installation guide</Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontStyle: "italic",
              display: "inline",
            }}
          >
            Check the box after completing the guide.
          </Typography>
        </Box>
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={hasReportButtonDeployment}
              disabled={isAckLoading || isStatusFetching}
              onChange={(e) => {
                acknowledgeTestSetup(e.target.checked);
              }}
            />
          }
          label="I confirm I have completed the guide."
        />
        {(isAckLoading || isStatusFetching) && <CircularProgress size={24} />}
      </FormGroup>
    </Box>
  );
};
