import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { SignatureManager } from "@/pages/phishing-simulation/company/companysignature/SignatureManager";

export function CompanySignature() {
  return (
    <Portlet>
      <PortletHeader title="Company Signature" />
      <PortletBody>
        <SignatureManager />
      </PortletBody>
    </Portlet>
  );
}
