import React, { ComponentType, PropsWithChildren, ReactNode } from "react";

type ComposeProvidersType = PropsWithChildren & {
  components: Array<ComponentType<PropsWithChildren>>;
};

const ComposeProviders = ({
  components,
  children,
}: ComposeProvidersType): ReactNode => {
  return components.reduceRight(
    (previouslyNestedComponents, Component) => (
      <Component>{previouslyNestedComponents}</Component>
    ),
    children,
  );
};

export default ComposeProviders;
