import React from "react";
import MenuStyle from "@/components/menu/Menu.module.css";

export default function MenuToggleIcon({ open }) {
  let style = open
    ? `${MenuStyle.cross} + ${MenuStyle.crossOpen}`
    : MenuStyle.cross;
  return (
    <div className={style}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 10L8 6L4 10"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
