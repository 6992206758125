import React from "react";
import { useBackend } from "@/utils/misc";
import ClicksPerDimensionChart from "@/pages/phishing-simulation/dashboard/ClicksPerDimensionChart";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { useUserContext } from "@/utils/contexts/UserContext";
import { CenteredStyledSpinner } from "@/components/Spinner";

export default function CompletedRatePerDepartments() {
  const user = useUserContext();
  const endpoint_url =
    import.meta.env.VITE_APP_ENDPOINT_LEARNING +
    `/stats/course_stats_per_departments?company_id=${
      user?.current_company?.id ?? ""
    }`;

  const { data, error, isPending } = useBackend(endpoint_url);

  let body;
  if (isPending || !data)
    body = <CenteredStyledSpinner width="50px" color="#777" />;
  if (error) body = <div>An error occurred</div>;
  if (data && !isPending) {
    if (data?.length === 0) {
      body = <div>No data available</div>;
    } else {
      body = (
        <ClicksPerDimensionChart
          labels={Object.keys(data)}
          clickRates={Object.values(data)}
          displayXTitle={false}
        />
      );
    }
  }

  return (
    <Portlet>
      <PortletHeader title={"Average completion rate per department"} />
      <PortletBody>{body}</PortletBody>
    </Portlet>
  );
}
