import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import { useDeleteSafeSenders } from "@/pages/banners/remote-apis/bannersCompanies";

export default function DeleteSafeItemsConfirmationDialog({
  target,
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  fetchSafeContacts,
}) {
  const { deleteSafeSenders } = useDeleteSafeSenders();

  async function handleConfirm() {
    setConfirmationDialogOpen(false);
    await deleteSafeSenders({ items: [target.item], type: target.type });
    fetchSafeContacts();
  }

  return (
    <Dialog open={confirmationDialogOpen} maxWidth={"xs"} fullWidth>
      <DialogTitle>Delete item</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography>
            <strong>You’re about to delete the item from the allowlist.</strong>
          </Typography>
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={() => setConfirmationDialogOpen(false)}>
            Cancel
          </Button>
          <Box>
            <Button
              disableElevation
              onClick={handleConfirm}
              variant={"contained"}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
