import { createApi } from "@reduxjs/toolkit/query/react";
import { RootState } from "@/store";
import {
  BannerSetupChecklistStatus,
  StepperStateKey,
} from "@/pages/banners/setup/BannersChecklistTypes";
import { SetupStep, StepStatusType } from "@/components/stepper/Stepper";
import { createSlice } from "@reduxjs/toolkit";
import { simulationBaseQuery } from "@/utils/rtkQuery";

const defaultSteps: SetupStep[] = [
  { label: "Company Setup", status: "succeeded", mandatory: true },
  { label: "API Access Setup", status: "uncompleted", mandatory: true },
  { label: "Test Deployment", status: "uncompleted", mandatory: false },
  { label: "Run historical analysis", status: "uncompleted", mandatory: false },
  { label: "Define your safe lists", status: "uncompleted", mandatory: false },
  { label: "Send sample emails", status: "uncompleted", mandatory: false },
  { label: "Engage Company", status: "uncompleted", mandatory: false },
];

function companySetupStatus(
  status: BannerSetupChecklistStatus,
): StepStatusType {
  if (status.banner_controller_infos === null) {
    return "uncompleted";
  }
  if (!status.company_in_catalogue) {
    return "warning";
  }
  return "succeeded";
}

function apiAccess(status: BannerSetupChecklistStatus): StepStatusType {
  if (status.banner_controller_infos === null) return "uncompleted";

  if (status.banner_controller_infos.banner_controller === "DEMO")
    return "succeeded";

  if (status.banner_controller_infos.banner_controller === "GMAIL") {
    return status.banner_controller_infos.gmail_service_account_id === null
      ? "error"
      : "succeeded";
  }

  if (status.banner_controller_infos.banner_controller === "OFFICE") {
    return status.banner_controller_infos.office_consent !== true
      ? "error"
      : "succeeded";
  }

  return "uncompleted";
}

function testDeployment(status: BannerSetupChecklistStatus): StepStatusType {
  if (status.deployment_test_done) return "succeeded";

  if (status.engaged_users === 0) return "uncompleted";
  else return "warning";
}

const bannersSetupChecklistSlice = createSlice({
  name: "bannerSetupChecklist",
  initialState: {
    steps: defaultSteps,
  },
  reducers: {
    setStepperState: (state, action) => {
      const status = action.payload as BannerSetupChecklistStatus;
      state.steps[StepperStateKey.COMPANY_SETUP].status =
        companySetupStatus(status);
      state.steps[StepperStateKey.API_ACCESS].status = apiAccess(status);
      state.steps[StepperStateKey.DEPLOYMENT_TEST].status =
        testDeployment(status);
      state.steps[StepperStateKey.HISTORICAL_ANALYSIS].status =
        status.historical_analysis_reports_count > 0
          ? "succeeded"
          : status.have_safe_lists
            ? "warning"
            : "uncompleted";
      state.steps[StepperStateKey.SAFE_LIST_SETUP].status =
        status.have_safe_lists ? "succeeded" : "uncompleted";
      state.steps[StepperStateKey.SAMPLE_EMAILS].status =
        status.sent_emails_done ? "succeeded" : "uncompleted";
      state.steps[StepperStateKey.ENGAGE_COMPANY].status = "uncompleted";
    },
  },
});

export const bannersSetupChecklistReducer = bannersSetupChecklistSlice.reducer;

export const { setStepperState } = bannersSetupChecklistSlice.actions;

export const selectBannersSetupChecklistStatus = (
  state: RootState,
): SetupStep[] => {
  return state.bannersChecklist.steps;
};

export const bannersSetupChecklistApi = createApi({
  reducerPath: "banners-setupchecklist-api",
  keepUnusedDataFor: 0,
  baseQuery: simulationBaseQuery(),
  tagTypes: ["BannersChecklist"],
  endpoints: (builder) => ({
    status: builder.query<BannerSetupChecklistStatus, void>({
      query: () => ({ url: "/banners/setup/status", method: "GET" }),
      providesTags: ["BannersChecklist"],
    }),
    sendDeploymentTest: builder.mutation<void, void>({
      query: () => ({
        url: "/users/gmail_controller_setup_email",
        method: "POST",
      }),
    }),
    acknowledgeTestSetup: builder.mutation<void, boolean>({
      query: (value) => ({
        url: "/banners/setup/acknowledge/test-setup",
        method: "POST",
        body: { value: value },
      }),
      invalidatesTags: ["BannersChecklist"],
    }),
    acknowledgeTestEmails: builder.mutation<void, boolean>({
      query: (value) => ({
        url: "/banners/setup/acknowledge/test-emails",
        method: "POST",
        body: { value: value },
      }),
      invalidatesTags: ["BannersChecklist"],
    }),
    acknowledgeGmailConsent: builder.mutation<void, boolean>({
      query: (value) => ({
        url: "/banners/setup/acknowledge/gmail-consent",
        method: "POST",
        body: { value: value },
      }),
      invalidatesTags: ["BannersChecklist"],
    }),

    templateNames: builder.query<string[], void>({
      query: () => ({ url: "/banners/get_email_template_names" }),
      transformResponse: (response: { names: string[] }) => response?.names,
    }),
  }),
});

export const {
  useStatusQuery,
  useSendDeploymentTestMutation,
  useAcknowledgeTestSetupMutation,
  useAcknowledgeTestEmailsMutation,
  useAcknowledgeGmailConsentMutation,
  useTemplateNamesQuery,
} = bannersSetupChecklistApi;
