import {
  AccordionSummary,
  List,
  Paper,
  ToggleButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#5867dd73",
    borderColor: "#5867dd8C",
  },
});

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  fontFamily: theme.fontFamily,
  color: theme.palette.text.secondary,
  width: "100%",
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  textAlign: "center",
  fontFamily: theme.fontFamily,
  color: theme.palette.text.secondary,
  fontSize: "1rem",
  fontWeight: "bolder",
}));

export const PaperHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1.2rem",
  paddingBottom: "1.2rem",
}));

export const StyledListWithSmallerIconSpacing = styled(List)({
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 10,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});
