import React from "react";

export const CircleIcon = ({
  color,
  size,
}: {
  color: string;
  size: string;
}) => {
  return (
    <div
      style={{
        borderRadius: "50%",
        backgroundColor: color,
        width: size,
        height: size,
        border: "2px solid white",
      }}
    ></div>
  );
};
