import { CourseList } from "@/types/awareness";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useHasCourseCatalogAdminRights } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosResponse } from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useEffect, useState } from "react";
import { sentryCaptureException } from "@/utils/sentry";
import {
  setCourses,
  setDepartments,
  setIsAdmin,
  setIsPublicWebchat,
  setOnboardingCourses,
  setRunCourses,
  setUserInfos,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { useDispatch } from "react-redux";

export function useCourseCatalog(isPublicWebchat: boolean) {
  const user = useUserContext();
  const isCourseCatalogAdmin = useHasCourseCatalogAdminRights();
  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(
    function fetchAndPopulateResources() {
      // launch the hook only if we have a consistent state
      if (
        isCourseCatalogAdmin == null ||
        (user?.current_company?.id == null && !isPublicWebchat)
      ) {
        return;
      }
      if (!shouldFetch) {
        return;
      }
      (async () => {
        let response: AxiosResponse<CourseList>;
        try {
          if (isPublicWebchat) {
            response = await axios.get<CourseList>(
              `${
                import.meta.env.VITE_APP_ENDPOINT_LEARNING
              }/courses/public-courses`,
            );
          } else {
            const token = await getAccessTokenSilently();
            response = await axios.get<CourseList>(
              `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/company/${
                user?.current_company?.id
              }/courses`,
              { headers: { Authorization: `Bearer ${token}` } },
            );
            const dataUserInfos = await axios.get(
              `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/user`,
              { headers: { Authorization: `Bearer ${token}` } },
            );
            if (isCourseCatalogAdmin) {
              const departments = await axios.get(
                `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/departments`,
                { headers: { Authorization: `Bearer ${token}` } },
              );
              dispatch(setDepartments(departments.data));
              dispatch(setIsAdmin(true));
            }
            dispatch(setUserInfos(dataUserInfos.data));
          }
          const courses_info: any = response.data?.courses_info;
          dispatch(setCourses(courses_info));
          dispatch(setOnboardingCourses(courses_info.slice(0, 1)));
          dispatch(setRunCourses(courses_info.slice(1)));
          dispatch(setIsPublicWebchat(isPublicWebchat));
        } catch (e) {
          sentryCaptureException(e);
          snackbar.showMessage("An error occurred while fetching courses");
          setError(true);
        } finally {
          setIsLoading(false);
        }
      })();
      setShouldFetch(false);
    },
    [
      getAccessTokenSilently,
      user,
      setIsLoading,
      snackbar,
      isPublicWebchat,
      isCourseCatalogAdmin,
      shouldFetch,
      setShouldFetch,
      dispatch,
    ],
  );

  return {
    isLoading,
    error,
  };
}
