import React, { useEffect, useState } from "react";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { FullSizeBigSpinner } from "@/components/Spinner";
import { Alert, Grid, Snackbar } from "@mui/material";
import { ContactType } from "@/pages/banners/preferences/state";
import { useGetCompany } from "@/pages/banners/remote-apis/bannersCompanies";
import TabPanelHeader from "@/pages/banners/preferences/TabPanelHeader";
import DataSafesListTable from "@/pages/banners/preferences/DataSafesListTable";

function SuccessSnackBar({ openSuccessSnackBar, setOpenSuccessSnackBar }) {
  const handClose = () => {
    setOpenSuccessSnackBar(false);
  };
  return (
    <>
      <Snackbar
        open={openSuccessSnackBar}
        autoHideDuration={7000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handClose}
      >
        <Alert onClose={handClose} severity="success" sx={{ width: "100%" }}>
          Item(s) added to safe lists.
        </Alert>
      </Snackbar>
    </>
  );
}

export function BannerPreferencesDashboard() {
  const { data, error, isPending, getCompany } = useGetCompany();
  const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);
  const [allowlistData, setAllowlistData] = useState({
    values: [],
    filteredValues: [],
    loading: true,
  });
  const [currentTab, setCurrentTab] = useState("domains");
  const [openSuccessSnackBar, setOpenSuccessSnackBar] =
    useState<boolean>(false);

  const fetchSafeContacts = async () => {
    await getCompany();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!alreadyLoaded) {
        await getCompany();
        setAlreadyLoaded(true);
      }
    };

    fetchData();
  }, [setAlreadyLoaded, alreadyLoaded, getCompany]);
  useEffect(() => {
    let currentItems;
    if (!isPending) {
      if (currentTab === "domains") {
        currentItems = data.filter((item) => item.type === ContactType.domain);
      } else {
        currentItems = data.filter(
          (item) => item.type === ContactType.email_address,
        );
      }
      setAllowlistData({
        values: currentItems,
        filteredValues: currentItems,
        loading: false,
      });
    }
  }, [currentTab, data, isPending]);

  if (error) {
    return <div>An error occurred</div>;
  } else if (allowlistData.loading) {
    return <FullSizeBigSpinner />;
  }

  return (
    <>
      <Grid item xs={12}>
        <GenericCard title={"Safe lists"} isLoading={allowlistData.loading}>
          <TabPanelHeader
            tabsHeaders={[
              {
                label: "Safe domains",
                key: "domains",
                helper:
                  "No banners will be displayed on emails coming from senders or domains on this list",
              },
              {
                label: "Safe senders",
                key: "email_addresses",
                helper:
                  "No banners will be displayed on emails coming from senders or domains on this list",
              },
            ]}
            tabsContents={["domains", "email_addresses"].map((item) => {
              return (
                <DataSafesListTable
                  data={allowlistData}
                  setData={setAllowlistData}
                  fetchSafeContacts={fetchSafeContacts}
                  setOpenSuccessSnackBar={setOpenSuccessSnackBar}
                  contactType={
                    item === "domains"
                      ? ContactType.domain
                      : ContactType.email_address
                  }
                />
              );
            })}
            setCurrentTab={setCurrentTab}
          />
        </GenericCard>
      </Grid>

      <SuccessSnackBar
        openSuccessSnackBar={openSuccessSnackBar}
        setOpenSuccessSnackBar={setOpenSuccessSnackBar}
      />
    </>
  );
}
