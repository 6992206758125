import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface TimeFrameSelectorProps {
  selectedTimeFrame: TimeFrame;
  setSelectedTimeFrame: (timeFrame: TimeFrame) => void;
  timeFrames: TimeFrame[];
}

const TimeFrameSelector: React.FC<TimeFrameSelectorProps> = ({
  selectedTimeFrame,
  setSelectedTimeFrame,
  timeFrames,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const [value, unit] = selectedValue.split("-");
    const newTimeFrame = timeFrames.find(
      (tf) => tf.value === parseInt(value) && tf.unit === unit,
    );
    if (newTimeFrame) {
      setSelectedTimeFrame(newTimeFrame);
    }
  };

  return (
    <FormControl
      sx={{
        width: "150px",
        background: selectedTimeFrame.value !== 3 ? "#ebefff" : "",
      }}
    >
      <InputLabel size="small" htmlFor="timeframe-select">
        Timeframe
      </InputLabel>
      <Select
        label="Timeframe"
        id="timeframe-select"
        size="small"
        value={`${selectedTimeFrame.value}-${selectedTimeFrame.unit}`}
        onChange={handleChange}
      >
        {timeFrames.map((timeFrame) => (
          <MenuItem
            value={`${timeFrame.value}-${timeFrame.unit}`}
            key={timeFrame.label}
          >
            {timeFrame.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeFrameSelector;
