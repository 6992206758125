import { alpha, Chip } from "@mui/material";
import React from "react";

const ThreatSourceChip = ({ source }: { source: ThreatSource | undefined }) => {
  if (!source) return null;

  const color = source === "system" ? "#7566FE" : "#7B7B9B";

  return (
    <Chip
      variant="outlined"
      size={"medium"}
      sx={{
        backgroundColor: alpha(color, 0.1),
        borderColor: color,
        color: color,
        fontWeight: "bold",
      }}
      label={source}
    />
  );
};

export default ThreatSourceChip;
