import React, { useState } from "react";
import { Card, Grid, Typography, FormControlLabel } from "@mui/material";
import { CourseSharingCardFooter } from "@/pages/awareness/courses/corporate-course-sharing/CourseSharingCardFooter";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";
import { useUpdateCourseSharingPolicyMutation } from "./courseSharingApi";
import { useSnackbar } from "material-ui-snackbar-provider";

enum AllowedSharingPolicy {
  COMPANY_ONLY = "company",
  COMPANIES_OF_CORPORATE = "corporate",
}

export const CourseSharingCardsContainer = ({
  learningUserInfos,
  course,
  onUpdateSharingPolicy,
}) => {
  const [updateCourseSharingPolicy] = useUpdateCourseSharingPolicyMutation();
  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleSharingToggle = async () => {
    setIsLoading(true);
    const newPolicy =
      course.sharing_policy === AllowedSharingPolicy.COMPANIES_OF_CORPORATE
        ? AllowedSharingPolicy.COMPANY_ONLY
        : AllowedSharingPolicy.COMPANIES_OF_CORPORATE;

    try {
      await updateCourseSharingPolicy({
        course_id: course.id,
        new_policy: newPolicy,
      }).unwrap();

      onUpdateSharingPolicy(course.id, newPolicy);
      snackbar.showMessage("Course sharing policy updated successfully");
    } catch (error) {
      snackbar.showMessage("Failed to update course sharing policy");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{ paddingLeft: "15px", paddingTop: "20px", fontSize: "24px" }}
          >
            {course.label_name}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography
            sx={{ paddingLeft: "15px", fontSize: "14px", color: "#64748B" }}
          >
            {course.description}{" "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ paddingRight: "20px" }}
        >
          <FormControlLabel
            control={
              <BootstrapStyleSwitch
                onChange={handleSharingToggle}
                id={`share-switch-${course.id}`}
                label=""
                checked={
                  course.sharing_policy ===
                  AllowedSharingPolicy.COMPANIES_OF_CORPORATE
                }
                disabled={isLoading}
              />
            }
            label="Shared"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#94A3B8",
              },
            }}
          />
        </Grid>
      </Grid>
      <CourseSharingCardFooter
        learningUserInfos={learningUserInfos}
        course={course}
      />
    </Card>
  );
};
