import React, { useMemo, useCallback, useState } from "react";
import { Button, Icon, Tooltip, Grid } from "@mui/material";
import { useUserContext } from "@/utils/contexts/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { extractErrorMessage } from "@/utils/misc";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useAuth0 } from "@auth0/auth0-react";
import { ButtonSpinner } from "@/components/Spinner";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  selectIsDemoAcme,
  selectSearchValue,
  setSearch,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useDispatch, useSelector } from "react-redux";
import { SearchField } from "@/components/SearchField";

export const ResearchBar = ({ onClickSubscribeAll }) => {
  const user = useUserContext();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const isDemoAcme = useSelector(selectIsDemoAcme);
  const searchValue = useSelector(selectSearchValue);

  let canCreateCourse = useMemo(() => {
    return user?.current_company?.id === user?.base_company?.id;
  }, [user?.current_company?.id, user?.base_company?.id]);

  const onSearchChange = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const [isCreatingCourse, setIsCreatingCourse] = useState(false);
  const addCourse = useCallback(async () => {
    setIsCreatingCourse(true);
    try {
      const { data } = await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/courses/`,
        {},
        {
          headers: {
            Authorization: `Bearer: ${await getAccessTokenSilently()}`,
          },
        },
      );
      snackbar.showMessage("Course successfully created");
      navigate(`/awareness/courses/${data.id}/?created=true`);
      return true;
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
    setIsCreatingCourse(false);
  }, [getAccessTokenSilently, navigate, snackbar]);

  return (
    <Grid
      container
      justifyContent={"space-between"}
      sx={{
        gap: "1rem",
        display: "flex",
        paddingBottom: "10px",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Grid item>
        <SearchField
          value={searchValue}
          onChange={onSearchChange}
          sx={{
            width: "250px",
          }}
          startIcon={<Icon baseClassName="fas" className="fa-search" />}
        />
      </Grid>
      <Grid item>
        <Tooltip
          title={
            canCreateCourse
              ? ""
              : `You can only subscribe course from ${user?.base_company?.name}.`
          }
          arrow
        >
          <span>
            <Button
              sx={{ marginLeft: "auto" }}
              size="small"
              color={"primary"}
              disabled={!canCreateCourse}
              onClick={onClickSubscribeAll}
              startIcon={<MenuBookIcon />}
            >
              Assign all courses
            </Button>
          </span>
        </Tooltip>

        <Tooltip
          title={
            canCreateCourse
              ? ""
              : `You can only create courses from ${user?.base_company?.name}.`
          }
          arrow
        >
          <span>
            <Button
              sx={{ marginLeft: "15px" }}
              size="small"
              variant="outlined"
              color={"primary"}
              disabled={!canCreateCourse}
              startIcon={
                isCreatingCourse ? (
                  <ButtonSpinner />
                ) : (
                  <Icon baseClassName="fas" className="fa-plus" />
                )
              }
              onClick={addCourse}
            >
              Create course
            </Button>
            {isDemoAcme && (
              <Button
                size="small"
                variant="contained"
                color={"primary"}
                sx={{
                  marginLeft: "5px",
                  background:
                    "linear-gradient(90deg, #7CBEE4 0%, #B461F5 100%)",
                }}
                startIcon={<AutoAwesomeIcon />}
              >
                Create course with AI
              </Button>
            )}
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
