import LineChart from "@/pages/phishing-simulation/dashboard/LineChart";
import { useBackend } from "@/utils/misc";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useState } from "react";

export default function Stats({
  department,
  timeframe,
  setSelectedPeriod,
  isCorporateView,
  activeUsersOnly,
}) {
  const [isMonthly, setIsMonthly] = useState(true);
  let errorMessage = "";

  const updateIsMonthly = (event, value) => {
    if (value === null) {
      return;
    }
    setIsMonthly(value);
    if (isMonthly) {
      setSelectedPeriod({ unit: "month", value: null });
    } else {
      setSelectedPeriod({ unit: "week", value: null });
    }
  };

  let endpointUrl = import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/stats";
  if (isCorporateView) {
    endpointUrl = endpointUrl + "/corporation";
  }
  // Add filters
  let params = new URLSearchParams();
  // Department filter
  if (department) {
    params.append("department", department);
  }
  // Date filter
  params.append("timeframe", timeframe);
  params.append("active_only", activeUsersOnly);
  endpointUrl = endpointUrl + "?" + params.toString();

  const { data, error, isPending } = useBackend(endpointUrl);

  if (error) {
    errorMessage = isCorporateView
      ? "Your company is not assigned to a corporate group"
      : "An error occurred";
    return <h3 className="error">{errorMessage}</h3>;
  }

  let title = isCorporateView
    ? "Average entities click rate (clicks / opened emails)"
    : "Click rate (clicks / opened emails)";

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <Stack direction="row" spacing={1}>
            <ToggleButtonGroup
              value={isMonthly}
              onChange={updateIsMonthly}
              exclusive
            >
              <ToggleButton value={false}>Weekly</ToggleButton>
              <ToggleButton value={true}>Monthly</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        }
      ></CardHeader>
      <CardContent sx={{ px: 3, pt: 1, pb: 0 }}>
        {isPending && <div>Pending...</div>}
        {data && !isPending && (
          <LineChart
            dataset={data}
            isMonthly={isMonthly}
            showBanners={data?.metadata?.show_banner_stats}
            setSelectedPeriod={setSelectedPeriod}
            isCorporateView={isCorporateView}
          />
        )}
      </CardContent>
    </Card>
  );
}
