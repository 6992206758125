import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import SplashScreen from "@/components/SplashScreen";
import { useUserContext } from "@/utils/contexts/UserContext";
import { RouteType } from "./AppRoutes";

type AuthenticatedComponentProps = {
  children: React.ReactNode;
  route: RouteType;
};

export function AuthenticatedComponent({
  children,
  route,
}: AuthenticatedComponentProps) {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();
  const { is_authenticated, permissions } = useUserContext();

  useEffect(() => {
    const params = window.location.search;
    if (params.includes("code=") || params.includes("search=")) {
      return;
    }
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      return;
    }
    loginWithRedirect({
      appState: {
        targetUrl: window.location.pathname + window.location.search,
      },
    });
  }, [isLoading, loginWithRedirect, isAuthenticated]);

  if (isLoading || !is_authenticated) {
    return <SplashScreen />;
  }
  if (
    route.permissions !== undefined &&
    !route.permissions.some((x) => permissions.includes(x))
  ) {
    return <Navigate to="/my-progress" />;
  } else if (!isAuthenticated) {
    return <SplashScreen />;
  } else {
    return <>{children}</>;
  }
}
