import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import { useAuth0 } from "@auth0/auth0-react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

export const CollapseAutocomplete = ({
  openCollapse,
  deployedUsers,
  setDeployedUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const fetchOption = useCallback(
    async (email) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      let urlParams = new URLSearchParams();
      urlParams.append("email", email);

      const endpoint_url =
        import.meta.env.VITE_APP_ENDPOINT_LEARNING +
        "/users/search_by_email?" +
        urlParams.toString();
      const result = await axios.get(endpoint_url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const selectedUsersId = deployedUsers.map(
        (selectedUser) => selectedUser.id,
      );
      const users = result.data.filter(
        (user) => !selectedUsersId.includes(user.id),
      );
      setOptions(users);
      setLoading(false);
    },
    [getAccessTokenSilently, deployedUsers],
  );

  const debouncedUpdate = useMemo(() => {
    return debounce(fetchOption, 1000);
  }, [fetchOption]);

  const removeSelectedUser = (id) => {
    const tmp = deployedUsers.filter((user) => user.id !== id);
    setDeployedUsers(tmp);
  };

  const handleChange = (event, newValue) => {
    setDeployedUsers(newValue);
  };
  const onSearchChange = (email) => {
    setLoading(true);
    debouncedUpdate(email);
  };

  return (
    <Collapse
      in={openCollapse}
      timeout="auto"
      sx={{ paddingLeft: "5rem" }}
      unmountOnExit
      key={"users_subset_collapsed"}
    >
      <Autocomplete
        size="small"
        multiple
        options={options}
        getOptionLabel={(option) => option.email}
        value={deployedUsers}
        loading={loading}
        onOpen={() => {
          onSearchChange("");
        }}
        onChange={handleChange}
        onInputChange={(event, value) => onSearchChange(value)}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField sx={{ width: "80%" }} {...params} label="email" />
        )}
      />
      {deployedUsers.map((value) => (
        <StyledBox sx={{ justifyContent: "unset" }}>
          <IconButton
            onClick={() => {
              removeSelectedUser(value.id);
            }}
          >
            <CloseIcon sx={{ width: "0.8em" }} />
          </IconButton>
          <Typography
            sx={{
              px: "1.5rem",
              width: "60%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            color={"text.secondary"}
          >
            {value.email}
          </Typography>
          {value.bot_installed ? (
            <CheckCircleOutlineOutlinedIcon color={"success"} />
          ) : (
            <CancelOutlinedIcon color={"error"} />
          )}
        </StyledBox>
      ))}
    </Collapse>
  );
};
