export const compareCoursePriorities = (course1, course2) =>
  (course1.priority ?? 9999999) - (course2.priority ?? 9999999);

export const downloadUserProgressRawData = (filename, csvData) => {
  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(csvData),
  );
  element.setAttribute("download", filename);
  element.click();
};
