import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { AllKeyPeople } from "@/pages/phishing-simulation/company/keypeople/AllKeyPeople";

export function KeyPeople() {
  return (
    <Portlet>
      <PortletHeader title="Key People" />

      <PortletBody>
        <div>
          <AllKeyPeople />
        </div>
      </PortletBody>
    </Portlet>
  );
}
