import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { sentryCaptureException } from "@/utils/sentry";
import { ActivateBrowserDefender } from "@/pages/browser-defender/components/ActivateBrowserDefender";
import { useUserContext } from "@/utils/contexts/UserContext";
import StickyHeader from "@/components/StickyHeader";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import DataTable from "@/pages/browser-defender/users/DataTable";
import DoughnutGraph from "@/pages/browser-defender/components/DoughnutGraph";

const statusMapping = {
  active: { color: "#1dc9b7", label: "Active" },
  with_partial_defense: { color: "#5CD85A", label: "Active w/o password" },
  disabled: { color: "#949291", label: "Disabled by user" },
  inactive: { color: "#ee97a7", label: "Last seen +30 days ago" },
  unprotected: { color: "#e76b82", label: "Never installed" },
};

export function Users() {
  const [activeFeature, setActiveFeature] = useState(true);
  const [usageInfo, setUsageInfo] = useState({
    values: [],
    filteredValues: [],
    loading: true,
    activeFeature: true,
  });

  const [stats, setStats] = useState({
    values: { statuses: {}, versions: [] },
    loading: true,
    error: false,
  });

  const [selectedUserStatus, setSelectedUserStatus] = useState("");

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchExtensionUsage = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const path =
        info_user.email === "demo@acme.com"
          ? "/demo/extension_users"
          : "/extension_users";

      const url = `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}${path}`;

      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setActiveFeature(false);
          return;
        }
      }

      setUsageInfo({
        values: resource.data,
        filteredValues: resource.data,
        loading: false,
      });
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently, info_user]);

  const fetchStats = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/stats/extension_usage`;

      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setStats({
            values: { statuses: {}, versions: [] },
            loading: false,
            error: true,
          });
          setActiveFeature(false);
          return;
        }
      }

      setStats({
        values: resource.data,
        loading: false,
        error: false,
      });
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchStats();
    fetchExtensionUsage();
  }, [fetchStats, fetchExtensionUsage, info_user]);

  function exportData() {
    const titleKeys = Object.keys(usageInfo.filteredValues[0]);

    const refinedData = [];
    refinedData.push(titleKeys);

    usageInfo.filteredValues.forEach((item) => {
      refinedData.push(
        Object.values({
          ...item,
          active_deployments: JSON.stringify(item.active_deployments),
        }),
      );
    });

    let csvContent = "";
    refinedData.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      URL.createObjectURL(
        new Blob([csvContent], { type: "text/csv;charset=utf-8," }),
      ),
    );
    link.setAttribute("download", "Browser Defender - extension usage.csv");
    link.click();
  }

  if (!activeFeature) {
    return (
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <div
          style={{ position: "absolute", left: "0%", right: "0%", top: "0%" }}
        >
          <ActivateBrowserDefender />
        </div>
      </div>
    );
  }

  return (
    <>
      <Box>
        <StickyHeader>
          <Stack
            direction="row"
            gap={3}
            justifyContent="end"
            alignItems="center"
          >
            <Typography
              variant="h2"
              style={{
                marginRight: "auto",
                maxHeight: "2rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Users
            </Typography>
          </Stack>
        </StickyHeader>
        <Grid container alignItems="stretch">
          <Grid item md={6} sm={6} xs={12}>
            <GenericCard title={"Users status"} isLoading={usageInfo.loading}>
              <DoughnutGraph
                data={stats.values.statuses}
                labelMapping={statusMapping}
                excluded={["total_users", "total_extensions"]}
                onClickCallbackAction={(value) => setSelectedUserStatus(value)}
              />
            </GenericCard>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <GenericCard
              title={"Extension Versions"}
              isLoading={usageInfo.loading}
            >
              <DoughnutGraph
                data={stats.values.versions.reduce((acc, item) => {
                  acc[item.name] = item.count;
                  return acc;
                }, {})}
              />
            </GenericCard>
          </Grid>
          <Grid item xs={12}>
            <GenericCard
              title={"Extension Usage Status"}
              isLoading={usageInfo.loading}
              action={
                <Button variant="outlined" onClick={() => exportData()}>
                  Export
                </Button>
              }
            >
              <DataTable
                data={usageInfo}
                setData={setUsageInfo}
                userStatus={selectedUserStatus}
                userStatusLabel={
                  statusMapping[selectedUserStatus]
                    ? statusMapping[selectedUserStatus].label
                    : ""
                }
                setUserStatus={setSelectedUserStatus}
              />
            </GenericCard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
