import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useSelector } from "react-redux";
import { selectSetupChecklistState } from "../SetupChecklistSlice";
import { Link } from "react-router-dom";

const MailingPermissions: React.FC = () => {
  const { has_mail_permissions: hasMailPermissions } = useSelector(
    selectSetupChecklistState,
  );

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        Allow Mantra to send phishing simulations via API
      </Typography>
      <Typography>
        You need to provide Mantra with the relevant API to let us send phishing
        simulations.
      </Typography>
      <Box display="flex" alignItems="center" gap="2.5rem">
        <Link
          to={hasMailPermissions ? "#" : "/general-settings#users-provisioning"}
          style={
            hasMailPermissions
              ? {
                  pointerEvents: "none",
                  cursor: "auto",
                }
              : {}
          }
        >
          <Button variant="contained" disabled={hasMailPermissions}>
            Grant API Permissions
          </Button>
        </Link>
        <Box display="flex" gap="0.5rem">
          {hasMailPermissions ? (
            <>
              <CheckCircleOutlinedIcon color="success" />
              <Typography variant="body_small" color="#2e7d32">
                It works!
              </Typography>
            </>
          ) : (
            <>
              <CancelOutlinedIcon color="error" />
              <Typography color="#ed3b5b">Not connected</Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MailingPermissions;
