import React from "react";
import theme from "@/theme";
import { alpha, Box, ListItem, Typography } from "@mui/material";

export const LeaderboardRow = ({ user_row, is_first_row }) => {
  const loggedRowStyle = {
    fontWeight: theme.typography.fontWeightBold,
  };
  return (
    <ListItem sx={{ py: 0 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderTop: is_first_row ? "0" : "1px solid " + theme.palette.divider,
          px: "7%",
          py: "8px",
          ...(user_row.is_logged_user && {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            border: "2px solid " + theme.palette.primary.main,
            borderRadius: "4px",
            color: theme.palette.primary.main,
          }),
        }}
      >
        <Typography
          sx={{
            ...(user_row.is_logged_user && loggedRowStyle),
          }}
        >
          {user_row.rank + "."}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            ...(user_row.is_logged_user && loggedRowStyle),
          }}
        >
          {user_row.name}
        </Typography>
        <Typography
          sx={{
            ...(user_row.is_logged_user && loggedRowStyle),
          }}
        >
          {user_row.stars + " ⭐"}
        </Typography>
      </Box>
    </ListItem>
  );
};
