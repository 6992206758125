import React from "react";
import { Checkbox } from "@mui/material";
import CollapsibleTable, {
  PaginationProps,
  SortingColumn,
  TableHeader,
} from "./CollapsibleTable";
import TableCell from "@mui/material/TableCell";
import StyledTableRow from "./StyledTableRow";
import ThreatStatusIcon from "../../common/ThreatStatusIcon";
import ThreatBannerLevelChip from "../../common/chips/ThreatBannerLevelChip";
import ThreatSourceChip from "../../common/chips/ThreatSourceChip";
import FilterButtons from "../buttons/FilterButtons";

const headersWithoutFeedback: TableHeader[] = [
  { label: "Report Date", alignment: "left" },
  { label: "Source", alignment: "center" },
  { label: "Banner level", alignment: "center" },
  { label: "Subject", alignment: "left" },
  { label: "Sender", alignment: "left" },
  { label: "Recipient", alignment: "left" },
];

const headersWithFeedback: TableHeader[] = [
  { label: "Report Date", alignment: "left" },
  { label: "Source", alignment: "center" },
  { label: "Banner level", alignment: "center" },
  { label: "Subject", alignment: "left" },
  { label: "Sender", alignment: "left" },
  { label: "Recipient", alignment: "left" },
  { label: "Feedback", alignment: "center" },
];

const sortingColumns: SortingColumn[] = [
  { label: "Report Date", name: "report_date" },
  { label: "Banner level", name: "banner_level" },
  { label: "Source", name: "source" },
  { label: "Sender", name: "sender" },
  { label: "Recipient", name: "recipient" },
];

type DataTableProps = {
  threats: ThreatInfo[];
  paginationProps: PaginationProps;
  selectedThreatIds: number[];
  onSelectedChange: (threatId: number[], selected: boolean) => void;
  onShowDetails: (threatId: number) => void;
  showFeedback: boolean;
  fetchOptions: ListFetchOptions;
  setFetchOptions: React.Dispatch<React.SetStateAction<ListFetchOptions>>;
};

const DataTable = ({
  threats,
  paginationProps,
  selectedThreatIds,
  onSelectedChange,
  onShowDetails,
  showFeedback,
  fetchOptions,
  setFetchOptions,
}: DataTableProps) => {
  const headers = showFeedback ? headersWithFeedback : headersWithoutFeedback;

  const filterButtons = (
    <>
      {"  "}
      <FilterButtons
        source={fetchOptions.source}
        riskLevel={fetchOptions.riskLevel}
        hasAttachments={fetchOptions.hasAttachments}
        setSource={(v) => setFetchOptions({ ...fetchOptions, source: v })}
        setRiskLevel={(v) => setFetchOptions({ ...fetchOptions, riskLevel: v })}
        setHasAttachments={(v) =>
          setFetchOptions({ ...fetchOptions, hasAttachments: v })
        }
      />
    </>
  );

  return (
    <CollapsibleTable
      headers={headers}
      paginationProps={paginationProps}
      fetchOptions={fetchOptions}
      setFetchOptions={setFetchOptions}
      selectAll={(e) =>
        onSelectedChange(
          threats.map((t) => t.id),
          (e.target as HTMLInputElement).checked,
        )
      }
      areAllSelected={threats.length === selectedThreatIds.length}
      sortingColumns={sortingColumns}
      filterButtons={filterButtons}
      rows={threats.map((threat: ThreatInfo) => {
        return (
          <StyledTableRow
            hover
            key={threat.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            onClick={() => onShowDetails(threat.id)}
          >
            <TableCell>
              <Checkbox
                color="primary"
                checked={selectedThreatIds.includes(threat.id)}
                onClick={(e) => {
                  onSelectedChange(
                    [threat.id],
                    !selectedThreatIds.includes(threat.id),
                  );
                  e.stopPropagation();
                }}
              />
            </TableCell>
            <TableCell component="th" scope="row" align={headers[0].alignment}>
              {new Date(Date.parse(threat.report_date)).toLocaleString(
                undefined,
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                },
              )}
            </TableCell>
            <TableCell align={headers[1].alignment}>
              <ThreatSourceChip source={threat.source} />
            </TableCell>
            <TableCell align={headers[2].alignment}>
              <ThreatBannerLevelChip bannerLevel={threat.bannerLevel} />
            </TableCell>
            <TableCell align={headers[3].alignment}>{threat.subject}</TableCell>
            <TableCell align={headers[4].alignment}>{threat.sender}</TableCell>
            <TableCell align={headers[5].alignment}>
              {threat.recipient}
            </TableCell>
            {showFeedback && (
              <TableCell align={headers[6].alignment}>
                <ThreatStatusIcon
                  status={threat.feedbackType}
                  comment={threat.feedbackComment}
                />
              </TableCell>
            )}
          </StyledTableRow>
        );
      })}
    />
  );
};

export default DataTable;
