import React from "react";

export default function CorporateSwitchToggleIcon({
  color = "#fff",
  ...props
}) {
  return (
    <div {...props}>
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7.57692L3.92308 10.5L6.84615 7.57692"
          stroke={color}
          strokeWidth="1.09615"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.84613 3.92308L3.92305 1L0.999977 3.92308"
          stroke={color}
          strokeWidth="1.09615"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
