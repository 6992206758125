import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useUserContext } from "@/utils/contexts/UserContext";
import PeopleIcon from "@mui/icons-material/People";
import BookIcon from "@mui/icons-material/Book";
import {
  selectCourses,
  selectDepartments,
  selectFilterDepartments,
  selectFilterSharingPolicy,
  setFilterDepartments,
  setFilterSharingPolicy,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { useDispatch, useSelector } from "react-redux";
import { AllowedSharingPolicy } from "@/pages/awareness/courses/utils/sharingPolicies";
import { FilterAssignment } from "@/pages/awareness/courses/utils/filterAssignmentEnum";

export const CourseFilterBar = () => {
  const user = useUserContext();
  const dispatch = useDispatch();

  const selectedAssignmentFilter = useSelector(selectFilterDepartments);
  const selectedSharingPolicy = useSelector(selectFilterSharingPolicy);
  const allDepartments = useSelector(selectDepartments);
  const allCourses = useSelector(selectCourses);

  const onChangeFilterAssignment = (e) => {
    dispatch(setFilterDepartments(e.target.value));
  };

  const onChangeSelectSharingPolicy = (e) => {
    dispatch(setFilterSharingPolicy(e.target.value));
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "flex-start", paddingTop: "15px" }}
    >
      <FormControl sx={{ minWidth: "270px" }}>
        <InputLabel id="assignment-filters-select-label">
          Assignment filter
        </InputLabel>
        <Select
          startAdornment={<PeopleIcon sx={{ marginRight: "10px" }} />}
          labelId="assignment-filter-label"
          id="assignment-filter-simple-select"
          label="Assignment filter"
          value={selectedAssignmentFilter}
          size={"small"}
          onChange={onChangeFilterAssignment}
          sx={{ borderRadius: "10px", width: "250px" }}
          renderValue={(selected) => {
            if (selected === FilterAssignment.NoFilter) {
              return "";
            }
            const selectedItem = [
              { id: FilterAssignment.NotAssigned, name: "Not assigned" },
              { id: FilterAssignment.Assigned, name: "Assigned" },
              { id: FilterAssignment.AllDepartments, name: "All departments" },
              { id: FilterAssignment.Everyone, name: "Everyone" },
              ...allDepartments,
            ].find((item) => item.id === selected);
            return selectedItem ? selectedItem.name : "";
          }}
        >
          <MenuItem
            key={"no-assignment-filter"}
            id={"select-no-assignment-filter"}
            value={FilterAssignment.NoFilter}
          >
            No filter
          </MenuItem>

          <MenuItem
            key={"not-assigned"}
            id={"select-not-assigned"}
            value={FilterAssignment.NotAssigned}
          >
            Not assigned
          </MenuItem>

          <MenuItem
            key={"assigned"}
            id={"select-assigned"}
            value={FilterAssignment.Assigned}
          >
            Assigned
          </MenuItem>

          <MenuItem
            key={"all-departments"}
            id={"select-all-departments"}
            value={FilterAssignment.AllDepartments}
          >
            All departments
          </MenuItem>

          <MenuItem
            key={"everyone"}
            id={"select-everyone"}
            value={FilterAssignment.Everyone}
          >
            Everyone
          </MenuItem>

          {allDepartments.map((department) => (
            <MenuItem key={department.id} value={department.id}>
              {department.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="course-owner-select-label">Type of Course</InputLabel>
        <Select
          startAdornment={<BookIcon sx={{ marginRight: "10px" }} />}
          labelId="course_select-label"
          id="course-simple-select"
          value={selectedSharingPolicy}
          label="Type of Course"
          size={"small"}
          onChange={onChangeSelectSharingPolicy}
          sx={{ borderRadius: "10px", width: "20rem" }}
        >
          <MenuItem
            id={"select-all-courses"}
            value={AllowedSharingPolicy.NO_POLICY}
          >
            All Courses
          </MenuItem>
          <MenuItem id={"select-mantra"} value={AllowedSharingPolicy.ALL}>
            Provided by Mantra
          </MenuItem>
          {allCourses.some(
            (c) =>
              c.sharing_policy === AllowedSharingPolicy.COMPANIES_OF_CORPORATE,
          ) && (
            <MenuItem
              id={"select-corporate"}
              value={AllowedSharingPolicy.COMPANIES_OF_CORPORATE}
            >
              Corporate Courses
            </MenuItem>
          )}
          <MenuItem
            id={"select-company"}
            value={AllowedSharingPolicy.COMPANY_ONLY}
          >
            {user.current_company?.name ?? "Custom"} Courses
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
