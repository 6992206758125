import { useState, useEffect } from "react";
import useThreatController from "./controller";

export const defaultListFetchOptions: ListFetchOptions = {
  searchTerm: "",
  sort: [],
  archived: false,
  source: null,
  riskLevel: null,
  hasAttachments: null,
};

const useThreatsPaginator = (defaultPageSize: number) => {
  const [items, setItems] = useState<ThreatInfo[]>([]);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [fetchOptions, setFetchOptions] = useState<ListFetchOptions>(
    defaultListFetchOptions,
  );
  const {
    fetchListResult: fetchCompleteListResult,
    getThreatList: getThreatCompleteList,
  } = useThreatController();
  const {
    fetchListResult: fetchPartialListResult,
    getThreatList: getThreatPartialList,
  } = useThreatController();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const [allRemovedIds, setAllRemovedIds] = useState<number[]>([]);

  const loadAllItems = async (
    startIndex_: number = 0,
    newFetchOptions?: ListFetchOptions,
    resetMode: boolean = false,
  ) => {
    if (startIndex_ < 0) {
      setItems([]);
      setStartIndex(0);
      setTotalCount(0);
    } else {
      const fetchOptions_ = newFetchOptions ?? fetchOptions;
      const ids = resetMode ? [] : items.map((item) => item.id);
      getThreatCompleteList(startIndex_, pageSize, fetchOptions_, [
        ...allRemovedIds,
        ...ids,
      ]);
    }
    if (newFetchOptions) {
      setFetchOptions(newFetchOptions);
    }
  };

  useEffect(() => {
    if (fetchCompleteListResult == null) {
      return;
    }
    if (fetchCompleteListResult.items.length > 0) {
      setItems(fetchCompleteListResult.items);
    } else {
      setItems([]);
    }
    setStartIndex(fetchCompleteListResult.startIndex);
    setTotalCount(fetchCompleteListResult.totalCount);
  }, [fetchCompleteListResult]);

  const loadPartialItems = async (
    startIndex_: number,
    removedIds: number[],
  ) => {
    setItems((prev) => [
      ...prev.filter((item) => !removedIds.includes(item.id)),
    ]);
    setStartIndex(startIndex_);
    const ids = items.map((item) => item.id);
    getThreatPartialList(
      startIndex_ + items.length - removedIds.length,
      pageSize - (items.length - removedIds.length),
      fetchOptions,
      [...allRemovedIds, ...removedIds, ...ids],
    );
  };

  useEffect(() => {
    if (fetchPartialListResult == null) {
      return;
    }
    if (fetchPartialListResult.items.length > 0) {
      setItems((prev) => {
        return [...prev, ...fetchPartialListResult.items];
      });
    }
    setTotalCount(fetchPartialListResult.totalCount);
  }, [fetchPartialListResult]);

  const moveToNextPage = () => {
    if (hasNextPage()) {
      loadAllItems(startIndex + pageSize);
    }
  };

  const moveToPreviousPage = () => {
    if (hasPreviousPage()) {
      loadAllItems(startIndex - pageSize);
    }
  };

  const hasNextPage = () => {
    return startIndex + pageSize < totalCount;
  };

  const hasPreviousPage = () => {
    return startIndex > 0;
  };

  const removeItems = (itemIds: number[]) => {
    setAllRemovedIds((prev) => [...prev, ...itemIds]);
    if (itemIds.length >= items.length && !hasNextPage()) {
      loadAllItems(startIndex - pageSize);
    } else {
      loadPartialItems(startIndex, itemIds);
    }
  };

  const getTotalPages = (): number => {
    return Math.ceil(totalCount / pageSize);
  };

  const getCurrentPage = (): number => {
    if (items.length === 0) return 0;
    return Math.floor(startIndex / pageSize) + 1;
  };

  const getTotalItems = (): number => {
    return totalCount;
  };

  const getStartIndex = (): number => {
    return startIndex;
  };

  const updateFetchOptions = (fetchOptions: ListFetchOptions) => {
    loadAllItems(0, fetchOptions, true);
  };

  useEffect(() => {
    loadAllItems(0, null, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  return {
    items,
    moveToPreviousPage,
    moveToNextPage,
    hasPreviousPage,
    hasNextPage,
    removeItems,
    getTotalPages,
    getCurrentPage,
    getTotalItems,
    getStartIndex,
    fetchOptions,
    updateFetchOptions,
    setPageSize,
    pageSize,
  };
};

export default useThreatsPaginator;
