import React from "react";
import DeleteButton from "../button/DeleteButton";
import FeedbackButtons from "@/pages/threatsng/components/common/button/FeedbackButtons";

const ArchivedThreatListButtonGroup = ({
  onFeedback,
  onDelete,
  actionButtonsEnabled,
}: {
  onFeedback: (feedback: FeedbackType) => void;
  onDelete: () => void;
  actionButtonsEnabled: boolean;
}) => {
  return (
    <>
      <FeedbackButtons onClick={onFeedback} enabled={actionButtonsEnabled} />
      <DeleteButton onClick={onDelete} enabled={actionButtonsEnabled} />
    </>
  );
};

export default ArchivedThreatListButtonGroup;
