import React, {
  ComponentClass,
  FunctionComponent,
  VoidFunctionComponent,
} from "react";

/**
 * Filters constructors that can implement "displayName".
 */
const hasDisplayName = (
  type: React.JSXElementConstructor<any>,
): type is FunctionComponent | VoidFunctionComponent | ComponentClass =>
  "displayName" in type;

/**
 * Filter children based on their displayName, using a filter function in argument.
 *
 * @example
 *  const Component1 = () => { ... };
 *  const Component2 = () => { ... };
 *  const Component3 = () => { ... };
 *
 *  Component1.displayName = "FooComponent1";
 *  Component2.displayName = "BarComponent1";
 *  Component3.displayName = "FooComponent2";
 *
 *  filterChildren(
 *    [<Component1 />, <Component2 />, <Component3 />],
 *    (_, childName) => childName.startsWith("Foo"),
 *  );
 *
 *  // Returns [<Component1 />, <Component3 />]
; */
export const filterChildren = (
  children: React.ReactNode | React.ReactNode[],
  filter: (
    child: React.ReactChild | React.ReactFragment | React.ReactPortal,
    name: string,
  ) => boolean,
) =>
  React.Children.toArray(children).filter((child) => {
    let displayName = "";
    if (
      React.isValidElement(child) &&
      typeof child.type !== "string" &&
      hasDisplayName(child.type)
    ) {
      displayName = child.type.displayName;
    }

    return filter(child, displayName);
  });

/**
 * Filter children where the displayName match exactly the key in argument. It is a wrapper over {@link filterChildren}.
 *
 * @example
 *  const Component1 = () => { ... };
 *  const Component2 = () => { ... };
 *
 *  Component1.displayName = "FooComponent";
 *  Component2.displayName = "BarComponent";
 *
 *  filterChildrenKey(
 *    [<Component1 />, <Component2 />],
 *    "FooComponent",
 *  );
 *
 *  // Returns [<Component1 />]
 */
export const filterChildrenKey = (children, key) =>
  filterChildren(children, (_, name) => name === key);
