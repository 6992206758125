import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { useUserContext } from "@/utils/contexts/UserContext";

export const Slack: React.FC = () => {
  const { current_company } = useUserContext();

  const addToSlackLink = `${
    import.meta.env.VITE_APP_CONNECT_TO_SLACK_LEARNING
  }/company/${current_company.domain}`;

  return (
    <div>
      <Portlet>
        <PortletHeader title="Slack Configuration" />
        <PortletBody>
          <div>Enable the Mantra bot to reach your team via Slack.</div>
          <br />
          <div>
            <a href={addToSlackLink} target="_blank" rel="noreferrer">
              <img
                alt="Add to Slack"
                height="40"
                width="139"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
              />
            </a>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};
