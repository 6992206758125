import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";

export default function BigNumber({
  title,
  value,
  height = 250,
  compact = false,
  className,
}) {
  if (compact) {
    value = Intl.NumberFormat("en", { notation: "compact" }).format(value);
  }
  return (
    <Portlet style={{ height: `{height}px` }} className={className}>
      <PortletHeader title={title} />

      <PortletBody>
        <div className="d-flex justify-content-center align-items-center">
          <h1 className="text-center mb-3">{value}</h1>
        </div>
      </PortletBody>
    </Portlet>
  );
}
