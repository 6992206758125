import React from "react";
import { Grid } from "@mui/material";
import { FolderDownloadIcon } from "@/components/icons/FolderDownloadIcon";
import { DownloadComplete } from "@/components/icons/DownloadComplete";
import { UserIcon } from "@/components/icons/UserIcon";
import StatsCard from "@/pages/browser-defender/components/StatsCard";

export default function StatCards({ data }) {
  return (
    <>
      <Grid item md={4} sm={6} xs={12}>
        <StatsCard
          title="Dangerous Browsing Alerts"
          icon={<FolderDownloadIcon fontSize="32px" color="#7567FE" />}
          data={<>{data.values && data.values.events_count}</>}
          isPending={data.loading}
          error={data.error}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <StatsCard
          title="Users Triggering Dangerous Browsing Alerts"
          icon={<UserIcon fontSize="32px" color="#7567FE" />}
          data={<>{data.values && data.values.users_count}</>}
          isPending={data.loading}
          error={data.error}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <StatsCard
          title="Dangerous Browsing Completed"
          icon={<DownloadComplete fontSize="32px" color="#7567FE" />}
          data={<>{data.values && data.values.completed_navigation_count}</>}
          isPending={data.loading}
          error={data.error}
        />
      </Grid>
    </>
  );
}
