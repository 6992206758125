import React, { ReactElement } from "react";
import { ArcElement, Chart, DoughnutController } from "chart.js";
import { Card, CardContent, CardHeader } from "@mui/material";

import { useBackend } from "@/utils/misc";
import UserPerLevelChart from "@/pages/phishing-simulation/dashboard/UserPerLevelChart";
import { CenteredStyledSpinner } from "@/components/Spinner";

Chart.register(ArcElement, DoughnutController);

export default function UserPerLevel({ department, activeUsersOnly }) {
  const users_levels_url = new URL(
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/users/levels",
  );
  if (department) {
    users_levels_url.searchParams.append("department", department);
  }
  users_levels_url.searchParams.append("active_only", activeUsersOnly);
  const { data, error, isPending } = useBackend(users_levels_url.href);

  let body: ReactElement | null;
  if (isPending || !data)
    body = <CenteredStyledSpinner width="50px" color="#777" />;
  if (error) body = <div>An error occurred</div>;
  if (data) {
    const labels = ["Beginner", "Intermediate", "Expert"];
    const levelPercentages = labels.map((level) =>
      Math.round(100 * data[level]),
    );
    body = (
      <UserPerLevelChart labels={labels} levelPercentages={levelPercentages} />
    );
  }
  return (
    <Card>
      <CardHeader title="Users per level (last 6 months)" />
      <CardContent>{body}</CardContent>
    </Card>
  );
}
