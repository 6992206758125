import { useCallback } from "react";
import useFetchThreatDelete from "./fetchers/fetch_threat_delete";
import useFetchThreatSendFeedback from "./fetchers/fetch_threat_feedbacks";
import useFetchThreatUnarchive from "./fetchers/fetch_threat_unarchive";
import { useFetchThreatDetails } from "./fetchers/fetch_threat_details";
import useFetchThreatList from "./fetchers/fetch_threat_list";

const useThreatController = () => {
  const { fetchThreatDelete } = useFetchThreatDelete();
  const { fetchThreatSendFeedback } = useFetchThreatSendFeedback();
  const { fetchThreatUnarchive } = useFetchThreatUnarchive();
  const { threatDetails, fetchThreatDetails } = useFetchThreatDetails();
  const { fetchResult: fetchListResult, fetchThreatList } =
    useFetchThreatList();

  const getThreatList = useCallback(
    async (
      startIndex: number,
      pageSize: number,
      fetchOptions: ListFetchOptions,
      removedIds: number[],
    ) => {
      fetchThreatList(startIndex, pageSize, fetchOptions, removedIds);
    },
    [fetchThreatList],
  );

  const getThreatDetails = useCallback(
    async (threatId: number) => {
      fetchThreatDetails(threatId);
    },
    [fetchThreatDetails],
  );

  const deleteThreats = useCallback(
    async (threatIds: number[]) => {
      //fetchDeleteThreats(threatIds);
      fetchThreatDelete(threatIds);
    },
    [fetchThreatDelete],
  );

  const sendFeedbacks = useCallback(
    async (
      threatIds: number[],
      feedbackType: FeedbackType,
      comment: string,
    ) => {
      //fetchSendFeedbacks(threatIds, feedbackType, comment);
      fetchThreatSendFeedback(threatIds, feedbackType, comment);
    },
    [fetchThreatSendFeedback],
  );

  const unarchiveThreats = useCallback(
    async (threatIds: number[]) => {
      //fetchUnarchive(threatIds);
      fetchThreatUnarchive(threatIds);
    },
    [fetchThreatUnarchive],
  );

  return {
    deleteThreats,
    sendFeedbacks,
    unarchiveThreats,
    getThreatDetails,
    threatDetails,
    getThreatList,
    fetchListResult,
  };
};

export default useThreatController;
