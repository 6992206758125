import React, { useState } from "react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { DeleteConfirmationDialog } from "@/components/modals/DeleteConfirmationDialog";

export const ResetStats = () => {
  const snackbar = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const title = "Erase simulation data";
  const dialogContent =
    "Clicking on “Confirm” will erase all data related to Phishing Simulation. You will lose all data related to previous simulations sent. Once clicked, you won’t be able to download previous simulations raw data. This action is non-reversible.";

  async function resetAllStatsForCompany(): Promise<void> {
    try {
      setButtonLoading(true);
      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/company/simulation/reset`,
        {},
      );
      snackbar.showMessage("data erased.");
      setOpenDialog(false);
    } catch (e) {
      snackbar.showMessage("Error: cannot erase data");
    }
    setButtonLoading(false);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Typography variant="h6">{title}</Typography>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <Typography>
            Clicking on this button will erase all data related to Phishing
            Simulation. This action is non-reversible.
          </Typography>
          <Button
            variant="contained"
            color={"error"}
            sx={{ marginTop: "1rem" }}
            onClick={() => setOpenDialog(true)}
            disabled={buttonLoading}
          >
            Erase all simulation data
          </Button>
        </Box>
      </CardContent>

      <DeleteConfirmationDialog
        isShown={openDialog}
        onConfirm={resetAllStatsForCompany}
        onConfirmText={"Confirm"}
        onCancel={() => setOpenDialog(false)}
        onCancelText={"Cancel"}
        title={title}
        content={dialogContent}
      />
    </Card>
  );
};
