import { useCallback, useEffect, useState } from "react";
import useThreatFetch from "./_fetch_get";
import { buildThreatListUrl } from "./_url_builders";

const isFetchResult = (o: any): o is FetchResult => {
  // TODO
  return true;
};

const useFetchThreatList = (factor: number = 2) => {
  const {
    data,
    isLoading: threatListIsLoading,
    isError: threatListIsError,
    threatFetch,
  } = useThreatFetch();
  const [fetchResult, setFetchResult] = useState<FetchResult | null>(null);

  const fetchThreatList = useCallback(
    async (
      startIndex: number,
      pageSize: number,
      fetchOptions: ListFetchOptions,
      removeIds: number[],
    ) => {
      const url = buildThreatListUrl(
        startIndex,
        pageSize * factor,
        fetchOptions,
      );
      const transformer = (json: any): any => {
        if (isFetchResult(json)) {
          const items = json.items.filter(
            (item) => !removeIds.includes(item.id),
          );
          return { ...json, items: items.slice(0, pageSize) };
        }
      };
      threatFetch(url, "GET", null, transformer);
    },
    [threatFetch, factor],
  );

  useEffect(() => {
    if (data === null) {
      setFetchResult(null);
      return;
    }
    if (isFetchResult(data)) {
      setFetchResult(data);
    } else {
      throw new Error("Invalid threat list data");
    }
  }, [data]);

  return {
    fetchResult,
    threatListIsLoading,
    threatListIsError,
    fetchThreatList,
  };
};

export default useFetchThreatList;
