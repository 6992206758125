import { styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";

const CondensedTableCell = styled(TableCell)(({ theme }) => ({
  "& .MuiTableCell-sizeSmall": {
    padding: "1rem 0",
  },
}));

export default CondensedTableCell;
