export const processLabel = (name: string) => {
  return name
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .slice(1)
    .join(" ");
};

export const processSaasLabel = (saas: string[]) => {
  const cleanedSaas = saas.filter((saas) => saas !== "All Users");
  if (cleanedSaas.length > 0) {
    return cleanedSaas[0];
  }
  return "";
};

// all types must start with capital letter to match the type names in DB
export enum TemplateTypeEnum {
  ALL = "all",
  CREDENTIALS = "Credentials",
  PERMISSION = "Permission",
  ATTACHMENT = "Attachment",
  DOWNLOAD = "Download",
  IMPERSONATION = "Impersonation",
  CLICK = "Click",
}

export type TemplateType = {
  name: string;
  content: string;
  available_languages: string[];
  category: TemplateTypeEnum;
  saas: string[];
};

export type SaasType = {
  id: number;
  name: string;
};

export enum ActiveStatusEnum {
  ALL = "all",
  ACTIVE = "active",
  BLOCKED = "blocked",
  NOT_INCLUDED = "not_included",
}
