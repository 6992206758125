import React, { useEffect, useMemo, useState } from "react";
import TimeFrameSelector from "@/components/controls/TimeFrameSelector";
import { useSimulationResource } from "@/utils/ResourceGet";
import BannerMailsProcessedChart from "@/pages/banners/dashboard/BannerMailsProcessedChart";
import BigNumber from "@/pages/phishing-simulation/dashboard/BasicStats/BigNumber";
import BannerMailsWithBannerChart from "@/pages/banners/dashboard/BannerMailsWithBannerChart";
import dayjs from "dayjs";
import MediumBarChart from "@/pages/banners/dashboard/MediumBarChart";
import css from "@/pages/banners/dashboard/MediumBarChart.module.css";
import { FullSizeBigSpinner } from "@/components/Spinner";
import { useStats } from "@/pages/banners/remote-apis/bannerStatistics";

function FakeDemoGraphs({ banners_types }: { banners_types: object }) {
  return (
    <div className={css.typesBannersDashboard}>
      <MediumBarChart
        data={banners_types["Company Impersonation"]}
        title={"Company Impersonation"}
      />
      <MediumBarChart
        data={banners_types["Employee Impersonation"]}
        title={"Employee Impersonation"}
      />
      <MediumBarChart
        data={banners_types["Contact Impersonation"]}
        title={"Contact Impersonation"}
      />
      <MediumBarChart
        data={banners_types["Brand Impersonation"]}
        title={"Brand Impersonation"}
      />
      <MediumBarChart
        data={banners_types["Contact Impersonation"]}
        title={"Suspicious attachments"}
      />
      <MediumBarChart
        data={banners_types["Suspicious File Transfer"]}
        title={"Suspicious file transfer"}
      />
      <MediumBarChart
        data={banners_types["Suspicious Financial Operation"]}
        title={"Suspicious financial operation"}
      />
      <MediumBarChart
        data={banners_types["Account Take-Over"]}
        title={"Account Take-Over"}
      />
    </div>
  );
}

export function BannerDashboard() {
  const [firstSimulationData] = useSimulationResource(
    "company/first_simulation_date",
    null,
  );
  const [dateSinceBeginningTimeFrame, setDateSinceBeginningTimeFrame] =
    useState<TimeFrame | null>(null);

  const timeFrames: TimeFrame[] = useMemo(
    () => [
      { label: "7 Days", value: 7, unit: "days" },
      { label: "1 Month", value: 1, unit: "months" },
      { label: "3 Months", value: 3, unit: "months" },
      { label: "6 Months", value: 6, unit: "months" },
      { label: "1 Year", value: 12, unit: "months" },
    ],
    [],
  );

  useEffect(() => {
    if (firstSimulationData) {
      setDateSinceBeginningTimeFrame({
        label: "Max",
        value: firstSimulationData.nb_months_since,
        unit: "months",
      });
    }
  }, [firstSimulationData]);

  const updatedTimeFrames = useMemo(() => {
    return dateSinceBeginningTimeFrame
      ? [...timeFrames, dateSinceBeginningTimeFrame]
      : timeFrames;
  }, [timeFrames, dateSinceBeginningTimeFrame]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>(
    timeFrames[0],
  );

  const now = useMemo(dayjs, []);

  const startDate = useMemo(() => {
    if (selectedTimeFrame.unit === "days") {
      return now.subtract(selectedTimeFrame.value, "day");
    } else if (selectedTimeFrame.unit === "months") {
      return now.subtract(selectedTimeFrame.value, "month");
    }
    return now;
  }, [now, selectedTimeFrame]);

  const endDate = now;
  const { data, error, isPending } = useStats(
    startDate.toDate(),
    endDate.toDate(),
  );

  if (error) {
    return <div>An error occurred</div>;
  } else if (isPending || data === null) {
    return (
      <FullSizeBigSpinner
        shouldDisplayText={true}
        text="This may take a while..."
      />
    );
  } else {
    return (
      <div>
        <div className="row flex-row-reverse">
          <TimeFrameSelector
            selectedTimeFrame={selectedTimeFrame}
            setSelectedTimeFrame={setSelectedTimeFrame}
            timeFrames={updatedTimeFrames}
          />
        </div>
        <div className="d-flex justify-content-between flex-wrap">
          <BigNumber
            className="mr-2"
            title="Mailboxes monitored"
            value={data.mailboxesMonitored}
            height={175}
            compact={true}
          />
          <BigNumber
            className="mr-2"
            title="Emails processed"
            value={data.messagesProcessed}
            height={175}
            compact={true}
          />
          <BigNumber
            className="mr-2"
            title="Banners added"
            value={data.messagesWithBanner}
            height={175}
            compact={true}
          />
          <BigNumber
            className="mr-2"
            title="Percentage of banners added"
            value={
              data.messagesProcessed
                ? (
                    (data.messagesWithBanner / data.messagesProcessed) *
                    100
                  ).toFixed(2) + " %"
                : "-"
            }
            height={175}
          />
        </div>
        <BannerMailsProcessedChart
          data={data.dailyStatistics}
          title="Processed emails"
        />
        <BannerMailsWithBannerChart
          data={data.dailyStatistics}
          title="Emails with banners"
        />
        {data.bannerTypes && (
          <FakeDemoGraphs banners_types={data.bannerTypes} />
        )}
      </div>
    );
  }
}
