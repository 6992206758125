import React from "react";

const BootstrapStyleSwitch = ({
  id,
  label,
  checked,
  onChange,
  disabled = false,
}) => {
  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <label
        style={{ cursor: "pointer" }}
        className="custom-control-label"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

export default BootstrapStyleSwitch;
