import React from "react";
import { useLocation } from "react-router-dom";
import { useUserContext } from "@/utils/contexts/UserContext";
import { Box, Grid, Stack, Typography } from "@mui/material";
import StickyHeader from "@/components/StickyHeader";
import ExtensionsTable from "@/pages/browser-defender/userDetails/ExtensionsTable";
import { GeneralInfoCard } from "@/pages/browser-defender/userDetails/GeneralInfoCard";
import { AlertsStatsCard } from "@/pages/browser-defender/userDetails/AlertsStatsCard";
import { LatestActivityCard } from "@/pages/browser-defender/userDetails/LatestActivityCard";
import { GeneralInfoCardDemo } from "@/pages/browser-defender/demo/userDetails/GeneralInfoCardDemo";
import { AlertsStatsCardDemo } from "@/pages/browser-defender/demo/userDetails/AlertsStatsCardDemo";
import { LatestActivityCardDemo } from "@/pages/browser-defender/demo/userDetails/LatestActivityCardDemo";
import ExtensionsTableDemo from "@/pages/browser-defender/demo/userDetails/ExtensionsTableDemo";

export function UserDetails() {
  const location = useLocation();

  const user_email = location.state.email;

  const admin = useUserContext();

  return (
    <Box>
      <StickyHeader>
        <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
          <Typography
            variant="h2"
            style={{
              marginRight: "auto",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            User details
          </Typography>
        </Stack>
      </StickyHeader>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="stretch"
        sx={{ marginBottom: "1rem" }}
      >
        <Grid item md={4} sm={4} xs={12}>
          {admin.email !== "demo@acme.com" ? (
            <GeneralInfoCard user_email={user_email} />
          ) : (
            <GeneralInfoCardDemo user_email={user_email} />
          )}
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          {admin.email !== "demo@acme.com" ? (
            <AlertsStatsCard user_email={user_email} />
          ) : (
            <AlertsStatsCardDemo user_email={user_email} />
          )}
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          {admin.email !== "demo@acme.com" ? (
            <LatestActivityCard user_email={user_email} />
          ) : (
            <LatestActivityCardDemo />
          )}
        </Grid>
      </Grid>
      {admin.email !== "demo@acme.com" ? (
        <ExtensionsTable user_email={user_email} />
      ) : (
        <ExtensionsTableDemo />
      )}
    </Box>
  );
}
