import { Button, DialogActions, DialogTitle, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { useDispatch } from "react-redux";
import { subscribeAll } from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useAuth0 } from "@auth0/auth0-react";
import { extractErrorMessage } from "@/utils/misc";
import { useUserContext } from "@/utils/contexts/UserContext";

export const SubscribeAllModal = ({
  openModal,
  setOpenModal,
  filteredCourse,
  userInfos,
}) => {
  const [subscribeAllLoading, setSubscribeAllLoading] = useState(false);
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const user = useUserContext();

  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleConfirm = async () => {
    setSubscribeAllLoading(true);
    const courseIds = filteredCourse.map((course) => course.id);
    const accessToken = await getAccessTokenSilently();
    try {
      await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_LEARNING +
          "/courses/subscriptions/multiple",
        {
          company_id: userInfos.company_id,
          courses_id: courseIds,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      dispatch(subscribeAll(courseIds));
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
    setSubscribeAllLoading(false);
    setOpenModal(false);
  };
  return (
    <Dialog open={openModal} fullWidth>
      <DialogTitle variant={"h3"} sx={{ fontSize: "24px" }}>
        Subscribe all displayed courses
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography sx={{ fontSize: "14px" }}>
          You're about to assign {filteredCourse.length} courses to all{" "}
          {user.current_company.name} employees. They will receive the courses
          gradually according to the delay between courses you specified.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          loading={subscribeAllLoading}
          variant={"contained"}
          onClick={handleConfirm}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
