import { BACKEND_URL } from "@/pages/banners/remote-apis/config";
import { useUserContext } from "@/utils/contexts/UserContext";
import axios from "axios";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { ContactType, SafeContact } from "@/pages/banners/preferences/state";
import { enqueueSnackbar } from "notistack";

export function transformToSafeContact(
  item: string,
  type: ContactType,
  added_by: string,
  added_at: Date,
): SafeContact {
  return {
    item,
    type,
    added_by,
    added_at,
  };
}

export function useGetCompany() {
  const [data, setData] = useState<SafeContact[] | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const user = useUserContext();

  function transformListToSafeContacts(
    list: any[],
    type: ContactType,
  ): SafeContact[] {
    return list.map((obj) =>
      transformToSafeContact(
        obj.domain || obj.sender,
        type,
        obj.added_by,
        new Date(obj.added_at),
      ),
    );
  }

  const getCompany = async () => {
    const accessToken = await getAccessTokenSilently();
    try {
      const url = new URL(
        `${BACKEND_URL}/banners/companies/${user.current_company.id}/`,
      );
      setIsPending(true);

      const response = await axios.get(url.href, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setIsPending(false);
      if (response.data) {
        const safeDomains = transformListToSafeContacts(
          response.data.safe_domains,
          ContactType.domain,
        );
        const safeSenders = transformListToSafeContacts(
          response.data.safe_senders,
          ContactType.email_address,
        );
        setData(safeDomains.concat(safeSenders));
      }
    } catch (error) {
      setError(error as Error);
      enqueueSnackbar("The request was unsuccessful.");
    }
  };
  return { data, error, isPending, getCompany };
}

export function useDeleteSafeSenders() {
  const { getAccessTokenSilently } = useAuth0();

  const user = useUserContext();
  const deleteSafeSenders = async ({ items, type }) => {
    const accessToken = await getAccessTokenSilently();

    let urlEndpoint: string;
    let dataName: string;

    if (type === ContactType.email_address) {
      urlEndpoint = "remove_safe_senders";
      dataName = "email_addresses";
    } else {
      urlEndpoint = "remove_safe_domains";
      dataName = "domains";
    }

    try {
      const url = new URL(
        `${BACKEND_URL}/banners/companies/${user.current_company.id}/${urlEndpoint}`,
      );
      await axios.delete(url.href, {
        data: {
          [dataName]: items,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } catch (error) {
      enqueueSnackbar("The request was unsuccessful.");
    }
  };
  return { deleteSafeSenders };
}

export function useSaveNewSafeSenders() {
  const { getAccessTokenSilently } = useAuth0();

  const user = useUserContext();

  const saveNewSafeSenders = async ({ items, type }) => {
    const accessToken = await getAccessTokenSilently();

    let urlEndpoint: string;
    let dataName: string;
    if (type === ContactType.email_address) {
      urlEndpoint = "add_safe_senders";
      dataName = "email_addresses";
    } else {
      urlEndpoint = "add_safe_domains";
      dataName = "domains";
    }

    try {
      const url = new URL(
        `${BACKEND_URL}/banners/companies/${user.current_company.id}/${urlEndpoint}`,
      );
      await axios.post(
        url.href,
        { [dataName]: items },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
    } catch (error) {
      enqueueSnackbar("The request was unsuccessful.");
    }
  };
  return { saveNewSafeSenders };
}
