import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { BackendSuspense } from "./BackendSuspense";
import { Tooltip } from "@mui/material";
export const NbEngagedUserCard = ({ className, engagedUsersPercentage }) => {
  return (
    <Tooltip title="Percent of users who started their awareness program (on track or on hold)">
      <Portlet
        className={className}
        style={{
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PortletHeader title="Engaged users" />
        <PortletBody style={{ height: "95px" }}>
          <div className="d-flex justify-content-center align-items-center">
            <BackendSuspense hasData={engagedUsersPercentage > 0}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <h1 className="mb-3">{engagedUsersPercentage}%</h1>
              </div>
            </BackendSuspense>
          </div>
        </PortletBody>
      </Portlet>
    </Tooltip>
  );
};
