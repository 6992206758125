import useThreatFetch from "./_fetch_get";
import { useCallback, useEffect, useState } from "react";
import { buildIsNewThreatsUIEnabledUrl } from "./_url_builders";

export type NewThreatsUIEnabled = {
  enabled: boolean;
};

const isNewThreatsUIEnabledType = (o: any): o is NewThreatsUIEnabled => {
  return "enabled" in o && typeof o.enabled === "boolean";
};

export const useIsNewThreatsUIEnabled = () => {
  const {
    data,
    isLoading: threatNewUIEnabledIsLoading,
    isError: threatNewUIEnabledIsError,
    threatFetch,
  } = useThreatFetch();
  const [isNewThreatsUIEnabled, setIsNewThreatsUIEnabled] = useState<
    boolean | null
  >(null);

  const fetchIsNewThreatsUIEnabled = useCallback(async () => {
    const url = buildIsNewThreatsUIEnabledUrl();
    threatFetch(url);
  }, [threatFetch]);

  useEffect(() => {
    if (data === null) {
      setIsNewThreatsUIEnabled(null);
      return;
    }
    if (isNewThreatsUIEnabledType(data)) {
      setIsNewThreatsUIEnabled(data.enabled);
    } else {
      throw new Error("Invalid threat details data");
    }
  }, [data]);

  return {
    isNewThreatsUIEnabled,
    threatNewUIEnabledIsLoading,
    threatNewUIEnabledIsError,
    fetchIsNewThreatsUIEnabled,
  };
};
