import { useUserContext } from "@/utils/contexts/UserContext";
import React, { useEffect } from "react";

export const BeamerInit: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { email, firstname, lastname, is_admin } = useUserContext();

  useEffect(() => {
    if (email == null || firstname == null || lastname == null) {
      return;
    }
    if (import.meta.env.VITE_APP_SHOULD_USE_BEAMER === "true") {
      // @ts-ignore
      window.beamer_config = {
        product_id: `${import.meta.env.VITE_APP_BEAMER_PRODUCT_ID}`,
        selector: "beamer-notification-bell",
        display: "popup",
        button: false,
        bounce: false,
        user_email: email,
        user_firstname: firstname,
        user_lastname: lastname,
        lazy: !is_admin,
      };
    }
  }, [email, firstname, lastname, is_admin]);

  return <>{children}</>;
};

export default BeamerInit;
