import { useBackend } from "@/utils/misc";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  DialogTitle,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
const colorText = "#646464";

export default function CreateCampaignConfirmationDialog({
  confirmationDialogOpen,
  sender,
  setConfirmationDialogOpen,
  onClickSendToAll,
  subject,
  departments,
  sendInProgress,
  targetChoice,
  users,
}) {
  const [totalReceiver, setTotalReceiver] = useState(0);

  let params = new URLSearchParams(
    departments.map(({ id }) => ["department_ids", id]),
  );
  const url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
    "/custom_campaign/total_receiver?" +
    params.toString();
  const { data } = useBackend(url);

  useEffect(() => {
    if (targetChoice === "departments" && data) {
      setTotalReceiver(data.total_receiver);
    } else if (targetChoice === "users") {
      setTotalReceiver(users.length);
    }
  }, [targetChoice, users, data]);
  return (
    <Dialog open={confirmationDialogOpen} maxWidth={"xs"} fullWidth>
      <DialogTitle>{"Send Campaign now"}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography>
            <strong>Are you sure you want to send this campaign ?</strong>
          </Typography>
          {data && (
            <>
              <Typography
                sx={{
                  color: colorText,
                  paddingTop: "15px",
                  fontWeight: "unset",
                }}
              >
                <strong>To :</strong> {totalReceiver} persons
              </Typography>
              <Typography sx={{ color: colorText, fontWeight: "unset" }}>
                <strong>From email :</strong> {sender}
              </Typography>
              <Typography sx={{ color: colorText, fontWeight: "unset" }}>
                <strong>Subject :</strong> {subject}
              </Typography>
            </>
          )}
        </Box>
      </DialogContent>

      <Box height={"10px"}>{sendInProgress && <LinearProgress />}</Box>
      <Divider />

      <DialogActions>
        <Button
          variant={"outlined"}
          disabled={sendInProgress}
          onClick={() => setConfirmationDialogOpen(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={sendInProgress}
          variant={"contained"}
          onClick={onClickSendToAll}
        >
          Yes, send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
