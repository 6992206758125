import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export function Confirmation() {
  return (
    <div>
      <br />
      <Portlet>
        <PortletHeader title="Import Users - Step 4/4: Confirmation" />
        <PortletBody>
          <p>
            Your users have been successfully imported.
            <br />
          </p>
          <div>
            <Button variant="contained">
              <Link to="/settings" style={{ color: "white" }}>
                Back to Settings
              </Link>
            </Button>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
}
