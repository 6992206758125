import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";
import { useStopSimulations } from "@/pages/phishing-simulation/setup/stopSimulations";
import { useStartSimulations } from "@/pages/phishing-simulation/setup/startSimulations";
import { useCompanySimulationStatus } from "@/pages/phishing-simulation/setup/useCompanySimulationStatus";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";

export const SimulationScheduling = () => {
  const [autoEngageToggleLoading, setAutoEngageToggleLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { isSimulationEnabled, error, loading, refetch } =
    useCompanySimulationStatus();
  const { stopSimulations } = useStopSimulations();
  const { startSimulations } = useStartSimulations();

  const isError = Boolean(error);

  const closeConfirmation = () => {
    setShowConfirmation(false);
    return true;
  };

  const handleToggleChange = (event) => {
    if (event.target.checked) {
      startSimulations(setAutoEngageToggleLoading, refetch);
    } else {
      setShowConfirmation(true);
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Automatic simulation delivery</Typography>
            <Tooltip
              title={
                "Simulations are sent on business days between 9:30 - 12:00 and 14:00 - 17:30."
              }
            >
              <div
                style={{
                  marginLeft: "12px",
                  marginBottom: "0px",
                  marginTop: "3px",
                }}
              >
                <BootstrapStyleSwitch
                  id="simulation-switch"
                  label=""
                  checked={isSimulationEnabled}
                  onChange={handleToggleChange}
                  disabled={isError || autoEngageToggleLoading || loading}
                />
              </div>
            </Tooltip>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Stack spacing={2}>
          <Box>
            <Typography>
              When activated, employees will receive simulations automatically
              based on the assignment you made in the{" "}
              <Link to={"/phishing-simulation/templates"}>
                template catalog.
              </Link>
            </Typography>
            <Typography>
              You can choose the minimum number of days between simulations
              below.
            </Typography>
          </Box>
        </Stack>
      </CardContent>
      <Dialog open={showConfirmation} onClose={closeConfirmation}>
        <DialogTitle>Deactivate automatic simulation delivery</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Employees won't receive any simulations automatically until you
            reactivate the automatic simulation delivery.
          </DialogContentText>
          <DialogActions>
            <Button onClick={closeConfirmation}>Cancel</Button>
            <LoadingButton
              variant={"contained"}
              onClick={() =>
                stopSimulations(
                  setAutoEngageToggleLoading,
                  closeConfirmation,
                  refetch,
                )
              }
              loading={autoEngageToggleLoading || loading}
              disabled={isError}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
