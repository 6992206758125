import React from "react";
import LayoutStyle from "@/components/layout/Layout.module.css";
import Menu from "@/components/menu/Menu";
import clsx from "clsx";

export const Layout = function ({ children, hasMenu, hasPadding }) {
  return (
    <div className={LayoutStyle.container}>
      {hasMenu && (
        <div className={LayoutStyle.menu}>
          <Menu />
        </div>
      )}
      <div
        className={clsx(
          LayoutStyle.mainContent,
          hasPadding && LayoutStyle.mainContentPadding,
        )}
      >
        {children}
      </div>
    </div>
  );
};
