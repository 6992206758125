import { useAuth0 } from "@auth0/auth0-react";
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router";
import SplashScreen from "../SplashScreen";

const Auth0Init = (props: PropsWithChildren): ReactNode => {
  const { isLoading, handleRedirectCallback } = useAuth0();
  const [shouldHandleCallback, setShouldHandleCallback] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!shouldHandleCallback) {
      return;
    }
    setShouldHandleCallback(false);
    const params = window.location.search;
    if (params.includes("code=") || params.includes("search=")) {
      handleRedirectCallback()
        .then((redirectStats) => {
          const targetUrl = redirectStats?.appState?.targetUrl;
          setRedirectUrl(targetUrl);
        })
        .catch((err) => {
          console.log(err);
          setRedirectUrl("/");
        });
    }
  }, [
    shouldHandleCallback,
    setShouldHandleCallback,
    handleRedirectCallback,
    setRedirectUrl,
  ]);

  useEffect(() => {
    if (!redirectUrl) {
      return;
    }
    if (redirectUrl !== "/callback") {
      navigate(redirectUrl);
      setRedirectUrl(null);
      return;
    }
    navigate("/");
    setRedirectUrl(null);
  }, [redirectUrl, navigate]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return <>{props.children}</>;
};

export default Auth0Init;
