import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  MailHost,
  SimulationSetupChecklistStatus,
} from "./SetupChecklistTypes";
import { RootState } from "@/store";
import { SetupStepper } from "./SetupStepperService";
import { SetupStep } from "@/components/stepper/Stepper";

const initialState: SimulationSetupChecklistStatus = {
  mail_host: MailHost.OFFICE,
  has_mail_permissions: false,
  has_provisioning_permissions: false,
  has_at_least_one_non_admin_user: false,
  delivery_tests: {},
  company_scan: {
    has_key_people: false,
    has_company_logo: false,
    has_email_signature: false,
    has_key_customers: false,
  },
  has_mapped_groups_to_departments: false,
  assign_templates_to_departments: "no-tags",
  has_confirmed_all_delivery_tests: false,
  has_report_button_deployment: false,
};

const simulationChecklistSlice = createSlice({
  name: "simulation-checklist",
  initialState: {
    steps: new SetupStepper(initialState).steps,
    status: initialState,
  },
  reducers: {
    setStepperState: (state, action) => {
      const status = action.payload as SimulationSetupChecklistStatus;
      state.steps = new SetupStepper(status).steps;
      state.status = status;
    },
  },
});

export const { setStepperState } = simulationChecklistSlice.actions;

export const selectSetupChecklistState = (
  state: RootState,
): SimulationSetupChecklistStatus => {
  return state.simulationChecklist.status;
};

export const selectStepperFromChecklistState = (
  state: RootState,
): SetupStep[] => {
  return state.simulationChecklist.steps;
};

export default simulationChecklistSlice.reducer;

export const simulationSetupChecklistApi = createApi({
  reducerPath: "simulation-setupchecklist-api",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["SimulationChecklist"],
  endpoints: (builder) => ({
    status: builder.query<SimulationSetupChecklistStatus, void>({
      query: () => ({ url: "/company/setup-status", method: "GET" }),
      providesTags: ["SimulationChecklist"],
    }),
    acknowledgeTestSetup: builder.mutation<void, boolean>({
      query: (value) => ({
        url: "/company/setup/acknowledge/test-setup",
        method: "POST",
        body: { value: value },
      }),
      invalidatesTags: ["SimulationChecklist"],
    }),
  }),
});

export const { useStatusQuery, useAcknowledgeTestSetupMutation } =
  simulationSetupChecklistApi;
