import React from "react";
import Tabs, {
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { Preferences } from "@/pages/browser-defender/setup/Preferences";
import StickyHeader from "@/components/StickyHeader";
import { Typography } from "@mui/material";
import { SetupChecklist } from "@/pages/browser-defender/setup/SetupChecklist";

const SetupInner: React.FC = () => {
  const { currentTabComponent } = useTabsContext();

  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">Browser Defender Setup</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
};

export const Setup: React.FC = () => {
  const tabs = [
    {
      anchor: "setup-checklist",
      label: "Setup Checklist",
      component: <SetupChecklist />,
    },
    {
      anchor: "preferences",
      label: "Preferences",
      component: <Preferences />,
    },
  ];

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <SetupInner />
    </TabsContextProvider>
  );
};
