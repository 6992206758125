import { Stack, Typography } from "@mui/material";
import ThreatSourceChip from "@/pages/threatsng/components/common/chips/ThreatSourceChip";
import ThreatStatusIcon from "@/pages/threatsng/components/common/ThreatStatusIcon";
import ThreatBannerLevelChip from "@/pages/threatsng/components/common/chips/ThreatBannerLevelChip";
import React from "react";

const BoxTitleBar = ({
  threatDetails,
  isThreatArchived,
}: {
  threatDetails: ThreatDetails;
  isThreatArchived: boolean;
}) => {
  return (
    <Stack
      direction="row"
      gap={3}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography
        variant="h5"
        style={{
          marginRight: "auto",
          maxHeight: "2rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        Email
      </Typography>

      <div>
        {isThreatArchived && (
          <ThreatStatusIcon
            status={threatDetails.feedbackType}
            comment={threatDetails.feedbackComment}
          />
        )}{" "}
        <ThreatSourceChip source={threatDetails.source} />{" "}
        <ThreatBannerLevelChip bannerLevel={threatDetails.bannerLevel} />
      </div>
    </Stack>
  );
};

export default BoxTitleBar;
