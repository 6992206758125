import {
  APIBannerUser,
  BannerToggleUsers,
} from "@/pages/banners/remote-apis/bannerUsers";
import { createApi } from "@reduxjs/toolkit/query/react";
import { enqueueSnackbar } from "notistack";
import { simulationBaseQuery } from "@/utils/rtkQuery";

const snackbarError = async (arg, { queryFulfilled }) => {
  try {
    await queryFulfilled;
  } catch (error) {
    enqueueSnackbar(error?.error?.data?.error ?? "Unknown Error", {
      variant: "error",
    });
  }
};

export const bannersUsersApi = createApi({
  reducerPath: "banners-users-api",
  baseQuery: simulationBaseQuery(),
  tagTypes: ["UserList", "ActiveUserList"],
  endpoints: (builder) => ({
    users: builder.query<APIBannerUser[], void>({
      query: () => ({
        url: "/banners/users",
        params: { active_only: false },
        method: "GET",
      }),
      providesTags: ["UserList"],
      onQueryStarted: snackbarError,
    }),
    activeUsers: builder.query<APIBannerUser[], void>({
      query: () => ({
        url: "/banners/users",
        params: { active_only: true },
        method: "GET",
      }),
      providesTags: ["ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    engageOrDisengage: builder.mutation<void, BannerToggleUsers>({
      query: (body) => ({
        url: "/user/gmail_controller",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    disengageCompany: builder.mutation<void, void>({
      query: () => ({ url: "/company/disengage", method: "POST" }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    batchEngage: builder.mutation<void, BannerToggleUsers[]>({
      query: (body) => ({
        url: "/user/gmail_controller/toggle_users",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
  }),
});

export const {
  useUsersQuery,
  useActiveUsersQuery,
  useEngageOrDisengageMutation,
  useDisengageCompanyMutation,
  useBatchEngageMutation,
} = bannersUsersApi;
