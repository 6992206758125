import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { extractErrorMessage } from "@/utils/misc";
import axios from "axios";
import TrashIconButton from "@/components/icons/TrashIconButton";

export function Delete({ tooltip, department, departments, setDepartments }) {
  const { getAccessTokenSilently } = useAuth0();

  const onDeleteClick = async () => {
    const accessToken = await getAccessTokenSilently();

    try {
      await axios.delete(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments",
        {
          data: department,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      let futureDepartments = [];
      for (let i = 0; i < departments.length; i++) {
        if (department.id !== departments[i].id) {
          futureDepartments.push(departments[i]);
        }
      }
      setDepartments(futureDepartments);
    } catch (error) {
      alert(extractErrorMessage(error));
    }
  };
  return <TrashIconButton onClick={onDeleteClick} title={tooltip} />;
}
