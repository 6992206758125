import { useLearningResource } from "@/utils/ResourceGet";
import { useUserContext } from "@/utils/contexts/UserContext";

export const useCourses = () => {
  const user = useUserContext();
  const [response, error] = useLearningResource(
    `company/${user?.current_company?.id}/courses`,
    { courses_info: [] },
  );

  return [
    response.courses_info.filter((course) => course.published === true),
    error,
  ];
};
