import React from "react";
import IconButton from "./IconButton";

const DownloadButton = ({ title, onClick, size = 16 }) => {
  return (
    <IconButton
      title={title && title.charAt(0).toUpperCase() + title.slice(1)}
      onClick={onClick}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path opacity="0.12" d="M3 21H21V15H3V21Z" fill="#7F8995" />
        <path
          d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
          stroke="#7F8995"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconButton>
  );
};

export default DownloadButton;
