import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  SxProps,
  Theme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

interface SearchFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  label?: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  InputProps?: object;
  startIcon?: React.ReactNode;
  size?: "small" | "medium";
}

export const SearchField: React.FC<SearchFieldProps> = ({
  value,
  onChange,
  onClear,
  label = "Search",
  placeholder,
  sx = {},
  InputProps = {},
  startIcon = <SearchIcon />,
  size = "small",
  ...props
}) => {
  const handleClear = () => {
    if (onClear) {
      onClear();
    } else {
      // Default behavior: empty the value
      onChange({
        target: { value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      size={size}
      sx={{
        width: "250px",
        "& .MuiInputBase-root": {
          paddingRight: 1,
        },
        ...sx,
      }}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={handleClear}
              edge="end"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
