import SortComponent from "@/components/sort/SortComponent";
import DataPerUsersRow from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUsersRow";
import {
  Box,
  Popper,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { debounce } from "lodash";
import * as React from "react";
import { useCallback, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const defaultColumns = [
  {
    name: "firstname",
    label: "First Name",
    sx: { paddingLeft: "0px" },
  },
  {
    name: "lastname",
    label: "Last Name",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "sent",
    label: "Sent",
  },
  {
    name: "level",
    label: "Level (6m)",
  },
  {
    name: "opened",
    label: "Opened",
  },
  {
    name: "reported",
    label: "Reported",
    popup: "Reported rate = number emails reported / number emails opened",
    popupVisibility: (data): boolean =>
      data.length > 0 ? !data[0].is_office : true,
  },
  {
    name: "clicked",
    label: "Clicked",
    popup: "Clicked rate = number emails clicked / number emails opened",
    popupVisibility: (data): boolean =>
      data.length > 0 ? !data[0].is_office : true,
  },
];

const compromisingActionChoices = [
  {
    name: "credential",
    label: "Credentials",
  },
  {
    name: "attachment",
    label: "Attachments",
  },
  {
    name: "download",
    label: "Downloads",
  },
  {
    name: "permission",
    label: "Permissions",
  },
];

const sortChoice = defaultColumns.concat(compromisingActionChoices);

const compromisingActionColumn = [
  {
    name: "compromisingAction",
    label: "Compromising Actions",
  },
];

const headers = defaultColumns.concat(compromisingActionColumn);

const TableHeaderColumnCell = ({ column, displayPopup }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <TableCell
      sx={column.sx}
      key={column.name}
      onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
    >
      {column.popup !== undefined && displayPopup ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <Typography>{column.label}</Typography>
          <HelpOutlineIcon sx={{ fontSize: "1.2rem", opacity: "50%" }} />
        </Box>
      ) : (
        <Typography>{column.label}</Typography>
      )}
      {column.popup !== undefined && displayPopup && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          sx={{
            pointerEvents: "none",
          }}
          placement="top"
        >
          <Paper sx={{ background: "black" }}>
            <Typography
              sx={{
                p: 1,
                fontSize: "0.8rem",
                width: "200px",
                textAlign: "center",
              }}
              color="white"
            >
              {column.popup}
            </Typography>
          </Paper>
        </Popper>
      )}
    </TableCell>
  );
};

export default function DataPerUsersTable({
  dataPerUsers,
  totalCount,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
  setSearchTerm,
  setOrderBy,
}) {
  const updateSearchItemAndPage = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setPage, setSearchTerm],
  );

  // memoize the debounce call with useMemo
  const debouncedUpdate = useMemo(() => {
    return debounce(updateSearchItemAndPage, 1000);
  }, [updateSearchItemAndPage]);

  const onChange = (e) => {
    const value = e.target.value;
    debouncedUpdate(value);
  };

  return (
    <Box>
      <TextField
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        sx={{ marginBottom: "10px" }}
        size={"small"}
        label={"Search"}
        onChange={onChange}
      />
      <SortComponent setOrderBy={setOrderBy} orderChoices={sortChoice} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingRight: "8px", paddingLeft: "8px" }} />
              {headers.map((column) => (
                <TableHeaderColumnCell
                  key={column.name}
                  column={column}
                  displayPopup={
                    column.popupVisibility
                      ? column.popupVisibility(dataPerUsers)
                      : true
                  }
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPerUsers.map((row) => {
              return <DataPerUsersRow key={row.id} rowData={row} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Box>
  );
}
