import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const riskIcons = {
  high: <WarningAmberIcon sx={{ color: "#F6285F" }} />,
  medium: <ErrorOutlineOutlinedIcon sx={{ color: "#FBE909" }} />,
  low: <CheckCircleOutlineOutlinedIcon sx={{ color: "#65c58b" }} />,
};

export default function ExtensionRisksList({ risks }) {
  return (
    <List style={{ marginLeft: "2rem" }}>
      {risks.map((item, index) => (
        <ListItem key={index} sx={{ padding: "0.1rem" }}>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            {riskIcons[item.level]}
          </ListItemIcon>
          {item.explanation}
        </ListItem>
      ))}
    </List>
  );
}
