import React from "react";

const EmailHeader = ({ header }: { header: EmailHeaderNameValue }) => {
  return (
    <>
      <div>
        <strong>{header.name}</strong>: {header.value}
      </div>
    </>
  );
};

export default EmailHeader;
