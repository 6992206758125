import React from "react";
import { TIMEZONES } from "@/pages/general-settings/UsersManagement/csvImport/timezone/TimeZones";
import { styled } from "@mui/system";
import { FormControl, MenuItem, Select } from "@mui/material";

const StyledFormControl = styled(FormControl)(() => ({
  "&.MuiFormControl-root": {
    margin: "0rem",
    minWidth: 200,
  },
}));

export function TimeZoneSelector({ index, mapping, updateTimezone }) {
  return (
    <tr>
      <th scope="row">{mapping.location}</th>
      <td>
        <StyledFormControl variant="standard">
          <Select
            labelId="monthago-select-label"
            id="monthago-select"
            value={mapping.timezone}
            onChange={(e) => updateTimezone(index, e.target.value)}
          >
            {TIMEZONES.sort().map((timezone) => (
              <MenuItem value={timezone} key={timezone}>
                {timezone}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </td>
    </tr>
  );
}
