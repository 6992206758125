import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "@/utils/contexts/UserContext";
import { Box } from "@mui/material";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import axios from "axios";
import { sentryCaptureException } from "@/utils/sentry";
import { BrowserIcon } from "@/components/icons/BrowserIcon";
import { FolderDownloadIcon } from "@/components/icons/FolderDownloadIcon";
import { PasswordExposedIcon } from "@/components/icons/PasswordExposedIcon";

export function LatestActivityCard({ user_email }) {
  const [latestAlerts, setLatestAlerts] = useState({ data: [], loading: true });

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchLatestAlerts = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const path =
        info_user.email === "demo@acme.com"
          ? "/demo/user/latest_alerts"
          : "/user/latest_alerts";

      const url = `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}${path}`;

      let resource;
      try {
        resource = await axios.post(
          url,
          { email: user_email, number_of_alerts: 6 },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      setLatestAlerts({
        data: resource.data,
        loading: false,
      });
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently, info_user, user_email]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchLatestAlerts();
  }, [fetchLatestAlerts, info_user]);

  return (
    <GenericCard title={"Latest activity"} isLoading={latestAlerts.loading}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          marginTop: "1rem",
        }}
      >
        {latestAlerts.data &&
          latestAlerts.data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: "1rem",
              }}
            >
              <Box sx={{ marginRight: "1rem" }}>
                {item.event_type === "Password alert" && (
                  <PasswordExposedIcon fontSize="18px" color="#7567FE" />
                )}
                {item.event_type === "Dangerous download" && (
                  <FolderDownloadIcon fontSize="18px" color="#7567FE" />
                )}
                {item.event_type === "Unsafe navigation" && (
                  <BrowserIcon fontSize="18px" color="#7567FE" />
                )}
              </Box>
              <Box>
                <b>{item.event_type}</b> on <b>{item.target}</b> on{" "}
                <i>{item.event_date}</i>
              </Box>
            </Box>
          ))}
      </Box>
    </GenericCard>
  );
}
