import { Stack, Typography } from "@mui/material";
import React from "react";

interface ErrorCatalogMessageText {
  first_line: string;
  second_line: string;
}

interface ErrorCatalogMessageProps {
  error_catalog_message_text: ErrorCatalogMessageText;
}
export const ErrorCatalogMessage = ({
  error_catalog_message_text,
}: ErrorCatalogMessageProps) => {
  return (
    <Stack height={"80%"} padding={"100px"} justifyContent={"center"}>
      <MessageLine>{error_catalog_message_text.first_line}</MessageLine>
      <MessageLine>{error_catalog_message_text.second_line}</MessageLine>
    </Stack>
  );
};

const MessageLine = ({ children }) => {
  return (
    <Typography
      sx={{ fontWeight: "200" }}
      align={"center"}
      variant={"h1"}
      color={"#130043"}
    >
      {children}
    </Typography>
  );
};
