import React from "react";
import { Tooltip } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

const ThreatStatusIcon = ({
  status,
  comment,
}: {
  status: FeedbackType;
  comment: string | undefined;
}) => {
  if (status == null) {
    return null;
  }
  let statusIcon = null;
  if (status.toLowerCase() === "spam") {
    statusIcon = <i className="fas fa-ban" />;
  } else if (status.toLowerCase() === "safe") {
    statusIcon = <i className="fas fa-check text-success" />;
  } else if (status.toLowerCase() === "suspicious") {
    statusIcon = <i className="fas fa-question text-warning" />;
  } else if (status.toLowerCase() === "unsafe") {
    statusIcon = <i className="fas fa-exclamation-circle text-danger" />;
  }
  return (
    <>
      {statusIcon}
      {comment && (
        <Tooltip
          title={comment}
          placement="right-end"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [-7, -20],
                  },
                },
              ],
            },
          }}
        >
          <span>
            {" "}
            <ChatBubbleOutlineIcon />
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default ThreatStatusIcon;
