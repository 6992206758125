import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import { ButtonSpinner } from "../Spinner";

export const ConfirmationDialog = ({
  isShown,
  onConfirm,
  onConfirmText,
  onCancel,
  onCancelText,
  title,
  content = "",
  isLoading = false,
  disabled = false,
  onClose = () => false,
  children = undefined,
}) => (
  <Dialog
    open={isShown}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>{children ?? content}</DialogContent>
    <DialogActions>
      {isLoading ? <ButtonSpinner sx={{ marginRight: "1rem" }} /> : ""}
      <Button
        onClick={onCancel}
        variant="contained"
        color="primary"
        autoFocus
        disabled={disabled}
      >
        {onCancelText}
      </Button>
      <Button
        onClick={onConfirm}
        variant="outlined"
        color="warning"
        disabled={disabled}
      >
        {onConfirmText}
      </Button>
    </DialogActions>
  </Dialog>
);
