import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type DropdownButtonItem = {
  label: string;
  value: string;
};

type DropdownButtonProps = {
  items: DropdownButtonItem[];
  label: string;
  value: string;
  setValue: (value: string) => void;
};

const DropdownButton = ({
  items,
  label,
  value,
  setValue,
}: DropdownButtonProps) => {
  return (
    <>
      <FormControl
        sx={{
          minWidth: 120,
        }}
      >
        <InputLabel size="small">{label}</InputLabel>
        <Select
          label={label}
          size="small"
          value={value}
          onChange={(event) => setValue(event.target.value)}
        >
          {items.map((item: DropdownButtonItem, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default DropdownButton;
