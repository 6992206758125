import CreateCampaignConfirmationDialog from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignCreate/CreateCampaignConfirmationDialog";
import EmailForm from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignCreate/EmailForm";
import { CustomCampaignTheme } from "@/pages/phishing-simulation/CustomCampaign/theme";
import { useSimulationResource } from "@/utils/ResourceGet";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmailPreview from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignCreate/EmailPreview";
import TelegramIcon from "@mui/icons-material/Telegram";
import { extractErrorMessage } from "./customCampaignError";
import { SelectTargetedRadioFormControl } from "@/pages/phishing-simulation/CustomCampaign/CustomCampaignCreate/SelectTargetedRadioFormControl";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ButtonSpinner } from "@/components/Spinner";

export const customCampaignFormSchema = z
  .object({
    campaignName: z
      .string()
      .min(1, { message: "You must provide a campaign name" }),
    targetedType: z.enum(["departments", "users"]).default("departments"),
    selectedDepartments: z.array(
      z.object({
        label: z.string(),
        id: z.number(),
      }),
    ),
    selectedUsers: z.array(z.string().email().toLowerCase()),
    subject: z.string().min(1, { message: "A subject is required" }),
    body: z.string().min(1, { message: "A body is required" }),
    sender: z.string().email({ message: "A sender is required" }).toLowerCase(),
    displayName: z.string().min(1, { message: "A display name is required" }),
    shouldSendToAll: z.boolean().default(false),
    attachment: z
      .object({
        name: z.string(),
        type: z.string(),
      })
      .nullable()
      .optional(),
    scenario: z.string().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    // don't validate departements or users when sending to myself
    if (!data.shouldSendToAll) {
      return true;
    }
    if (
      data.targetedType === "departments" &&
      data.selectedDepartments.length < 1
    ) {
      ctx.addIssue({
        path: ["selectedDepartments"],
        code: z.ZodIssueCode.custom,
        message: "You should select at least one department",
      });
    } else if (data.targetedType === "users" && data.selectedUsers.length < 1) {
      ctx.addIssue({
        path: ["selectedUsers"],
        code: z.ZodIssueCode.custom,
        message: "You should select at least one user",
      });
    }
  });

export const useCustomCampaignCreate = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [sendInProgress, setSendInProgress] = useState(false);
  const [sendToMyselfInProgress, setSendToMyselfInProgress] = useState(false);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm<CustomCampaignFormSchema>({
    resolver: zodResolver(customCampaignFormSchema),
    defaultValues: {
      campaignName: locationState?.subject
        ? `"${locationState?.subject}" campaign`
        : "",
      body: locationState?.content ?? "",
      subject: locationState?.subject ?? "",
      sender: locationState?.from_email ?? "",
      displayName: locationState?.from_name ?? "",
      targetedType: "departments",
      selectedDepartments: [],
      selectedUsers: [],
      attachment: locationState?.attachment ?? null,
      scenario: locationState?.scenario ?? null,
    },
    mode: "onBlur",
  });

  const onClickSendToMySelf = async (data) => {
    setSendToMyselfInProgress(true);
    const token = await getAccessTokenSilently();
    try {
      await axios.post(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
          "/custom_campaign/preview_custom_campaign",
        {
          body: data.body,
          from_email: data.sender,
          from_name: data.displayName,
          subject: data.subject,
          attachment: data.attachment,
          scenario: data.scenario,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      enqueueSnackbar("The email was sent successfully ", {
        variant: "success",
      });
    } catch (error) {
      setSendToMyselfInProgress(false);
      enqueueSnackbar(extractErrorMessage(error));
    }
    setSendToMyselfInProgress(false);
  };

  const onClickSendToAll = async (data) => {
    const token = await getAccessTokenSilently();
    setSendInProgress(true);
    try {
      await axios
        .post(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/custom_campaign/create_and_send_custom_campaign",
          {
            campaign_name: data.campaignName,
            department_ids: data.selectedDepartments.map(({ id }) => id),
            user_emails: data.selectedUsers,
            body: data.body,
            audience_target: data.targetedType,
            from_email: data.sender,
            from_name: data.displayName,
            subject: data.subject,
            attachment: data.attachment,
            scenario: data.scenario,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          enqueueSnackbar("Emails were sent successfully ", {
            variant: "success",
            persist: true,
          });
          setTimeout(() => {
            navigate("/phishing-simulation/custom-campaign");
          }, 2000);
        });
      setSendInProgress(true);
    } catch (error) {
      setSendInProgress(false);
      enqueueSnackbar(extractErrorMessage(error), { variant: "error" });
    }
  };

  // TODO handle isLoading/isError
  const [departments] = useSimulationResource(
    "departments_from_user?with_id=True",
    [],
  );
  // TODO handle isLoading/isError
  const [users] = useSimulationResource("users/basic_infos", []);

  return {
    departments,
    users,
    onClickSendToMySelf,
    onClickSendToAll,
    sendToMyselfInProgress,
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    sendInProgress,
    trigger,
    control,
  };
};

type CustomCampaignFormSchema = z.infer<typeof customCampaignFormSchema>;

export default function PanelCreateCustomCampaign() {
  const campaignNameRef = useRef<HTMLElement>(undefined);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    sendInProgress,
    setValue,
    trigger,
    onClickSendToAll,
    onClickSendToMySelf,
    sendToMyselfInProgress,
    control,
  } = useCustomCampaignCreate();

  return (
    <ThemeProvider theme={CustomCampaignTheme}>
      <Card
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <CardHeader title="New Campaign" />
        <Divider />
        <CardContent>
          <Typography ref={campaignNameRef}>Campaign Name</Typography>
          <TextField
            id={"campaignName"}
            name="campaignName"
            size={"small"}
            {...register("campaignName")}
            error={!!errors.campaignName}
            helperText={errors.campaignName?.message}
          />
          <Typography>Targets</Typography>
          <SelectTargetedRadioFormControl
            watch={watch}
            errors={errors}
            control={control}
            setValue={setValue}
          />
          <Divider />
          <Grid container spacing={3} marginTop={0}>
            <Grid item xs={6}>
              <EmailForm
                control={control}
                register={register}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6}>
              <EmailPreview
                subject={watch("subject")}
                sender={watch("sender")}
                body={watch("body")}
                displayName={watch("displayName")}
              />
            </Grid>
          </Grid>
        </CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            backgroundColor: "#f7f8fa",
            borderTop: "1px solid #ebedf2",
            padding: ".75rem 1.25rem",
          }}
        >
          <Button
            variant="outlined"
            onClick={(data) => {
              setValue("shouldSendToAll", false);
              handleSubmit(onClickSendToMySelf)(data);
            }}
            sx={{ marginRight: "10px" }}
          >
            {sendToMyselfInProgress ? <ButtonSpinner sx={{ mr: 2 }} /> : null}
            Send to myself
          </Button>
          <Button
            variant="contained"
            startIcon={<TelegramIcon />}
            onClick={() => {
              setValue("shouldSendToAll", true);
              trigger();
              if (Object.keys(errors).length === 0) {
                setConfirmationDialogOpen(true);
              } else {
                campaignNameRef.current?.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Send campaign now
          </Button>
          <CreateCampaignConfirmationDialog
            confirmationDialogOpen={confirmationDialogOpen}
            onClickSendToAll={handleSubmit(onClickSendToAll)}
            setConfirmationDialogOpen={setConfirmationDialogOpen}
            sender={watch("sender")}
            subject={watch("subject")}
            targetChoice={watch("targetedType")}
            users={watch("selectedUsers")}
            departments={watch("selectedDepartments")}
            sendInProgress={sendInProgress}
          />
        </div>
      </Card>
    </ThemeProvider>
  );
}
