import React from "react";
import { useDownloadUsers } from "@/pages/general-settings/UsersManagement/utils";
import { LinkButton } from "@/utils/misc";

export function ExportCSVLink() {
  const { startDownload } = useDownloadUsers();

  return (
    <LinkButton onClick={() => startDownload()}>
      download your current users in CSV here
    </LinkButton>
  );
}
