import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AllowedSharingPolicy } from "@/pages/awareness/courses/utils/sharingPolicies";

export const CourseSharingCardFooter = ({ learningUserInfos, course }) => {
  const { t } = useTranslation("translation", {
    lng: learningUserInfos.language,
  });

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        paddingRight: "15px",
        paddingTop: "10px",
      }}
    >
      <Grid item>
        <Typography
          sx={{
            fontStyle: "italic",
            paddingLeft: "15px",
            fontSize: "12px",
            color: "#94A3B8",
          }}
        >
          <Box fontWeight="1000" display="inline">
            {course.sharing_policy === AllowedSharingPolicy.COMPANY_ONLY
              ? t("My company only")
              : t("Shared with subsidiaries")}
          </Box>{" "}
        </Typography>
      </Grid>
    </Grid>
  );
};
