import React from "react";

import { DocBlock } from "@/pages/support/DocBlock";
import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import { useUserContext } from "@/utils/contexts/UserContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Office = [
  {
    title: "Setup Phishing Simulation",
    description: "How to set Phishing Simulation",
    link: "https://scribehow.com/page-embed/Setup_For_Microsoft_Simulation_SSO_API_Sync__YYb0p6qZTPObuQnhE2gj8w",
  },
  {
    title: "Setup Awareness",
    description: "How to set Awareness with Teams",
    link: "https://scribehow.com/page-embed/Awareness_Setup_Teams__bEY7pwTITLubuarJG74nlg",
  },
  {
    title: "Setup Smart Banners",
    description: "How to set Smart Banners in your mailbox",
    link: "https://scribehow.com/shared/Allow_Mantra_to_display_Banners_to_your_users__5F1dEVMVQ6e2a9dNM6vmQA",
  },
];
const Gmail = [
  {
    title: "Setup Phishing Simulation",
    description: "How to set Phishing Simulation",
    link: "https://scribehow.com/page/Setup_for_Google_Simulation_SSO_API_Sync__xYsiB6buQRu_ZFuN9eF1Ug",
  },
  {
    title: "Setup Awareness",
    description: "How to set Awareness with Slack",
    link: "https://scribehow.com/page-embed/Awareness_Setup_Slack__ODwVnGP6QVmqlMuSu0uAAg",
  },
  {
    title: "Setup Smart Banners",
    description: "How to set Smart Banners in your mailbox",
    link: "https://scribehow.com/shared/Allow_Mantra_to_display_Banners_to_your_users__9n8BRGLuRDiNPc-6GHzLtA",
  },
];
const docCommon = [
  {
    title: "Common Actions",
    description: "",
    link: "https://scribehow.com/page-embed/Common_Actions__0rQKj4gjRQOASQ45d4FPDQ",
  },
];
export function Documentation() {
  const userContext = useUserContext();
  const mailHost = userContext?.current_company?.mailHost ?? "";

  return (
    <>
      {mailHost === "OFFICE"
        ? Office.map((doc) => (
            <Accordion
              sx={{
                borderTop: 0,
                borderRadius: "6px",
                "&::before": {
                  opacity: 0,
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{doc.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DocBlock doc={doc} />
              </AccordionDetails>
            </Accordion>
          ))
        : Gmail.map((docG) => (
            <Accordion
              sx={{
                borderTop: 0,
                borderRadius: "6px",
                "&::before": {
                  opacity: 0,
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{docG.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DocBlock doc={docG} />
              </AccordionDetails>
            </Accordion>
          ))}
      {docCommon.map((doc) => (
        <Accordion
          sx={{
            borderTop: 0,
            borderRadius: "6px",
            "&::before": {
              opacity: 0,
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{doc.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocBlock doc={doc} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
