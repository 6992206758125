import React from "react";

const Title = () => {
  return (
    <>
      <span>Threat list</span>
    </>
  );
};

export default Title;
