import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const PermissionsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ fontSize: 12 }} htmlColor={"#98A5B3"} {...props}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.24999 7L6.41666 8.16667L9.04166 5.54167M10.4423 2.9158C10.5625 3.20632 10.793 3.43724 11.0834 3.5578L12.1014 3.97949C12.3919 4.09984 12.6227 4.33067 12.7431 4.62121C12.8634 4.91174 12.8634 5.23819 12.7431 5.52873L12.3217 6.54606C12.2013 6.83672 12.2011 7.1635 12.3221 7.45402L12.7427 8.47105C12.8024 8.61495 12.8331 8.7692 12.8331 8.92497C12.8331 9.08075 12.8025 9.23501 12.7429 9.37892C12.6833 9.52284 12.5959 9.65361 12.4857 9.76374C12.3755 9.87387 12.2448 9.96122 12.1008 10.0208L11.0835 10.4422C10.793 10.5623 10.5621 10.7929 10.4416 11.0832L10.0199 12.1013C9.89954 12.3918 9.66871 12.6226 9.37819 12.743C9.08766 12.8633 8.76122 12.8633 8.4707 12.743L7.45341 12.3216C7.16287 12.2015 6.83655 12.2018 6.5462 12.3223L5.52818 12.7434C5.23781 12.8634 4.91166 12.8633 4.62137 12.7431C4.33108 12.6228 4.10039 12.3923 3.97996 12.102L3.55816 11.0837C3.43804 10.7932 3.20747 10.5622 2.91715 10.4417L1.89913 10.02C1.60873 9.8997 1.37797 9.66901 1.25759 9.37864C1.1372 9.08827 1.13704 8.76198 1.25713 8.47149L1.67851 7.45416C1.79855 7.16361 1.79831 6.83728 1.67783 6.54692L1.25706 5.52813C1.1974 5.38423 1.16668 5.22998 1.16666 5.07421C1.16663 4.91843 1.1973 4.76418 1.25691 4.62026C1.31652 4.47634 1.40391 4.34558 1.51407 4.23544C1.62424 4.12531 1.75502 4.03797 1.89895 3.9784L2.91625 3.55701C3.2065 3.43699 3.43727 3.20671 3.55792 2.9167L3.9796 1.89864C4.09994 1.60811 4.33076 1.37727 4.62129 1.25693C4.91181 1.13659 5.23825 1.13659 5.52877 1.25693L6.54607 1.67832C6.8366 1.79837 7.16292 1.79813 7.45327 1.67764L8.47172 1.25758C8.76221 1.13731 9.08857 1.13733 9.37904 1.25765C9.66951 1.37797 9.90031 1.60874 10.0207 1.8992L10.4425 2.91756L10.4423 2.9158Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
