const mapErrorMessage = new Map<string, string>([
  [
    'Field content must contains at least a click url : You should add a proper click url of the form <a href="{{ click_url }}">my link</a>.If the {{ click_url }} is not needed (attachement type mail)',
    "" +
      'Field body must contain a click url with the form : <a href="{{ click_url }}">Click here </a>',
  ],
]);

export function extractErrorMessage(error) {
  let data;
  if (error.response == null) {
    data = error.message;
  } else {
    data = error.response.data;

    if (typeof error.response.data == "object") {
      data = JSON.stringify(error.response.data);
    }

    if (error.response.headers["content-type"] === "text/html") {
      data`${error.response.statusText} (${error.response.status})`;
    }
  }

  return mapErrorMessage.get(data) || data;
}
