import React from "react";
import { Box, Divider, Typography } from "@mui/material";

export const SectionTitleComponent = ({ title }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ marginTop: "2rem" }}>
      <Typography
        sx={{
          fontSize: "14px",
          color: "#94A3B8",
          fontWeight: "700",
          marginRight: "1rem",
          marginLeft: "3rem",
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{
          flexGrow: 1,
          color: "#94A3B8",
          opacity: 1,
          border: 0.8,
          marginRight: "3rem",
        }}
      />
    </Box>
  );
};
