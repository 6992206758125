import React, { ReactElement } from "react";
import ClicksPerDimensionChart from "@/pages/phishing-simulation/dashboard/ClicksPerDimensionChart";
import { Card, CardHeader, CardContent } from "@mui/material";
import { CenteredStyledSpinner } from "@/components/Spinner";

export default function ClicksPerDimension({
  title,
  backgroundColor,
  dataErrorPending,
}) {
  let body: ReactElement | null;
  if (dataErrorPending.isPending || !dataErrorPending.data)
    body = <CenteredStyledSpinner width="50px" color="#777" />;
  if (dataErrorPending.error) body = <div>An error occurred</div>;
  if (dataErrorPending.data && !dataErrorPending.isPending) {
    if (dataErrorPending.data?.length === 0) {
      body = <div>No dataErrorPending.data available</div>;
    } else {
      body = (
        <ClicksPerDimensionChart
          labels={Object.keys(dataErrorPending.data)}
          clickRates={Object.values(dataErrorPending.data)}
          backgroundColor={backgroundColor}
        />
      );
    }
  }
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>{body}</CardContent>
    </Card>
  );
}
