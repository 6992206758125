import { Box, Button, Paper, Stack } from "@mui/material";
import React, { createContext, useEffect, useMemo, useState } from "react";
import Stepper, { SetupStep } from "@/components/stepper/Stepper";

type SetupChecklistProps = {
  steps: SetupStep[];
  children?: React.ReactNode;
};

export const SetupChecklistContext = createContext({ activeIndex: 0 });

export const SetupChecklist: React.FC<SetupChecklistProps> = ({
  steps,
  children,
}: SetupChecklistProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const prev = () => setActiveIndex((prev) => prev - 1);
  const next = () => setActiveIndex((prev) => prev + 1);
  const onClickStepper = (_: any, index: number) => {
    setActiveIndex(index);
  };
  const activeStep = useMemo(() => {
    return steps[activeIndex];
  }, [activeIndex, steps]);

  useEffect(
    function updateActiveStepBasedOnStepsStatus() {
      let updatedIndex = -1;
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].status !== "succeeded") {
          updatedIndex = i;
          break;
        }
      }
      if (updatedIndex === -1) {
        updatedIndex = steps.length - 1;
      }
      setActiveIndex(updatedIndex);
    },
    [steps, setActiveIndex],
  );
  return (
    <Stack
      display="grid"
      gap="1rem"
      gridTemplateColumns="2fr 3fr"
      alignItems="start"
    >
      <Paper sx={{ px: "2.5rem", py: "4rem" }}>
        <Stepper
          steps={steps}
          activeIndex={activeIndex}
          onClick={onClickStepper}
        />
      </Paper>
      <Paper sx={{ p: "2.5rem" }}>
        <SetupChecklistContext.Provider value={{ activeIndex: activeIndex }}>
          {children}
        </SetupChecklistContext.Provider>
        <Box marginTop="3rem" display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            disabled={activeIndex === 0}
            onClick={prev}
          >
            Previous
          </Button>
          {activeIndex < steps.length - 1 ? (
            <Button variant="outlined" onClick={next}>
              {activeStep.mandatory ? "Next" : "Skip"}
            </Button>
          ) : null}
        </Box>
      </Paper>
    </Stack>
  );
};
