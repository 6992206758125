import React from "react";
import styled from "styled-components";
import theme from "@/theme";

const StickyDiv = styled.div<{ $height: string; $paddingTop: string }>`
  position: sticky;
  top: -1.5rem;
  height: ${(props) => props.$height ?? "75px"};
  margin: -1.5rem -16px 1rem -16px;
  padding: ${(props) => props.$paddingTop ?? "1.5rem"} 2rem 1.5rem 2rem;
  background: ${theme.palette.background.paper};
  z-index: 105;
  box-shadow:
    0px 2px 8px rgba(4, 9, 33, 0.08),
    0px 2px 0px rgba(4, 9, 33, 0.04);

  @media (min-width: 500px) {
    top: -2rem;
  }
`;

const StickyHeader = ({
  children,
  height = "75px",
  paddingTop = "1.5rem",
  ...props
}) => {
  return (
    <StickyDiv $height={height} $paddingTop={paddingTop} {...props}>
      {children}
    </StickyDiv>
  );
};

export default StickyHeader;
