import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogContentText,
} from "@mui/material";
import { ButtonSpinner } from "../Spinner";

export const DeleteConfirmationDialog = ({
  isShown,
  onConfirm,
  onConfirmText,
  onCancel,
  onCancelText,
  title,
  content = "",
  isLoading = false,
  onClose = () => false,
  confirmText = "DELETE",
}) => {
  const [inputValue, setInputValue] = React.useState("");

  const isConfirmEnabled = inputValue === confirmText;
  const verifyAndConfirm = () => {
    if (isConfirmEnabled) {
      onConfirm();
    } else {
      alert("Please type the correct text to confirm.");
    }
  };

  return (
    <Dialog
      open={isShown}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <DialogContentText color="error" sx={{ mt: 2, marginBottom: "5px" }}>
          Confirm deletion by typing '<strong>{confirmText}</strong>' below.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Delete"
          fullWidth
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        {isLoading ? <ButtonSpinner sx={{ marginRight: "1rem" }} /> : ""}
        <Button
          onClick={onCancel}
          variant="contained"
          color="primary"
          autoFocus
        >
          {onCancelText}
        </Button>
        <Button
          onClick={verifyAndConfirm}
          variant="outlined"
          color="warning"
          disabled={!isConfirmEnabled}
        >
          {onConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
