import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useSimulationResource } from "@/utils/ResourceGet";
import { useSnackbar } from "material-ui-snackbar-provider";
import axios from "axios";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";

const ENDPOINT = "company/landing_page_override_status";

export const LandingPageOverride = () => {
  const snackbar = useSnackbar();
  const [{ enabled: optionEnabled }, readError] = useSimulationResource(
    ENDPOINT,
    false,
  );

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(optionEnabled);
  }, [optionEnabled]);

  async function onChange(): Promise<void> {
    const initialCheckValue = checked;
    const targetCheckValue = !checked;
    try {
      setChecked(targetCheckValue);
      await axios.put(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${ENDPOINT}`,
        { enabled: targetCheckValue },
      );
      snackbar.showMessage(
        `Landing page override has been ${
          targetCheckValue ? "enabled" : "disabled"
        }.`,
      );
    } catch {
      snackbar.showMessage("Could not update value.");
      setChecked(initialCheckValue);
    }
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Landing page override</Typography>
            <div
              style={{
                marginLeft: "12px",
                marginBottom: "0px",
                marginTop: "3px",
              }}
            >
              <BootstrapStyleSwitch
                label=""
                id="landingPageOverride"
                checked={checked}
                onChange={onChange}
                disabled={readError !== null}
              />
            </div>
          </Box>
        }
      />
      <CardContent>
        <Typography>
          When activated, users that click on a phishing simulation will see an
          inconspicuous 404 page instead of the corresponding learning module.
          <Tooltip
            title="This 404 landing page aligns with your SSO provider."
            arrow
          >
            <InfoIcon
              sx={{
                ml: 1,
                fontSize: "medium",
                cursor: "pointer",
                verticalAlign: "middle",
                color: "darkgray",
              }}
            />
          </Tooltip>
        </Typography>
        {readError !== null && (
          <Typography style={{ color: "red" }}>
            Could not retrieve data.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
