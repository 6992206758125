import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

export const labellingOptions = {
  noAction: {
    title: "Don't %ACTION_LOWER% any alert",
    description:
      "Label all events related to this domain and do not %ACTION_LOWER% any of them",
    apiAttribute: "no_action",
  },
  archiveSelected: {
    title: "%ACTION_UPPER% selection",
    description:
      "Label all events related to this domain and %ACTION_LOWER% %SELECTED_COUNT% selected alerts",
    apiAttribute: "%ACTION_LOWER%_selected",
  },
  archiveSimilarByUserAndDomain: {
    title: "%ACTION_UPPER% related alerts for this user and domain",
    description:
      "Label all events related to this domain and %ACTION_LOWER% %RELATED_COMBINED_COUNT% related domain and user alerts",
    apiAttribute: "%ACTION_LOWER%_similar_by_user_and_domain",
  },
  archiveSimilarByDomain: {
    title: "%ACTION_UPPER% related alerts for domain",
    description:
      "Label all events related to this domain and %ACTION_LOWER% %RELATED_DOMAIN_COUNT% related domain alerts",
    apiAttribute: "%ACTION_LOWER%_similar_by_domain",
  },
};

export const passwordMisuseDomainLabelButtonOptions = [
  {
    key: "reuse",
    value: {
      title: "Credentials reuse",
      description: "Keep triggering alerts for domain(s) and ...",
      icon: <ReportProblemIcon sx={{ color: "#F19949" }} />,
    },
  },
  {
    key: "safe",
    value: {
      title: "Authorized domain",
      description: "Stop triggering alerts for domain(s) and ...",
      icon: <CheckCircleIcon sx={{ color: "#35D9C0" }} />,
    },
  },
  {
    key: "blocked",
    value: {
      title: "Blocked domain",
      description: "Block browsing on domain(s) and ...",
      icon: <ReportIcon sx={{ color: "#F6285F" }} />,
    },
  },
];

export const mantraDomainLabelOptions = [
  {
    key: "potential_phishing",
    value: {
      title: "Phishing attack",
      description: "",
      icon: <AutoAwesomeIcon sx={{ color: "#F6285F" }} />,
    },
  },
  {
    key: "reuse",
    value: {
      title: "Credentials reuse",
      description: "",
      icon: <AutoStoriesIcon sx={{ color: "#030ffc" }} />,
    },
  },
];
