import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { AwarenessSetupChecklist } from "./SetupChecklistTypes";
import { SetupStepper } from "./AwarenessSetupStepperService";

const initialState: AwarenessSetupChecklist = {
  messagingPlatform: null,
  oneCourseAssigned: false,
  courseDeliveryActivated: false,
  messagingPlatformInstalled: false,
};

const awarenessChecklistSlice = createSlice({
  name: "awareness-checklist",
  initialState,
  reducers: {
    updateChecklistState: (_, action): AwarenessSetupChecklist => {
      return {
        messagingPlatform: action.payload.messaging_platform,
        oneCourseAssigned: action.payload.one_course_assigned,
        courseDeliveryActivated: action.payload.course_delivery_activated,
        messagingPlatformInstalled: action.payload.messaging_platform_installed,
      };
    },
    updateMessagingPlatformInstalledStatus: (state, action) => {
      state.messagingPlatformInstalled = action.payload;
    },
  },
});

export const { updateChecklistState, updateMessagingPlatformInstalledStatus } =
  awarenessChecklistSlice.actions;

export const selectAwarenessSetupChecklistState = (
  state: RootState,
): AwarenessSetupChecklist => {
  return state.awarenessChecklist;
};

export const selectStepperFromChecklistState = (state: RootState) => {
  return new SetupStepper(state.awarenessChecklist).steps;
};

export default awarenessChecklistSlice.reducer;
