import React from "react";
import TopBar from "./topbar/TopBar";

type HeaderProps = {
  actionButtonsEnabled: boolean;
  onFeedback: (feedback: FeedbackType) => void;
  onDelete: () => void;
  onUnarchive: () => void;
  isArchived: boolean;
  onTabChange: (tab: ThreatListTab) => void;
};

const Header = ({
  actionButtonsEnabled,
  onFeedback,
  onDelete,
  onUnarchive,
  isArchived,
  onTabChange,
}: HeaderProps) => {
  return (
    <>
      <TopBar
        actionButtonsEnabled={actionButtonsEnabled}
        onFeedback={onFeedback}
        onDelete={onDelete}
        onUnarchive={onUnarchive}
        isArchived={isArchived}
        onTabChange={onTabChange}
      />
    </>
  );
};

export default Header;
