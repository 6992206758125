import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import theme from "@/theme";

const FullSizeCenterSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const CenterSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export function ButtonSpinner({ size = "1.5rem", ...props }) {
  return <CircularProgress size={size} {...props} />;
}

export function BigSpinner({ shouldDisplayText = false, text = "" }) {
  const [displayText, setDisplayText] = useState(true);

  useEffect(() => {
    if (!shouldDisplayText) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setDisplayText(true);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [shouldDisplayText, setDisplayText]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <div style={{ height: "1em" }}>{displayText && text}</div>
      <CircularProgress
        size="100px"
        sx={{ color: theme.palette.primary.main }}
      />
    </div>
  );
}

export function CenteredBigSpinner() {
  return (
    <CenterSpinnerContainer>
      <CircularProgress
        size="100px"
        sx={{ color: theme.palette.primary.main }}
      />
    </CenterSpinnerContainer>
  );
}

export function FullSizeBigSpinner({ shouldDisplayText = false, text = "" }) {
  const [displayText, setDisplayText] = useState(false);

  useEffect(() => {
    if (!shouldDisplayText) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setDisplayText(true);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [shouldDisplayText, setDisplayText]);

  return (
    <FullSizeCenterSpinnerContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div style={{ height: "1em" }}>{displayText && text}</div>
        <CircularProgress
          size="100px"
          sx={{ color: theme.palette.primary.main }}
        />
      </div>
    </FullSizeCenterSpinnerContainer>
  );
}

export const CenteredStyledSpinner = (props) => {
  const size = props.width ? props.width : "2.5rem";
  const color = props.color ? props.color : "#5d78ff";

  return (
    <CenterSpinnerContainer>
      <CircularProgress size={size} sx={{ color: color }} />
    </CenterSpinnerContainer>
  );
};
