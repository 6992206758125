import React from "react";
import { Typography } from "@mui/material";

export function StatsItem({ number, subtitle, iconImage }) {
  return (
    <div className="col-md-6">
      <div className="row">
        <div className="col-md-3 my-auto">
          <img src={iconImage} height={20} width={20} alt="icon" />
        </div>
        <div className="col-md-9" style={{ lineHeight: "90%" }}>
          {number}
          <br />
          <Typography component={"span"} sx={{ fontSize: 12, color: "black" }}>
            {subtitle}
          </Typography>
        </div>
      </div>
    </div>
  );
}
