import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";

export const ThreatCommentDialog = ({
  openCommentDialog,
  setOpenCommentDialog,
  selectedThreatsCount,
  updateSelectedThreats,
  action,
}) => {
  const [comment, setComment] = useState("");

  const placeholder = "This email is " + action + " because :";
  useEffect(() => {
    setComment("");
  }, [openCommentDialog]);

  const dialogTitle = `Comment ${selectedThreatsCount} ${action} email${
    selectedThreatsCount > 1 ? "s" : ""
  }`;

  const inputTooLong = comment.length > 255;
  const onClickConfirm = () => {
    updateSelectedThreats(action, comment);
    setOpenCommentDialog(!openCommentDialog);
  };
  return (
    <Dialog open={openCommentDialog} maxWidth={"xs"} fullWidth>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            rows={5}
            variant={"outlined"}
            multiline
            sx={{ width: "100%", backgroundColor: "#FBFBFC" }}
            placeholder={placeholder}
            error={inputTooLong}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComment(event.target.value);
            }}
          />

          <Typography
            sx={{
              color: "#9d9d9f",
              fontStyle: "italic",
              fontSize: "12px",
              paddingTop: "5px",
            }}
          >
            {inputTooLong
              ? "You have reached the character limit (255)."
              : "This comment will be included in your feedback email."}
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={"outlined"}
          onClick={() => setOpenCommentDialog(false)}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          disabled={inputTooLong}
          onClick={onClickConfirm}
        >
          Send feedback emails
        </Button>
      </DialogActions>
    </Dialog>
  );
};
