import React, { useEffect } from "react";
import { usePublicLearningResource } from "@/utils/ResourceGet";
import { Webchat } from "@/pages/webchat/Webchat";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { extractErrorMessage } from "@/utils/misc";
import { FullSizeBigSpinner } from "@/components/Spinner";

export function PublicWebchat({ all = false }) {
  const { userId } = useParams();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [connectionInfo, connectionInfoError, fetchConnectionInfo] =
    usePublicLearningResource(
      `public/webchat/user/${userId}/connection-info`,
      {
        conversation_id: "",
        access_token: "",
        user_id: "",
        user_name: "",
        course_name: "",
        interactions: [],
      },
      false,
    );

  useEffect(() => {
    if (userId) {
      fetchConnectionInfo();
    }
  }, [fetchConnectionInfo, userId]);

  const changeCourseLanguage = (language) => {
    axios
      .post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/public/webchat/course/${
          connectionInfo.course_id
        }/start`,
        { language },
      )
      .then((response) => {
        if (all) {
          navigate(`/demo/webchat/all/${response.data.id}`);
        } else {
          navigate(`/demo/webchat/${response.data.id}`);
        }
      })
      .catch(displayError);
  };

  const displayError = (error) => {
    snackbar.showMessage(extractErrorMessage(error));
  };

  if (connectionInfoError) {
    return <div>An error occurred. Tech team has been notified.</div>;
  }

  if (!connectionInfo.user_id) {
    return (
      <FullSizeBigSpinner
        shouldDisplayText={true}
        text="This may take a while..."
      />
    );
  }

  return (
    <Webchat
      connectionInfo={connectionInfo}
      courseTitle={connectionInfo.course_name}
      goBackPath={
        all ? "/demo/awareness/courses/all" : "/demo/awareness/courses"
      }
      allInteractions={connectionInfo.interactions}
      additionalButtons={[
        <button key="en" onClick={() => changeCourseLanguage("en")}>
          Switch to English
        </button>,
        <button key="fr" onClick={() => changeCourseLanguage("fr")}>
          Passer en Français
        </button>,
      ].filter((button) => button.key !== connectionInfo.language)}
      loadingHistory={false}
    />
  );
}
