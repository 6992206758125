import React from "react";
import { Grid } from "@mui/material";
import { CourseSharingCardsContainer } from "@/pages/awareness/courses/corporate-course-sharing/CourseSharingCardsContainer";
import { CourseType } from "@/types/awareness";

export const CourseSharingCardsContainerList = ({
  courses,
  learningUserInfos,
  onUpdateSharingPolicy,
}) => {
  return (
    <Grid container spacing={2}>
      {courses.map((course: CourseType) => (
        <Grid
          key={course.id}
          item
          xs={12}
          sx={{ marginLeft: "3rem", marginRight: "3rem" }}
        >
          <CourseSharingCardsContainer
            learningUserInfos={learningUserInfos}
            course={course}
            onUpdateSharingPolicy={onUpdateSharingPolicy}
          />
        </Grid>
      ))}
    </Grid>
  );
};
