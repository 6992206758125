import { useEffect, useState } from "react";
import useThreatController from "./controller";

const isCurrentItemIndexSet = (
  currentIndex: number | null,
): currentIndex is number => {
  return currentIndex != null;
};

type useThreatItemNavigatorParameterTypes = {
  items: ThreatInfo[];
  moveToPreviousPage: () => void;
  moveToNextPage: () => void;
  hasPreviousPage: () => boolean;
  hasNextPage: () => boolean;
  removeItems: (itemIds: number[]) => void;
  getStartIndex: () => number;
};

export const useThreatNavigator = ({
  items,
  moveToPreviousPage,
  moveToNextPage,
  hasPreviousPage,
  hasNextPage,
  removeItems,
  getStartIndex,
}: useThreatItemNavigatorParameterTypes) => {
  const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null);
  const [item, setItem] = useState<ThreatDetails | null>(null);
  const { threatDetails: fetchedTreatDetails, getThreatDetails } =
    useThreatController();

  const setup = (itemId: number): void => {
    const index = items.findIndex((item) => item.id === itemId);
    if (index === -1) {
      throw new Error(`Item not found: ${itemId}`);
    }
    setCurrentItemIndex(index);
  };

  const moveToPreviousItem = (): void => {
    if (!isCurrentItemIndexSet(currentItemIndex)) {
      return;
    }
    if (hasPreviousItem()) {
      if (currentItemIndex > 0) {
        setCurrentItemIndex((prevIndex) => prevIndex - 1);
      } else {
        moveToPreviousPage();
        setCurrentItemIndex(-1);
      }
    }
  };

  useEffect(() => {
    if (currentItemIndex < 0) {
      setCurrentItemIndex(items.length - 1);
    }
  }, [currentItemIndex, items.length]);

  useEffect(() => {
    const f = async () => {
      if (!isCurrentItemIndexSet(currentItemIndex)) {
        setItem(null);
        return;
      }
      if (currentItemIndex < 0) {
        setItem(null);
        return;
      }
      if (!items[currentItemIndex].id) {
        setItem(null);
        return;
      }
      getThreatDetails(items[currentItemIndex].id);
      //setItem(await fetchThreat(items[currentItemIndex].id));
    };
    f();
  }, [currentItemIndex, items, getThreatDetails]);

  useEffect(() => {
    setItem(fetchedTreatDetails);
  }, [fetchedTreatDetails]);

  const moveToNextItem = (): void => {
    if (!isCurrentItemIndexSet(currentItemIndex)) {
      return;
    }
    if (!hasNextItem()) {
      return;
    }
    if (currentItemIndex < items.length - 1) {
      setCurrentItemIndex((prevIndex) => prevIndex + 1);
    } else {
      moveToNextPage();
      setCurrentItemIndex(0);
    }
  };

  const removeCurrentItem = (): void => {
    if (!isCurrentItemIndexSet(currentItemIndex)) {
      return;
    }
    if (!hasNextItem()) {
      setCurrentItemIndex((prev) => prev - 1);
    }
    removeItems([items[currentItemIndex].id]);
  };

  const hasPreviousItem = (): boolean => {
    if (!isCurrentItemIndexSet(currentItemIndex)) {
      return false;
    }
    return currentItemIndex > 0 || hasPreviousPage();
  };

  const hasNextItem = (): boolean => {
    if (!isCurrentItemIndexSet(currentItemIndex)) {
      return false;
    }
    return currentItemIndex < items.length - 1 || hasNextPage();
  };

  const getCurrentItemIndex = (): number | null => {
    return getStartIndex() + currentItemIndex + 1;
  };

  const close = () => {
    setCurrentItemIndex(null);
  };

  return {
    item,
    setup,
    close,
    hasNextItem,
    hasPreviousItem,
    moveToPreviousItem,
    moveToNextItem,
    removeCurrentItem,
    getCurrentItemIndex,
  };
};

export default useThreatNavigator;
