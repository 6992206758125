import { useUserContext } from "@/utils/contexts/UserContext";
import { capitalize } from "@/utils/strings";
import TablePagination from "@mui/material/TablePagination";

import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";
import {
  useFetchAwarenessUsersQuery,
  useUpdateUseWebchatMutation,
} from "@/pages/awareness/userApi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompanyMessagingPlatform,
  selectCurrentPage,
  selectPageSize,
  selectTotalUsers,
  selectUsers,
  setCurrentPage,
  setPageSize,
} from "@/pages/awareness/userSlice";
import { AwarenessUsersConfigFilterBar } from "@/pages/awareness/dashboard/awareness-users-config/AwarenessUsersConfigFilterBar";

type Column = {
  name: string;
  condition?: (messaging_platform: string) => boolean;
};

const COLUMNS: Column[] = [
  { name: "first name" },
  { name: "last name" },
  { name: "email" },
  { name: "departments" },
];

const OptionalColumns: Column[] = [
  {
    name: "webchat activated",
    condition: (messaging_platform) => {
      return messaging_platform !== "webchat";
    },
  },
];

const AwarenessUsersRow = ({ user, messaging_platform }): React.JSX.Element => {
  const [updateUseWebchat, { isLoading: isLoadingUpdate }] =
    useUpdateUseWebchatMutation();
  console.log(messaging_platform);

  return (
    <TableRow>
      <TableCell>{user.firstname}</TableCell>
      <TableCell>{user.lastname}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.departments}</TableCell>
      {messaging_platform !== "webchat" && (
        <TableCell>
          <BootstrapStyleSwitch
            onChange={(event) => {
              updateUseWebchat({
                users_id: [user.id],
                use_webchat: event.target.checked,
              });
            }}
            id={`switch-${user.id}`}
            label=""
            checked={user.use_webchat}
            disabled={isLoadingUpdate}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

const AwarenessUsersHeadRow = ({ messaging_platform }): React.JSX.Element => {
  return (
    <TableRow>
      {COLUMNS.map((column) => (
        <TableCell>
          <strong>{capitalize(column.name)}</strong>
        </TableCell>
      ))}

      {OptionalColumns.map((column) => {
        return (
          column.condition &&
          column.condition(messaging_platform) && (
            <TableCell>
              <strong>{capitalize(column.name)}</strong>
            </TableCell>
          )
        );
      })}
    </TableRow>
  );
};

export const AwarenessUsersConfig = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const { current_company } = useUserContext();

  const totalUsers = useSelector(selectTotalUsers);
  const pageSize = useSelector(selectPageSize);
  const currentPage = useSelector(selectCurrentPage);
  const users = useSelector(selectUsers);

  const [selectedDepartment, setSelectedDepartment] = useState<number>(-4);
  const [searchValue, setSearchValue] = useState("");
  const messaging_platform = useSelector(selectCompanyMessagingPlatform);

  const { isLoading: isLoadingFetch } = useFetchAwarenessUsersQuery({
    company_id: current_company.id,
    page: currentPage + 1,
    page_size: pageSize,
    search_value: searchValue,
    department_id: selectedDepartment < 0 ? undefined : selectedDepartment,
  });

  const [updateUseWebchat, { isLoading: isLoadingUpdate }] =
    useUpdateUseWebchatMutation();

  return (
    <Box>
      <AwarenessUsersConfigFilterBar
        messaging_platform={messaging_platform}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        setSearchValue={setSearchValue}
        updateUseWebchat={updateUseWebchat}
        isLoadingUpdate={isLoadingUpdate}
      />
      <Box sx={{ height: "5px" }}>{isLoadingFetch && <LinearProgress />}</Box>
      <Paper sx={{ marginTop: "5px" }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <AwarenessUsersHeadRow messaging_platform={messaging_platform} />
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <AwarenessUsersRow
                  messaging_platform={messaging_platform}
                  user={user}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalUsers}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={(_, newPage) => {
            dispatch(setCurrentPage(newPage));
          }}
          onRowsPerPageChange={(event) => {
            dispatch(setPageSize(parseInt(event.target.value, 10)));
            dispatch(setCurrentPage(0));
          }}
        />
      </Paper>
    </Box>
  );
};
