import { captureException } from "@sentry/react";
import { useCallback } from "react";

type ExceptionInfos = {
  name: string;
  message: string;
};

export const sentryShouldIgnoreExtension = (exception: Error): boolean => {
  const exceptionsToIgnore: ExceptionInfos[] = [
    {
      name: "NotFoundError",
      message:
        "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    },
    {
      name: "NotFoundError",
      message:
        "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    },
  ];
  for (let toIgnore of exceptionsToIgnore) {
    if (
      exception instanceof DOMException &&
      exception.name === toIgnore.name &&
      exception.message === toIgnore.message
    ) {
      return true;
    }
  }
  return false;
};

export interface SentryCaptureParams {
  /**
   * An additional array of error statuses to send to Sentry. By default, all statuses < 500 are caught.
   */
  send?: number[];
  /**
   * An array of error statuses to ignore. If the error has any of the given statuses, it will show a snackbar but
   * not send any data to Sentry.
   */
  ignore: number[];
  /**
   * An alternative message to display in the snackbar. The original error is still sent to Sentry.
   */
  display?: string;
}

export type SentryCaptureFN = (
  error: any,
  params?: SentryCaptureParams,
) => void;

export interface SentryCaptureHook {
  /**
   * Capture an error and display it in the snackbar. If the error status is < 500, the error is also sent to Sentry.
   */
  capture: SentryCaptureFN;
}

interface StatusError {
  status: number;
}

export const sentryCaptureException = (err: Error | StatusError) => {
  if (import.meta.env.VITE_APP_ENVIRONMENT !== "development") {
    captureException(err);
  }
  console.log(err);
};

export const useSentryCapture = (): SentryCaptureHook => {
  const capture = useCallback(
    (
      error: Error | StatusError,
      { send, ignore }: SentryCaptureParams = { ignore: [] },
    ) => {
      // Non-HTTP error.
      if (!("status" in error)) {
        return sentryCaptureException(error);
      }

      // Ignore 5XX statuses as they may already be sent by the backend.
      if (
        (error.status < 500 || send?.includes(error.status)) &&
        !ignore.includes(error.status)
      ) {
        return sentryCaptureException(error);
      }

      console.error(error);
    },
    [],
  );

  return { capture };
};
