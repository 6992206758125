import { Stack } from "@mui/material";
import React from "react";
import { TemplateList } from "./TemplateList";
import { TemplateFooter } from "./TemplateFooter";

export const TemplateListSection = () => {
  return (
    <Stack spacing={3}>
      <TemplateList />
      <TemplateFooter />
    </Stack>
  );
};
