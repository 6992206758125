import { RootState } from "@/store";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * Query wrapper with embed Bearer Authentication
 * @param baseUrl
 */
const authenticatedBaseQuery = (baseUrl: string) => {
  return fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });
};

/**
 * Base Query wrapper for simulation calls
 */
export const simulationBaseQuery = () =>
  authenticatedBaseQuery(`${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}`);

/**
 * Base Query wrapper for learning calls
 */
export const learningBaseQuery = () =>
  authenticatedBaseQuery(`${import.meta.env.VITE_APP_ENDPOINT_LEARNING}`);
