import React, { useEffect, useMemo, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useUserContext } from "@/utils/contexts/UserContext";
import ListItemsFormField from "@/components/forms/ListItemsFormField";
import { Box, Typography } from "@mui/material";
import { ButtonSpinner } from "@/components/Spinner";
import InfoTooltip from "@/components/InfoTooltip";

const ROUTE = `${
  import.meta.env.VITE_APP_ENDPOINT_SIMULATION
}/groups/sync/whitelist`;

const WHITELIST_TOOLTIP_CONTENT = `
You can enter group names one by one here.
You may also paste a list of group names separated by a comma or
line breaks. They will all be added to the list.
`;

export type WhitelistedGroup = {
  name: string;
  id: number;
};

const useWhitelistedGroup = () => {
  const [groups, setGroups] = useState<WhitelistedGroup[]>([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isErrorGroups, setIsErrorGroups] = useState(false);

  const validate = (name: string) =>
    groups.find((group) => group.name === name) == null && name.length > 0;

  useEffect(() => {
    setIsLoadingGroups(true);
    axios
      .get(ROUTE)
      .then((response) => {
        setGroups(response.data.whitelisted);
      })
      .catch(() => {
        setIsErrorGroups(true);
      })
      .finally(() => {
        setIsLoadingGroups(false);
      });
  }, []);

  const addGroup = async (groupName: string) => {
    const response = await axios.post(ROUTE, { name: groupName });
    setGroups((groups) => {
      const groupsCopy = [...groups];
      groupsCopy.push({
        id: response.data.id,
        name: groupName,
      });
      return groupsCopy;
    });
  };

  const deleteGroup = async (key: number) => {
    const indexGroupToDelete = groups.findIndex((group) => group.id === key);
    const groupToDelete = { ...groups.find((group) => group.id === key) };
    setGroups((groups) => {
      return groups.filter((group) => group.id !== key);
    });
    try {
      await axios.delete(`${ROUTE}/${key}`);
    } catch (e) {
      setGroups((groups) => {
        const groupsCopy = [...groups];
        groupsCopy.splice(indexGroupToDelete, 0, groupToDelete);
        return groupsCopy;
      });
      throw e;
    }
  };

  const bulkAddGroups = async (groupNames: string[]) => {
    const groupsHashset = new Set(groupNames);
    const groupNamesHashset = new Set(groups.map((group) => group.name));
    // @ts-ignore
    const filteredNames = groupsHashset.difference(groupNamesHashset);

    if (filteredNames.size === 0) {
      throw new Error("no new group entered");
    }

    const newGroups = await Promise.all(
      filteredNames
        .values()
        .map((groupName: string) => axios.post(ROUTE, { name: groupName })),
    );

    setGroups((groups) => {
      const groupsCopy = [...groups];
      groupsCopy.push(
        ...newGroups.map((value: AxiosResponse<{ id: number }>) => ({
          id: value.data.id,
          name: JSON.parse(value.config.data).name,
        })),
      );
      return groupsCopy;
    });
  };

  const modifyGroup = async (groupName: string, key: string) => {
    await axios.patch(`${ROUTE}/${key}`, { name: groupName });
    setGroups((groups) => {
      const groupsCopy = [...groups];
      const group = groupsCopy.find((group) => Number(key) === group.id);
      group.name = groupName;
      return groupsCopy;
    });
  };

  return {
    groups,
    addGroup,
    bulkAddGroups,
    deleteGroup,
    isLoadingGroups,
    modifyGroup,
    isErrorGroups,
    setGroups,
    validate,
  };
};

export function InnerGroupWhitelist() {
  const {
    groups,
    addGroup,
    deleteGroup,
    isLoadingGroups,
    isErrorGroups,
    validate,
    bulkAddGroups,
    modifyGroup,
  } = useWhitelistedGroup();

  const items = useMemo(() => {
    return groups.map((user) => ({
      value: user.name,
      key: user.id.toString(),
    }));
  }, [groups]);

  if (isLoadingGroups) {
    return <ButtonSpinner />;
  }

  if (isErrorGroups) {
    return (
      <Typography color="error">
        Error: unable to fetch whitelisted groups
      </Typography>
    );
  }

  return (
    <ListItemsFormField
      newItemPlaceholder="group1"
      items={items}
      addItem={addGroup}
      bulkAddItems={bulkAddGroups}
      deleteItem={deleteGroup}
      setItem={modifyGroup}
      validate={validate}
      itemErrorMessage="Error while trying to add whitelisted group, you may try again"
      itemSuccessMessage="Successfully uploaded"
      editErrorMessage="Unable to modify the group name, verify that no whitelisted group have the same name"
      bulkAddErrorMessage="Unable to add emails, verify that those are valid whitelisted group names"
      bulkSomeDuplicatedEntriesMessage={(validEntries: number) =>
        `${validEntries} whitelisted groups were added`
      }
      tooltipPlaceholder={<InfoTooltip content={WHITELIST_TOOLTIP_CONTENT} />}
    />
  );
}

export const GroupWhitelist = () => {
  const { current_company } = useUserContext();

  return (
    <Box>
      Add one group name per line below to specify which groups and their
      members should be imported from your{" "}
      {current_company.provider === "GOOGLE" ? "Google Workspace" : "Azure AD"}
      . Only users who belong to the listed groups will be imported. Leave empty
      to import all groups and their members.
      <br />
      <InnerGroupWhitelist />
    </Box>
  );
};
