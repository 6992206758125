import React from "react";

const CopyIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_23_2334)">
        <path
          d="M10.1845 10.2578V11.9429C10.1845 12.617 10.1845 12.9541 10.0533 13.2115C9.93795 13.438 9.75382 13.6222 9.52734 13.7376C9.26986 13.8687 8.93281 13.8687 8.25871 13.8687H3.68486C3.01076 13.8687 2.67371 13.8687 2.41623 13.7376C2.18975 13.6222 2.00562 13.438 1.89022 13.2115C1.75903 12.9541 1.75903 12.617 1.75903 11.9429V7.36907C1.75903 6.69496 1.75903 6.35791 1.89022 6.10044C2.00562 5.87396 2.18975 5.68982 2.41623 5.57443C2.67371 5.44324 3.01076 5.44324 3.68486 5.44324H5.36996M7.29579 10.2578H11.8696C12.5437 10.2578 12.8808 10.2578 13.1383 10.1266C13.3647 10.0112 13.5489 9.82709 13.6643 9.60061C13.7955 9.34314 13.7955 9.00608 13.7955 8.33198V3.75814C13.7955 3.08403 13.7955 2.74698 13.6643 2.48951C13.5489 2.26303 13.3647 2.07889 13.1383 1.9635C12.8808 1.83231 12.5437 1.83231 11.8696 1.83231H7.29579C6.62169 1.83231 6.28464 1.83231 6.02716 1.9635C5.80068 2.07889 5.61655 2.26303 5.50115 2.48951C5.36996 2.74698 5.36996 3.08403 5.36996 3.75814V8.33198C5.36996 9.00608 5.36996 9.34314 5.50115 9.60061C5.61655 9.82709 5.80068 10.0112 6.02716 10.1266C6.28464 10.2578 6.62169 10.2578 7.29579 10.2578Z"
          stroke="#7F8995"
          strokeWidth="1.3541"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_2334">
          <rect
            width="14.4437"
            height="14.4437"
            fill="white"
            transform="translate(0.55542 0.628693)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
