import React from "react";
import style from "./Threats.module.css";
import { ThreatListHeaderTooltip } from "./ThreatListHeaderTooltip";
import { styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import { TabProps } from "@mui/material/Tab";

const ThreatTab = styled(Tab)<TabProps>(({ theme }) => ({
  minHeight: "10px",
  maxHeight: "10px",
}));

type ThreatListHeaderProps = {
  isArchiveDisplayed: boolean;
  setArchiveMode: (v: boolean) => void;
};

export const ThreatListHeader = ({
  isArchiveDisplayed,
  setArchiveMode,
}: ThreatListHeaderProps) => {
  const tab = isArchiveDisplayed ? 1 : 0;
  return (
    <div className={style.threatListHeader}>
      <div style={{ marginRight: "auto", marginLeft: "1rem" }}>
        <Tabs
          indicatorColor="primary"
          textColor="inherit"
          className="builder-tabs"
          value={tab}
          onChange={(_, nextTab) => {
            setArchiveMode(Boolean(nextTab));
          }}
          sx={{
            "& .MuiTabs-scroller": {
              maxHeight: "30px",
              display: "flex",
              alignItems: "center",
            },
            minHeight: "initial",
          }}
        >
          <ThreatTab disableRipple label="Threats" />
          <ThreatTab disableRipple label="Archived" />
        </Tabs>
      </div>
      <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
        <ThreatListHeaderTooltip />
      </div>
    </div>
  );
};
