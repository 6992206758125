import * as React from "react";
import { styled } from "@mui/material/styles";

import { Box, Tab, Tabs } from "@mui/material";

function CustomTabPanel({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanelHeader({
  tabsHeaders,
  tabsContents,
  setCurrentTab,
  defaultTab = null,
}) {
  if (!defaultTab) {
    defaultTab = 0;
  }
  const [value, setValue] = React.useState(defaultTab);

  const handleChange = (event, newValue) => {
    setCurrentTab(tabsHeaders[newValue].key);
    setValue(newValue);
  };

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTab-root": {
      color: "#6A707B",
      fontWeight: "500",
      fontSize: "15px",
      lineHeight: "24px",
      textTransform: "none",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#061123",
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={value} onChange={handleChange}>
          {tabsHeaders.map((info, index) => {
            return <Tab label={info.label} key={index} {...a11yProps(index)} />;
          })}
        </StyledTabs>
      </Box>
      {tabsContents.map((label, index) => {
        return (
          <CustomTabPanel value={value} index={index} key={index}>
            {label}
          </CustomTabPanel>
        );
      })}
    </Box>
  );
}

export default TabPanelHeader;
