import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "@/components/content/Portlet";
import { BarPart } from "@/pages/awareness/dashboard/BarPart";
import { BackendSuspense } from "@/pages/awareness/dashboard/BackendSuspense";
import { HeaderBox } from "@/utils/styled-components";
import SplitButton from "@/pages/awareness/progress/SplitButton";
import React, { useState } from "react";
import RestartDropOffConfirmationDialog from "@/pages/awareness/progress/RestartDropOffConfirmationDialog";

export const UserRepartitionStats = ({ data, isPending, error }) => {
  const onTrackColor = "#64C58B";
  const dropOffColor = "#6DBAE5";
  const notStartedColor = "#EFBC5B";
  const notSetupColor = "#DCE5EC";
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [target, setTarget] = useState("drop_off");
  const onClickRestart = (mode) => {
    setConfirmationDialogOpen(true);
    setTarget(mode);
  };

  return (
    <Portlet>
      <PortletHeader
        title="Current user repartition"
        toolbar={
          <HeaderBox>
            <SplitButton onClickRestart={onClickRestart} />
          </HeaderBox>
        }
      />
      <PortletBody>
        <RestartDropOffConfirmationDialog
          setConfirmationDialogOpen={setConfirmationDialogOpen}
          target={target}
          confirmationDialogOpen={confirmationDialogOpen}
        />
        <div className="d-flex justify-content-center align-items-center">
          <BackendSuspense
            data={data}
            error={error}
            isPending={isPending}
            hasData={data?.user_count}
          >
            <div className="w-100 d-flex">
              {data?.user_ongoing_count > 0 && (
                <BarPart
                  width={Math.round(data.user_ongoing_rate * 100)}
                  userCount={data.user_ongoing_count}
                  label="On track"
                  color={onTrackColor}
                  tooltip="Completed course or interacted with a course less than a month ago"
                />
              )}
              {data?.user_dropoff_count > 0 && (
                <BarPart
                  width={Math.round(data.user_dropoff_rate * 100)}
                  userCount={data.user_dropoff_count}
                  label="Late"
                  color={dropOffColor}
                  tooltip="Interacted with a course more than a month ago"
                />
              )}
              {data?.user_not_started_count > 0 && (
                <BarPart
                  width={Math.round(data.user_not_started_rate * 100)}
                  userCount={data.user_not_started_count}
                  label="Not started"
                  color={notStartedColor}
                  tooltip="Never interacted with any course"
                />
              )}
              {data?.user_not_set_up_count > 0 && (
                <BarPart
                  width={Math.round(data.user_not_set_up_rate * 100)}
                  userCount={data.user_not_set_up_count}
                  label="Not Set Up"
                  color={notSetupColor}
                  tooltip="Can’t receive any course"
                />
              )}
              {data?.user_ongoing_count <= 0 &&
              data?.user_dropoff_count <= 0 &&
              data?.user_not_started_count <= 0 &&
              data?.user_not_set_up_count <= 0
                ? "no data available"
                : ""}
            </div>
          </BackendSuspense>
        </div>
      </PortletBody>
    </Portlet>
  );
};
