import DropdownButton from "@/pages/threatsng/components/listview/buttons/DropdownButton";
import React from "react";

type FilterButtonsProps = {
  source: ThreatSource | null;
  riskLevel: BannerLevel | "none" | null;
  hasAttachments: boolean | null;
  setSource: (v: ThreatSource | null) => void;
  setRiskLevel: (v: BannerLevel | "none" | null) => void;
  setHasAttachments: (v: boolean | null) => void;
};

const FilterButtons = ({
  source,
  riskLevel,
  hasAttachments,
  setSource,
  setRiskLevel,
  setHasAttachments,
}: FilterButtonsProps) => {
  return (
    <>
      <DropdownButton
        items={[
          { label: "System", value: "system" },
          { label: "User", value: "user" },
          { label: "All", value: "all" },
        ]}
        label="Source"
        value={source || "all"}
        setValue={(v) => setSource(v === "all" ? null : (v as ThreatSource))}
      />{" "}
      <DropdownButton
        items={[
          { label: "No banner", value: "none" },
          { label: "yellow", value: "suspicious" },
          { label: "red", value: "very_suspicious" },
          { label: "All", value: "all" },
        ]}
        label="Banner level"
        value={riskLevel || "all"}
        setValue={(v) =>
          setRiskLevel(v === "all" ? null : (v as BannerLevel | "none"))
        }
      />{" "}
      <DropdownButton
        items={[
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
          { label: "All", value: "all" },
        ]}
        label="Has attachments"
        value={
          hasAttachments === null
            ? "all"
            : hasAttachments === true
              ? "yes"
              : "no"
        }
        setValue={(v) => setHasAttachments(v === "all" ? null : v === "yes")}
      />
    </>
  );
};

export default FilterButtons;
