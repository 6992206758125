import React from "react";
import {
  Modal,
  ModalContent,
  ModalTitle,
} from "../../../../components/modals/Modal";
import { Button } from "@mui/material";
import DeleteCourseModalStyle from "./DeleteCourseModal.module.css";
import { ButtonSpinner } from "@/components/Spinner";
import { useDeleteCourseByIdMutation } from "@/api";
import {
  selectCourseId,
  selectCurrentAvailableLanguages,
  selectCurrentLocalizedCourse,
} from "./courseSlice";
import { useSelector } from "react-redux";
import { languageDisplayName } from "@/utils/misc";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "material-ui-snackbar-provider";
import { selectIsMantraUser } from "@/utils/contexts/AuthSlice";

export function DeleteCourseModal({ show, close }) {
  const navigate = useNavigate();
  const courseId = useSelector(selectCourseId);
  const availableLanguages = useSelector(selectCurrentAvailableLanguages);
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  const [deleteCourse, { isLoading }] = useDeleteCourseByIdMutation();
  const snackbar = useSnackbar();
  const isMantraUser = useSelector(selectIsMantraUser);

  const clickDeleteCourse = async () => {
    if (isLoading) {
      return;
    }
    const response = await deleteCourse(courseId);
    // @ts-ignore
    if (response.error !== undefined) {
      snackbar.showMessage("Error unable to delete course");
    } else {
      snackbar.showMessage("Course successfully deleted");
      navigate("/awareness/courses");
    }
  };

  return (
    <Modal show={show} close={close}>
      <ModalTitle>
        <h3>Are you sure you want to delete this course ?</h3>
      </ModalTitle>
      <ModalContent className={DeleteCourseModalStyle.content}>
        <h3>{localizedCourse?.name}</h3>
        {availableLanguages.length > 0 ? (
          <>
            <p>
              This operation is irrevocable and will permanently delete all
              content of this course in the following languages (including
              drafts) :
            </p>
            <ul>
              {availableLanguages.map((language) => (
                <li key={language}>
                  {languageDisplayName(language, isMantraUser)}
                </li>
              ))}
            </ul>
          </>
        ) : (
          ""
        )}
        <div className={DeleteCourseModalStyle.buttonsPanel}>
          <Button variant="contained" color="primary" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="warning"
            disabled={isLoading}
            onClick={clickDeleteCourse}
          >
            <div className="d-flex align-items-center">
              {isLoading ? (
                <ButtonSpinner className="mr-2" color="error" />
              ) : (
                ""
              )}
              Delete this course
            </div>
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}
